import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import '../Public/css/headerdoc.scss';
import '../Public/css/headerresponsivedoc.scss';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import CustomButton from '../Components/CustomButton';
import ModalGlobal from '../Components/ModalGlobal';
import ErrorBoundary from '../Components/ErrorBoundary';
import Login from '../Components/Login';
import { withNamespaces } from 'react-i18next';
import { setToken, logOutApp, setUserCredentials } from '../../GlobalModules/Login/Actions/LoginAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams } from 'react-router-dom';
const channel = new BroadcastChannel('foobar');

function Header(props) {
    const { type } = useParams();
    const [loginModalFlag, setLoginModalFlag] = useState(false)
    const [isLogout, setisLogout] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false);
    const loginUserModal = () => {
        setLoginModalFlag(true)
    }
    const closeLoginUserModal = () => {
        setLoginModalFlag(false)
    }

    const loginFn = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
    }
    const registerFn = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/register`);
    }


    const logout = () => {
        const { logOutApp, history, setToken, setUserCredentials } = props;
        logOutApp(props.accessToken, props.token).then(() => {            
            setToken("", "", "", "").then(() => {
                setUserCredentials({})
            })
            setTimeout(() => {
                setisLogout(true)
            }, 1000)
        })
    }

    useEffect(() => {
        if (isLogout) {
            let obj = {
                isLogout: isLogout,
                allTabAction: "logoutAllTab"
            }
            channel.postMessage(obj);
        }
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [isLogout])

    const isSticky = (e) => {
        const header = document.querySelector('.bottom-header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    
    const goHome = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }

    const bookingHistoryFn = () => {
        setShowOverlay(false);
        props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }

    const creditNotesFn = () => {
        setShowOverlay(false);
        props.history.push(`/${localStorage.getItem('i18nextLng')}/credit_note`);
    }


    let user_profile_image = "";
    if (props.userCredentials && props.userCredentials.user_details && props.userCredentials.user_details.hasOwnProperty('profile_img_url')) {
        let parseObject = props.userCredentials.user_details.profile_img_url ? JSON.parse(props.userCredentials.user_details.profile_img_url) : "";
        if (parseObject != "" && parseObject.hasOwnProperty('img_url') && parseObject.img_url != "") {
            user_profile_image = parseObject.img_url
        }
    }
    let user_first_name = props.userCredentials && props.userCredentials.user_details && props.userCredentials.user_details.first_name
    let user_last_name = props.userCredentials && props.userCredentials.user_details && props.userCredentials.user_details.last_name

    const bookingConfirmation = () => {
        let bookingId = 3
        props.history.push(`/${localStorage.getItem('i18nextLng')}/payment_success/${bookingId}`);
    }


    const notfound = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/notfound`);
    }
    const manageAccount = () => {
        setShowOverlay(false);
        props.history.push(`/${localStorage.getItem('i18nextLng')}/manage_account`);
    }

    const changePassword = () => {
        setShowOverlay(false);
        props.history.push(`/${localStorage.getItem('i18nextLng')}/change_password`);
    }

    const handleOverlayToggle = () => {
        setShowOverlay(!showOverlay);
    };
    const handlePopoverExited = () => {
        setShowOverlay(false);
    };

    let url = window.location.href;
    let currentRouteName = url.split("/").pop();

    return (
        <div className={currentRouteName == 'home' ? "header-inner" : "header-inner"}>
            <div className='bottom-header'>
                <Navbar bg="primary" expand="md" className='nav_top'>
                    <Container>
                        <div className='innContainer'>
                            <Navbar.Brand href="#">
                                <div className='websiteLogoText'>
                                    <div className='websiteLogo'>
                                        <img src={require('../Public/images/stayingLogo.png')} className="sidelogo" />
                                    </div>
                                </div>
                            </Navbar.Brand>
                            <div className='abs-btn-panel'>
                                {Object.keys(props.userCredentials).length == 0 &&
                                    <CustomButton
                                        onClick={loginFn}
                                        className="login-btn btn btn-primary"
                                        children='Login / Signup'
                                        type="button"
                                    />}
                                {Object.keys(props.userCredentials).length > 0 &&
                                    <div className='logoutBox'>
                                        <div className='profileImg'>
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{`${user_first_name} ${user_last_name}`}</Tooltip>}>
                                                {user_profile_image != "" ?
                                                    <img className="user_icon" src={user_profile_image} /> :
                                                    <img src={require('../Public/images/usericon.png')} />}
                                            </OverlayTrigger>
                                        </div>
                                        <OverlayTrigger
                                            rootClose={true}
                                            trigger="click"
                                            placement="bottom"
                                            show={showOverlay}
                                            onToggle={handleOverlayToggle}
                                            onExited={handlePopoverExited}
                                            overlay={
                                                <Popover className="group_single_action_inner group_single_action_inner_header">
                                                    <Popover.Body>
                                                        <div className="group_action_row group_action_row_header">
                                                            {currentRouteName == 'home' ? <></> :
                                                                <button className="group_action_btn_popup" onClick={() => { goHome() }}>
                                                                    <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Home</span></span>
                                                                </button>
                                                            }
                                                            {Object.keys(props.userCredentials).length > 0 && !props.userCredentials.user_details.is_first_time &&
                                                                <>
                                                                    <button className="group_action_btn_popup" onClick={() => { bookingHistoryFn() }}>
                                                                        <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Booking History</span></span>
                                                                    </button>

                                                                    <button className="group_action_btn_popup" onClick={() => { creditNotesFn() }}>
                                                                        <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Credit Notes</span></span>
                                                                    </button>
                                                                </>
                                                            }

                                                            {Object.keys(props.userCredentials).length > 0 && !props.userCredentials.user_details.is_first_time &&
                                                                <button className="group_action_btn_popup" onClick={() => { manageAccount() }}>
                                                                    <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Manage Account</span></span>
                                                                </button>}

                                                            <button className="group_action_btn_popup" onClick={() => { changePassword() }}>
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Change Password</span></span>
                                                            </button>
                                                            <button className="group_action_btn_popup" onClick={() => { logout() }}>
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Log Out</span></span>
                                                            </button>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <button className='arrow-Down-btn'><i className="fa fa-caret-down" aria-hidden="true"></i></button>
                                        </OverlayTrigger>
                                    </div>
                                }
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </div>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={loginModalFlag}
                    onHide={closeLoginUserModal}
                    title="Sign in"
                    className="modalcustomize mondimension loginModal"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Confirm"
                    saveButtonClassName="btn btn-primary savebtn"
                    cancelShow={true}
                    body={
                        <>
                            <ErrorBoundary title="BookRoomModalContent Error">
                                <Login
                                    history={props.history}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
        </div>
    );
}


const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,
    };
}
export default connect(mapStateToProps, { setUserCredentials, logOutApp, setUserCredentials, setToken })(withNamespaces()(Header));
