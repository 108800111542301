import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../../../../Utility/Public/css/common.scss';
import '../../../../Utility/Public/css/commonresponsive.scss';
import '../Assets/css/paymentdoc.scss';
import '../Assets/css/paymentresponsivedoc.scss';
import BookingUserDetails from '../Components/BookingUserDetails';
import BookingPaymentDetails from '../Components/BookingPaymentDetails';
import PaymentPage from '../Pages/PaymentPage';
import CountryList from '../../../../Utility/Components/Country';
import ValidateUtility from '../../../../../src/Utility/ValidateUtility';
import { useParams } from 'react-router-dom';
import { paymentDetailsApi, createPaymentApi } from '../Controller/PaymentController';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Payment(props) {


  const [nextPageFlag, setNextPageFlag] = useState("2ndTab")
  const [selectedCountryCode, setSelectedCountryCode] = useState("in")
  const [selectedCountry, setSelectedCountry] = useState({ "label": "India", "value": "in" });


  const [userDetailsFormData, setUserDetailsFormData] = useState({
    "firstName": "Jane",
    "lastName": "Doe",
    "email": "jane.doe@mettletech.in",
    "contact": "918910236586",
    "address1": "354 Oyster Point Blvd",
    "description": "For hotel booking in kolkata",
    "city": "South San Francisco",
    "state": "CA",
    "zip": "94080",
    "country": "us",
  })

  const [errorUserDetailsFormData, setErrorUserDetailsFormData] = useState({
    "firstName": "",
    "lastName": "",
    "email": "",
    "contact": "",
    "address1": "",
    "description": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": "",
  })

  const { paymentDetailsHash } = useParams();
  const [paymentDetailsDataFromParam, setPaymentDetailsDataFromParam] = useState({})
  const [paymentDetailsData, setpaymentDetailsData] = useState("")
  const [userDetailsData, setUserDetailsData] = useState({})
  const [paymentTypeList, setPaymentTypeList] = useState({ "label": "Online", "value": "online" });
  const [selectedPaymentType, SetselectedPaymentType] = useState({ "label": "Online", "value": "online" });
  const [paymentAmountType, setPaymentAmountType] = useState('minimumPayment');
  const [paymentAmountTypeError, setPaymentAmountTypeError] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [paidAmountError, setPaidAmountError] = useState('');

  useEffect(() => {
    if (paymentDetailsHash) {
      let parsedPaymentDetailsHash = JSON.parse(atob(paymentDetailsHash))
      setPaymentDetailsDataFromParam(parsedPaymentDetailsHash)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(paymentDetailsDataFromParam).length > 0) {
      let nameSplit = paymentDetailsDataFromParam.customer_name.split(" ")
      let firstName = nameSplit[0]
      let lastName = nameSplit[nameSplit.length - 1]
      let contactNo = paymentDetailsDataFromParam.customer_phone.replace('+', '')
      setUserDetailsFormData({
        "firstName": `${firstName}`,
        "lastName": `${lastName}`,
        "email": `${paymentDetailsDataFromParam.customer_email.replace(/\+/g, '')}`,
        "contact": `${contactNo}`,
        "address1": "354 Oyster Point Blvd",
        "description": "For hotel booking in kolkata",
        "city": "South San Francisco",
        "state": "CA",
        "zip": "94080",
        "country": "us",
      })
    }
  }, [paymentDetailsDataFromParam])



  const handelChange = (e, type) => {

    let tempFormData = { ...userDetailsFormData };
    let tempErrorFormData = { ...errorUserDetailsFormData }

    if (type == "first_name") {
      var expr = /^[a-zA-Z0-9 ]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['firstName'] = ""
          tempFormData["firstName"] = ""
        } else {
          tempFormData["firstName"] = e.target.value
          tempErrorFormData['firstName'] = ""
        }
      }
    }

    if (type == "last_name") {
      var expr = /^[a-zA-Z0-9 ]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['lastName'] = ""
          tempFormData["lastName"] = ""
        } else {
          tempFormData["lastName"] = e.target.value
          tempErrorFormData['lastName'] = ""
        }
      }
    }

    if (type == "email") {
      if (e.target.value == "") {
        tempErrorFormData['email'] = ""
        tempFormData["email"] = ""
      } else {
        let emailValidate = ValidateUtility.validate_email(e.target.value);
        if (emailValidate) {
          tempFormData["email"] = e.target.value
          tempErrorFormData['email'] = ""
        } else {
          tempFormData["email"] = e.target.value
          tempErrorFormData['email'] = "Enter valid email"
        }
      }
    }

    if (type == "contact") {
      var expr = /^[0-9]*$/;

      if (!expr.test(e)) {

      } else {
        if (e == "") {
          tempErrorFormData['contact'] = ""
          tempFormData["contact"] = ""
        } else {
          let phone_number_length = e.length;
          let phoneValidate = false;
          if (phone_number_length >= 10 && phone_number_length <= 12) {
            phoneValidate = true;
          }
          if (phoneValidate) {
            tempFormData["contact"] = e
            tempErrorFormData['contact'] = ""
          } else {
            tempFormData["contact"] = e
            tempErrorFormData['contact'] = "Enter Correct Phone number"
          }
        }
      }
    }

    if (type == "address1") {
      var expr = /^[a-zA-Z0-9 ,/.-]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['address1'] = ""
          tempFormData["address1"] = ""
        } else {
          tempFormData["address1"] = e.target.value
          tempErrorFormData['address1'] = ""
        }
      }
    }
    if (type == "description") {
      var expr = /^[a-zA-Z0-9 ,/.-]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['description'] = ""
          tempFormData["description"] = ""
        } else {
          tempFormData["description"] = e.target.value
          tempErrorFormData['description'] = ""
        }
      }
    }

    if (type == "city") {
      var expr = /^[a-zA-Z0-9 ]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['city'] = ""
          tempFormData["city"] = ""
        } else {
          tempFormData["city"] = e.target.value
          tempErrorFormData['city'] = ""
        }
      }
    }
    if (type == "state") {
      var expr = /^[a-zA-Z0-9 ]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['state'] = ""
          tempFormData["state"] = ""
        } else {
          tempFormData["state"] = e.target.value
          tempErrorFormData['state'] = ""
        }
      }
    }
    if (type == "zip") {
      //var expr = /^[0-9]*$/;
      // if (!expr.test(e.target.value)) {

      // } else {
      if (e.target.value == "") {
        tempErrorFormData['zip'] = ""
        tempFormData["zip"] = ""
      } else {
        let zip_length = e.target.value.length;
        let zipValidate = false;
        if (zip_length == 6) {
          zipValidate = true;
        }
        if (zipValidate) {
          tempFormData["zip"] = e.target.value
          tempErrorFormData['zip'] = ""
        } else {
          tempFormData["zip"] = e.target.value
          tempErrorFormData['zip'] = ""
        }
      }
      //}
    }
    if (type == "country") {
      if (e) {
        tempErrorFormData['country'] = ""
        tempFormData["country"] = e.value.toLowerCase()
        tempFormData["contact1"] = ""
        tempFormData["contact2"] = ""
        setSelectedCountry(e)
        setSelectedCountryCode(e.value.toLowerCase().toString())
      } else {
        tempFormData["country"] = "in"
        tempErrorFormData['country'] = ""
        tempFormData["contact1"] = ""
        tempFormData["contact2"] = ""
        setSelectedCountry({ "label": "India", "value": "in" })
      }
    }

    setUserDetailsFormData(tempFormData)
    setErrorUserDetailsFormData(tempErrorFormData)
  }

  const userDetailsFormDataValidation = () => {
    let tempFormData = { ...userDetailsFormData }
    let tempErrorFormData = { ...errorUserDetailsFormData }
    let valid = true;

    if ((tempFormData.firstName).trim() == "") {
      tempErrorFormData['firstName'] = "Required field"
      valid = false
    }

    if ((tempFormData.lastName).trim() == "") {
      tempErrorFormData['lastName'] = "Required field"
      valid = false
    }

    if (tempFormData.email != "") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(tempFormData.email);
      if (emailValidate) {
        tempErrorFormData['email'] = ""
      } else {
        valid = false;
        tempErrorFormData['email'] = "Enter valid email"
      }
    } else {
      valid = false;
      tempErrorFormData['email'] = "Required field"
    }


    if (tempFormData.contact != "") {
      let phone_number_length = tempFormData.contact.length;
      let phoneValidate = false;

      if (phone_number_length >= 10 && phone_number_length <= 12) {
        phoneValidate = true;
      }
      if (phoneValidate) {
        tempErrorFormData['contact'] = ""
      } else {
        valid = false;
        tempErrorFormData['contact'] = "Enter valid contact number"
      }
    } else {
      valid = false;
      tempErrorFormData['contact'] = "Required field"
    }


    if ((tempFormData.address1).trim() == "") {
      valid = false;
      tempErrorFormData['address1'] = "Required field"
    }

    // if ((tempFormData.address1).trim() != "" && (tempFormData.description).trim() != "" && (tempFormData.address1).trim() == (tempFormData.description).trim()) {
    //   valid = false;
    //   tempErrorFormData['description'] = "Address can't be same"
    // }

    if ((tempFormData.city).trim() == "") {
      valid = false;
      tempErrorFormData['city'] = "Required field"
    }

    if ((tempFormData.state).trim() == "") {
      valid = false;
      tempErrorFormData['state'] = "Required field"
    }

    if (tempFormData.zip == "") {
      valid = false;
      tempErrorFormData['zip'] = "Required field"
    } else {
      let zip_length = tempFormData.zip.length;
      //let zipValidate = false;
      // if (zip_length == 6) {
      //   zipValidate = true;
      // }
      // if (zipValidate) {
      // } else {
      //   valid = false;
      //   tempErrorFormData['zip'] = "Enter valid Zip code"
      // }
    }

    if (tempFormData.country == "") {
      valid = false;
      tempErrorFormData['country'] = "Required field"
    }

    setErrorUserDetailsFormData(tempErrorFormData)
    return valid;
  }

  const formatingUserDetailsDataset = () => {
    let hash = {}
    let tempFormData = { ...userDetailsFormData };
    hash['first_name'] = (tempFormData.firstName).trim();
    hash['last_name'] = (tempFormData.lastName).trim();
    hash['name'] = (tempFormData.firstName).trim()+ " " + (tempFormData.lastName).trim();
    // hash['amount'] = paymentDetailsData.currency.toLowerCase() == "inr" ? parseFloat(paymentDetailsData.amount)  : parseFloat(paymentDetailsData.amount)
    hash['amount'] = ""
    //hash['currency'] = paymentDetailsData.currency.toLowerCase()
    hash['currency'] = "usd"
    hash['description'] = (tempFormData.description).trim()
    hash['line1'] = (tempFormData.address1).trim()
    hash['postal_code'] = tempFormData.zip
    hash['city'] = (tempFormData.city).trim()
    hash['state'] = (tempFormData.state).trim()
    hash['country'] = tempFormData.country.toUpperCase()

    hash['email'] = tempFormData.email
    hash['contact'] = tempFormData.contact

    return hash;
  }

  const onSubmitBookingUserDetails = () => {

    let vaildData = userDetailsFormDataValidation();
    if (vaildData) {
      if (paymentAmountType == "minimumPayment") {
        setPaidAmount(parseFloat(paymentDetailsDataFromParam.minimum_booking_amount).toFixed(2));
        setPaidAmountError("")
      }
      let dataSet = formatingUserDetailsDataset();
      setUserDetailsData(dataSet)
      setNextPageFlag("3rdTab")
    }

  }

  const onSubmitBookingPaymentDetails = () => {
    setNextPageFlag("4thTab")
  }

  const onClick2ndTab = () => {
    if (nextPageFlag == "3rdTab") {
      setNextPageFlag("2ndTab")
    }
  }

  const onClick3rdTab = () => {
    if (nextPageFlag == "4thTab") {
      setNextPageFlag("3rdTab")
    }
  }


  const handelChangePaymentMode = (e, type) => {
    const { checked } = e.target;

    if (type === "minimumPayment") {
      if (checked) {
        setPaymentAmountType('minimumPayment')
        setPaymentAmountTypeError("")
        setPaidAmountError("");

        setPaidAmount(parseFloat(paymentDetailsDataFromParam.minimum_booking_amount).toFixed(2))
      }
    }
    if (type === "fullPayment") {
      if (checked) {
        setPaymentAmountType('fullPayment')
        setPaymentAmountTypeError("")
        setPaidAmount(parseFloat(paymentDetailsDataFromParam.total_booking_amount).toFixed(2));
        setPaidAmountError("");
      }
    }
    if (type === "customPayment") {
      if (checked) {
        setPaymentAmountType('customPayment')
        setPaymentAmountTypeError("")
        setPaidAmount("");
        setPaidAmountError("");

      }
    }

    if (type == "paid_amount" && paymentAmountType == "customPayment") {
      var expr = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!expr.test(e.target.value)) {
      } else {
        if (e.target.value == "") {
          setPaidAmount("");
          setPaidAmountError("");
        } else {
          setPaidAmount(e.target.value);
          setPaidAmountError("");
        }
      }
    }

  }

  return (
    <div className='paymentBox'>
      <div className='container'>
        <div className='paymentInnerBox'>
          <div className='stepBoxes d-none d-lg-block d-md-block'>
            <div className='row'>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='stepBox active'>
                    <span><i>✓</i></span>
                    <strong>Your selection</strong>
                  </div>
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='stepBox '>
                    <span onClick={onClick2ndTab}>{nextPageFlag == "3rdTab" || nextPageFlag == "4thTab" ? <i>✓</i> : 2}</span>
                    <strong>Your details</strong>
                  </div>
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='stepBox'>
                    <span onClick={onClick3rdTab}>{nextPageFlag == "4thTab" ? <i>✓</i> : 3}</span>
                    <strong>Payment Details</strong>
                  </div>
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='stepBox'>
                    <span> 4 </span>
                    <strong>Final step</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='paymentBodyContent'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>
                <div className='leftBodyContent'>
                  <div className='bookingDetailsBox'>
                    <h3>Hotel Holiday Home</h3>
                    <div className='hotelAddress'>
                      <p>4 Shore Road, Plot 24, Economy Hotel Sector , New Digha, 721428 Digha, India</p>
                      <ul>
                        <li><i className="fa fa-television" aria-hidden="true"></i> tv</li>
                        <li><i className="fa fa-wifi" aria-hidden="true"></i> Wifi</li>
                        <li><i className="fa fa-car" aria-hidden="true"></i> Parking</li>
                        <li><i className="fa fa-bath" aria-hidden="true"></i> Swiming Pool</li>

                      </ul>
                    </div>
                  </div>
                  <div className='bookingDetailsBox'>
                    <h3>Your booking details</h3>
                    <div className='checkInOutBoxes'>
                      <div className='checkInOutBox'>
                        <h5>Check-in</h5>
                        <p>Tue 26 Dec 2023</p>
                        <p>From 12:00</p>
                      </div>
                      <div className='checkInOutBox'>
                        <h5>Check-out</h5>
                        <p>Wed 27 Dec 2023</p>
                        <p>Until 12:00</p>
                      </div>
                    </div>
                    <div className='totalLength'>
                      <p>Total length of stay:</p>
                      <p><strong>1 night </strong></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-8 col-md-8 col-sm-12'>
                <div className='userDetailsBox'>
                  {nextPageFlag == "2ndTab" ?
                    <>
                      <h3>Enter your details </h3>
                      <BookingUserDetails
                        onSubmitBookingUserDetails={onSubmitBookingUserDetails}
                        selectedCountryCode={selectedCountryCode}
                        selectedCountry={selectedCountry}
                        formData={userDetailsFormData}
                        errorFormData={errorUserDetailsFormData}
                        handelChange={handelChange}
                        CountryList={CountryList}
                      />
                    </>

                    : null}

                  {nextPageFlag == "3rdTab" ?
                    <BookingPaymentDetails
                      onSubmitBookingPaymentDetails={onSubmitBookingPaymentDetails}
                      paymentDetailsDataFromParam={paymentDetailsDataFromParam}
                      paymentTypeList={paymentTypeList}
                      selectedPaymentType={selectedPaymentType}
                      handelChangePaymentMode={handelChangePaymentMode}
                      paymentAmountType={paymentAmountType}
                      paidAmount={paidAmount}
                      paidAmountError={paidAmountError}
                    />
                    :
                    null
                  }
                  {/* {nextPageFlag == "4thTab" && Object.keys(userDetailsData).length > 0 && Object.keys(paymentDetailsDataFromParam).length > 0 && paidAmount ?
                    <PaymentPage
                      userDetailsData={userDetailsData}
                      paymentDetailsData={paymentDetailsDataFromParam}
                      paidAmount={paidAmount}
                    />
                    :
                    null
                  } */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token
  };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })(withRouter(Payment));

