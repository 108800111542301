import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import CustomButton from '../../../../Utility/Components/CustomButton';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import CustomInput from '../../../../Utility/Components/CustomInput';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';
import moment from 'moment';
import { paymentCreate, uploadScreenshot, getCreditPoint, paymentPriceDistribution, subscribApiCall } from '../Controller/HomeController';
import ValidateUtility from '../../../../Utility/ValidateUtility';
import Utility from "../../../../Utility/Utility";
import '../Assets/css/paymentmodaldoc.scss';

import { customerWorkStatus } from '../../../../Utility/Controller/UtilityController';

function PaymentModeModalContent(props) {
    const [paymentTypeList, setPaymentTypeList] = useState([{ "label": "Online", "value": 'online' }])
    const [selectedPaymentType, setSelectedPaymentType] = useState({ "label": "Online", "value": 'online' })
    const [selectedPaymentTypeError, setSelectedPaymentTypeError] = useState("")
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [selectedDateTimeError, setSelectedDateTimeError] = useState("");
    const [uploadFile, setUploadFile] = useState([]);
    const [addPreviewImage, setAddPreviewImage] = useState([]);
    const [addPreviewImageError, setAddPreviewImageError] = useState("");
    const [paymentAmountType, setPaymentAmountType] = useState('payTotal');
    const [paymentAmountTypeError, setPaymentAmountTypeError] = useState('');
    const [selectedImageName, setSelectedImageName] = useState("");
    const [paymentAmountDisableFlag, setPaymentAmountDisableFlag] = useState(true);
    const [issAvailableCreditPointIsInMinus, setIsAvailableCreditPointIsInMinus] = useState(false);
    const [isPaymentRevertNeeded, setIsPaymentRevertNeeded] = useState(false);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [imageData, setImageData] = useState([]);
    const [ifscAsMandatory, setIfscAsMandatory] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [paymentFormData, setPaymentFormData] = useState({
        "account_holder_name": "",
        "bank_name": "",
        "account_number": "",
        "ifsc_code": "",
        "branch_name": "",
        "upi_id": "",
        "transaction_id": "",
        "amount": "",
        "comments": "",
        "credit_reference_id": "",
        "available_credit_point": "",
        "credit_amount": "",
        "credit_point_vaild_through": "",
        "payment_details": [{
            "payment_details_amount": "",
            "payment_details_reference_id": "",
            "payment_details_transaction_date_time": "",
            "payment_details_screenshot": [],
            "payment_details_screenshot_name": ""
        }]
    })

    const [paymentFormDataError, setPaymentFormDataError] = useState({
        "account_holder_name": "",
        "bank_name": "",
        "account_number": "",
        "ifsc_code": "",
        "branch_name": "",
        "upi_id": "",
        "transaction_id": "",
        "amount": "",
        "comments": "",
        "credit_reference_id": "",
        "available_credit_point": "",
        "credit_amount": "",
        "credit_point_vaild_through": "",
        "payment_details": [{
            "payment_details_amount": "",
            "payment_details_reference_id": "",
            "payment_details_transaction_date_time": "",
            "payment_details_screenshot": [],
            "payment_details_screenshot_name": ""
        }]
    })
    const [selectedDateTimeForOnlinePayment, setSelectedDateTimeForOnlinePayment] = useState(null);
    // credit point form
    const [totalCreditPoint, setTotalCreditPoint] = useState("");
    const [creditAmountDisableFlag, setCreditAmountDisableFlag] = useState(true);
    const [isToday, setIsToday] = useState(true);
    const paymentDetailsRef = useRef(null);

    useEffect(() => {
        if (props.callFrom == 'subscription') {
            setPaymentAmountType("");
            setPaymentTypeList([{ "label": "Cash", "value": 'cash' }, { "label": "Online", "value": 'online' }]);
        }
    }, [props.callFrom, props.subscribeAmount])


    useEffect(() => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        if (paymentAmountType == "" && props.callFrom == 'subscription') {
            let subscribeAmountValue = props.subscribeAmount
            tempFormData = { ...tempFormData, "amount": subscribeAmountValue }
            tempFormData = {
                ...tempFormData, "payment_details": [{
                    "payment_details_amount": subscribeAmountValue,
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]
            }

            tempErrorFormData = { ...tempErrorFormData, "amount": "" }
            setSelectedDateTimeForOnlinePayment(null)
            setPaymentFormData(tempFormData)
            setPaymentFormDataError(tempErrorFormData)
        }
    }, [paymentAmountType])

    useEffect(() => {
    }, [paymentFormData])

    // Add PaymentDetails
    const handelChangePaymentModeModal = (e, type) => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
        if (type == 'paymentType') {
            if (e != null) {
                setSelectedPaymentType(e)
                setSelectedPaymentTypeError("")
                if (props.editedFlag) {

                } else {
                    if (e.value == "cash") {
                        tempFormData["comments"] = ""
                        setPaymentAmountType('payTotal');
                        setPaymentAmountDisableFlag(true)
                        tempFormData["amount"] = props.callFrom == 'subscription' ? props.subscribeAmount : Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
                        setSelectedDateTime(null)
                        tempErrorFormData["amount"] = ""
                        setSelectedDateTimeError("")
                    }
                    if (e.value == "online") {
                        tempFormData["account_holder_name"] = ""
                        tempFormData["bank_name"] = ""
                        tempFormData["account_number"] = ""
                        tempFormData["ifsc_code"] = ""
                        tempFormData["branch_name"] = ""
                        tempFormData["upi_id"] = ""
                        tempFormData["transaction_id"] = ""
                        tempFormData["comments"] = ""

                        tempFormData['payment_details'] = [{
                            "payment_details_amount": props.callFrom == 'subscription' ? props.subscribeAmount : Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2),
                            "payment_details_reference_id": "",
                            "payment_details_transaction_date_time": "",
                            "payment_details_screenshot": ""
                        }]
                        tempErrorFormData['payment_details'] = [{
                            "payment_details_amount": "",
                            "payment_details_reference_id": "",
                            "payment_details_transaction_date_time": "",
                            "payment_details_screenshot": ""
                        }]
                        tempErrorFormData["account_holder_name"] = ""
                        tempErrorFormData["bank_name"] = ""
                        tempErrorFormData["account_number"] = ""
                        tempErrorFormData["ifsc_code"] = ""
                        tempErrorFormData["branch_name"] = ""
                        tempErrorFormData["upi_id"] = ""
                        tempErrorFormData["transaction_id"] = ""
                        tempErrorFormData["amount"] = ""
                        tempErrorFormData["comments"] = ""
                        setSelectedDateTime(null)
                        setSelectedDateTimeForOnlinePayment(null)
                        setSelectedDateTimeError("")
                        setPaymentAmountType('payTotal');
                        setPaymentAmountDisableFlag(true)
                        tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
                    }
                    if (e.value == "creditpoint") {
                        tempFormData["credit_reference_id"] = ""
                        tempFormData["available_credit_point"] = ""
                        tempFormData["credit_amount"] = ""
                        tempFormData["comments"] = ""
                        setCreditAmountDisableFlag(true)
                        tempErrorFormData["credit_reference_id"] = ""
                        tempErrorFormData["available_credit_point"] = ""
                        tempErrorFormData["credit_amount"] = ""
                        tempErrorFormData["comments"] = ""
                    }
                }
            } else {
                setSelectedPaymentTypeError("Required field")
            }
        }

        if (type == "account_holder_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['account_holder_name'] = ""
                    tempFormData["account_holder_name"] = ""
                } else {
                    tempFormData["account_holder_name"] = e.target.value
                    tempErrorFormData['account_holder_name'] = ""
                }
            }
        }

        if (type == "bank_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['bank_name'] = ""
                    tempFormData["bank_name"] = ""
                } else {
                    tempFormData["bank_name"] = e.target.value
                    tempErrorFormData['bank_name'] = ""
                }
            }
        }

        if (type == "account_number") {
            if (e.target.value == "") {
                tempFormData['account_number'] = "";
                tempErrorFormData['account_number'] = ""
                if (isPaymentRevertNeeded) {
                    tempErrorFormData['ifsc_code'] = ""
                    setIfscAsMandatory(false)
                }
            } else {
                if (isPaymentRevertNeeded) {
                    setIfscAsMandatory(true)
                }
                let accountNumberValidate = ValidateUtility.validateBankAccountNumber(e.target.value);
                if (accountNumberValidate) {
                    tempFormData['account_number'] = e.target.value.toUpperCase();
                    tempErrorFormData['account_number'] = ""
                } else {
                    tempFormData['account_number'] = e.target.value.toUpperCase();
                    tempErrorFormData['account_number'] = props.t('bankAccountNumber')
                }
            }
        }

        if (type == "ifsc_code") {
            if (e.target.value == "") {
                tempFormData['ifsc_code'] = "";
                tempErrorFormData['ifsc_code'] = ""
            } else {
                let IFSCValidate = ValidateUtility.validateIFSC(e.target.value);
                if (IFSCValidate) {
                    tempFormData['ifsc_code'] = e.target.value.toUpperCase();
                    tempErrorFormData['ifsc_code'] = ""
                } else {
                    tempFormData['ifsc_code'] = e.target.value.toUpperCase();
                    tempErrorFormData['ifsc_code'] = props.t('bankIFSC')
                }
            }
        }

        if (type == "branch_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['branch_name'] = ""
                    tempFormData["branch_name"] = ""
                } else {
                    tempFormData["branch_name"] = e.target.value
                    tempErrorFormData['branch_name'] = ""
                }
            }
        }

        if (type == "upi_id") {
            tempFormData["upi_id"] = e.target.value
            tempErrorFormData['upi_id'] = ""
        }

        if (type == "transaction_id") {
            var expr = /^[a-zA-Z0-9-/]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['transaction_id'] = ""
                    tempFormData["transaction_id"] = ""
                } else {
                    tempFormData["transaction_id"] = e.target.value
                    tempErrorFormData['transaction_id'] = ""
                }
            }
        }

        if (type == "amount") {
            var expr = /^\d{0,8}(\.\d{0,2})?$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['amount'] = ""
                    tempFormData["amount"] = ""
                } else {
                    tempFormData["amount"] = e.target.value
                    tempErrorFormData['amount'] = ""
                }
            }
        }

        if (type == "credit_reference_id") {
            var expr = /^[a-zA-Z0-9-/]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['credit_reference_id'] = ""
                    tempFormData["credit_reference_id"] = ""
                    setTotalCreditPoint("")
                    tempFormData["available_credit_point"] = ""
                    tempFormData["credit_amount"] = ""
                    tempErrorFormData["available_credit_point"] = ""
                    tempErrorFormData["credit_amount"] = ""
                    setPaymentFormData(tempFormData)
                    setCreditAmountDisableFlag(true)
                    setIsAvailableCreditPointIsInMinus(false)
                } else {
                    tempFormData["credit_reference_id"] = e.target.value
                    tempErrorFormData['credit_reference_id'] = ""
                    setTotalCreditPoint("")
                    tempFormData["available_credit_point"] = ""
                    tempFormData["credit_amount"] = ""
                    tempErrorFormData["available_credit_point"] = ""
                    tempErrorFormData["credit_amount"] = ""
                    setPaymentFormData(tempFormData)
                    setCreditAmountDisableFlag(true)
                    setIsAvailableCreditPointIsInMinus(false)
                }
            }
        }

        if (type == "credit_amount") {
            var expr = /^\d{0,8}(\.\d{0,2})?$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['credit_amount'] = ""
                    tempFormData["credit_amount"] = ""
                    tempFormData["available_credit_point"] = parseFloat(totalCreditPoint).toFixed(2)
                } else {
                    let total_credit_point = parseFloat(totalCreditPoint);
                    let enteredValue = parseFloat(e.target.value);
                    let available_credit_point = (total_credit_point - enteredValue).toFixed(2);

                    tempFormData["credit_amount"] = e.target.value
                    tempFormData["available_credit_point"] = available_credit_point
                    tempErrorFormData['credit_amount'] = ""

                    if (tempFormData["available_credit_point"] < 0) {
                        setIsAvailableCreditPointIsInMinus(true)
                    } else {
                        setIsAvailableCreditPointIsInMinus(false)
                    }
                }
            }
        }

        if (type == "comments") {

            if (e.target.value == "") {
                tempErrorFormData['comments'] = ""
                tempFormData["comments"] = ""
            } else {
                tempFormData["comments"] = e.target.value
                tempErrorFormData['comments'] = ""
            }
        }

        setPaymentFormData(tempFormData)
        setPaymentFormDataError(tempErrorFormData)
    }

    useEffect(() => {

    }, [issAvailableCreditPointIsInMinus])

    useEffect(() => {

        if (Number(props.dueAmount) <= 0) {
            setShowWarning(true);
        }

        if (paymentAmountType == "payTotal") {
            let tempFormData = { ...paymentFormData };
            let tempErrorFormData = { ...paymentFormDataError }

            let tempPaymentDetails = [...tempFormData.payment_details];
            let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

            let tempPaymentDetailsSlice = tempPaymentDetails.slice();
            let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()


            tempPaymentDetailsSlice[0].payment_details_amount = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2);


            tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
            tempErrorFormData['amount'] = ""
            setPaymentFormData({
                ...tempFormData,
                payment_details: tempPaymentDetailsSlice
            });
            setPaymentFormDataError({
                ...tempErrorFormData,
                payment_details: tempPaymentDetailsErrorSlice
            });
        }
    }, [])

    useEffect(() => {
        if (paymentAmountType == "payMinimum") {
            let tempFormData = { ...paymentFormData };
            let tempErrorFormData = { ...paymentFormDataError }
            let tempPaymentDetails = [...tempFormData.payment_details];
            let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
            let tempPaymentDetailsSlice = tempPaymentDetails.slice();
            let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()
            tempPaymentDetailsSlice[0].payment_details_amount = props.minimumAmount;
            tempFormData["amount"] = props.minimumAmount
            tempErrorFormData['amount'] = ""
            setPaymentFormData({
                ...tempFormData,
                payment_details: tempPaymentDetailsSlice
            });

            setPaymentFormDataError({
                ...tempErrorFormData,
                payment_details: tempPaymentDetailsErrorSlice
            });
        }
        if (paymentAmountType == "payTotal") {
            let tempFormData = { ...paymentFormData };
            let tempErrorFormData = { ...paymentFormDataError }

            let tempPaymentDetails = [...tempFormData.payment_details];
            let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

            let tempPaymentDetailsSlice = tempPaymentDetails.slice();
            let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()


            tempPaymentDetailsSlice[0].payment_details_amount = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2);

            tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
            tempErrorFormData['amount'] = ""
            setPaymentFormData({
                ...tempFormData,
                payment_details: tempPaymentDetailsSlice
            });

            setPaymentFormDataError({
                ...tempErrorFormData,
                payment_details: tempPaymentDetailsErrorSlice
            });
        }
    }, [paymentAmountType])




    useEffect(() => {
        if (paymentAmountType == "payTotal") {
            let tempFormData = { ...paymentFormData };
            let tempErrorFormData = { ...paymentFormDataError }

            let tempPaymentDetails = [...tempFormData.payment_details];
            let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

            let tempPaymentDetailsSlice = tempPaymentDetails.slice();
            let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()


            tempPaymentDetailsSlice[0].payment_details_amount = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2);

            tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
            tempErrorFormData['amount'] = ""
            setPaymentFormData({
                ...tempFormData,
                payment_details: tempPaymentDetailsSlice
            });

            setPaymentFormDataError({
                ...tempErrorFormData,
                payment_details: tempPaymentDetailsErrorSlice
            });
        }
    }, [props.dueAmount])

    const handelChangeRadioButton = (e, type) => {
        const { checked } = e.target;
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }

        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

        let tempPaymentDetailsSlice = tempPaymentDetails.slice();
        let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()


        if (type === "payTotal") {
            tempPaymentDetailsSlice[0].payment_details_amount = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2);
            if (checked) {
                setPaymentAmountType('payTotal')
                setPaymentAmountTypeError("")
                tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
                tempErrorFormData['amount'] = ""
                setPaymentAmountDisableFlag(true)
                setImageData([])
                setUploadFile([])
                setSelectedDateTimeForOnlinePayment(null)
                tempFormData['payment_details'] = [{
                    "payment_details_amount": tempPaymentDetailsSlice[0].payment_details_amount,
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]
                tempErrorFormData['payment_details'] = [{
                    "payment_details_amount": "",
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]

            }
        }
        if (type === "payMinimum") {
            tempPaymentDetailsSlice[0].payment_details_amount = props.minimumAmount;
            if (checked) {
                setPaymentAmountType('payMinimum')
                setPaymentAmountTypeError("")
                tempFormData["amount"] = props.minimumAmount
                tempErrorFormData['amount'] = ""
                setPaymentAmountDisableFlag(true)
                setImageData([])
                setUploadFile([])
                setSelectedDateTimeForOnlinePayment(null)
                tempFormData['payment_details'] = [{
                    "payment_details_amount": tempPaymentDetailsSlice[0].payment_details_amount,
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]
                tempErrorFormData['payment_details'] = [{
                    "payment_details_amount": "",
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]

            }
        }
        if (type === "otherAmount") {
            if (checked) {
                setPaymentAmountType('otherAmount')
                setPaymentAmountTypeError("")
                tempFormData["amount"] = ""
                tempErrorFormData['amount'] = ""
                if (selectedPaymentType.value == "online") {
                    setPaymentAmountDisableFlag(true)
                } else {
                    setPaymentAmountDisableFlag(false)
                }
                setImageData([])
                setUploadFile([])
                setSelectedDateTimeForOnlinePayment(null)
                tempFormData['payment_details'] = [{
                    "payment_details_amount": "",
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]
                tempErrorFormData['payment_details'] = [{
                    "payment_details_amount": "",
                    "payment_details_reference_id": "",
                    "payment_details_transaction_date_time": "",
                    "payment_details_screenshot": ""
                }]
            }
        }

        setPaymentFormData(tempFormData)
        setPaymentFormDataError(tempErrorFormData)
    }

    const handelChangePaymentRevertCheckbox = (e) => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        const { checked } = e.target;
        if (checked) {
            setIsPaymentRevertNeeded(checked)
            if (tempFormData['account_number'] != "") {
                setIfscAsMandatory(true)
            } else {
                setIfscAsMandatory(false)
            }
        } else {
            setIsPaymentRevertNeeded(checked)
            setIfscAsMandatory(checked)
            tempErrorFormData['account_holder_name'] = ""
            tempErrorFormData['bank_name'] = ""
            tempErrorFormData['account_number'] = ""
            tempErrorFormData['ifsc_code'] = ""
        }
        setPaymentFormDataError(tempErrorFormData)
    }


    const handleDateTimeChange = (date) => {
        if (date != "" && date != null) {

            const currentDateTime = new Date();
            const selectedDateTime = new Date(date);
            // Check if the selected date has a time (hours, minutes, seconds)
            const hasTime = selectedDateTime.getHours() !== 0 || selectedDateTime.getMinutes() !== 0 || selectedDateTime.getSeconds() !== 0;
            if (!hasTime) {
                // If no time is selected, set it to the current time
                selectedDateTime.setHours(currentDateTime.getHours());
                selectedDateTime.setMinutes(currentDateTime.getMinutes());
                selectedDateTime.setSeconds(currentDateTime.getSeconds());
            }
            setSelectedDateTime(selectedDateTime);
            setSelectedDateTimeError("")
        }
    };

    const getCurrentTime = () => {
        const currentDate = new Date();
        return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes()
        );
    };

    const getBeginningOfDay = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
    };


    useEffect(() => {
        if (selectedDateTime) {
            setIsToday(selectedDateTime.toDateString() === getCurrentTime().toDateString())
        } else {
            setIsToday(true)
        }
    }, [selectedDateTime])

    useEffect(() => {
        if (selectedDateTimeForOnlinePayment) {
            setIsToday(selectedDateTimeForOnlinePayment.toDateString() === getCurrentTime().toDateString())
        } else {
            setIsToday(true)
        }
    }, [selectedDateTimeForOnlinePayment])

    const generateTimeList = () => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();

        const timeList = [];

        for (let h = 0; h <= hours; h++) {
            for (let m = 0; m < 60; m += 15) {
                const formattedHour = h < 10 ? `0${h}` : `${h}`;
                const formattedMinute = m === 0 ? '00' : `${m}`;

                const time = new Date(0, 0, 0, h, m);
                timeList.push({
                    value: `${formattedHour}:${formattedMinute}`,
                    label: `${formattedHour}:${formattedMinute}`,
                });
            }
        }

        return timeList;
    };


    const validationFromData = () => {
        let tempFormData = { ...paymentFormData }
        let tempErrorFormData = { ...paymentFormDataError }
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
        let valid = true
        if (selectedPaymentType.value == "cash") {
            if (props.callFrom != "subscription") {
                if (tempFormData.amount == "") {
                    tempErrorFormData['amount'] = "Required field"
                    valid = false
                }
            }
            if (props.callFrom != "subscription") {
                if (isNaN(parseFloat(tempFormData.amount)) || parseFloat(tempFormData.amount) <= 0) {
                    tempErrorFormData['amount'] = "Amount should be greater than 0"
                    valid = false
                }
            }
            if (selectedDateTime == "" || selectedDateTime == null) {
                setSelectedDateTimeError("Required field")
                valid = false
            }
            if (props.callFrom != "subscription") {
                if (paymentAmountType == "") {
                    valid = false;
                    setPaymentAmountTypeError("Required field")
                }
            }
            if (paymentAmountType == "otherAmount" && Number(parseFloat(tempFormData.amount)) < Number(parseFloat(props.minimumAmount))) {
                valid = false;
                if (props.callFrom == "quotation") {
                    Utility.toastNotifications(`Minimum amount is not paid yet , ${props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? Utility.getCurrencySymbol(props.currency) : ""} ${parseFloat(parseFloat(props.minimumAmount) - parseFloat(tempFormData.amount)).toFixed(2)} still need to be paid for conversion to booking`, "Error", "error")
                } else {
                    Utility.toastNotifications("The amount required should be either equal to or surpass the minimum amount", "Warning", "warning")
                }
            }
        }

        if (selectedPaymentType.value == "online") {
            if (isPaymentRevertNeeded) {
                if ((tempFormData.account_holder_name).trim() == "") {
                    tempErrorFormData['account_holder_name'] = "Required field"
                    valid = false
                }

                if (tempFormData.bank_name == "") {
                    tempErrorFormData['bank_name'] = "Required field"
                    valid = false
                }

                if (tempFormData.account_number == "") {
                    tempErrorFormData['account_number'] = "Required field"
                    valid = false
                }

                if (tempFormData.account_number != "" && tempFormData.ifsc_code == "") {
                    tempErrorFormData['ifsc_code'] = "Required field"
                    valid = false
                }

            } else {
                tempErrorFormData['account_holder_name'] = ""
                tempErrorFormData['bank_name'] = ""
                tempErrorFormData['account_number'] = ""
            }

            if (tempFormData.account_number != "") {
                let accountNumberValidate = ValidateUtility.validateBankAccountNumber(tempFormData.account_number);
                if (accountNumberValidate) { } else {
                    tempErrorFormData['account_number'] = props.t('bankAccountNumber')
                    valid = false
                }
            }

            if (tempFormData.ifsc_code != "") {
                let IFSCValidate = ValidateUtility.validateIFSC(tempFormData.ifsc_code);
                if (IFSCValidate) { } else {
                    tempErrorFormData['ifsc_code'] = props.t('bankIFSC')
                    valid = false
                }
            }

            let totalAmount = 0;
            const referenceIds = {};

            tempPaymentDetails.forEach((value, index) => {

                let found = false;

                if (props.callFrom != "subscription") {
                    if (value.payment_details_amount === "") {
                        tempPaymentDetailsError[index].payment_details_amount = "Required field";
                        valid = false;
                    } else {
                        totalAmount += parseFloat(value.payment_details_amount);
                        tempPaymentDetailsError[index].payment_details_amount = "";
                    }
                }

                if (props.callFrom != "subscription") {

                    if (isNaN(parseFloat(value.payment_details_amount)) || parseFloat(value.payment_details_amount) <= 0) {
                        tempPaymentDetailsError[index].payment_details_amount = "Amount should be greater than 0";
                        valid = false;
                    }
                }

                if (value.payment_details_reference_id === "") {
                    tempPaymentDetailsError[index].payment_details_reference_id = "Required field";
                    valid = false;
                } else {
                    tempPaymentDetailsError[index].payment_details_reference_id = "";
                }

                // Check duplicate reference id
                const referenceId = value.payment_details_reference_id.toLowerCase();
                if (referenceId != "") {
                    if (referenceIds[referenceId]) {
                        found = true;
                    } else {
                        referenceIds[referenceId] = true;
                    }
                    if (found) {
                        tempPaymentDetailsError[index].payment_details_reference_id = "Reference Id cannot be duplicate";
                        valid = false;
                    }
                }

                if (value.payment_details_transaction_date_time === "") {
                    tempPaymentDetailsError[index].payment_details_transaction_date_time = "Required field";
                    valid = false;
                } else {
                    tempPaymentDetailsError[index].payment_details_transaction_date_time = "";
                }
            })

            if (Number(totalAmount) > 0 && Number(parseFloat(totalAmount)) < Number(parseFloat(tempFormData.amount))) {
                valid = false
                Utility.toastNotifications("The amount required should be either equal to or surpass the payable amount", "Warning", "warning")
            }

            if (paymentAmountType == "") {
                valid = false;
                setPaymentAmountTypeError("Required field")
            }
            if (paymentAmountType == "otherAmount" && Number(parseFloat(tempFormData.amount)) < Number(parseFloat(props.minimumAmount))) {
                valid = false;
                if (props.callFrom == "quotation") {
                    Utility.toastNotifications(`Minimum amount is not paid yet , ${props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? Utility.getCurrencySymbol(props.currency) : ""} ${parseFloat(parseFloat(props.minimumAmount) - parseFloat(tempFormData.amount)).toFixed(2)} still need to be paid for conversion to booking`, "Error", "error")
                } else {
                    Utility.toastNotifications("The amount required should be either equal to or surpass the minimum amount", "Warning", "warning")
                }
            }

        }

        if (selectedPaymentType.value == "creditpoint") {
            if (tempFormData.credit_reference_id == "") {
                tempErrorFormData['credit_reference_id'] = "Required field"
                valid = false
            }

            if (tempFormData.credit_amount == "") {
                tempErrorFormData['credit_amount'] = "Required field"
                valid = false
            }

            if (parseFloat(tempFormData.credit_amount) <= 0) {
                tempErrorFormData['credit_amount'] = "Credit amount should be greater than 0"
                valid = false
            }

            if (selectedDateTime == "" || selectedDateTime == null) {
                setSelectedDateTimeError("Required field")
                valid = false
            }

            if (props.isFirstPayment && parseFloat(tempFormData.credit_amount) < parseFloat(props.minimumAmount)) {
                valid = false
                Utility.toastNotifications("There are not enough credit notes available for this transaction. Kindly utilize it at a later time or for another transaction", "Warning", "warning")
            }

        }

        if (tempFormData.upi_id != "") {
            let validUpi = Utility.validateUPI(tempFormData.upi_id);
            if (!validUpi) {
                valid = false
                tempErrorFormData['upi_id'] = "Please input valid upiId"
            }


        }

        setPaymentFormDataError({
            ...tempErrorFormData,
            payment_details: tempPaymentDetailsError
        });

        setPaymentFormDataError(tempErrorFormData)
        return valid
    }

    const formatingDataset = () => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
        let dataset = [];
        if (selectedPaymentType.value == "cash") {

            let hash = {}

            if (props.bookingMasterId != "" || props.bookingMasterId != null) {
                hash['booking_id'] = props.bookingMasterId;
            }
            if (paymentFormData.amount != "") {
                hash['amount'] = paymentFormData.amount;
            }
            if (selectedPaymentType.value != "") {
                hash['payment_mode'] = selectedPaymentType.value;
            }

            if (selectedDateTime != "" || selectedDateTime != null) {
                hash['payment_date'] = moment(selectedDateTime).format('YYYY-MM-DD HH:mm');
            }
            hash['comments'] = paymentFormData.comments;
            dataset.push(hash)
        }
        if (selectedPaymentType.value == "online") {
            let hash = {}
            tempPaymentDetails.map((val, idx) => {
                let hash = {}
                if (props.bookingMasterId != "" || props.bookingMasterId != null) {
                    hash['booking_id'] = props.bookingMasterId;
                }
                hash['payee_ac_holder_name'] = paymentFormData.account_holder_name;
                hash['bank_name'] = paymentFormData.bank_name;
                hash['payee_bank_ac_no'] = paymentFormData.account_number;
                hash['payee_bank_ifsc_code'] = paymentFormData.ifsc_code;
                hash['payee_bank_branch_name_code'] = paymentFormData.branch_name;
                hash['upi_id'] = paymentFormData.upi_id;
                hash['amount'] = val.payment_details_amount;
                hash['payment_ref_id'] = val.payment_details_reference_id;
                hash['payment_date'] = moment(val.payment_details_transaction_date_time).format('YYYY-MM-DD HH:mm');
                hash['comments'] = paymentFormData.comments;
                if (selectedPaymentType.value != "") {
                    hash['payment_mode'] = selectedPaymentType.value;
                }
                dataset.push(hash)
            })
        }

        if (selectedPaymentType.value == "creditpoint") {
            let hash = {}

            if (props.bookingMasterId != "" || props.bookingMasterId != null) {
                hash['booking_id'] = props.bookingMasterId;
            }

            if (props.branch_id != "" || props.branch_id != null) {
                hash['branch_id'] = props.branch_id.toString();
            }

            if (selectedPaymentType.value != "") {
                hash['payment_mode'] = selectedPaymentType.value;
            }

            if (paymentFormData.credit_reference_id != "") {
                hash['payment_ref_id'] = paymentFormData.credit_reference_id;
            }

            if (paymentFormData.credit_amount != "") {
                hash['amount'] = paymentFormData.credit_amount;
            }

            if (selectedDateTime != "" || selectedDateTime != null) {
                hash['payment_date'] = moment(selectedDateTime).format('YYYY-MM-DD HH:mm');
            }

            if (paymentFormData.credit_point_vaild_through != "") {
                hash['vaild_through'] = paymentFormData.credit_point_vaild_through;
            }

            if (props.customerId != "") {
                hash['customer_id'] = props.customerId.toString();
            }
            hash['comments'] = paymentFormData.comments;
            dataset.push(hash)
        }
        return dataset;
    }


    const transformResponse = (response) => {
        return response.map((item, index) => {
            const transformedPayments = item.data.payment_result_arr.map((paymentResult) => {
                return {
                    index: index,
                    success: true,
                    message: 'Transaction completed successfully',
                    data: {
                        id: paymentResult.id,
                        created_by: paymentResult.created_by,
                        payment_date: paymentResult.payment_date,
                        payment_json: paymentResult.payment_json,
                        booking_id: paymentResult.booking_id,
                        amount: paymentResult.amount,
                        payment_mode: paymentResult.payment_mode,
                        recipet_id: paymentResult.recipet_id,
                        payment_type: paymentResult.payment_type,
                        payment_status: paymentResult.payment_status,
                        payment_ref_id: paymentResult.payment_ref_id,
                        payment_card_number: paymentResult.payment_card_number,
                        cheque_number: paymentResult.cheque_number,
                        bank_name: paymentResult.bank_name,
                        payment_flow_type: paymentResult.payment_flow_type,
                        payee_bank_ac_no: paymentResult.payee_bank_ac_no,
                        payee_bank_ifsc_code: paymentResult.payee_bank_ifsc_code,
                        payee_ac_holder_name: paymentResult.payee_ac_holder_name,
                        payee_bank_branch_name_code: paymentResult.payee_bank_branch_name_code,
                        comments: paymentResult.comments,
                    },
                };
            });

            return transformedPayments;
        }).flat();
    };


    const confirmPayemntModeModalFn = () => {
        const { loaderStateTrue, loaderStateFalse } = props;

        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        let valid = validationFromData()

        if (valid) {
            if (props.callFrom == "booking") {
                let paymentDataset = formatingDataset();
                let bookingDataset = props.bookingDetailsDatasetWithoutPayment;
                bookingDataset[0]['payment_arr'] = paymentDataset
                let combinedData = bookingDataset
                loaderStateTrue();
                paymentPriceDistribution(combinedData).then((response) => {
                    if (response.length > 0) {
                        if (response[0].success) {
                            props.closePayModalSuccessFn();
                            if (response[0].data.hasOwnProperty('payment_result_arr') && response[0].data.payment_result_arr) {
                                let screenshotData =
                                    uploadScreenshotImage(transformResponse(response), "post")
                            }
                            Utility.toastNotifications(response[0].message, "Success", "success");
                            let dataset = {
                                [props.selected_branch?.branch_id]:
                                {
                                    type: "booking completed"
                                }
                            }
                            customerWorkStatus(dataset);

                        } else {
                            Utility.toastNotifications(response[0].message, "Error", "error");
                        }
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    console.error("************error*************", error)
                    if (error) {
                        //Utility.toastNotifications(error.message, "Error", "error");
                    }
                    loaderStateFalse();
                    if (error.message == "Network Error") {

                    }
                });
            } else if (props.callFrom == "subscription") {
                let dataSet;
                if (selectedPaymentType.value == "cash") {
                    dataSet = [{
                        org_id: props.subscribeHotelId,
                        subscription_type_id: props.subscription_type_id,
                        amount: props.subscribeAmount,
                        payment_mode: selectedPaymentType.value,
                        payment_date: moment(selectedDateTime).format('YYYY-MM-DD HH:mm'),
                        subscription_type: 'subscribe',
                    }]
                }
                if (selectedPaymentType.value == "online") {
                    dataSet = [{
                        org_id: props.subscribeHotelId,
                        subscription_type_id: props.subscription_type_id,
                        amount: props.subscribeAmount,
                        payment_mode: selectedPaymentType.value,
                        payment_date: moment(paymentFormData.payment_details[0].payment_details_transaction_date_time).format('YYYY-MM-DD HH:mm'),
                        payment_ref_id: moment(paymentFormData.payment_details[0].payment_details_reference_id).format('YYYY-MM-DD HH:mm'),
                        subscription_type: 'subscribe',
                    }]
                }
                loaderStateTrue();
                subscribApiCall(dataSet).then((response) => {
                    if (response.length > 0) {
                        if (response[0].success) {
                            props.closeallModal();
                            Utility.toastNotifications(response[0].message, "Success", "success");
                        } else {
                            Utility.toastNotifications(response[0].message, "Error", "error");
                        }
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    console.error("************error*************", error)
                    if (error) {
                    }
                    loaderStateFalse();
                    if (error.message == "Network Error") {

                    }
                });
            } else {
                if (props.callFrom == "quotation") {
                    let dataset = formatingDataset()
                    props.closeModalCallFromQuotationFn(dataset, imageData);
                } else {
                    let dataset = formatingDataset()
                    let type = 'post';
                    let id = ""
                    if (props.editedFlag) {
                        id = editedId
                        type = "patch";
                        dataset = dataset[0];
                    }
                    loaderStateTrue();
                    paymentCreate(dataset, id, type).then((response) => {
                        if (type == "post") {
                            if (response[0].success) {
                                if (selectedPaymentType.value == "cash") {
                                    resetFromData()
                                    tempFormData["comments"] = ""
                                    props.resetDataGridFnFromChild()
                                    setSelectedPaymentType({ "label": "Cash", "value": 'cash' })
                                    loaderStateFalse();
                                    Utility.toastNotifications(response[0].message, "Success", "success")
                                }

                                if (selectedPaymentType.value == "online") {
                                    if (uploadFile[0]) {
                                        uploadScreenshotImage(response, "post")
                                    } else {
                                        resetFromData();
                                        setIsPaymentRevertNeeded(false)
                                        setIfscAsMandatory(false)
                                        tempFormData["account_holder_name"] = ""
                                        tempFormData["bank_name"] = ""
                                        tempFormData["account_number"] = ""
                                        tempFormData["ifsc_code"] = ""
                                        tempFormData["branch_name"] = ""
                                        tempFormData["upi_id"] = ""
                                        tempFormData["comments"] = ""
                                        tempFormData['payment_details'] = [{
                                            "payment_details_amount": "",
                                            "payment_details_reference_id": "",
                                            "payment_details_transaction_date_time": "",
                                            "payment_details_screenshot": ""
                                        }]
                                        tempErrorFormData['payment_details'] = [{
                                            "payment_details_amount": "",
                                            "payment_details_reference_id": "",
                                            "payment_details_transaction_date_time": "",
                                            "payment_details_screenshot": ""
                                        }]
                                        props.resetDataGridFnFromChild();
                                        setSelectedPaymentType({ "label": "Online", "value": 'online' })
                                        loaderStateFalse()
                                        Utility.toastNotifications(response[0].message, "Success", "success")
                                    }
                                }

                                if (selectedPaymentType.value == "creditpoint") {
                                    resetFromData()
                                    tempFormData["credit_reference_id"] = ""
                                    tempFormData["available_credit_point"] = ""
                                    tempFormData["credit_amount"] = ""
                                    tempFormData["comments"] = ""
                                    props.resetDataGridFnFromChild()
                                    setSelectedPaymentType({ "label": "Credit Point", "value": 'creditpoint' })
                                    loaderStateFalse();
                                    Utility.toastNotifications(response[0].message, "Success", "success")
                                }
                                setPaymentFormData(tempFormData)
                                setPaymentFormDataError(tempErrorFormData)

                            } else {
                                loaderStateFalse();
                                Utility.toastNotifications(response[0].message, "Error", "error")
                            }
                        } else {
                            if (response.success) {
                                if (selectedPaymentType.value == "cash") {
                                    resetFromData();
                                    setSelectedPaymentType({ "label": "Cash", "value": 'cash' })
                                    closePaymentModeModalChild()
                                    props.resetDataGridFnFromChild()
                                    loaderStateFalse();
                                    Utility.toastNotifications(response.message, "Success", "success")
                                }

                                if (selectedPaymentType.value == "online") {
                                    if (uploadFile[0]) {
                                        uploadScreenshotImage([{ "data": { "id": editedId, "payee_ac_holder_name": paymentFormData.account_holder_name } }], "patch")
                                    } else {
                                        resetFromData();
                                        setSelectedPaymentType({ "label": "Online", "value": 'online' })
                                        closePaymentModeModalChild()
                                        props.resetDataGridFnFromChild()
                                        loaderStateFalse();
                                        Utility.toastNotifications(response.message, "Success", "success")
                                    }
                                }

                                if (selectedPaymentType.value == "creditpoint") {
                                    resetFromData();
                                    setSelectedPaymentType({ "label": "Credit Point", "value": 'creditpoint' })
                                    closePaymentModeModalChild()
                                    props.resetDataGridFnFromChild()
                                    loaderStateFalse();
                                    Utility.toastNotifications(response.message, "Success", "success")
                                }

                            } else {
                                loaderStateFalse();
                                Utility.toastNotifications(response.message, "Error", "error")
                            }
                        }
                    }).catch((error) => {
                        loaderStateFalse();
                    });
                }
            }

        }
    }

    const uploadScreenshotImage = (responseData, callFrom) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }

        let saveImageData = [];
        let uploadFileArray = [];
        let imageDetails = [];
        let payeeAccountHolderName = ""

        imageData.map((item, index) => {
            let responseObject = responseData.filter((ele) => {
                return ele.data.payment_ref_id == item.referenceId
            })
            imageDetails.push({ payment_id: responseObject[0].data.id })
            payeeAccountHolderName = responseObject[0].data.payee_ac_holder_name
            uploadFileArray.push(item.file)
        })

        let saveImageDataObj = {
            "type": 'payment_screensort',
            "payee_ac_holder_name": payeeAccountHolderName,
            "image_details": imageDetails
        }
        saveImageData.push(saveImageDataObj)

        const formData = new FormData();
        formData.append('formData', JSON.stringify(saveImageData));

        uploadFileArray.map((imgObj, index) => {
            formData.append('file', imgObj)
        })
        loaderStateTrue();
        uploadScreenshot(formData).then((response) => {
            if (response[0].success) {
                resetFromData();
                setIsPaymentRevertNeeded(false)
                setIfscAsMandatory(false)
                setImageData([])
                setUploadFile([])
                props.resetDataGridFnFromChild();
                setSelectedPaymentType({ "label": "Online", "value": 'online' })
                if (callFrom == "patch") {
                    closePaymentModeModalChild()
                    props.resetDataGridFnFromChild()
                }
                loaderStateFalse();
                Utility.toastNotifications(responseData[0].message, "Success", "success")
            } else {
                loaderStateFalse();
                Utility.toastNotifications(response[0].message, "Error", "error")
            }
        }).catch((error) => {
            loaderStateFalse();
        });
    }

    const imageUpload = (event) => {
        const file = event.target.files[0];
        let targetFileSplit = event.target.files[0].name.split('.');
        let lastElement = targetFileSplit.pop();
        if (lastElement == 'JPEG' || lastElement == 'jpeg' || lastElement == 'jpg' || lastElement == 'JPG' || lastElement == 'png' || lastElement == 'PNG') {
            const fsize = event.target.files[0].size;
            const filesize = Math.round((fsize / 1024));
            if (filesize >= 300) {
                Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
            } else {
                setUploadFile([file]);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setAddPreviewImage([reader.result]);
                }
                if (file) {
                    setSelectedImageName(file.name);
                    reader.readAsDataURL(file);
                }
                setAddPreviewImageError("")
            }
        } else {
            Utility.toastNotifications("Please upload JPG ,JPEG or PNG file format", "Warning", "warning");
        }

    }

    const resetFromData = (callFrom = "") => {
        if (callFrom == "closeModal") {
            setSelectedPaymentType({ "label": "Online", "value": 'online' })
        }

        setPaymentFormData({
            "account_holder_name": "",
            "bank_name": "",
            "account_number": "",
            "ifsc_code": "",
            "branch_name": "",
            "upi_id": "",
            "transaction_id": "",
            "amount": "",
            "comments": "",
            "credit_reference_id": "",
            "available_credit_point": "",
            "credit_amount": "",
            "credit_point_vaild_through": "",
            "payment_details": [{
                "payment_details_amount": "",
                "payment_details_reference_id": "",
                "payment_details_transaction_date_time": "",
                "payment_details_screenshot": ""
            }]
        })

        setPaymentFormDataError({
            "account_holder_name": "",
            "bank_name": "",
            "account_number": "",
            "ifsc_code": "",
            "branch_name": "",
            "upi_id": "",
            "transaction_id": "",
            "amount": "",
            "comments": "",
            "credit_reference_id": "",
            "available_credit_point": "",
            "credit_amount": "",
            "credit_point_vaild_through": "",
            "payment_details": [{
                "payment_details_amount": "",
                "payment_details_reference_id": "",
                "payment_details_transaction_date_time": "",
                "payment_details_screenshot": ""
            }]
        })

        setSelectedPaymentTypeError("")
        setSelectedDateTime(null);
        setSelectedDateTimeForOnlinePayment(null)
        setAddPreviewImage([]);
        setAddPreviewImageError("")
        setUploadFile([]);
        setAddPreviewImage([]);
        setSelectedImageName("");
        setSelectedDateTimeError("");
        setTotalCreditPoint("");
        setCreditAmountDisableFlag(true);
        setPaymentAmountDisableFlag(true);
        setPaymentAmountType('payTotal');
    }


    useEffect(() => {
        if (props.editedFlag) {
        }
    }, [props.editedFlag])


    const setPaymentModeModalEditData = () => {
        let dataSet = props.editedRowData
        if (dataSet != "") {
            if (dataSet.payment_mode == "cash") {

                setSelectedPaymentType({ "label": "Cash", "value": 'cash' })
                setPaymentFormData({
                    "amount": dataSet.hasOwnProperty('amount') && dataSet.amount != "" && dataSet.amount != null ? dataSet.amount : "",
                    "comments": dataSet.hasOwnProperty('comments') && dataSet.comments != "" && dataSet.comments != null ? dataSet.comments : "",
                    "account_holder_name": "",
                    "account_number": "",
                    "ifsc_code": "",
                    "branch_name": "",
                    "upi_id": "",
                    "transaction_id": "",
                    "credit_reference_id": "",
                    "credit_amount": ""
                })
                let transactionDateAndTime = dataSet.hasOwnProperty('payment_date') && dataSet.payment_date != "" && dataSet.payment_date != null ? new Date(dataSet.payment_date) : "";
                setSelectedDateTime(transactionDateAndTime)
            }

            if (dataSet.payment_mode == "online") {

                setSelectedPaymentType({ "label": "Online", "value": 'online' })
                setPaymentFormData({
                    "account_holder_name": dataSet.hasOwnProperty('payee_ac_holder_name') && dataSet.payee_ac_holder_name != "" && dataSet.payee_ac_holder_name != null ? dataSet.payee_ac_holder_name : "",
                    "bank_name": dataSet.hasOwnProperty('bank_name') && dataSet.bank_name != "" && dataSet.bank_name != null ? dataSet.bank_name : "",
                    "account_number": dataSet.hasOwnProperty('payee_bank_ac_no') && dataSet.payee_bank_ac_no != "" && dataSet.payee_bank_ac_no != null ? dataSet.payee_bank_ac_no : "",
                    "ifsc_code": dataSet.hasOwnProperty('payee_bank_ifsc_code') && dataSet.payee_bank_ifsc_code != "" && dataSet.payee_bank_ifsc_code != null ? dataSet.payee_bank_ifsc_code : "",
                    "branch_name": dataSet.hasOwnProperty('payee_bank_branch_name_code') && dataSet.payee_bank_branch_name_code != "" && dataSet.payee_bank_branch_name_code != null ? dataSet.payee_bank_branch_name_code : "",

                    //"upi_id":

                    "transaction_id": dataSet.hasOwnProperty('payment_ref_id') && dataSet.payment_ref_id != "" && dataSet.payment_ref_id != null ? dataSet.payment_ref_id : "",

                    "amount": dataSet.hasOwnProperty('amount') && dataSet.amount != "" && dataSet.amount != null ? dataSet.amount : "",
                    "comments": dataSet.hasOwnProperty('comments') && dataSet.comments != "" && dataSet.comments != null ? dataSet.comments : "",
                    "credit_reference_id": "",
                    "credit_amount": ""
                })
                let transactionDateAndTime = dataSet.hasOwnProperty('payment_date') && dataSet.payment_date != "" && dataSet.payment_date != null ? new Date(dataSet.payment_date) : "";
                setSelectedDateTime(transactionDateAndTime)

                if (dataSet.hasOwnProperty("payment_screensort") && dataSet.payment_screensort != null) {
                    setSelectedImageName(dataSet.payment_screensort != null && dataSet.payment_screensort != "" ? JSON.parse(dataSet.payment_screensort).file_name : "")
                }

                if (dataSet.hasOwnProperty("payment_screensort") && dataSet.payment_screensort != null) {
                    setAddPreviewImage([dataSet.payment_screensort != null && dataSet.payment_screensort != "" ? JSON.parse(dataSet.payment_screensort).img_url : require('../../../../Utility/Public/images/no-picture.jpg')])
                }
            }

            if (dataSet.payment_mode == "creditpoint") {
                setSelectedPaymentType({ "label": "Credit Point", "value": 'creditpoint' })

                setPaymentFormData({
                    "account_holder_name": "",
                    "bank_name": "",
                    "account_number": "",
                    "ifsc_code": "",
                    "branch_name": "",
                    "upi_id": "",
                    "transaction_id": "",
                    "amount": "",

                    "available_credit_point": "",
                    "comments": dataSet.hasOwnProperty('comments') && dataSet.comments != "" && dataSet.comments != null ? dataSet.comments : "",
                    "credit_reference_id": "",
                    "credit_amount": "",
                })
                setSelectedDateTime(null)
                setSelectedImageName("")
                setAddPreviewImage(require('../../../../Utility/Public/images/no-picture.jpg'))
            }

        }
    }


    const fetchCreditPoint = () => {
        const { loaderStateTrue, loaderStateFalse } = props;

        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        let valid = true
        if (tempFormData.credit_reference_id == "") {
            tempErrorFormData['credit_reference_id'] = "Required field"
            valid = false
        }
        setPaymentFormDataError(tempErrorFormData)
        if (valid) {
            let data = { "credit_point_ref_id": tempFormData['credit_reference_id'] };
            let filter = {}
            filter['filters'] = JSON.stringify(data);
            loaderStateTrue();
            getCreditPoint(filter).then((response) => {
                if (response.hasOwnProperty('success')) {
                    if (response.success) {
                        if (response.data.length > 0) {
                            setTotalCreditPoint(parseFloat(response.data[0].credit_points).toFixed(2))
                            tempFormData["available_credit_point"] = parseFloat(response.data[0].credit_points).toFixed(2)
                            if (parseFloat(props.dueAmount) >= parseFloat(response.data[0].credit_points)) {
                                tempFormData["credit_amount"] = parseFloat(response.data[0].credit_points).toFixed(2)
                            } else {
                                tempFormData["credit_amount"] = parseFloat(props.dueAmount).toFixed(2)
                            }
                            tempFormData["credit_point_vaild_through"] = response.data[0].vaild_through
                            tempErrorFormData["credit_amount"] = ""
                            setPaymentFormData(tempFormData)
                            setPaymentFormDataError(tempErrorFormData)
                            setCreditAmountDisableFlag(true)
                            setIsAvailableCreditPointIsInMinus(false)
                        } else {
                            setTotalCreditPoint("")
                            tempFormData["available_credit_point"] = ""
                            tempFormData["credit_amount"] = ""
                            setPaymentFormData(tempFormData)
                            setCreditAmountDisableFlag(true)
                            setIsAvailableCreditPointIsInMinus(false)
                            Utility.toastNotifications("No record found", "Error", "error")
                        }
                        loaderStateFalse();
                    } else {
                        setTotalCreditPoint("")
                        tempFormData["available_credit_point"] = ""
                        tempFormData["credit_amount"] = ""
                        setPaymentFormData(tempFormData)
                        setCreditAmountDisableFlag(true)
                        setIsAvailableCreditPointIsInMinus(false)
                        loaderStateFalse();
                        Utility.toastNotifications(response.message, "Warning", "warning")
                    }
                }
                if (response.status === 403) {
                    setTotalCreditPoint("")
                    tempFormData["available_credit_point"] = ""
                    tempFormData["credit_amount"] = ""
                    setPaymentFormData(tempFormData)
                    setCreditAmountDisableFlag(true)
                    setIsAvailableCreditPointIsInMinus(false)
                    loaderStateFalse();
                    Utility.toastNotifications(response.message, "Error", "error")
                }
                //loaderStateFalse();
            }).catch((error) => {
                loaderStateFalse();
                if (error.response.status === 400) {
                    setTotalCreditPoint("")
                    tempFormData["available_credit_point"] = ""
                    tempFormData["credit_amount"] = ""
                    setPaymentFormData(tempFormData)
                    setCreditAmountDisableFlag(true)
                    setIsAvailableCreditPointIsInMinus(false)
                    loaderStateFalse();
                    Utility.toastNotifications(error.response.data.message, "Error", "error")
                }
            });
        }

    }


    const closePaymentModeModalChild = () => {
        resetFromData("closeModal");
        props.closePaymentModeModalFnChild();
    }


    const paymentDetailsUi = () => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError }
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
        let arry = [];
        if (tempPaymentDetails.length > 0) {
            tempPaymentDetails.map((val, idx) => {
                arry.push(
                    <div className='col-md-12 loopRow' key={idx}>
                        <div className="row">
                            <div className='col-md-10'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName={props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? "Amount (" + Utility.getCurrencySymbol(props.currency) + ")" : "Amount"}
                                            errorLabel={tempPaymentDetailsError[idx].payment_details_amount}
                                            name="payment_details_amount"
                                            type="text"
                                            value={val.payment_details_amount}
                                            labelPresent={true}
                                            onChange={(e) => handelChangePaymentDetails(e, idx, 'payment_details_amount')}
                                            isDisabled={props.callFrom == 'subscription' ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Reference Id"
                                            errorLabel={tempPaymentDetailsError[idx].payment_details_reference_id}
                                            name="payment_details_reference_id"
                                            type="text"
                                            value={val.payment_details_reference_id || ""}
                                            labelPresent={true}
                                            onChange={(e) => handelChangePaymentDetails(e, idx, 'payment_details_reference_id')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <div className='input_field_inner dateTimePickerInner dateTimePickerInnerTop onlineDate'>
                                            <label className="input_label input_label_color">Transaction Date & Time <span>*</span></label>
                                            <ErrorBoundary title="CommonDateTimePicker Error">
                                                <CommonDateTimePicker
                                                    dateRange={false}
                                                    selected={val.payment_details_transaction_date_time}
                                                    onChange={(e) => handelChangePaymentDetails(e, idx, 'payment_details_transaction_date_time')}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}
                                                    dateFormat="dd-MM-yyyy HH:mm"
                                                    minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                                                    maxDate={new Date()}
                                                    placement="left"
                                                    minTime={getBeginningOfDay(new Date())}
                                                    maxTime={isToday ? new Date() : new Date(0, 0, 0, 23, 59, 59)}
                                                />

                                            </ErrorBoundary>
                                            <div className="col-md-12 errorClass error_div">{tempPaymentDetailsError[idx].payment_details_transaction_date_time}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 relativeBox">
                                <div className={tempPaymentDetails.length > 1 ? "screenshotBoxes rightSpace" : "screenshotBoxes"}>
                                    <div className='input_field_inner screenshot'>
                                        <div className="input_containers">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">Browse</button>
                                                <input type="file" name="myfile" accept={Utility.acceptFileType()}
                                                    onChange={(e) => handelChangePaymentDetails(e, idx, 'payment_details_screenshot')}
                                                />
                                                <span className="plusicon"><i className="fa fa-plus" aria-hidden="true"></i> <input type="file" name="myfile"
                                                    onChange={(e) => handelChangePaymentDetails(e, idx, 'payment_details_screenshot')}
                                                /></span>
                                            </div>
                                            <div className="canvas">
                                                <img src={imageData[idx] ? imageData[idx].image : require('../../../../Utility/Public/images/no-picture.jpg')} />
                                            </div>
                                        </div>

                                        <div className="col-md-12 errorClass error_div">{tempPaymentDetailsError[idx].payment_details_screenshot}</div>
                                    </div>

                                </div>
                                {tempPaymentDetails.length > 1 ?
                                    <span onClick={(e) => { reomovePaymentDetailsDataBlock(e, idx, val) }} className="trash_icon_btn"><i className="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                    : <div></div>
                                }
                            </div>

                        </div>
                        <div ref={paymentDetailsRef}></div>
                    </div>
                )
            })
        }
        return arry;
    }

    const handelChangePaymentDetails = (e, i, type) => {
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError };
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];
        let tempPaymentDetailsSlice = tempPaymentDetails.slice();
        let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()
        if (type == "payment_details_amount") {
            var expr = /^\d{0,8}(\.\d{0,2})?$/;
            if (!expr.test(e.target.value)) {
            } else {
                if (e.target.value === null || e.target.value === "") {
                    if (i === 0) {
                        tempPaymentDetailsSlice[i].payment_details_amount = "";
                        tempPaymentDetailsErrorSlice[i].payment_details_amount = "";
                        if (paymentAmountType == "payTotal") {
                            tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
                        }
                        if (paymentAmountType == "payMinimum") {
                            tempFormData["amount"] = props.minimumAmount
                        }

                        if (paymentAmountType === "otherAmount") {
                            let totalAmount = 0;
                            for (let u = 0; u < tempPaymentDetailsSlice.length; u++) {
                                totalAmount += parseFloat(Number(tempPaymentDetailsSlice[u].payment_details_amount) || 0);
                            }
                            tempFormData["amount"] = Number(totalAmount)
                        }

                        setTotalPayableAmount(tempFormData["amount"]);
                    } else {
                        if (paymentAmountType == "payTotal") {
                            tempFormData["amount"] = Number(parseFloat(props.dueAmount)) >= 0 ? Number(parseFloat(props.dueAmount)).toFixed(2) : Number(0).toFixed(2)
                        }
                        if (paymentAmountType == "payMinimum") {
                            tempFormData["amount"] = props.minimumAmount
                        }

                        if (paymentAmountType === "otherAmount") {
                            let deductedValue = parseFloat(Number(tempPaymentDetailsSlice[i].payment_details_amount) || 0);
                            if (!isNaN(deductedValue)) {
                                tempFormData["amount"] -= deductedValue;
                            }
                        }
                        tempPaymentDetailsSlice[i].payment_details_amount = "";
                        tempPaymentDetailsErrorSlice[i].payment_details_amount = "";
                        setTotalPayableAmount(tempFormData["amount"]);

                    }
                } else {
                    tempPaymentDetailsSlice[i].payment_details_amount = e.target.value;
                    tempPaymentDetailsErrorSlice[i].payment_details_amount = "";

                    if (paymentAmountType === "otherAmount") {
                        let totalAmount = 0;
                        for (let u = 0; u < tempPaymentDetailsSlice.length; u++) {
                            totalAmount += parseFloat(Number(tempPaymentDetailsSlice[u].payment_details_amount) || 0);
                        }
                        tempFormData["amount"] = Number(totalAmount);
                        setTotalPayableAmount(totalAmount);
                    }
                }
            }
        }

        if (type == "payment_details_reference_id") {
            var expr = /^[a-zA-Z0-9-/]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempPaymentDetailsSlice[i].payment_details_reference_id = ""
                    tempPaymentDetailsErrorSlice[i].payment_details_reference_id = ""
                } else {
                    tempPaymentDetailsSlice[i].payment_details_reference_id = e.target.value
                    tempPaymentDetailsErrorSlice[i].payment_details_reference_id = ""
                    var imgObj = imageData.find((img) => img.ind === i);
                    if (imgObj) {
                        imgObj.referenceId = e.target.value;
                    }
                }
            }
        }

        if (type == "payment_details_transaction_date_time") {
            if (e != "" && e != null) {
                const currentDateTime = new Date();
                const selectedDateTime = new Date(e);
                // Check if the dates are equal
                if (currentDateTime.getFullYear() === selectedDateTime.getFullYear() &&
                    currentDateTime.getMonth() === selectedDateTime.getMonth() &&
                    currentDateTime.getDate() === selectedDateTime.getDate()) {
                    if (selectedDateTime < currentDateTime) {
                        selectedDateTime.setHours(selectedDateTime.getHours());
                        selectedDateTime.setMinutes(selectedDateTime.getMinutes());
                        selectedDateTime.setSeconds(selectedDateTime.getSeconds());
                    } else {
                        selectedDateTime.setHours(currentDateTime.getHours());
                        selectedDateTime.setMinutes(currentDateTime.getMinutes());
                        selectedDateTime.setSeconds(currentDateTime.getSeconds());
                    }
                }

                const hasTime = selectedDateTime.getHours() !== 0 || selectedDateTime.getMinutes() !== 0 || selectedDateTime.getSeconds() !== 0;

                if (!hasTime) {
                    // If no time is selected, set it to the current time
                    selectedDateTime.setHours(currentDateTime.getHours());
                    selectedDateTime.setMinutes(currentDateTime.getMinutes());
                    selectedDateTime.setSeconds(currentDateTime.getSeconds());
                }

                setSelectedDateTimeForOnlinePayment(selectedDateTime)
                tempPaymentDetailsSlice[i].payment_details_transaction_date_time = selectedDateTime
                tempPaymentDetailsErrorSlice[i].payment_details_transaction_date_time = ""
            }
        }

        if (type == "payment_details_screenshot") {

            const fileInput = e.target;
            const storedFiles = e.target.files[0] // Store the files before clearing the input
            fileInput.value = '';

            const file = storedFiles;
            let targetFileSplit = file.name.split('.');
            let lastElement = targetFileSplit.pop();
            const allowedTypes = Utility.acceptFileType();
            Utility.checkFileType(file, allowedTypes)
                .then(results => {
                    if (results.isValid) {
                        const fsize = file.size;
                        const filesize = Math.round((fsize / 1024));
                        if (filesize >= 300) {
                            Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
                        } else {
                            setUploadFile([file]);
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                setImageData(prevImageData => {
                                    const newImageData = [...prevImageData];
                                    newImageData[i] = {
                                        image: reader.result,
                                        imageName: file.name,
                                        file,
                                        ind: i,
                                        referenceId: tempPaymentDetailsSlice[i].payment_details_reference_id
                                    };
                                    return newImageData;
                                });
                                tempPaymentDetailsSlice[i].payment_details_screenshot = [reader.result];
                            };
                            if (file) {
                                tempPaymentDetailsSlice[i].payment_details_screenshot_name = file.name
                                reader.readAsDataURL(file);
                            }
                            tempPaymentDetailsErrorSlice[i].payment_details_screenshot = ""
                        }
                    } else {
                        Utility.toastNotifications("File format is not supported, it should be in jpg, jpeg or png format", "Warning", "warning");
                    }
                })
                .catch(error => {
                    console.error("Error:", error);

                });
        }
        setPaymentFormData({
            ...tempFormData,
            payment_details: tempPaymentDetailsSlice
        });

        setPaymentFormDataError({
            ...tempErrorFormData,
            payment_details: tempPaymentDetailsErrorSlice
        });


    }

    const addPaymentDetailsDataBlock = () => {

        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError };

        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

        let tempPaymentDetailsSlice = tempPaymentDetails.slice();
        let tempPaymentDetailsErrorSlice = tempPaymentDetailsError.slice()

        let totalAmount = paymentAmountType === "payTotal" ? parseFloat(props.dueAmount) : parseFloat(props.minimumAmount)
        let paidAmount = 0

        tempPaymentDetailsSlice.map((item, idx) => {
            paidAmount += Number(parseFloat(item.payment_details_amount))
        })

        let finalPaidAmount = 0;

        if (paymentAmountType === "otherAmount") {
            finalPaidAmount = Number(0).toFixed(2)
        } else if (paymentAmountType === "payMinimum" && Number(parseFloat(props.minimumAmount)) <= 0) {
            finalPaidAmount = Number(0).toFixed(2)
        } else {
            finalPaidAmount = Number(Number(totalAmount) - Number(paidAmount)).toFixed(2)
            if (Number(paidAmount) > Number(totalAmount)) {
                finalPaidAmount = Number(0).toFixed(2)
            }
        }

        setPaymentFormData((prevPaymentFormData) => ({
            ...prevPaymentFormData,
            payment_details: [
                ...prevPaymentFormData.payment_details,
                {
                    payment_details_amount: finalPaidAmount,
                    payment_details_reference_id: "",
                    payment_details_transaction_date_time: "",
                    payment_details_screenshot: ""
                }
            ]
        }));

        setPaymentFormDataError((prevPaymentFormData) => ({
            ...prevPaymentFormData,
            payment_details: [
                ...prevPaymentFormData.payment_details,
                {
                    payment_details_amount: "",
                    payment_details_reference_id: "",
                    payment_details_transaction_date_time: "",
                    payment_details_screenshot: ""
                }
            ]
        }));


    }

    const reomovePaymentDetailsDataBlock = (e, i, val) => {
        e.preventDefault();
        let tempFormData = { ...paymentFormData };
        let tempErrorFormData = { ...paymentFormDataError };
        let tempPaymentDetails = [...tempFormData.payment_details];
        let tempPaymentDetailsError = [...tempErrorFormData.payment_details];

        setImageData(prevImageData => {
            const newImageData = [...prevImageData];
            newImageData.splice(i, 1);
            return newImageData;
        });

        tempPaymentDetails.splice(i, 1);
        tempPaymentDetailsError.splice(i, 1);

        if (paymentAmountType == "otherAmount") {
            let totalAmount = 0;
            for (let u = 0; u < tempPaymentDetails.length; u++) {
                totalAmount += parseFloat(Number(tempPaymentDetails[u].payment_details_amount) || 0);
            }
            tempFormData["amount"] = Number(totalAmount);
        }

        setPaymentFormData({
            ...tempFormData,
            payment_details: tempPaymentDetails
        });

        setPaymentFormDataError({
            ...tempErrorFormData,
            payment_details: tempPaymentDetailsError
        });
    };

    useEffect(() => {
        let tempFormData = { ...paymentFormData };
        let tempPaymentDetails = [...tempFormData.payment_details];
        if (tempPaymentDetails.length > 1) {
            if (paymentDetailsRef.current) {
                paymentDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [paymentFormData]);


    const handleCloseWarning = () => {
        setShowWarning(false);
    };

    return (
        <>
            <div className="gridcontainer">
                <div className="modalinnerbody">
                    <div className="innerbodydimension">
                        <div className="paymentModePaymentDetailsInner">
                            {showWarning && (
                                <div className="alert alert-warning alert-dismissible">
                                    <a className="close" onClick={handleCloseWarning} aria-label="close">
                                        &times;
                                    </a>
                                    <strong>Warning! </strong>You have no due amount. No further payment is required at this time.
                                </div>
                            )}
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className='col-md-6 paymentModePaymentDetails payToBox'>
                                        <fieldset>
                                            <legend><h2 className='customerPaymentDetailsLabel legendTitle'>Payment Info</h2></legend>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dropdowninnerbox">
                                                        <ErrorBoundary title="CustomInput Error">
                                                            <label>Payment Type <span>*</span></label>
                                                            <AutosuggestComponent
                                                                handleOnChange={(e) => handelChangePaymentModeModal(e, "paymentType")}
                                                                options={paymentTypeList}
                                                                selectedValue={selectedPaymentType}
                                                                name=''
                                                                isMulti={false}
                                                                placeholder=""
                                                                isDisabled={false}
                                                                isSearchable={true}
                                                                menuPosition="fixed"
                                                            />
                                                        </ErrorBoundary>
                                                        <div className="col-md-12 errorClass error_div">{selectedPaymentTypeError}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 payableAmountBox">
                                                    <CustomInput
                                                        parentClassName="input_field_inner"
                                                        labelName={props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? "Payable Amount (" + Utility.getCurrencySymbol(props.currency) + ")" : "Payable Amount"}
                                                        //errorLabel={paymentFormDataError.amount}
                                                        name="amount"
                                                        type="text"
                                                        value={paymentFormData.amount}
                                                        labelPresent={true}
                                                        requiredStar={false}
                                                        isDisabled={paymentAmountDisableFlag}
                                                    //onChange={(e) => handelChangePaymentModeModal(e, 'amount')}
                                                    />
                                                </div>
                                            </div>
                                            {selectedPaymentType.value == "online" ?
                                                <>
                                                    {props.callFrom != 'subscription' ?
                                                        <div className="row">
                                                            {/* // new code */}

                                                            <div className='col-md-12'>

                                                                <div className='paymentModeBox'>

                                                                    <label className="checkboxcontainer">Pay Total
                                                                        <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payTotal")} checked={paymentAmountType == "payTotal" ? true : false} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    {
                                                                        props.isFirstPayment ?
                                                                            <label className="checkboxcontainer">Pay Minimum
                                                                                <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payMinimum")} checked={paymentAmountType == "payMinimum" ? true : false} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            :
                                                                            null
                                                                    }
                                                                    <label className="checkboxcontainer fullWidth">Enter Other Amount
                                                                        <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "otherAmount")} checked={paymentAmountType == "otherAmount" ? true : false} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <div className="col-md-12 errorClass error_div">{paymentAmountTypeError}</div>
                                                                </div>

                                                            </div>

                                                            {/* // end new code */}
                                                        </div> : null}
                                                </> : null}
                                        </fieldset>

                                    </div>

                                    {
                                        selectedPaymentType.value == "online" && props.callFrom != 'subscription' ?
                                            <div className="col-md-6 paymentModePaymentDetails payToBox">
                                                <fieldset>
                                                    <legend><h2 className='customerPaymentDetailsLabel legendTitle'>Pay To</h2></legend>
                                                    <div className={props.callFrom == "booking" ?
                                                        props.priceDistributionResponseDataFromReducer.branch_details.qr_img_url ? 'tableBox tableBoxFlex' : 'tableBox'
                                                        : props.bankInfoForPayments.BookingMasterBranchData.qr_img_url ? 'tableBox tableBoxFlex' : 'tableBox'}>

                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    Account Number
                                                                </td>
                                                                <td>: &nbsp;

                                                                    {
                                                                        props.callFrom == "booking" ?

                                                                            props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.hasOwnProperty('bank_details') && props.priceDistributionResponseDataFromReducer.branch_details.bank_details != null && JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).account_no ?
                                                                                JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).account_no : "-"
                                                                            :
                                                                            props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData.hasOwnProperty('bank_details') && props.bankInfoForPayments.BookingMasterBranchData.bank_details != null && JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).account_no ?
                                                                                JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).account_no : "-"

                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    IFSC Code
                                                                </td>
                                                                <td>: &nbsp;
                                                                    {
                                                                        props.callFrom == "booking" ?

                                                                            props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.hasOwnProperty('bank_details') && props.priceDistributionResponseDataFromReducer.branch_details.bank_details != null && JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).ifsc_code ?
                                                                                JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).ifsc_code : "-"
                                                                            :

                                                                            props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData.hasOwnProperty('bank_details') && props.bankInfoForPayments.BookingMasterBranchData.bank_details != null && JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).ifsc_code ?
                                                                                JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).ifsc_code : "-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Bank Name
                                                                </td>
                                                                <td>: &nbsp;
                                                                    {
                                                                        props.callFrom == "booking" ?

                                                                            props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.hasOwnProperty('bank_details') && props.priceDistributionResponseDataFromReducer.branch_details.bank_details != null && JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).bank ?
                                                                                JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).bank : "-"

                                                                            :
                                                                            props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData.hasOwnProperty('bank_details') && props.bankInfoForPayments.BookingMasterBranchData.bank_details != null && JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).bank ?
                                                                                JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).bank : "-"

                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Branch Name
                                                                </td>
                                                                <td>: &nbsp;
                                                                    {props.callFrom == "booking" ?

                                                                        props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.hasOwnProperty('bank_details') && props.priceDistributionResponseDataFromReducer.branch_details.bank_details != null && JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).branch ?
                                                                            JSON.parse(props.priceDistributionResponseDataFromReducer.branch_details.bank_details).branch : "-"
                                                                        :
                                                                        props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData.hasOwnProperty('bank_details') && props.bankInfoForPayments.BookingMasterBranchData.bank_details != null && JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).branch ?
                                                                            JSON.parse(props.bankInfoForPayments.BookingMasterBranchData.bank_details).branch : "-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    UPI Id
                                                                </td>
                                                                <td>: &nbsp;
                                                                    {props.callFrom == "booking" ?

                                                                        props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.upi_id ?
                                                                            props.priceDistributionResponseDataFromReducer.branch_details.upi_id : "-"
                                                                        :

                                                                        props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData != null && props.bankInfoForPayments.BookingMasterBranchData.upi_id ?
                                                                            props.bankInfoForPayments.BookingMasterBranchData.upi_id : "-"

                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        {

                                                            props.callFrom == "booking" ?

                                                                props.priceDistributionResponseDataFromReducer && props.priceDistributionResponseDataFromReducer.hasOwnProperty('branch_details') && props.priceDistributionResponseDataFromReducer.branch_details != null && props.priceDistributionResponseDataFromReducer.branch_details.qr_img_url ?
                                                                    <table>
                                                                        <tr>
                                                                            <td>
                                                                                <label>QR Code</label>
                                                                                <div className="canvas">
                                                                                    <a href={props.priceDistributionResponseDataFromReducer.branch_details.qr_img_url} title='Download QR'>
                                                                                    <img src={props.priceDistributionResponseDataFromReducer.branch_details.qr_img_url} /></a>
                                                                                </div>

                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    : null
                                                                :
                                                                props.bankInfoForPayments && props.bankInfoForPayments.hasOwnProperty('BookingMasterBranchData') && props.bankInfoForPayments.BookingMasterBranchData != null && props.bankInfoForPayments.BookingMasterBranchData.qr_img_url ?
                                                                    <table>
                                                                        <tr>
                                                                            <td>
                                                                                <label>QR Code</label>
                                                                                <div className="canvas">
                                                                                    <img src={props.bankInfoForPayments.BookingMasterBranchData.qr_img_url} />
                                                                                </div>

                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    : null

                                                        }
                                                    </div>


                                                </fieldset>

                                            </div>
                                            : null
                                    }
                                    {
                                        selectedPaymentType.value == "creditpoint" && props.isFirstPayment ?
                                            <div className="col-md-6">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Minimum Payable Amount"
                                                    //errorLabel={paymentFormDataError.credit_reference_id}
                                                    name="minimum_payable_amount"
                                                    type="text"
                                                    value={props.minimumAmount}
                                                    labelPresent={true}
                                                    isDisabled={true}
                                                    requiredStar={false}
                                                //onChange={(e) => handelChangePaymentModeModal(e, 'credit_reference_id')}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            {
                                selectedPaymentType.value == "cash" ?
                                    <>
                                        <div className='col-md-12'>
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    {props.callFrom != 'subscription' ?
                                                        <div className='paymentModeBox'>

                                                            <label className="checkboxcontainer">Pay Total
                                                                <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payTotal")} checked={paymentAmountType == "payTotal" ? true : false} />
                                                                <span className="checkmark"></span>
                                                            </label>

                                                            {
                                                                props.isFirstPayment ?
                                                                    <label className="checkboxcontainer">Pay Minimum
                                                                        <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payMinimum")} checked={paymentAmountType == "payMinimum" ? true : false} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    :
                                                                    null
                                                            }

                                                            <label className="checkboxcontainer fullWidth">Enter Other Amount
                                                                <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "otherAmount")} checked={paymentAmountType == "otherAmount" ? true : false} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <div className="col-md-12 errorClass error_div">{paymentAmountTypeError}</div>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-md-6">
                                                    <CustomInput
                                                        parentClassName="input_field_inner"
                                                        labelName={props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? "Amount (" + Utility.getCurrencySymbol(props.currency) + ")" : "Amount"}
                                                        errorLabel={paymentFormDataError.amount}
                                                        name="amount"
                                                        type="text"
                                                        value={paymentFormData.amount}
                                                        labelPresent={true}
                                                        isDisabled={paymentAmountDisableFlag}
                                                        onChange={(e) => handelChangePaymentModeModal(e, 'amount')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='input_field_inner dateTimePickerInner dateTimePickerInnerTop'>
                                                        <label className="input_label input_label_color">Transaction Date & Time <span>*</span></label>
                                                        <ErrorBoundary title="CommonDateTimePicker Error">
                                                            <CommonDateTimePicker
                                                                dateRange={false}
                                                                selected={selectedDateTime}
                                                                onChange={handleDateTimeChange}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={1}
                                                                dateFormat="dd-MM-yyyy HH:mm"
                                                                minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                                                                maxDate={new Date()}
                                                                minTime={getBeginningOfDay(new Date())}
                                                                maxTime={isToday ? new Date() : new Date(0, 0, 0, 23, 59, 59)}
                                                                placement="left"
                                                            //maxTime={selectedDateTime.toDateString() === getCurrentTime().toDateString() ? getCurrentTime() : undefined }
                                                            // minTime= {selectedDateTime? selectedDateTime.toDateString() === getCurrentTime().toDateString() ? generateTimeList() : undefined : undefined }
                                                            //minTime={getBeginningOfDay(new Date())} // Set minTime to the beginning of the day
                                                            //maxTime={getCurrentTime()}
                                                            //minTime={isToday ? null : getBeginningOfDay(selectedDateTime)} // Adjust minTime based on the selected date
                                                            //maxTime={isToday ? getCurrentTime() : new Date(0, 0, 0, 23, 59, 59)} // Adjust maxTime based on the selected date
                                                            // minTime={getBeginningOfDay(new Date())}
                                                            // maxTime={new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())}

                                                            />
                                                        </ErrorBoundary>
                                                        <div className="col-md-12 errorClass error_div">{selectedDateTimeError}</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <CustomInput
                                                        parentClassName="input_field_inner"
                                                        labelName="Comments"
                                                        errorLabel={paymentFormDataError.comments}
                                                        name="comments"
                                                        type="textarea"
                                                        value={paymentFormData.comments}
                                                        labelPresent={true}
                                                        requiredStar={false}
                                                        onChange={(e) => handelChangePaymentModeModal(e, 'comments')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : selectedPaymentType.value == "online" ?
                                        <>
                                            {/* {props.callFrom != 'subscription' ?
                                                <div className='col-md-12'>
                                                    <div className="row">

                                                        <div className='col-md-8'>
                                                            <div className='paymentModeBox'>

                                                                <label className="checkboxcontainer">Pay Total
                                                                    <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payTotal")} checked={paymentAmountType == "payTotal" ? true : false} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                {
                                                                    props.isFirstPayment ?
                                                                        <label className="checkboxcontainer">Pay Minimum
                                                                            <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "payMinimum")} checked={paymentAmountType == "payMinimum" ? true : false} />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        :
                                                                        null
                                                                }
                                                                <label className="checkboxcontainer fullWidth">Enter Other Amount
                                                                    <input type="radio" name="payment" onClick={(e) => handelChangeRadioButton(e, "otherAmount")} checked={paymentAmountType == "otherAmount" ? true : false} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                <div className="col-md-12 errorClass error_div">{paymentAmountTypeError}</div>
                                                            </div>
                                                        </div>







                                                    </div>
                                                </div>
                                                : null} */}

                                            <div className="paymentModePaymentDetails mobView">
                                                {props.callFrom != 'subscription' ?
                                                    <fieldset>
                                                        <legend><h2 className='customerPaymentDetailsLabel legendTitle'>Payment Details</h2></legend>
                                                        {paymentDetailsUi()}
                                                        {
                                                            props.isFirstPayment ?
                                                                <div className='col-md-12 rightSideBtn'>
                                                                    <button className='addEventButton' onClick={addPaymentDetailsDataBlock}>+ Add more payment</button>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </fieldset>
                                                    : null}
                                                {props.callFrom == 'subscription' ?
                                                    <>
                                                        {paymentDetailsUi()}
                                                    </>
                                                    : null}

                                            </div>
                                            {props.callFrom != 'subscription' ?
                                                <div className="paymentModeCustomerDetails">
                                                    <fieldset>
                                                        <legend><h2 className='customerDetailsLabel legendTitle'>Customer Payment Details</h2></legend>
                                                        <div className='col-md-12'>
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <div className='chkBoxPanel customPayment'>
                                                                        <label className="chkContainer">For booking cancellation, following details are required
                                                                            <input type="checkbox" checked={isPaymentRevertNeeded} onChange={(e) => { handelChangePaymentRevertCheckbox(e) }} /> 
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="Account Holder Name"
                                                                        errorLabel={paymentFormDataError.account_holder_name}
                                                                        name="account_holder_name"
                                                                        type="text"
                                                                        value={paymentFormData.account_holder_name}
                                                                        labelPresent={true}
                                                                        requiredStar={isPaymentRevertNeeded}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'account_holder_name')}
                                                                    />

                                                                </div>
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="Account Number"
                                                                        errorLabel={paymentFormDataError.account_number}
                                                                        name="account_number"
                                                                        type="text"
                                                                        value={paymentFormData.account_number}
                                                                        labelPresent={true}
                                                                        requiredStar={isPaymentRevertNeeded}
                                                                        onChange={(e) => { handelChangePaymentModeModal(e, "account_number") }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="IFSC Code"
                                                                        errorLabel={paymentFormDataError.ifsc_code}
                                                                        name="ifsc_code"
                                                                        type="text"
                                                                        value={paymentFormData.ifsc_code}
                                                                        labelPresent={true}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'ifsc_code')}
                                                                        requiredStar={ifscAsMandatory}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="Bank Name"
                                                                        errorLabel={paymentFormDataError.bank_name || ""}
                                                                        name="bank_name"
                                                                        type="text"
                                                                        value={paymentFormData.bank_name}
                                                                        labelPresent={true}
                                                                        requiredStar={isPaymentRevertNeeded}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'bank_name')}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="Branch Name"
                                                                        errorLabel={paymentFormDataError.branch_name}
                                                                        name="branch_name"
                                                                        type="text"
                                                                        value={paymentFormData.branch_name}
                                                                        labelPresent={true}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'branch_name')}
                                                                        requiredStar={false}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="UPI Id"
                                                                        errorLabel={paymentFormDataError.upi_id}
                                                                        name="upi_id"
                                                                        type="text"
                                                                        value={paymentFormData.upi_id}
                                                                        labelPresent={true}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'upi_id')}
                                                                        requiredStar={false}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </fieldset>
                                                </div>
                                                : null}


                                            <div className='col-md-12'>
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Comments"
                                                    errorLabel={paymentFormDataError.comments}
                                                    name="comments"
                                                    type="textarea"
                                                    value={paymentFormData.comments}
                                                    labelPresent={true}
                                                    requiredStar={false}
                                                    onChange={(e) => handelChangePaymentModeModal(e, 'comments')}
                                                />
                                            </div>

                                        </>
                                        :
                                        <>
                                            <div className="creditNoteDetails">
                                                <fieldset>
                                                    <legend><h2 className='creditNoteDetailsLabel legendTitle'>Credit Note Details</h2></legend>
                                                    <div className='col-md-12'>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className='paymentFlexBox'>
                                                                    <CustomInput
                                                                        parentClassName="input_field_inner"
                                                                        labelName="Credit Reference Id"
                                                                        errorLabel={paymentFormDataError.credit_reference_id}
                                                                        name="credit_reference_id"
                                                                        type="text"
                                                                        value={paymentFormData.credit_reference_id}
                                                                        labelPresent={true}
                                                                        onChange={(e) => handelChangePaymentModeModal(e, 'credit_reference_id')}
                                                                    />
                                                                    <button type="button" className={paymentFormDataError.credit_reference_id ? "modbtn topSpaceWithError" : "modbtn topSpace"} onClick={(e) => fetchCreditPoint(e)} >Check</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <CustomInput
                                                                    parentClassName={issAvailableCreditPointIsInMinus ? "input_field_inner available_credit_point_field" : "input_field_inner"}
                                                                    labelName="Available Credit Note"
                                                                    errorLabel={paymentFormDataError.available_credit_point}
                                                                    name="availableCreditPoint"
                                                                    type="text"
                                                                    value={paymentFormData.available_credit_point}
                                                                    labelPresent={true}
                                                                    isDisabled={true}
                                                                    requiredStar={false}
                                                                //onChange={(e) => handelChangePaymentModeModal(e, 'available_credit_point')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <CustomInput
                                                                    parentClassName="input_field_inner"
                                                                    labelName={props.currency != "" && Utility.getCurrencySymbol(props.currency) != null ? "Credit Amount (" + Utility.getCurrencySymbol(props.currency) + ")" : "Credit Amount"}
                                                                    errorLabel={paymentFormDataError.credit_amount}
                                                                    name="credit_amount"
                                                                    type="text"
                                                                    value={paymentFormData.credit_amount}
                                                                    labelPresent={true}
                                                                    //placeholder={creditAmountPlaceHolder}
                                                                    isDisabled={creditAmountDisableFlag}
                                                                    onChange={(e) => handelChangePaymentModeModal(e, 'credit_amount')}
                                                                />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className='input_field_inner dateTimePickerInner dateTimePickerInnerTop creditPointDate'>
                                                                    <label className="input_label input_label_color">Transaction Date & Time <span>*</span></label>
                                                                    <ErrorBoundary title="CommonDateTimePicker Error">
                                                                        <CommonDateTimePicker
                                                                            dateRange={false}
                                                                            selected={selectedDateTime}
                                                                            onChange={handleDateTimeChange}
                                                                            showTimeSelect
                                                                            timeFormat="HH:mm"
                                                                            timeIntervals={1}
                                                                            dateFormat="dd-MM-yyyy HH:mm"
                                                                            minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                                                                            maxDate={new Date()}
                                                                            placement="left"
                                                                            minTime={getBeginningOfDay(new Date())}
                                                                            maxTime={isToday ? new Date() : new Date(0, 0, 0, 23, 59, 59)}
                                                                        />
                                                                    </ErrorBoundary>
                                                                    <div className="col-md-12 errorClass error_div">{selectedDateTimeError}</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className='col-md-12'>
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Comments"
                                                    errorLabel={paymentFormDataError.comments}
                                                    name="comments"
                                                    type="textarea"
                                                    value={paymentFormData.comments}
                                                    labelPresent={true}
                                                    requiredStar={false}
                                                    onChange={(e) => handelChangePaymentModeModal(e, 'comments')}
                                                />
                                            </div>
                                        </>

                            }

                        </div>
                    </div>
                    <div className="col-md-12 text-center topspace">
                        <button type="button" className="modbtn" onClick={(e) => confirmPayemntModeModalFn()} >{props.editedFlag ? "Update" : "Confirm"}</button>
                        <button type="button" className="modbtn cancelbtn" onClick={(e) => { closePaymentModeModalChild() }}
                        >Cancel</button>
                    </div>
                </div >
            </div >
        </>
    )
}

PaymentModeModalContent.defaultProps = {
    editedRowData: {},
    editedFlag: false,
    editedId: "",
    bookingMasterId: "",
    dueAmount: "",
    minimumAmount: "",
    bookingDetailsDatasetWithoutPayment: null,
    isFirstPayment: false,
    callFrom: "",
    branch_id: "",
    closeModalCallFromQuotationFn: () => { },
    customerId: "",
    subscribeHotelId: "",
    subscribeAmount: "",
    subscription_type_id: ""
}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,
        selected_branch: globalState.mainReducerData.selected_branch,
        priceDistributionResponseDataFromReducer: globalState.mainReducerData.priceDistributionResponseData,

    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(PaymentModeModalContent));
