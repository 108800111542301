import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { paymentDetailsApi } from '../../V1/Modules/Home/Components/HotelBranch/Controller/HotelBranchController'
// import {
//     PaymentElement,
//     Elements,
//     ElementsConsumer,
// } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import '../Assets/css/paymentdoc.scss';

//const stripePromise = loadStripe(process.env.STRIPE_TOKEN);
// const options = {
//     // passing the client secret obtained from the server
//     clientSecret: 'pi_3OPePXSGWzbdc5dR14gMcpUE_secret_jhaj9EH5CTqvMEECee4fmCq3P',
// };

const options = {
    mode: 'payment',
    amount: 1,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
        /*...*/
    },
};


// const InjectedCheckoutForm = (props) => (
//     <ElementsConsumer>
//         {({ stripe, elements ,options}) => (
//             <CheckoutForm stripe={stripe} elements={elements} options={options} userDetailsData = {props.userDetailsData} paymentDetailsData= {props.paymentDetailsData} paidAmount= {props.paidAmount}/>
//         )}
//     </ElementsConsumer>
// );

class PaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentToken: "",
            isTokenExpired: false,
            paymentDetailsResponseData: {},
        }
        this.paymentTokenRef = React.createRef();
    }

    componentDidMount() {
        //this.updatePaymentToken();
    }

    // updatePaymentToken() {
    //     // const newPaymentToken = this.props.match.params.paymentToken;
    //     const newPaymentTokenHss = this.props.match.params.hss;
    //     const newPaymentTokenPss = this.props.match.params.pss;
    //     const newPaymentTokenSss = this.props.match.params.sss;
    //     const newPaymentToken = newPaymentTokenHss + '.' + newPaymentTokenPss + '.' + newPaymentTokenSss;
    //     if (this.paymentTokenRef.current !== newPaymentToken) {
    //         this.setState({
    //             paymentToken: newPaymentToken
    //         }, () => {
    //             this.verifyToken();
    //             this.paymentTokenRef.current = newPaymentToken;
    //         });
    //     }
    // }

    // verifyToken = () => {
    //     const { paymentToken } = this.state;
    //     try {
    //         const tokenParts = paymentToken.split('.');
    //         const payloadBase64 = tokenParts[1];
    //         const decodedPayload = JSON.parse(atob(payloadBase64));
    //         const isTokenExpired = decodedPayload.exp && decodedPayload.exp * 1000 < Date.now();
    //         if (!isTokenExpired) {
    //             this.setState({
    //                 isTokenExpired: true
    //             })
    //         } else {
    //             let tempDecodedPayload = { ...decodedPayload }
    //             if (tempDecodedPayload.hasOwnProperty('pay_request_id') && tempDecodedPayload.pay_request_id != null) {
    //                 let id = tempDecodedPayload.pay_request_id;
    //                 this.getPaymentDetailsApiFn(id);
    //             }
    //         }

    //     } catch (error) {
    //         console.error('Error decoding token========>', error);
    //     }
    // };

    // getPaymentDetailsApiFn = (id) => {
    //     const promise = new Promise((resolve, reject) => {
    //         const { loaderStateTrue, loaderStateFalse } = this.props;
    //         loaderStateTrue();
    //         paymentDetailsApi(id).then((response) => {
    //             if (response.success) {
    //                 this.setState({
    //                     paymentDetailsResponseData: response.data
    //                 })
    //                 if (response.data.hasOwnProperty('is_paid') && response.data.is_paid != null && response.data.is_paid == true) {
    //                     this.setState({
    //                         isTokenExpired: true
    //                     })
    //                 }
    //             }
    //             loaderStateFalse();
    //         }).catch((error) => {
    //             console.error("************error*************", error)
    //             if (error) {
    //             }
    //             loaderStateFalse();
    //             if (error.message == "Network Error") {
    //             }
    //         });
    //     })
    //     return promise;
    // }


    render() {
        return (

            <div className="paymentContainer">
                <div id="Checkout" className="inline">
                    {/* <h1>Pay Invoice</h1>
                    {this.state.isTokenExpired ? <h6 style={{ color: 'red' }}>The link has been expired .please contact with the adminstrator.</h6> : ""} */}


                    {/* <div className="form-group">
                            <label for="PaymentAmount">Payment amount</label>
                            <div className="amount-placeholder">
                                <span>{this.state.paymentDetailsResponseData.hasOwnProperty('currency') ? this.getCurrencySymbol(this.state.paymentDetailsResponseData.currency) + " " : ' '}</span>
                                <span>{this.state.paymentDetailsResponseData.hasOwnProperty('amount') ? this.state.paymentDetailsResponseData.amount : '0'}</span>
                            </div>
                        </div> */}
                        {/* <div className="form-group">
                            <Elements stripe={stripePromise} options={options} >
                                <InjectedCheckoutForm userDetailsData = {this.props.userDetailsData} paymentDetailsData= {this.props.paymentDetailsData} paidAmount= {this.props.paidAmount}/>
                            </Elements>
                        </div> */}


                </div>
            </div>



        );
    }
}

export default PaymentPage

