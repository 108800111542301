import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Assets/css/footerListdoc.scss';
import '../Assets/css/footerListresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu';

function PrivacyPolicy(props) {


    const history = useHistory();

    useEffect(() => {


    }, []);

    const homeMenuClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }



    return (

        <div className="aboutUsPage">
            <div className='footerBoxCover'>
                <div className='container'>
                    <div className="filterBox filterBoxMBottom">
                        <div className="leftSectionFilter">
                            <h2 className="pageTitle">Privacy Policy</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    PrivacyPolicy_Display={true}
                    homeMenuClick={homeMenuClick}
                    active_PrivacyPolicy={true}
                />

                <div className='aboutPageDetails'>
                <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <p>This Privacy Policy describes how The Staying collects, uses, and discloses your personal information when you use our website www.thestaying.com and the choices you have associated with that information.</p>
                            </div>
                            <div className='aboutbox'>
                                <h4>Information We Collect</h4>
                                <p>We collect information that you provide to us directly, such as when you create an account, make a booking, or contact us for support. This information may include your name, email address, phone number, payment information, and any other information you choose to provide.</p>
                                <p>We also collect information automatically when you use our Site, such as your IP address, browser type, device information, and cookies. This information is used to improve our services and provide a personalized experience for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                    <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">How We Use Your Information</h5>
                                 <div className="card-body">
                                    <p className="card-text">We use your information to:
                                    <br></br>
                                    &#x2022; Process your bookings and payments.
                                    &#x2022; Provide customer support.
                                    &#x2022; Improve our services and develop new features.
                                    &#x2022; Communicate with you about your bookings and updates to our services.
                                    &#x2022; Comply with legal requirements.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">Cookies</h5>
                                 <div className="card-body">
                                    <p className="card-text">We use cookies and similar technologies to provide and personalize our services, analyze performance, and deliver targeted ads. You can manage cookies through your browser settings, but disabling them may affect your experience on our Site.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <h4>Data Security</h4>
                                <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.</p>
                            </div>
                            <div className='aboutbox'>
                                <h4>Changes to This Policy</h4>
                                <p>We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our Site prior to the change becoming effective. Your continued use of our Site after the changes indicates your acceptance of the updated policy.</p>
                            </div>
                            <div className='aboutbox'>
                                <h4>Contact Us</h4>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at following Contact Information.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}





const mapStateToProps = (globalState) => {
    return {
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })
    (withNamespaces()(PrivacyPolicy));