import React, { Component, useState, useEffect, useRef } from 'react';
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/homedoc.scss';
import '../Assets/css/homeresponsivedoc.scss';
import CustomInput from '../../../../Utility/Components/CustomInput';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import moment from 'moment';
import { destinationList, filterPanelList } from '../Controller/HomeController';
import { loaderStateTrue, loaderStateFalse, selectChooseBranch, userSearchData, roleWisePermission, userSearchDataError, trendingDestination } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../../../../src/GlobalModules/Login/Actions/LoginAction';
import Select, { components } from "react-select";
import Utility from '../../../../Utility/Utility';
function SearchPanel(props) {
    const [searchingData, setSearchingData] = useState({
        destination: "",
        start_date: new Date(),
        end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        adult: 1,
        children: 0,
        children_age: [],
        selectedRoom: {},
        filterData: {}
    })
    const [searchingDataError, setSearchingDataError] = useState({
        destination: "",
        start_date: null,
        end_date: null,
        adult: 1,
        children: 0,
        children_age: [],
        selectedRoom: {},
        filterData: {}
    })

    const [selectedDestination, setSelectedDestination] = useState({});
    const [locationList, setLocationList] = useState([]);
    const [adultChildPlaceHolderText, setAdultChildPlaceHolderText] = useState("1 Adult - 0 Child");

    const [childrenAgeList, setChildrenAgeList] = useState({});
    const childAgeinputRef = useRef(null);
    const [isChildAgeNeed, setIsChildAgeNeed] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const myParam = useParams();
    const [searchSelectedLocationChangeFlag, setSearchSelectedLocationChangeFlag] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    //initially fetch filter panel options list
    useEffect(() => {
        getFilterPanelList()
    }, [])

    // fetch destinations list
    useEffect(() => {
        getDestinationList();
    }, [props.trendingDestinationData])


    //after state change fetch destinations list
    useEffect(() => {
        if (searchSelectedLocationChangeFlag) {
            getDestinationList();
        }
    }, [searchSelectedLocationChangeFlag])

    //when add more children smooth scroll on child age input section
    useEffect(() => {
        if (searchingData.children > 2) {
            if (childAgeinputRef.current) {
                childAgeinputRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [searchingData.children]);

    //filter panel list get
    const getFilterPanelList = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let data = {}
        filterPanelList(data).then((response) => {
            setChildrenAgeListFN(response.data.chaild_max_age)
        }).catch((error) => {
        });
    }

    //set children age list
    const setChildrenAgeListFN = (child_age_limit = 10) => {
        if (child_age_limit == 0) {
            child_age_limit = process.env.CHILD_AGE_LIMIT;
        }
        const newArray = [];
        for (let i = 1; i <= child_age_limit; i++) {
            newArray.push({ label: `${i} Year`, value: i });
        }
        setChildrenAgeList(newArray);
    }

    //fetch destination list api
    const getDestinationList = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let data = {};
        if (props.trendingDestinationData && props.trendingDestinationData.hasOwnProperty('type') && props.trendingDestinationData.type && props.trendingDestinationData.type == 'state' && !searchSelectedLocationChangeFlag) {
            data['type'] = 'state';
            data['type_name'] = props.trendingDestinationData?.location;
        }
        destinationList(data).then((response) => {
            let locationListData = [];
            if (response.data.length > 0) {
                response.data.map((e, i) => {
                    if (props.trendingDestinationData && props.trendingDestinationData.hasOwnProperty('type') && props.trendingDestinationData.type && props.trendingDestinationData.type !== 'trending') {
                        if (i == 0) {
                            let tempFormData = {};
                            tempFormData["destination"] = e
                            let reducerData = props.userSearchDataFromReducer
                            let tempSearchData = { ...reducerData, ...tempFormData }
                            props.userSearchData(tempSearchData)
                            setSelectedDestination({ "label": e, "value": e })
                        }
                    }
                    let branchListObj = {};
                    branchListObj['label'] = e;
                    branchListObj['value'] = e;
                    locationListData.push(branchListObj)
                })
            } else {
                let searchData = { ...searchingData }
                searchData = { ...searchData, 'destination': "" }
                setSearchingData(searchData)
                setSelectedDestination({})
            }

            if (locationListData.length > 0) {
                setLocationList(locationListData);
                if (props.callFrom == 'search' && searchSelectedLocationChangeFlag) {
                    setSelectedDestination(locationListData[0]);
                    let searchData = { ...searchingData }
                    searchData = { ...searchData, 'destination': locationListData[0].value }
                    setSearchingData(searchData)
                }
            } else {
                if (props.callFrom == 'search' && searchSelectedLocationChangeFlag) {
                    setSelectedDestination({})
                    let searchData = { ...searchingData }
                    searchData = { ...searchData, 'destination': '' }
                    setSearchingData(searchData)
                }
            }
            setSearchSelectedLocationChangeFlag(false)
        }).catch((error) => {
        });
    }

    //search panel input field set after some change
    useEffect(() => {
        let allAgesNotEmpty = searchingData.children_age.every(child => child.age !== "");
        if (allAgesNotEmpty) {
            setIsChildAgeNeed(false);
        } else {
            setIsChildAgeNeed(true);
            let children_age_error = [];
            if (searchingData.children_age.length > 0) {
                searchingData.children_age.forEach(child => {
                    if (child.age == "") {
                        children_age_error.push({ age: "Required field" });
                    } else {
                        children_age_error.push({ age: "" });
                    }
                });
            }
            setSearchingDataError(prevState => ({
                ...prevState,
                children_age: children_age_error
            }));
        }

        if (searchingData.destination == "") {
            setSearchingDataError(prevState => ({
                ...prevState,
            }));
        }
        if (searchingData.start_date == "" || searchingData.start_date == null) {
            setSearchingDataError(prevState => ({
                ...prevState,
                start_date: "Required field"
            }));
        }

        if (searchingData.end_date == "" || searchingData.end_date == null) {
            setSearchingDataError(prevState => ({
                ...prevState,
                end_date: "Required field"
            }));
        }
    }, [searchingData])


    //booking search data validation
    const validSearchDataFn = () => {
        const { loaderStateTrue, loaderStateFalse, userSearchDataFromReducer } = props;
        let tempFormData = { ...searchingData };
        let tempErrorFormData = { ...searchingDataError }
        let valid = true;

        if ((userSearchDataFromReducer && userSearchDataFromReducer.end_date != "" || userSearchDataFromReducer && userSearchDataFromReducer.end_date != null) && (userSearchDataFromReducer && userSearchDataFromReducer.start_date != "" || userSearchDataFromReducer && userSearchDataFromReducer.start_date != null)) {
            const startDate = new Date(userSearchDataFromReducer.start_date);
            const endDate = new Date(userSearchDataFromReducer.end_date);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const differenceInDays = timeDifference / (1000 * 3600 * 24);
            if (differenceInDays > 30) {
                valid = false;
                tempErrorFormData['end_date'] = "Required field";
                setSearchingDataError(tempErrorFormData)
                Utility.toastNotifications("Sorry, reservations for more than 30 nights are not possible.", "Warning", "warning");
            }
        }
        if (userSearchDataFromReducer && userSearchDataFromReducer.hasOwnProperty('end_date') && userSearchDataFromReducer.end_date == null) {
            valid = false
        }

        return valid;
    }


    //redux search data validate  
    useEffect(() => {
        let valid = validSearchDataFn()
        let allAgesNotEmpty = searchingData.children_age.every(child => child.age !== "");
        if (props.callFrom == "search" && allAgesNotEmpty && searchingData?.start_date && searchingData.start_date != "" && props.userSearchDataFromReducer?.start_date && props.userSearchDataFromReducer.start_date != "" && searchingData?.end_date && searchingData.end_date != "" && props.userSearchDataFromReducer?.end_date && props.userSearchDataFromReducer.end_date != "" && searchingData?.destination && searchingData.destination != "" && searchingData?.adult && searchingData.adult != "" && !showOverlay && valid) {
            let allAgesNotEmpty = props.userSearchDataFromReducer.children_age.every(child => child.age !== "");
            if (allAgesNotEmpty) {
                props.searchHotelBranch()
            } else {
                props.searchDateValidation()
            }
        }
        if (!valid) {
            props.searchDateValidation()
        }
    }, [props.userSearchDataFromReducer])

    //date range picker onblur event
    const handleBlur = () => {
        if (!searchingData.end_date && searchingData.start_date) {
            const nextDay = new Date(searchingData.start_date);
            nextDay.setDate(nextDay.getDate() + 1);
            handelChangeSearch([searchingData.start_date, nextDay], 'date');
        }
    };

    //clear state value
    const clearStateValue = (event, type) => {
        let trendingData = { 'location': '', 'type': '' }
        props.trendingDestination(trendingData);
        setSearchSelectedLocationChangeFlag(true)
    }

    //search panel inuts onchange event
    const handelChangeSearch = (event, type) => {
        let tempFormData = { ...searchingData };
        let tempErrorFormData = { ...searchingDataError }
        if (type == "search_destination") {
            if (event) {
                tempFormData["destination"] = event.value
                tempErrorFormData['destination'] = ""
                setSelectedDestination(event)
            } else {
                tempErrorFormData['destination'] = ""
                tempFormData["destination"] = ""
            }
        }

        if (type == "date") {
            if (event) {
                let [start, end] = event;
                if (end && start.getTime() === end.getTime()) {
                    return;
                }
                tempFormData["start_date"] = start
                tempFormData["end_date"] = end
                tempErrorFormData['start_date'] = ""
                tempErrorFormData['end_date'] = ""
            } else {
                tempFormData["start_date"] = ""
                tempFormData["end_date"] = ""
                tempErrorFormData['start_date'] = ""
                tempErrorFormData['end_date'] = ""
            }
        }

        if (type == "adult") {
            if (event) {
                tempFormData["adult"] = event.target.value
                tempErrorFormData['adult'] = ""
                tempErrorFormData['children'] = ""
            } else {
                tempErrorFormData['adult'] = ""
                tempErrorFormData['children'] = ""
                tempFormData["adult"] = 1
            }
        }

        if (type == "children") {
            if (event) {
                tempFormData["children"] = event.target.value
                tempErrorFormData['children'] = ""
                tempErrorFormData['adult'] = ""
            } else {
                tempErrorFormData['children'] = ""
                tempErrorFormData['adult'] = ""
                tempFormData["children"] = 0
            }
        }

        if (type == "adult-inc") {
            if (event) {
                tempFormData["adult"] = Number(searchingData.adult) + 1
                tempErrorFormData['adult'] = ""
                setAdultChildPlaceHolderText(`${(Number(searchingData.adult) + 1)} ${(Number(searchingData.adult) + 1) > 1 ? "Adults" : "Adult"} - ${searchingData.children} ${(searchingData.children <= 1) ? "Child" : "Children"}`)
            } else {
                tempFormData["adult"] = 0
                tempErrorFormData['adult'] = ""
            }
        }

        if (type == "adult-dec") {
            if (event) {
                tempFormData["adult"] = Number(searchingData.adult) - 1
                tempErrorFormData['adult'] = ""

                setAdultChildPlaceHolderText(`${Number(searchingData.adult) - 1} ${(Number(searchingData.adult) - 1 > 1) ? "Adults" : "Adult"} - ${searchingData.children} ${(searchingData.children <= 1) ? "Child" : "Children"}`);

            } else {
                tempFormData["adult"] = 1
                tempErrorFormData['adult'] = ""
            }
        }

        if (type == "child-inc") {
            if (event) {
                tempFormData["children"] = Number(searchingData.children) + 1
                tempErrorFormData['children'] = ""
                const adultCount = searchingData.adult;
                const childCount = Number(searchingData.children) + 1;
                const childOrChildren = childCount <= 1 ? "Child" : "Children";
                const adultOrAdults = Number(adultCount) <= 1 ? "Adult" : "Adults";
                setAdultChildPlaceHolderText(`${adultCount} ${adultOrAdults} - ${childCount} ${childOrChildren}`);
                tempFormData['children_age'].push({ age: "" })
                if (props.userSearchDataFromReducer?.children_age && props.userSearchDataFromReducer.children_age.length > 0) {
                    tempErrorFormData['children_age'].push({ age: "" });
                } else {
                    tempErrorFormData['children_age'].push({ age: "" })
                }

            } else {
                tempErrorFormData['children'] = ""
                tempFormData["children"] = 0
            }
        }

        if (type == "child-dec") {
            if (event) {
                tempFormData["children"] = Number(searchingData.children) - 1
                tempErrorFormData['children'] = ""
                setAdultChildPlaceHolderText(`${searchingData.adult} ${(searchingData.adult === 1) ? "Adult" : "Adults"} - ${Number(searchingData.children) - 1} ${(Number(searchingData.children) - 1 <= 1) ? "Child" : "Children"}`);
                tempFormData['children_age'].pop({ age: "" })
                tempErrorFormData['children_age'].pop({ age: "" })
            } else {
                tempErrorFormData['children'] = ""
                tempFormData["children"] = 0
            }
        }
        setSearchingData(tempFormData)
        setSearchingDataError(tempErrorFormData)
    }

    useEffect(() => {
        if (searchingData) {
            let reducerData = props.userSearchDataFromReducer
            let tempSearchData = { ...reducerData, ...searchingData }
            props.userSearchData(tempSearchData)
        }
    }, [searchingData])

    useEffect(() => {
        if (searchingDataError) {
            let searchDataError = props.userSearchErrorDataFromReducer
            let tempSearchDataError = { ...searchDataError, ...searchingDataError }
            props.userSearchDataError(tempSearchDataError)
        }
    }, [searchingDataError])


    //initially set search panel data
    useEffect(() => {
        let childrenAgeArr = []
        if (props.userSearchDataFromReducer && props.userSearchDataFromReducer.hasOwnProperty("children_age") && props.userSearchDataFromReducer.children_age && props.userSearchDataFromReducer.children_age.length > 0) {
            childrenAgeArr = props.userSearchDataFromReducer.children_age
        }
        if (props.userSearchDataFromReducer && Object.keys(props.userSearchDataFromReducer).length > 0) {
            const today = moment();
            let modifiedStartDate = props.userSearchDataFromReducer.hasOwnProperty("start_date") && props.userSearchDataFromReducer.start_date ? moment(props.userSearchDataFromReducer.start_date) : null;
            let modifiedEndDate = props.userSearchDataFromReducer.hasOwnProperty("end_date") && props.userSearchDataFromReducer.end_date ? moment(props.userSearchDataFromReducer.end_date) : null;

            if (modifiedStartDate && modifiedStartDate.isBefore(today, 'day')) {
                modifiedStartDate = today;
                modifiedEndDate = moment(today).add(1, 'day');
            }

            const formattedStartDate = modifiedStartDate ? modifiedStartDate.toDate() : new Date();
            const formattedEndDate = modifiedEndDate ? modifiedEndDate.toDate() : new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

            setSearchingData({
                destination: props.userSearchDataFromReducer.hasOwnProperty("destination") ? props.userSearchDataFromReducer.destination : "",
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                adult: props.userSearchDataFromReducer.hasOwnProperty("adult") ? props.userSearchDataFromReducer.adult : "",
                children: props.userSearchDataFromReducer.hasOwnProperty("children") ? props.userSearchDataFromReducer.children : "",
                children_age: childrenAgeArr
            });

            setSelectedDestination({ "label": props.userSearchDataFromReducer.destination && props.userSearchDataFromReducer.destination != "" ? props.userSearchDataFromReducer.destination : "", "value": props.userSearchDataFromReducer.destination && props.userSearchDataFromReducer.destination != "" ? props.userSearchDataFromReducer.destination : "" })
            setAdultChildPlaceHolderText(`${props.userSearchDataFromReducer.adult} ${props.userSearchDataFromReducer.adult > 1 ? "Adults" : "Adult"} - ${props.userSearchDataFromReducer.children} ${props.userSearchDataFromReducer.children <= 1 ? "Child" : "Children"}`)
        }

        if (props.userSearchErrorDataFromReducer && Object.keys(props.userSearchErrorDataFromReducer).length > 0) {
            let children_age = props.userSearchDataFromReducer.hasOwnProperty("children_age") && props.userSearchDataFromReducer.children_age != null ? props.userSearchDataFromReducer.children_age : [];
            let children_age_error = [];
            if (children_age.length > 0) {
                children_age.forEach(child => {
                    if (child.age == "") {
                        children_age_error.push({ age: "Required field" });
                    } else {
                        children_age_error.push({ age: "" });
                    }
                });
            }
            setSearchingDataError({
                destination: props.userSearchErrorDataFromReducer.hasOwnProperty("destination") && props.userSearchErrorDataFromReducer.destination ? props.userSearchErrorDataFromReducer.destination : "",

                start_date: props.userSearchErrorDataFromReducer.hasOwnProperty("start_date") && props.userSearchErrorDataFromReducer.start_date ? props.userSearchErrorDataFromReducer.start_date : null,
                end_date: props.userSearchErrorDataFromReducer.hasOwnProperty("end_date") && props.userSearchErrorDataFromReducer.end_date ? props.userSearchErrorDataFromReducer.end_date : null,
                adult: props.userSearchErrorDataFromReducer.hasOwnProperty("adult") && props.userSearchErrorDataFromReducer.adult ? props.userSearchErrorDataFromReducer.adult : 1,
                children: props.userSearchErrorDataFromReducer.hasOwnProperty("children") && props.userSearchErrorDataFromReducer.children ? props.userSearchErrorDataFromReducer.children : 0,
                children_age: children_age_error,
                selectedRoom: props.userSearchErrorDataFromReducer.hasOwnProperty("selectedRoom") && props.userSearchErrorDataFromReducer.selectedRoom ? props.userSearchErrorDataFromReducer.selectedRoom : {},
                filterData: props.userSearchErrorDataFromReducer.hasOwnProperty("filterData") && props.userSearchErrorDataFromReducer.filterData ? props.userSearchErrorDataFromReducer.filterData : {},
            })

            if (props.userSearchErrorDataFromReducer.children_age && props.userSearchErrorDataFromReducer.children_age.length > 0) {

                props.userSearchErrorDataFromReducer.children_age.map((val, idx) => {
                    if (val.age == "Required field") {
                    }
                })
            }
        }
    }, [])


    //validate branch search data before api hits
    const validSearchData = () => {
        let tempFormData = { ...searchingData };
        let tempErrorFormData = { ...searchingDataError }
        let childrenAgesTemp = tempFormData.children_age.slice();
        let childrenAgesErrorTemp = tempErrorFormData.children_age.slice();
        let valid = true
        if (tempFormData.destination == "") {
            tempErrorFormData['destination'] = "Required field"
            valid = false
        }
        if (tempFormData.start_date == "" || tempFormData.start_date == null) {
            tempErrorFormData['start_date'] = "Required field"
            valid = false
        }
        if (tempFormData.end_date == "" || tempFormData.end_date == null) {
            tempErrorFormData['end_date'] = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        }
        if ((tempFormData.end_date != "" || tempFormData.end_date != null) && (tempFormData.start_date != "" || tempFormData.start_date != null)) {
            const startDate = new Date(tempFormData.start_date);
            const endDate = new Date(tempFormData.end_date);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const differenceInDays = timeDifference / (1000 * 3600 * 24);
            if (differenceInDays > 30) {
                valid = false;
                tempErrorFormData['end_date'] = "Required field"
                Utility.toastNotifications("Sorry, reservations for more than 30 nights are not possible.", "Warning", "warning");
            }
        }
        if (childrenAgesTemp.length > 0) {
            childrenAgesTemp.map((val, idx) => {
                if (val.age == "") {
                    childrenAgesErrorTemp[idx].age = "Required field";
                    valid = false;
                } else {
                    childrenAgesErrorTemp[idx].age = ""
                }
            })
        }
        setSearchingDataError({
            ...tempErrorFormData,
            children_age: childrenAgesErrorTemp
        });
        setSearchingDataError(tempErrorFormData)
        return valid;
    }

    useEffect(() => {
    }, [searchingDataError])

    //search hotel branch api
    const searchHotelBranch = Utility.debounceButton(() => {
        let valid = validSearchData()
        if (props.callFrom == "home") {
            if (searchingData) {
                if (valid) {
                    let trendingData = { 'location': '', 'type': '' }
                    props.trendingDestination(trendingData);
                    props.searchHotelBranch()
                }
            }
        } else {
            if (valid) {
                props.searchHotelBranch()
            }
        }
    }, 300);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
        }),
        menu: (provided, state) => ({
            ...provided,
            position: "fixed",
            width: "50%",
            right: 40,
            background: "white",
            border: "1px solid #D6D6D6",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
        })

    };

    //child age input ui
    const childAgeDropdownUi = () => {
        if (!searchingData || !searchingData.children) {
            return null;
        }
        let childrenData = searchingData.children;
        let arry = [];
        for (let i = 0; i < childrenData; i++) {
            arry.push(
                <>
                    <div className={searchingDataError.children_age[i]?.age && searchingDataError.children_age[i]?.age == "Required field" ? "dropdowninnerbox searchError" : "dropdowninnerbox"} key={i}>
                        <Select
                            onChange={(e) => handelChangeChildrenAge(e, i, "childrenAge")}
                            options={childrenAgeList}
                            name='children_age'
                            placeholder="Age needed"
                            isSearchable={true}
                            menuShouldScrollIntoView={true}
                            menuPlacement="bottom"
                            styles={customStyles}
                            maxMenuHeight={150}
                            value={props.userSearchDataFromReducer?.children_age?.length > 0 && props.userSearchDataFromReducer.children_age[i]?.age ? props.userSearchDataFromReducer.children_age[i].age : ""}
                        />
                    </div>
                </>
            )
        }

        return arry;


    }

    //child age entry onchange event
    const handelChangeChildrenAge = (event, idx, type) => {
        let tempFormData = { ...searchingData };
        let tempErrorFormData = { ...searchingDataError }
        let childrenAgesTemp = tempFormData.children_age.slice();
        let childrenAgesErrorTemp = tempErrorFormData.children_age.slice();
        if (type == "childrenAge") {
            if (event) {
                childrenAgesTemp[idx] = { ...childrenAgesTemp[idx], "age": event };
                childrenAgesErrorTemp[idx] = { ...childrenAgesErrorTemp[idx], "age": "" };
            } else {
                childrenAgesTemp[idx] = { ...childrenAgesTemp[idx], "age": "" };
                childrenAgesErrorTemp[idx] = { ...childrenAgesErrorTemp[idx], "age": "" };
            }
        }
        setSearchingData({
            ...tempFormData,
            children_age: childrenAgesTemp
        });
        setSearchingDataError({
            ...tempErrorFormData,
            children_age: childrenAgesErrorTemp
        });
    }

    //when child age inputable overlay is open , this time validate the data
    useEffect(() => {
        const { userSearchDataFromReducer } = props;
        let valid = validSearchDataFn()
        if (props.callFrom == "search" && userSearchDataFromReducer?.start_date && userSearchDataFromReducer.start_date != "" && userSearchDataFromReducer?.end_date && userSearchDataFromReducer.end_date != "" && userSearchDataFromReducer?.destination && userSearchDataFromReducer.destination != "" && userSearchDataFromReducer?.adult && userSearchDataFromReducer.adult != "" && !showOverlay && valid) {
            let allAgesNotEmpty = userSearchDataFromReducer.children_age.every(child => child.age !== "");
            if (allAgesNotEmpty) {
                props.searchHotelBranch();
            } else {
                props.searchDateValidation()
            }
        }
        if (!valid) {
            props.searchDateValidation()
        }
    }, [showOverlay])

    const handleOverlayToggle = () => {
        setShowOverlay(!showOverlay);
    };
    const handlePopoverExited = () => {
        setShowOverlay(false);
    };

    //cancel/modify button onchange event
    const clearLocationAndDates = () => {
        setSearchingData({
            destination: "",
            start_date: new Date(),
            end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            adult: 1,
            children: 0,
            children_age: [],
            selectedRoom: {},
            filterData: {}
        })
        setSelectedDestination({})
        setAdultChildPlaceHolderText("1 Adult - 0 Child")
    }


    useEffect(() => {
    }, [searchingDataError])

    //destination field focus event
    const handleFocus = () => {
        setIsFocused(true);
    };

    //destination field on blur event
    const handleOnBlurChange = () => {
        setIsFocused(false);
    };

    return (
        <>
            <div className={props.trendingDestinationData && props.trendingDestinationData.hasOwnProperty('type') && props.trendingDestinationData.type && props.trendingDestinationData.type == 'state' ? "search-panel-slider stateFilter" : 'search-panel-slider'}>
                <div className='container'>
                    <div className='inn-search-panel-slider'>
                        <div className='row search-cover'>
                            {(props.userSearchErrorDataFromReducer && props.userSearchErrorDataFromReducer.destination == "Required field") && (!isFocused) &&
                                <div className='location errorMsgBox'>
                                    <label>Please enter a destination to start searching.</label>
                                </div>
                            }
                            {props.trendingDestinationData && props.trendingDestinationData.hasOwnProperty('type') && props.trendingDestinationData.type && props.trendingDestinationData.type == 'state' &&
                                <div className='col-sm-1 paddingL8 searchLocation'>
                                    <div className='row'>
                                        <div className='search-panel-slider-box locationSearch'>
                                            {props.trendingDestinationData.location && (
                                                <>
                                                    <div className='locWithIcon'>
                                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                                        <span>{props.trendingDestinationData.location}</span>
                                                    </div>
                                                    <button className='clearBtn' onClick={(e) => clearStateValue(e, 'clear_state')}>✖</button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>}
                            <div className='col-sm-3 paddingL8'>
                                <div className='row'>
                                    <div className='search-panel-slider-box destinationSearch'>
                                        <ErrorBoundary title="CustomInput Error">
                                            <div className={props.userSearchErrorDataFromReducer && props.userSearchErrorDataFromReducer.destination == "Required field" ? "dropdowninnerbox searchError" : "dropdowninnerbox"}>
                                                <img className="ico ico-location" src={require('../../../../Utility/Public/images/location1.png')} />
                                                <AutosuggestComponent
                                                    handleOnChange={(e) => handelChangeSearch(e, 'search_destination')}
                                                    options={locationList}
                                                    selectedValue={Object.keys(selectedDestination).length > 0 && selectedDestination.label != "" ? selectedDestination : { value: 'default', label: 'Choose destination', isDisabled: true, isHidden: true }}
                                                    name='destination'
                                                    isMulti={false}
                                                    placeholder="Select your destination"
                                                    isDisabled={false}
                                                    isSearchable={true}
                                                    handleOnBlurChange={handleOnBlurChange}
                                                    handleFocus={handleFocus}
                                                />
                                            </div>
                                            <div className="col-md-12 errorClass error_div"></div>
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                            {/* } */}
                            <div className='col-sm-3 paddingR8'>
                                <div className='row'>
                                    <div className='datePickerBoxCover'>
                                        <div className='datePickerBoxes'>
                                            {searchingData.start_date != null ?
                                                <div className='datepickerBox'>
                                                    <strong>{searchingData.start_date.getDate()}</strong>
                                                    <p>{Utility.getMonthName(searchingData.start_date.getMonth())}, {searchingData.start_date.getFullYear()} <span>Check In</span></p>
                                                </div> :
                                                <div className='datepickerBox'>
                                                    <strong>{new Date().getDate()}</strong>
                                                    <p>{Utility.getMonthName(new Date().getMonth())}, {new Date().getFullYear()} <span>Check In</span></p>
                                                </div>}
                                            {searchingData.end_date != null ?
                                                <div className='datepickerBox'>
                                                    <strong>{searchingData.end_date.getDate()}</strong>
                                                    <p>{Utility.getMonthName(searchingData.end_date.getMonth())}, {searchingData.end_date.getFullYear()} <span>Check Out</span></p>
                                                </div> :
                                                <div className='datepickerBox'>
                                                    <strong>{(new Date().getDate() + 1)}</strong>
                                                    <p>{Utility.getMonthName(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getMonth())}, {new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getFullYear()} <span>Check Out</span></p>
                                                </div>}
                                        </div>
                                        <div className={searchingDataError.start_date == "Required field" || searchingDataError.end_date == "Required field" ? 'search-panel-slider-box choosedateSearch' : 'search-panel-slider-box choosedateSearch'}>
                                            <ErrorBoundary title="CommonDateTimePicker Error" >
                                                <img className="ico ico-date fixedIco" src={require('../../../../Utility/Public/images/calendar-outline1.png')} />
                                                <CommonDateTimePicker
                                                    dateRange={true}
                                                    selected={searchingData.start_date}
                                                    onChange={(e) => handelChangeSearch(e, 'date')}
                                                    onBlur={handleBlur}
                                                    startDate={searchingData.start_date}
                                                    endDate={searchingData.end_date}
                                                    selectsRange={true}
                                                    isClearable={true}
                                                    placeholderText="Choose date"
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() + 1)}
                                                    dateFormat={!searchingData.end_date ? "E d MMM" : "E d MMM"}
                                                    placement="bottom"
                                                />
                                            </ErrorBoundary>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={props.trendingDestinationData && props.trendingDestinationData.location != "" ? "col-sm-3 paddingL8 poPSec" : "col-sm-3 paddingL8 mTop10"}>
                                <div className='row'>

                                    <OverlayTrigger
                                        rootClose={true}
                                        trigger="click"
                                        placement="bottom"
                                        show={showOverlay}
                                        onToggle={handleOverlayToggle}
                                        onExited={handlePopoverExited}
                                        overlay={
                                            <Popover className='popover_action_inner childAdultsBox'>
                                                <Popover.Body>
                                                    <div className="popover_action_row">
                                                        <ul>
                                                            <li>
                                                                <div className='left-row-box'>{Number(searchingData.adult) <= 1 ? 'Adult' : 'Adults'}</div>
                                                                <div className='right-row-box'>
                                                                    <div className="input-group">
                                                                        <div className={searchingData.adult == 1 ? "input-group-prepend disabled" : "input-group-prepend"}>
                                                                            <button className={searchingData.adult == 1 ? "btn btn-outline-primary disabled" : "btn btn-outline-primary"} type="button" onClick={(e) => handelChangeSearch(e, 'adult-dec')}>-</button>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={searchingData.adult} onChange={(e) => handelChangeSearch(e, 'adult')} disabled />
                                                                        <div className="input-group-prepend">
                                                                            <button className="btn btn-outline-primary" type="button" onClick={(e) => handelChangeSearch(e, 'adult-inc')}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='left-row-box'>{Number(searchingData.children) <= 1 ? 'Child' : 'Children'}</div>
                                                                <div className='right-row-box'>
                                                                    <div className="input-group">
                                                                        <div className={searchingData.children == 0 ? "input-group-prepend disabled" : "input-group-prepend"}>
                                                                            <button className={searchingData.children == 0 ? "btn btn-outline-primary disabled" : "btn btn-outline-primary"} type="button" onClick={(e) => handelChangeSearch(e, 'child-dec')}>-</button>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={searchingData.children} onChange={(e) => handelChangeSearch(e, 'children')} disabled />
                                                                        <div className="input-group-prepend">
                                                                            <button className="btn btn-outline-primary" type="button" onClick={(e) => handelChangeSearch(e, 'child-inc')}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className='childrenAgeee'>
                                                                {childAgeDropdownUi()}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <div className={isChildAgeNeed ? 'search-panel-slider-box searchError' : 'search-panel-slider-box'}>
                                            <img className="ico" src={require('../../../../Utility/Public/images/people-outline1.png')} />
                                            <span className="input_field_inner ">
                                                <button className='input__fields_property'>{adultChildPlaceHolderText} </button>
                                                <i className="fa fa-angle-down down-arrow" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </OverlayTrigger>
                                </div>

                            </div>
                            <div className='col-sm-1 paddingR8 submitButton'>
                                <div className='row'>
                                    <div className='search-panel-slider-box'>
                                        <button type="button" className="search-btn" onClick={() => { searchHotelBranch() }}><i className="fa fa-search" aria-hidden="true"></i> <span>Search</span></button>
                                    </div>
                                </div>
                            </div>
                            <button className='cancelModifyBtn' onClick={() => { clearLocationAndDates() }}>Cancel / Modify</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

SearchPanel.defaultProps = {
    searchFromHomePageFn: () => { },
    callFrom: ""
}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        userSearchDataFromReducer: globalState.mainReducerData.userSearchData,
        userSearchErrorDataFromReducer: globalState.mainReducerData.userSearchDataError,
        trendingDestinationData: globalState.mainReducerData.trendingDestination
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials, logOutApp, roleWisePermission, userSearchData, setToken, userSearchDataError, trendingDestination })(SearchPanel);
