class Utility {
    static getExpiryDetails = (expiresIn) => {
        var today = new Date();
        var afterAddWithToday = new Date();
        afterAddWithToday.setSeconds(afterAddWithToday.getSeconds() + (expiresIn - 120));
        let data = {};
        data["loggedInTime"] = today
        data["expiryTime"] = afterAddWithToday
        data["expiryInterval"] = expiresIn
        return data;
    }
}
export default Utility;