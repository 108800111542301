export const userPersonalInfoGetApi = (data) => {
    return data.data;
}
export const personalDetailsUpdateApi = (data) => {
    return data.data;
}
export const userBasicInfoUpdatePatch = (data) => {
    return data.data;
}

export const currentUser = (currentUserData) => {
    let response = currentUserData.data;
    let currentUserResData = {};
    if (response) {
        if (response.success) {
            currentUserResData = {
                "success": response.success,
                "data": {
                    "id": response.data.id,
                    "user_details": {
                        "first_name": response.data.user_details.first_name,
                        "last_name": response.data.user_details.last_name,
                        "user_email": response.data.user_details.user_email,
                        "active": response.data.user_details.active,
                        "profile_img_url": response.data.user_details.profile_img_url,
                        "role_id": response.data.user_details.role_id,
                        "role_name": response.data.user_details.role_name.toLowerCase(),
                        "user_type": response.data.user_details.user_type,
                        "contact_number": response.data.user_details.contact_number,
                        "role_permission": response.data.user_details.role_permission,
                        "org_id": response.data.user_details.org_id,
                        "org_name": response.data.user_details.org_name,
                        "is_subscribed": response.data.user_details.is_subscribed,
                        "mfa": response.data.user_details.mfa,
                    }
                }
            }
        } else {
            currentUserResData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return currentUserResData;
}

export const verifySoftwareTokenPut = (roledata) => {
    return roledata.data
}
export const mfaAssignPut = (roledata) => {
    return roledata.data
}