import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class PhoneNumberWithCountryCode extends Component {
    render() {
        const { title, labelShow, disabled, onChange, value, error, parentClassName, labelClassName, errorClassName, errorLabelShow, country, requirStart, isRequiredField, disableDropdown, selectRef } = this.props;
        return (
            <div className={parentClassName}>
                {labelShow ?
                    <label className={labelClassName}>{title} {requirStart && <span className='startColor'>{isRequiredField ? "*" : ""}</span>}</label> : null}
                <PhoneInput
                    disabled={disabled}
                    country={country}
                    value={value}
                    onChange={(e, country) => onChange(e, country)}
                    ref={selectRef}
                />
                {errorLabelShow ?
                    <div className={errorClassName}>{error}</div> : null}
            </div>
        );
    }
}
PhoneNumberWithCountryCode.defaultProps = {
    parentClassName: "input_field_inner_phone",
    labelClassName: "input_field_inner_phone_label",
    errorClassName: "errorClass error_div error_phone_number",
    title: "Phone Number",
    labelShow: true,
    disabled: false,
    errorLabelShow: true,
    requirStart: false,
    isRequiredField: true,
    disableDropdown: false,
    selectRef: null
}

export default PhoneNumberWithCountryCode;