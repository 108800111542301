export const userDetailsGet = (userData) => {
    let resData = {};
    let data = [];
    if (userData.data.success) {
        let userDetailsTempHash = {};
        userDetailsTempHash.user_details = {};

        let userDetailsApiResData = userData.data.data.length > 0 ? userData.data.data[0] : [];
        userDetailsTempHash.user_details.active = userDetailsApiResData.hasOwnProperty('active') && userDetailsApiResData.active ? userDetailsApiResData.active : "";
        userDetailsTempHash.user_details.contact_number = userDetailsApiResData.hasOwnProperty('contact_number') && userDetailsApiResData.contact_number ? userDetailsApiResData.contact_number : "";
        userDetailsTempHash.user_details.email = userDetailsApiResData.hasOwnProperty('email') && userDetailsApiResData.email ? userDetailsApiResData.email : "";
        userDetailsTempHash.user_details.first_name = userDetailsApiResData.hasOwnProperty('first_name') && userDetailsApiResData.first_name ? userDetailsApiResData.first_name : "";
        userDetailsTempHash.user_details.id = userDetailsApiResData.hasOwnProperty('id') && userDetailsApiResData.id ? userDetailsApiResData.id : "";
        userDetailsTempHash.user_details.last_name = userDetailsApiResData.hasOwnProperty('last_name') && userDetailsApiResData.last_name ? userDetailsApiResData.last_name : "";
        userDetailsTempHash.user_details.profile_img = userDetailsApiResData.hasOwnProperty('profile_img_url') && userDetailsApiResData.profile_img_url ? userDetailsApiResData.profile_img_url : {};
        userDetailsTempHash.user_details.org_id = userDetailsApiResData.hasOwnProperty('UserOrgRelation') && userDetailsApiResData.UserOrgRelation.length > 0 && userDetailsApiResData.UserOrgRelation[0].hasOwnProperty('org_id') ? userDetailsApiResData.UserOrgRelation[0].org_id : "";
        data.push(userDetailsTempHash);
        resData = {
            "success": userData.data.success,
            "total": userData.data.total,
            "data": data
        }
    } else {
        resData = {
            "success": false,
            "message": 'Something went wrong'
        }
    }

    return resData;
}

export const userDeleteGet = (userDeleteData) => {
    return userDeleteData.data
}

export const resetPasswordApi = (data) => {
    return data.data
}

export const usersGet = (usersData) => {
    return usersData.data
}

export const currentUser = (currentUserData) => {
    let response = currentUserData.data;
    let currentUserResData = {};
    if (response) {
        if (response.success) {
            currentUserResData = {
                "success": response.success,
                "data": {
                    "id": response.data.id,
                    "user_details": {
                        "first_name": response.data.user_details.first_name,
                        "last_name": response.data.user_details.last_name,
                        "user_email": response.data.user_details.user_email,
                        "active": response.data.user_details.active,
                        "profile_img_url": response.data.user_details.profile_img_url,
                        "role_id": response.data.user_details.role_id,
                        "role_name": response.data.user_details.role_name.toLowerCase(),
                        "user_type": response.data.user_details.user_type,
                        "contact_number": response.data.user_details.contact_number,
                        "role_permission": response.data.user_details.role_permission,
                        "org_id": response.data.user_details.org_id,
                        "org_name": response.data.user_details.org_name,
                        "is_subscribed": response.data.user_details.is_subscribed,
                        "mfa": response.data.user_details.mfa,
                    }
                }
            }
        } else {
            currentUserResData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return currentUserResData;
}

export const userChangePasswordApi = (ChangePasswordData) => {
    return ChangePasswordData.data
}