import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/profiledoc.scss';
import '../Assets/css/profileresponsivedoc.scss';
import { connect } from 'react-redux';
import { setToken, logOutApp } from '../../../GlobalModules/Login/Actions/LoginAction';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import CustomInput from '../../../Utility/Components/CustomInput';
import Utility from '../../../../src/Utility/Utility'
import * as ProfileController from '../Controller/ProfileController'
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu'

function ChangePassword(props) {

    const [changePasswordFormData, setChangePasswordFormData] = useState({
        userEmail: "",
        currentPassword: "",
        newPassword: "",
        retypeNewPassword: "",

    })
    const [changePasswordErrorFormData, setChangePasswordErrorFormData] = useState({
        userEmail: "",
        currentPassword: "",
        newPassword: "",
        retypeNewPassword: "",

    })

    const [currentPassLock, setCurrentPassLock] = useState(false)
    const [currentPassType, setCurrentPassType] = useState("password")

    const [newPassLock, setNewPassLock] = useState(false)
    const [newPassType, setNewPassType] = useState("password")

    const [retypeNewPassLock, setRetypeNewPassLock] = useState(false)
    const [retypeNewPassType, setRetypeNewPassType] = useState("password")

    const history = useHistory();
    useEffect(() => {
        if (!props.isLoggedIn) { //checking if user is in login state cant go to login or register page
            history.push(`/${localStorage.getItem('i18nextLng')}/login`);
        } else {
            sessionStorage.setItem('previousUrl', `/${localStorage.getItem('i18nextLng')}/change_password`);
        }
    }, [props.isLoggedIn]);


    useEffect(() => {
        //initially change password state change according to the data
        let tempChangePasswordFormData = { ...changePasswordFormData };
        let tempChangePasswordFormDataError = { ...changePasswordErrorFormData }

        const { userCredentials } = props;
        let user_email = "";
        if (userCredentials && userCredentials.user_details && userCredentials.user_details.user_email != "") {
            user_email = userCredentials.user_details.user_email
        }

        tempChangePasswordFormData["userEmail"] = user_email
        tempChangePasswordFormDataError["userEmail"] = ""
        setChangePasswordFormData(tempChangePasswordFormData)
        setChangePasswordErrorFormData(tempChangePasswordFormDataError)

    }, []);


    const handleChangeChangePassword = (e) => {  //password inputs haldle change fn
        let tempChangePasswordFormData = { ...changePasswordFormData };
        let tempChangePasswordFormDataError = { ...changePasswordErrorFormData }

        if (e.target.name == "currentPassword") {
            if (e.target.value == "") {
                tempChangePasswordFormData["currentPassword"] = ""
                tempChangePasswordFormDataError["currentPassword"] = ""
            } else {
                tempChangePasswordFormData["currentPassword"] = e.target.value
                tempChangePasswordFormDataError["currentPassword"] = ""
            }
        }

        if (e.target.name == "newPassword") {
            if (e.target.value == "") {
                tempChangePasswordFormData["newPassword"] = ""
                tempChangePasswordFormDataError["newPassword"] = ""
            } else {
                tempChangePasswordFormData["newPassword"] = e.target.value
                tempChangePasswordFormDataError["newPassword"] = ""
            }
        }

        if (e.target.name == "retypeNewPassword") {
            if (e.target.value == "") {
                tempChangePasswordFormData["retypeNewPassword"] = ""
                tempChangePasswordFormDataError["retypeNewPassword"] = ""
            } else {
                tempChangePasswordFormData["retypeNewPassword"] = e.target.value
                tempChangePasswordFormDataError["retypeNewPassword"] = ""
            }
        }
        setChangePasswordFormData(tempChangePasswordFormData)
        setChangePasswordErrorFormData(tempChangePasswordFormDataError)
    }

    const hideShowEyeButton = (callFrom, type) => {
        //hide show eye button 
        if (type == "text") {
            if (callFrom == "currentPassword") {
                setCurrentPassLock(true)
                setCurrentPassType("text")
            }
            if (callFrom == "newPassword") {
                setNewPassLock(true)
                setNewPassType("text")
            }
            if (callFrom == "retypeNewPassword") {
                setRetypeNewPassLock(true)
                setRetypeNewPassType("text")
            }
        } else {
            if (callFrom == "currentPassword") {
                setCurrentPassLock(false)
                setCurrentPassType("password")
            }
            if (callFrom == "newPassword") {
                setNewPassLock(false)
                setNewPassType("password")
            }
            if (callFrom == "retypeNewPassword") {
                setRetypeNewPassLock(false)
                setRetypeNewPassType("password")
            }
        }
    }

    const validateChangePasswordFormData = () => {

        let tempChangePasswordFormData = { ...changePasswordFormData };
        let tempChangePasswordFormDataError = { ...changePasswordErrorFormData }

        let valid = true;

        if (tempChangePasswordFormData.currentPassword == "") {
            valid = false;
            tempChangePasswordFormDataError["currentPassword"] = "Required Field"
        }

        if (tempChangePasswordFormData.newPassword == "") {
            valid = false;
            tempChangePasswordFormDataError["newPassword"] = "Required Field"
        }

        if (tempChangePasswordFormData.retypeNewPassword == "") {
            valid = false;
            tempChangePasswordFormDataError["retypeNewPassword"] = "Required Field"
        }

        if (tempChangePasswordFormData.newPassword != "" && tempChangePasswordFormData.retypeNewPassword != "") {
            if (tempChangePasswordFormData.newPassword == tempChangePasswordFormData.retypeNewPassword && tempChangePasswordFormData.newPassword != "" && tempChangePasswordFormData.retypeNewPassword != "") {
                tempChangePasswordFormDataError["newPassword"] = ""
                tempChangePasswordFormDataError["retypeNewPassword"] = ""
            } else {
                valid = false;
                Utility.toastNotifications(props.t('passwordAndConfirmPasswordNotMatch'), "Error", "error");
            }
        }

        setChangePasswordFormData(tempChangePasswordFormData)
        setChangePasswordErrorFormData(tempChangePasswordFormDataError)

        return valid;
    }


    const changePasswordSubmit = () => {
        const { loaderStateTrue, loaderStateFalse, history, logOutApp } = props

        let valid = validateChangePasswordFormData();
        if (valid) {
            let data = {
                "pre_password": changePasswordFormData.currentPassword,
                "new_password": changePasswordFormData.newPassword
            };
            loaderStateTrue();
            ProfileController.userNewPasswordChange(data).then((response) => {
                loaderStateFalse();
                if (response.success) {
                    resetFormData();
                    logOutApp(props.accessToken, props.token).then(() => {
                        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
                    })
                    Utility.toastNotifications(response.message, "Success", "success");
                } else {
                    Utility.toastNotifications(response.message, "Error", "error");
                }
            }).catch((error) => {
                console.error("************error*************", error)
                if (error) {
                }
                loaderStateFalse();
                if (error.message == "Network Error") {
                }
            });

        }

    }

    const resetFormData = () => {
        setChangePasswordFormData({
            userEmail: "",
            currentPassword: "",
            newPassword: "",
            retypeNewPassword: "",

        })
        setChangePasswordErrorFormData({
            userEmail: "",
            currentPassword: "",
            newPassword: "",
            retypeNewPassword: "",

        })
        setNewPassType("password")
        setRetypeNewPassType("password")
        setNewPassLock(false)
        setRetypeNewPassLock(false)
    }

    const homeMenuClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }
    const changePasswordClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/change_password`);
    }

    useEffect(() => {
        localStorage.setItem('pageArr', JSON.stringify([]))
        breadcrumbUrlFn()
    }, [])

    const breadcrumbUrlFn = () => {
        let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
        if (!breadcrumb.includes('home')) {
            let pageArr = []
            pageArr.push('home')
            localStorage.setItem('pageArr', JSON.stringify(pageArr));
        }
    }

    return (

        <div className="loginBackground">
            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    changePasswordDisplay={true}
                    homeMenuClick={homeMenuClick}
                    changePasswordClick={changePasswordClick}
                    activeChangePassword={true}
                />
            </div>
            <div className='container'>
                <div className='innLoginPage forgotpassword-passhints'>
                    <div className='row'>
                        <div className='col-sm-4 d-none d-lg-block d-md-block'>
                            <div className='leftInnLoginPage'>
                                <img src={require('../../../Utility/Public/images/resetIcon.png')} className="loginIcon" />
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                            <div className='rightInnLoginPage'>
                                <div className="left_section login_box_inner white_background forgotpass">
                                    <div className="logo-box-inner">
                                        <div className="titleposition">
                                            <h2 className="titleone">Change Password</h2>
                                            <h3 className="titletwo">Online property booking management</h3>
                                        </div>
                                    </div>
                                    <div className="itemsContainer login_left_inner">
                                        <div className="forgotFormDiv">
                                            <div>
                                                <CustomInput
                                                    parentClassName="loginFormInnerDiv input_field_small_size email_disable_field"
                                                    name="useremail"
                                                    value={changePasswordFormData.userEmail}
                                                    errorLabel={changePasswordErrorFormData.userEmail}
                                                    readOnly={true}
                                                    placeholder={props.t('emailIdPlaceHolder')}
                                                />
                                                <div className='positionRelative'>
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size"
                                                        onChange={handleChangeChangePassword}
                                                        name="currentPassword"
                                                        type={currentPassType == "text" ? "text" : "password"}
                                                        value={changePasswordFormData.currentPassword}
                                                        errorLabel={changePasswordErrorFormData.currentPassword}
                                                        placeholder={props.t('enterCurrentPassword')}
                                                        autoComplete="off"
                                                    />
                                                    {!currentPassLock ?
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("currentPassword", "text") }}>
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </span>
                                                        :
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("currentPassword", "password") }}>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </span>

                                                    }
                                                </div>
                                                <div className='positionRelative'>
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size"
                                                        onChange={handleChangeChangePassword}
                                                        name="newPassword"
                                                        type={newPassType == "text" ? "text" : "password"}
                                                        value={changePasswordFormData.newPassword}
                                                        errorLabel={changePasswordErrorFormData.newPassword}
                                                        placeholder={props.t('NewpasswordPlaceHolder')}
                                                        autoComplete="off"
                                                    />
                                                    {!newPassLock ?
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("newPassword", "text") }}>
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </span>
                                                        :
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("newPassword", "password") }}>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </span>

                                                    }
                                                </div>
                                                <div className='positionRelative'>
                                                    <CustomInput
                                                        parentClassName="loginFormInnerDiv input_field_small_size"
                                                        onChange={handleChangeChangePassword}
                                                        name="retypeNewPassword"
                                                        type={retypeNewPassType == "text" ? "text" : "password"}
                                                        value={changePasswordFormData.retypeNewPassword}
                                                        placeholder={props.t('RetypenewpasswordPlaceHolder')}
                                                        errorLabel={changePasswordErrorFormData.retypeNewPassword}
                                                        autoComplete="off"
                                                    />
                                                    {!retypeNewPassLock ?
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypeNewPassword", "text") }}>
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </span>
                                                        :
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypeNewPassword", "password") }}>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </span>

                                                    }
                                                </div>
                                                <div className="forgor_btnBoxes forgotpassmodify">
                                                    <div className="forgor_btn_inner_div bottom15">
                                                        <button className="login-btn btn btn-primary" onClick={changePasswordSubmit}>{props.t('ChangePasswordButton')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='password-rules-box forgot-rule-box change-pass-box'>
                                        <div className='pass-right-box'>
                                            <h5>Password Hints:</h5>
                                            <ul className='password-inner-box'>
                                                <li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
                                                <li><span>Require numbers</span></li>
                                                <li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
                                                <li><span>Require uppercase letters</span></li>
                                                <li><span>Require lowercase letters</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, logOutApp })
    (withNamespaces()(ChangePassword));
