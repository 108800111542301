import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import Utility from '../../../../Utility/Utility';
import moment from 'moment';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary'
import CustomInput from '../../../../Utility/Components/CustomInput'
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent'
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker'

import { withNamespaces } from 'react-i18next';

function BookingCancellationDetails(props) {
    let bookingFromDate = moment(props.bookingDetailsData.checked_in).format('DD-MM-YYYY')
    let bookingToDate = moment(props.bookingDetailsData.checked_out).format('DD-MM-YYYY')
    const currentDate = new Date();
    let cancellationDate = moment(currentDate).format('DD-MM-YYYY')
    let totalAmount = props.bookingDetailsData.hasOwnProperty('total_amount') && props.bookingDetailsData.total_amount
    let paidAmount = props.bookingDetailsData.hasOwnProperty('total_paid') && props.bookingDetailsData.total_paid
    let minimumBookingPercent = props.bookingDetailsData.hasOwnProperty('minimum_percent') && props.bookingDetailsData.minimum_percent
    let minimumBookingAmount = props.bookingDetailsData.hasOwnProperty('minimum_booking_amount') && props.bookingDetailsData.minimum_booking_amount

    let cancellationPercent = props.bookingDetailsData.hasOwnProperty('cancellation_percent') && props.bookingDetailsData.cancellation_percent
    let cancellationAmount = props.bookingDetailsData.hasOwnProperty('cancellation_amount') && props.bookingDetailsData.cancellation_amount
    let amountRefunded = props.bookingDetailsData.hasOwnProperty('amount_refunded') && props.bookingDetailsData.amount_refunded

    let currency = props.bookingDetailsData.hasOwnProperty('currency') && props.bookingDetailsData.currency

    let cancellation_gst_percentage = props.bookingDetailsData.hasOwnProperty('cancellation_gst_percentage') && props.bookingDetailsData.cancellation_gst_percentage
    let gstAmount = 0
    if (props.cancellationAmount > 0) {
        gstAmount = (cancellation_gst_percentage / 100) * props.cancellationAmount;
    }
    let withoutFlowCancellationPoliciesRefundedAmount = Number(paidAmount) - (Number(gstAmount) + Number(props.cancellationAmount));

    let full_amount_without_any_tax = props.bookingDetailsData.hasOwnProperty('full_amount_without_any_tax') && props.bookingDetailsData.full_amount_without_any_tax

    let cancellation_gst_amount = props.bookingDetailsData.hasOwnProperty('cancellation_gst_amount') && props.bookingDetailsData.cancellation_gst_amount
    let cancellation_full_amount = props.bookingDetailsData.hasOwnProperty('cancellation_full_amount') && props.bookingDetailsData.cancellation_full_amount

    let room_amount_without_any_tax = props.bookingDetailsData.hasOwnProperty('room_amount_without_any_tax') && props.bookingDetailsData.room_amount_without_any_tax
    let cancellation_amount_on_room = props.bookingDetailsData.hasOwnProperty('cancellation_amount_on_room') && props.bookingDetailsData.cancellation_amount_on_room
    let cancellation_room_gst_amount = props.bookingDetailsData.hasOwnProperty('cancellation_room_gst_amount') && props.bookingDetailsData.cancellation_room_gst_amount
    let cancellation_room_full_amount = props.bookingDetailsData.hasOwnProperty('cancellation_room_full_amount') && props.bookingDetailsData.cancellation_room_full_amount
    let amount_room_refunded = props.bookingDetailsData.hasOwnProperty('amount_room_refunded') && props.bookingDetailsData.amount_room_refunded

    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension">
                    <div className='bookingDetailsInner'>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Check-In <span>|</span></label>
                            <label className="rightBox">{bookingFromDate != "" ? bookingFromDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Check-Out <span>|</span></label>
                            <label className="rightBox">{bookingToDate != "" ? bookingToDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Cancellation Date <span>|</span></label>
                            <label className="rightBox">{cancellationDate != "" ? cancellationDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Total Amount <span className='inclusiveExclusive'>(Exclusive of all taxes)</span> <span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(full_amount_without_any_tax).toFixed(2)}</label>
                        </div>
                        <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Paid Amount <span className='tillDate'>(Till {cancellationDate})</span><span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(paidAmount).toFixed(2)}</label>
                        </div>
                    </div>
                    <div className='bookingDetailsInner topSpace'>
                        {props.bookingRulesFlag &&
                            <>
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Deduct ({Number(cancellationPercent).toFixed(2)} %)<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellationAmount).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_amount_on_room).toFixed(2)}</label>}
                                </div>
                                {cancellation_gst_percentage > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Cancellation GST ({Number(cancellation_gst_percentage).toFixed(2)} %)<span>|</span></label>
                                        {!props.totalAmountExAllTaxesFlag ?
                                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_gst_amount).toFixed(2)}</label> :
                                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_room_gst_amount).toFixed(2)}</label>
                                        }
                                    </div>
                                }
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Total Cancellation Amount<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(cancellation_full_amount).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(cancellation_room_full_amount).toFixed(2)}</label>
                                    }
                                </div>
                                {/* new add */}
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Refund Amount<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(amountRefunded).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(amount_room_refunded).toFixed(2)}</label>
                                    }
                                </div>
                            </>}

                        {!props.bookingRulesFlag &&
                            <>
                                <div className='bookingDetailsRow '>
                                    <ErrorBoundary title="CustomInput Error">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName={"Cancellation Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                            errorLabel={props.cancellationAmountError}
                                            name="cancellation_amount"
                                            type="text"
                                            value={props.cancellationAmount}
                                            labelPresent={true}
                                            requiredStar={false}
                                            onChange={(e) => props.handleChangeBookingRules(e, 'cancellation_amount')}
                                        />
                                    </ErrorBoundary>
                                </div>
                                {cancellation_gst_percentage > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Cancellation GST ({Number(cancellation_gst_percentage).toFixed(2)} %)<span>|</span></label>
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(gstAmount).toFixed(2)}</label>
                                    </div>
                                }
                                {amountRefunded != "" && amountRefunded > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Refund Amount<span>|</span></label>
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {withoutFlowCancellationPoliciesRefundedAmount.toFixed(2)}</label>
                                    </div>}
                            </>
                        }
                    </div>

                    {amountRefunded != "" && amountRefunded > 0 &&
                        <div className='paymentModeSectionInner'>
                            <div className="dropdowninnerbox">
                                <ErrorBoundary title="CustomInput Error">
                                    <label>Payment Mode <span>*</span></label>
                                    <AutosuggestComponent
                                        handleOnChange={(e) => props.handelChangePaymentMode(e, "payment_mode")}
                                        options={props.paymentModeList}
                                        selectedValue={props.selectedPaymentModeData}
                                        name=''
                                        isMulti={false}
                                        placeholder=""
                                        isSearchable={true}
                                        menuPosition="fixed"
                                        isDisabled={true}
                                    />
                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.paymentModeData}</div>
                                </ErrorBoundary>
                            </div>
                        </div>
                    }
                    {amountRefunded != "" && amountRefunded > 0 && props.selectedPaymentModeData.value == "credit_note" &&
                        <div className='creditNotesSection'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <ErrorBoundary title="CustomInput Error">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                errorLabel={props.paymentModeFormDataError.amount}
                                                name="amount"
                                                type="text"
                                                value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                labelPresent={true}
                                                requiredStar={true}
                                                isDisabled={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='dropdowninnerbox cancellationDropPosition'>
                                            <label>Credit Note Valid Till <span>*</span></label>
                                            <ErrorBoundary title="CommonDateTimePicker Error">
                                                <CommonDateTimePicker
                                                    dateRange={false}
                                                    selected={props.selectedCreditNoteValidityDateTime}
                                                    onChange={(e) => props.handelChangePaymentMode(e, "credit_notes_validity")}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Select date"
                                                    minDate={new Date(moment.now())}
                                                    disabled={true}
                                                />
                                                <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.credit_notes_validity}</div>
                                            </ErrorBoundary>

                                        </div>
                                    </div>
                                </div>
                                <div className='credit-note-text'>
                                    Note: <span>The refunded amount will be converted into credit notes, and corresponding confirmation will be sent to you over the email. For any other refund processes, please contact the administrator.</span>
                                </div>
                                <div ref={props.paymentModeRef}></div>
                            </div>
                        </div>
                    }
                    <div className='col-md-12 cancellation_reason_box'>
                        <ErrorBoundary title="CustomInput Error">
                            <CustomInput
                                parentClassName="input_field_inner"
                                labelName="Reason for Cancellation"
                                errorLabel={props.paymentModeFormDataError.cancellation_reason}
                                name="cancellation_reason"
                                type="textarea"
                                value={props.paymentModeFormData.cancellation_reason}
                                labelPresent={true}
                                requiredStar={true}
                                onChange={(e) => props.handelChangePaymentMode(e, 'cancellation_reason')}
                            />
                        </ErrorBoundary>
                    </div>
                    <div ref={props.reasonForCancellationRef}></div>
                </div>
            </div >
        </div >
    );
};

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token
    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(BookingCancellationDetails));
