import React from 'react';
import { get, post, put, del } from '../../../Utility/Http';
import { login, currentUser, forcePasswordChangeGet, forgotPasswordGet, changePasswordGet, rolePermissionApi, getVersionListGet, userCreatePost, userCreatePatch, getfetchApiKeyGet, getPopularDestinationsApiGet, getTrendingDestinationsApiGet, verifyOtpPost, verifyMfaOtpPost, resendOtpGet, associateSoftwareTokenPut, mfaAssignPut } from '../Model/LoginModel';

export const loginGetApi = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/login`, data, null);
    return login(response);
};
export const getCurrentUser = (data) => {
    return get(`${process.env.EXTENDED_URL}currentuser`, data).then((response) => {
        return currentUser(response)
    })
};
export const rolePermissionGetApi = (data) => {
    return get(`${process.env.EXTENDED_URL}generic/roles_permissions`, data).then((response) => {
        return rolePermissionApi(response)
    })
};

export const forcePasswordChange = (data, headers) => {
    return post(`${process.env.EXTENDED_URL}users/userforcepasswordchange`, data, headers).then((response) => {
        return forcePasswordChangeGet(response)
    })
};
export const forgotPassword = (data) => {
    return put(`${process.env.EXTENDED_URL}users/forgotpassword`, data, null).then((response) => {
        return forgotPasswordGet(response)
    })
};
export const changePassword = (data) => {
    return put(`${process.env.EXTENDED_URL}users/confirmforgotpassword`, data, null).then((response) => {
        return changePasswordGet(response)
    })
};
export const getVersionList = (data) => {
    return get(`${process.env.EXTENDED_URL}version`, data).then((response) => {
        return getVersionListGet(response)
    })
};

export const userCreate = (data, id, type) => {
    if (type == 'post') {
        return post(`${process.env.EXTENDED_URL}users/signup_customer`, data).then((response) => {
            return userCreatePost(response)
        });
    } else {
        return patch(`${process.env.EXTENDED_URL}customer/${id}`, data).then((response) => {
            return userCreatePatch(response)
        });
    }
};

export const getfetchApiKey = async () => {
    const response = await get(`${process.env.EXTENDED_URL}customer/check_domain`, null);
    return getfetchApiKeyGet(response);
};
export const getPopularDestinationsApi = async () => {
    const response = await get(`${process.env.EXTENDED_URL}customer/state`, null);
    return getPopularDestinationsApiGet(response);
};
export const getTrendingDestinationsApi = async () => {
    const response = await get(`${process.env.EXTENDED_URL}customer/mostPopular`, null);
    return getTrendingDestinationsApiGet(response);
};
export const verifyOtp = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/confirm_signup`, data);
    return verifyOtpPost(response);
};
export const verifyMfaOtp = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/mfa/verify`, data);
    return verifyMfaOtpPost(response);
};
export const resendOtp = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/resend_confirmation`, data);
    return resendOtpGet(response);
};
export const associateSoftwareToken = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/associate_software_token`, data);
    return associateSoftwareTokenPut(response);
};
export const mfaAssign = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/usr_mfa_assign`, data);
    return mfaAssignPut(response);
};

