import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Public/css/headerdoc.scss';
import '../Public/css/headerresponsivedoc.scss';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import CustomButton from './CustomButton';
import ModalGlobal from './ModalGlobal';
import ErrorBoundary from './ErrorBoundary';
import { useNavigate } from 'react-router-dom';

function Login(props) {
    const paymentPageFN = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/payment`);
    }

    return (
        <div className="login-inner">
            <div className="loginBoxInner">
                <div className="logoBoxInner">
                    <div className="titleposition">
                        <img src={require('../../Utility/Public/images/stayingLogo.png')} className="logoInner" />
                        <h2 className="titleone">Sign in to unlock the best of The Staying.</h2>
                        <h3 className="titletwo"></h3>
                    </div>
                </div>
                <form className="loginFormDiv">
                    <div className="parentClassName">
                        <input type="text"
                            name="email"
                            placeholder="User Name"
                            className="passInputClass"
                            id="login_useremail"
                        />
                    </div>
                    <div className="parentClassName">
                        <input type={"password"}
                            name="password"
                            placeholder="Password"
                            className="passInputClass"
                            id='login_userpassword'
                        />
                    </div>
                    <div className="loginFormInnerDiv logbtndiv">
                        <CustomButton
                            onClick={paymentPageFN}
                            className="login-btn btn btn-primary"
                            children='Login'
                            type="button"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
