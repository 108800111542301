class ValidateUtility {
    static validate_Phone_Number = (value) => {
        var number = value;
        var filter = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static validate_Phone_Number_without_plus = (value) => {
        var number = value;
        var filter = /^(\+?\d{1,2}[ -]?)?(\(\+?\d{2,3}\)|\+?\d{2,3})?[ -]?\d{8,15}[ -]?\d{3,4}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static extractFileExtension = (fileName) => {
        const lastDotIndex = fileName.lastIndexOf(".");
        var fileNameExtension = ""
        if (lastDotIndex !== -1) {
            fileNameExtension = fileName.substring(lastDotIndex + 1).toLowerCase();
        }
        return fileNameExtension;
    }

    static validate_email = (value) => {
        var number = value;
        var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static validateStringOnly = (value) => {
        var number = value;
        var filter = /^[a-zA-Z\s]*$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static isNumeric(num) {
        return !isNaN(num)
    }

    static bankAccountNumberValidation = (value) => {

        let valid = true;
        let number = this.isNumeric(value);
        if (number && value.length > 4) {
            valid = true;
        } else {
            valid = false;
        }


        return valid;
    }

    static formatApiResponse = (data) => {
        let response = {}
        Object.keys(data).map((item, idx) => {
            response[item] = data[item]
        })
        return response
    }

    static validate_gstn_Number = (value) => {
        var number = value;
        var filter = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static validate_pan_Number = (value) => {
        var number = value;
        var filter = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static validate_aadhar_number = (value) => {
        var number = value;
        var filter = /^\d{12}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static validate_identity_card_number = (value) => {
        var number = value;
        var filter = /^[A-Za-z0-9]{8,}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }
    static validate_driving_licence_number = (value) => {
        var number = value;
        var filter = /^[A-Za-z0-9]{6,12}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }
    static validateBankAccountNumber = (value) => {
        var number = value.toUpperCase();
        var filter = /^\d{9,18}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }
    static validateIFSC = (value) => {
        var number = value.toUpperCase();
        var filter = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    static deleteAndBefore(phoneNumber, countryCode) {
        var escapedCountryCode = countryCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        var regex = new RegExp("^" + escapedCountryCode);
        return phoneNumber.replace(regex, "");
    }

}
export default ValidateUtility;