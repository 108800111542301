import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/homedoc.scss';
import '../Assets/css/homeresponsivedoc.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SearchPanel from '../Components/SearchPanel';
import FilterPanel from '../Components/FilterPanel';
import MultiRangeSlider from "../../../../Utility/Components/MultiRangeSlider";
import Pagination from 'react-bootstrap/Pagination';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Utility from '../../../../Utility/Utility';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import { loaderStateTrue, loaderStateFalse, selectChooseBranch, userSearchData, roleWisePermission } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../../../../src/GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal'
import ReviewContentShowModal from '../Components/ReviewContentShowModal';
import {
  hotelRoomTypesSearchList,
  destinationList
} from '../Controller/HomeController';
import BreadcrumbMenu from '../../../../Utility/Components/BreadcrumbMenu';
import Tooltip from 'react-bootstrap/Tooltip';
import SkeletonThemeContentList from '../../../../Utility/Components/SkeletonThemeContentList';
import LazyImage from '../../../../Utility/Components/LazyImageComponent';
import CancellationDetailsModalContent from '../Components/CancellationDetailsModalContent';

function SearchPage(props) {
  const myParam = useParams();
  const [isViewportBottom, setIsViewportBottom] = useState(false);
  const [showAllFlag, setShowAllFlag] = useState(false);
  const [leftSearchFlag, setLeftSearchFlag] = useState("hide-bar");
  const [searchResult, setsearchResult] = useState([]);
  const [searchingDataFromSearchPanel, setSearchingDataFromSearchPanel] = useState({})
  const [dataFromFilterPanel, setDataFromFilterPanel] = useState({})
  const history = useHistory();
  const [reviewShowModalFlag, setReviewShowModalFlag] = useState(false)
  const [ratingDataArr, setRatingDataArr] = useState([])
  const [apiCallFinish, setApiCallFinish] = useState(false)
  const [filterApiCallSuccess, setFilterApiCallSuccess] = useState(false)
  const [overlay, setOverlay] = useState(true)
  const [viewCancellationData, setViewCancellationData] = useState([])
  const [viewCancellationDetailsModalFlag, setViewCancellationDetailsModalFlag] = useState(false)


  //show review modal fn
  const showReviewModalFn = (data) => {
    setReviewShowModalFlag(true)
    setRatingDataArr(data)
  }

  //close review modal fn
  const closeReviewModalFn = () => {
    setReviewShowModalFlag(false)
    setRatingDataArr([])
  }

  //Initially fetch user loggedin or not
  useEffect(() => {
    if (!props.isLoggedIn) {
    } else {
      sessionStorage.setItem('previousUrl', `/${localStorage.getItem('i18nextLng')}/search`);
    }
  }, [props.isLoggedIn]);



  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      if (scrollTop >= 1050) {
        setIsViewportBottom(true);
      } else {
        setIsViewportBottom(false);
      }
      if (scrollPosition + windowHeight > documentHeight - 600) {

        setIsViewportBottom(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showAllFN = () => {
    setShowAllFlag(!showAllFlag);
  }

  //filter bar show hide for mobile fn
  const mobFilterFN = () => {
    if (leftSearchFlag == "hide-bar") {
      setLeftSearchFlag("show-bar");
    } else {
      setLeftSearchFlag("hide-bar");
    }
  }

  //filter bar hide cross buttton click for mobile fn
  const crossFN = () => {
    setLeftSearchFlag("hide-bar");
  }

  //search data validation fn
  const validSearchData = () => {
    const { loaderStateTrue, loaderStateFalse, userSearchDataFromReducer } = props;
    let valid = true
    if ((userSearchDataFromReducer.end_date != "" || userSearchDataFromReducer.end_date != null) && (userSearchDataFromReducer.start_date != "" || userSearchDataFromReducer.start_date != null)) {
      const startDate = new Date(userSearchDataFromReducer.start_date);
      const endDate = new Date(userSearchDataFromReducer.end_date);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const differenceInDays = timeDifference / (1000 * 3600 * 24);
      if (differenceInDays > 30) {
        valid = false;
        Utility.toastNotifications("Sorry, reservations for more than 30 nights are not possible.", "Warning", "warning");
      }
    }
    return valid;
  }


  //when some filteration done then hotel search occure
  useEffect(() => {
    if (filterApiCallSuccess) {
      const { loaderStateTrue, loaderStateFalse, userSearchDataFromReducer } = props;
      if (userSearchDataFromReducer?.start_date && userSearchDataFromReducer.start_date != "" && userSearchDataFromReducer?.end_date && userSearchDataFromReducer.end_date != "" && userSearchDataFromReducer?.destination && userSearchDataFromReducer.destination != "" && userSearchDataFromReducer?.adult && userSearchDataFromReducer.adult != "") {
        let allAgesNotEmpty = userSearchDataFromReducer.children_age.every(child => child.age !== "");
        let valid = validSearchData();
        if (valid && allAgesNotEmpty) {
          setFilterApiCallSuccess(false);
          searchHotelBranch();
        } else {
          setsearchResult([])
          setApiCallFinish(true)
          setTimeout(() => {
            setOverlay(false)
          }, 500);
        }
      } else {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
      }
    }
  }, [filterApiCallSuccess])

  //check filter data will populate or not 
  const filterApiSuccessCheckFn = (data) => {
    setFilterApiCallSuccess(data);
  }

  //date validation check on hotel search
  const searchDateValidation = () => {
    setsearchResult([]);
    setTimeout(() => {
      setOverlay(false)
    }, 500);
  }

  //hotel branch search api
  const searchHotelBranch = () => {
    const { loaderStateTrue, loaderStateFalse, userSearchDataFromReducer } = props;
    setOverlay(true)
    setsearchResult([]);
    setTimeout(() => {
      setApiCallFinish(false);
      //dataset creation
      let data = {};
      let filter_hash = {};
      data['startDate'] = moment(userSearchDataFromReducer.start_date).format('YYYY-MM-DD');
      data['endDate'] = moment(userSearchDataFromReducer.end_date).format('YYYY-MM-DD');
      data['location'] = userSearchDataFromReducer.destination;
      data['adult'] = userSearchDataFromReducer.adult;
      let agesArr = [];
      if (userSearchDataFromReducer.children_age.length > 0) {
        userSearchDataFromReducer.children_age.forEach((val) => {
          let agesObj = {};
          agesObj["age"] = val.age.value;
          agesArr.push(agesObj);
        });
      }
      data['childs'] = JSON.stringify(agesArr);
      if (userSearchDataFromReducer?.filterData?.property_type && userSearchDataFromReducer.filterData.property_type.length > 0) {
        filter_hash['property_type'] = userSearchDataFromReducer.filterData.property_type.join(',')
      }
      if (userSearchDataFromReducer?.filterData?.amenities && userSearchDataFromReducer.filterData.amenities.length > 0) {
        filter_hash['amenity_types'] = userSearchDataFromReducer.filterData.amenities.join(',')
      }
      if (userSearchDataFromReducer?.filterData?.meals && userSearchDataFromReducer.filterData.meals.length > 0) {
        filter_hash['meals'] = userSearchDataFromReducer.filterData.meals.join(',')
      }
      if (userSearchDataFromReducer?.filterData?.budget_max && userSearchDataFromReducer.filterData.budget_max != "") {
        filter_hash['basic_room_rate'] = userSearchDataFromReducer.filterData.budget_max
      }
      if (userSearchDataFromReducer?.filterData?.budget_min !== undefined && userSearchDataFromReducer.filterData.budget_min !== "") {
        filter_hash['basic_room_rate_min'] = userSearchDataFromReducer.filterData.budget_min
      }
      let propertyRating = []
      if (userSearchDataFromReducer.filterData.property_rating && userSearchDataFromReducer.filterData.property_rating.length > 0) {
        const updatedPropertyRating = userSearchDataFromReducer.filterData.property_rating.map(item => {
          const numericPart = item.match(/\d+/);
          return numericPart ? numericPart[0] + " " : item;
        });
        propertyRating = updatedPropertyRating
      }
      if (propertyRating.length > 0) {
        filter_hash['star_category'] = propertyRating.join(',')
      }
      if (Object.keys(filter_hash)) {
        data["customer_search_hash"] = JSON.stringify(filter_hash)
      }
      hotelRoomTypesSearchList(data).then(async (response) => {
        if (response.success) {
          if (response.success) {
            let newDataSet = await formatingSearchData(response.data);
            setsearchResult(newDataSet)
            setTimeout(() => {
              setOverlay(false)
            }, 500);
          }
        } else {
          Utility.toastNotifications("Data not found", "Warning", "warning");
        }
      }).catch((error) => {
        console.error("************error*************", error)
        if (error) {
        }
        setApiCallFinish(true)
        setTimeout(() => {
          setOverlay(false)
        }, 500);
        if (error.message == "Network Error") {
        }
      });
    }, 200);
  }

  //after geting branch search result
  useEffect(() => {
    const { loaderStateTrue, loaderStateFalse, userSearchDataFromReducer } = props;
    if (searchResult) {
      setApiCallFinish(true)
    }
  }, [searchResult]);


  //formatting branch data from search response
  const formatingSearchData = async (data) => {
    let branchArray = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        for (let b = 0; b < data[i].branch_details.length; b++) {
          let newKeydata = data[i].branch_details[b];
          newKeydata["hotel_details"] = data[i].hotel_details
          branchArray.push(newKeydata)
        }
      }
    }
    return branchArray;
  }

  // Callback function to receive data from the FilterPanel child component
  const receiveDataFromFilterPanel = (data) => {
    setDataFromFilterPanel(data);
  };

  //breadcrumb menu click fn
  const homeMenuClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
  }
  const hotelListClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/search`);
  }
  const hotelDetailsClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
  }

  const bookingListClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
  }

  //when land on search page scroll to top position
  useEffect(() => {
    breadcrumbUrlFn();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, [])

  //breadcrumb menu option function
  const breadcrumbUrlFn = () => {
    let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
    let newArray = breadcrumb.slice(0, breadcrumb.indexOf('search') + 1);
    if (!breadcrumb.includes('search')) {
      breadcrumb.push('search')
      localStorage.setItem('pageArr', JSON.stringify(breadcrumb));
    } else {
      localStorage.setItem('pageArr', JSON.stringify(newArray));
    }
  }

  //view cancellation details data set
  const viewCancellationDetails = (data) => {
    setViewCancellationData(data);
  }

  //after data set view cancellation details modal open
  useEffect(() => {
    if (viewCancellationData.length > 0) {
      setViewCancellationDetailsModalFlag(true)
    }
  }, [viewCancellationData])

  //cancellation details modal close fn
  const closeCancellationDetailsModalFn = () => {
    setViewCancellationData([])
    setViewCancellationDetailsModalFlag(false)
  }

  return (
    <>
      {overlay &&
        <div className='overlayBox'></div>}
      <div className='searchPageBox'>
        <div className='searchPanel searchPanelInner'>
          <SearchPanel
            searchDateValidation={searchDateValidation}
            callFrom="search"
            searchHotelBranch={searchHotelBranch}
          />
        </div>
        <div className='clearfix'></div>
        <div className='breadcrumbPanelSec'>
          <div className='container'>
            <BreadcrumbMenu
              homeMenuDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('home') ? true : false}
              hotelListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('search') ? true : false}
              hotelDetailsDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('hotel_details') ? true : false}
              bookingListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('booking_history') ? true : false}
              homeMenuClick={homeMenuClick}
              hotelListClick={hotelListClick}
              hotelDetailsClick={hotelDetailsClick}
              bookingListClick={bookingListClick}
              activeHotelList={true}
            />
          </div>
        </div>
        <div className='searchBodyPanel'>
          <div className='container'>
            <div className='innSearchBodyPanel'>
              <div className='row'>
                <div className='mob-filter'>
                  <button onClick={() => { mobFilterFN() }}><i className="fa fa-filter" aria-hidden="true"></i>
                    Filter</button>
                </div>

                <FilterPanel
                  leftSearchFlag={leftSearchFlag}
                  crossFN={crossFN}
                  sendDataToParent={receiveDataFromFilterPanel}
                  searchHotelBranch={searchHotelBranch}
                  filterApiSuccessCheckFn={filterApiSuccessCheckFn}
                />
                {apiCallFinish ?
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 hotelList">
                    <div className='searchRight'>
                      {searchingDataFromSearchPanel.start_date && searchingDataFromSearchPanel.start_date != "" && searchingDataFromSearchPanel.end_date && searchingDataFromSearchPanel.end_date != "" &&
                        <h6><span>{searchingDataFromSearchPanel.destination}</span>: <span>{searchResult.length}</span> properties found <em>[{Utility.calculateDaysNights(searchingDataFromSearchPanel.start_date, searchingDataFromSearchPanel.end_date)} night, {searchingDataFromSearchPanel.adult} adults, {searchingDataFromSearchPanel.children} child]</em></h6>
                      }
                      <div className='hotelListBoxes'>
                        <HotelListItem searchResult={searchResult} {...props} adult={searchingDataFromSearchPanel.adult} child={searchingDataFromSearchPanel.children} start_date={searchingDataFromSearchPanel.start_date} end_date={searchingDataFromSearchPanel.end_date} showReviewModalFn={showReviewModalFn} apiCallFinish={apiCallFinish
                        } viewCancellationDetails={viewCancellationDetails} />
                      </div>
                    </div>
                  </div> :
                  <SkeletonThemeContentList />
                }
              </div>
            </div>
          </div>
        </div>
        <ErrorBoundary title="ModalGlobal Error">
          <ModalGlobal
            show={reviewShowModalFlag}
            onHide={closeReviewModalFn}
            title="Review"
            className="modalcustomize mondimension reviewDialogModal"
            footer={false}
            closeButton={true}
            saveButtonLabel="Submit"
            saveButtonClassName="modbtn savebtn"
            cancelShow={false}
            saveShow={false}
            onCancel={closeReviewModalFn}
            cancelButtonClassName="modbtn cancelbtn"
            footerClassName="text-center topspace"
            body={
              <>
                <ErrorBoundary title="ReviewContentShowModal Error">
                  <ReviewContentShowModal
                    ratingData={ratingDataArr}
                  />
                </ErrorBoundary>
              </>
            }
          />
        </ErrorBoundary>

        <ErrorBoundary title="ModalGlobal Error">
          <ModalGlobal
            show={viewCancellationDetailsModalFlag}
            onHide={closeCancellationDetailsModalFn}
            title={viewCancellationData.length > 1 ? "Cancellation Rules" : 'Cancellation Rule'}
            className="modalcustomize mondimension reviewDialogModal cancellationRuleModal"
            footer={false}
            closeButton={true}
            saveButtonLabel="Submit"
            saveButtonClassName="modbtn savebtn"
            cancelShow={false}
            saveShow={false}
            onCancel={closeCancellationDetailsModalFn}
            cancelButtonClassName="modbtn cancelbtn"
            footerClassName="text-center topspace"
            body={
              <>
                <ErrorBoundary title="cancellation details Error">
                  <CancellationDetailsModalContent
                    viewCancellationData={viewCancellationData}
                  />
                </ErrorBoundary>
              </>
            }
          />
        </ErrorBoundary>
      </div>
    </>
  );

}




//Hotel listing ui
const HotelListItem = (props) => {
  const hotelRoomTypeDetails = (branchData) => {
    branchData['adults'] = props.adult;
    branchData['child'] = props.child;
    branchData['start_date'] = moment(props.start_date).format('YYYY-MM-DD');
    branchData['end_date'] = moment(props.end_date).format('YYYY-MM-DD');

    const { userSearchDataFromReducer } = props;
    let tempReducer = userSearchDataFromReducer;
    tempReducer['selectedRoom'] = {};
    props.userSearchData(tempReducer);
    props.selectChooseBranch(branchData);
    props.history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
  }

  if (props.searchResult.length > 0) {
    return props.searchResult.map((branchData, index) => (
      <div className='hotelListBox' key={index}>
        <div className='hotelListBoxLeft'>
          <LazyImage
            src={Object.keys(branchData.branch_images).length > 0 ? JSON.parse(branchData.branch_images.image_url).img_url : require('../../../../../public/blankImagehotel.jpg')}
            alt={require('../../../../../public/blankImagehotel.jpg')}
            loaderImage={require('../../../../../public/blankImagehotel.jpg')}
            className={"carouselImgpic"}
          />
        </div>
        <div className='hotelListBoxRight'>
          <div className='hotelDetailsBoxes'>
            <div className='hotelDetailsBoxLT'>
              <h5>
                {branchData.hasOwnProperty('star_category') &&
                  branchData.star_category &&
                  Number(branchData.star_category) > 0 ?
                  <OverlayTrigger overlay={<Tooltip>{branchData.star_category} Star Property</Tooltip>}>
                    <span>{branchData.branch_name}  </span>
                  </OverlayTrigger> : <span>{branchData.branch_name}</span>}

                {branchData.hasOwnProperty('star_category') &&
                  branchData.star_category &&
                  Number(branchData.star_category) > 0 &&
                  [...Array(Math.round(Number(branchData.star_category)))].map((_, index) => (
                    <span className='ratingStar' key={index}>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </span>
                  ))}
                {/* If there's no rating or the avg is 0, display no stars */}
                {!(branchData.hasOwnProperty('star_category') &&
                  branchData.star_category &&
                  Number(branchData.star_category) > 0) && (
                    <span></span>
                  )}

              </h5>
              <ul className='destinationName'>
                {branchData.branch_full_details.hasOwnProperty('address1') && branchData.branch_full_details.address1 &&
                  <li>
                    <OverlayTrigger overlay={<Tooltip>Address</Tooltip>}>
                      <span ><i className="fa fa-map-marker" aria-hidden="true"></i>
                        {branchData.branch_full_details.address1}
                        {branchData.branch_full_details.hasOwnProperty('address2') && branchData.branch_full_details.address2 != "" &&
                          <>, {branchData.branch_full_details.address2}</>
                        }
                        {branchData.branch_full_details.hasOwnProperty('city') && branchData.branch_full_details.city != "" &&
                          <>, {branchData.branch_full_details.city}</>
                        }
                        {branchData.branch_full_details.hasOwnProperty('state') && branchData.branch_full_details.state != "" &&
                          <>, {branchData.branch_full_details.state}</>
                        }
                        {branchData.branch_full_details.hasOwnProperty('zip') && branchData.branch_full_details.zip != "" &&
                          <>, {branchData.branch_full_details.zip}</>
                        }
                      </span>
                    </OverlayTrigger>
                  </li>
                }
                {branchData.branch_full_details.hasOwnProperty('property_type') && branchData.branch_full_details.property_type &&
                  <li>
                    <OverlayTrigger overlay={<Tooltip>Property type</Tooltip>}>
                      <span >
                        <i className="fa fa-home" aria-hidden="true"></i>
                        {branchData.branch_full_details.property_type}
                      </span>
                    </OverlayTrigger>
                  </li>
                }
              </ul>
              {branchData.branch_wise_amenity.length > 0 &&
                <div className='hotelListBoxRightInnBox'>
                  <p><strong>Property Amenities</strong></p>
                  <ul>
                    {branchData.branch_wise_amenity.map((value, i) => (
                      <li key={i}>
                        <span>
                          <LazyImage
                            src={value?.logo_url && value?.logo_url != "" ? JSON.parse(value?.logo_url).img_url : require('../../../../Utility/Public/images/no-icon1.png')}
                            alt={require('../../../../Utility/Public/images/no-icon1.png')}
                            loaderImage={require('../../../../Utility/Public/images/no-icon1.png')}
                            className={"facilityIcon"}
                          />
                          {value.amenity_name}
                        </span>
                      </li>))}
                  </ul>
                </div>}
              <div className='hotelListBoxRightInnBoxBottom'>
                {branchData.cancellationRule.length == 0 ?
                  <p className='freeCancellation'>✔ Free cancellation.</p>
                  :
                  <p onClick={() => props.viewCancellationDetails(branchData.cancellationRule)} className='cancellationRules'><b>↠</b>Cancellation rules applicable.</p>
                }
                {branchData.branch_wise_booking_rule && Number(branchData.branch_wise_booking_rule.booking_advance_percent) <= 0 ?
                  <p><strong className='grnColor'>✔ No prepayment needed</strong> – pay at the property.</p> :
                  <p><strong><b>↠</b>A minimum payment of {branchData.branch_wise_booking_rule?.booking_advance_percent}% will be required.</strong></p>
                }
                {branchData.branch_wise_total_available_rooms && Number(branchData.branch_wise_total_available_rooms) < 6 && <em>Hurry! Only {branchData.branch_wise_total_available_rooms} room left at this price on our site</em>}
              </div>
            </div>
            <div className='hotelDetailsBoxRT'>
              <div className="media reviewBox">
                <div className="media-body">
                  <h4 className="media-heading">Review score</h4>
                  {branchData.hasOwnProperty('customer_rating') &&
                    branchData.customer_rating &&
                    branchData.customer_rating.hasOwnProperty('ratings') && branchData.customer_rating.ratings && (branchData.customer_rating.ratings).length > 0 ?
                    <p><a onClick={(e) => { props.showReviewModalFn(branchData.customer_rating.ratings) }}> {(branchData.customer_rating.ratings).length} Review(s)</a></p> :
                    <p><a > No review</a></p>
                  }
                </div>
                {branchData.hasOwnProperty('customer_rating') &&
                  branchData.customer_rating &&
                  branchData.customer_rating.hasOwnProperty('avg') && branchData.customer_rating.avg != null &&
                  <div className="media-right">
                    {Number(branchData.customer_rating.avg) != 0 ? parseFloat(Math.round(branchData.customer_rating.avg * 10) / 10).toFixed(1) : "0"}
                  </div>}
              </div>
              <h5>{Utility.getCurrencySymbol(branchData.currency)} {branchData.total_room_rate}/Night
              </h5>
            </div>
          </div>
          <button className='availabilityBtn' onClick={() => { hotelRoomTypeDetails(branchData) }}>See availability <i className="fa fa-angle-right" aria-hidden="true"></i></button>
        </div>
        {branchData.food_availability.is_breakfast_available &&
          <div className="vc"><span>Food available</span></div>}
      </div>
    ));
  } else {
    if (props.apiCallFinish) {
      return <div className='norecordfound'>No Data Found</div>
    }
  }
};
const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    userSearchDataFromReducer: globalState.mainReducerData.userSearchData,
    trendingDestinationData: globalState.mainReducerData.trendingDestination
  };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials, logOutApp, roleWisePermission, userSearchData, setToken, selectChooseBranch })(SearchPage);





