import React, { useState, useEffect } from 'react';
import { loaderStateTrue, loaderStateFalse, userSearchData, priceDistributionResponseData, priceDistributionRequestData, trendingDestination } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import '../../../../Utility/Public/css/common.scss';
import '../../Payment/Assets/css/paymentdoc.scss';
import '../../Payment/Assets/css/paymentresponsivedoc.scss';
import PartialPaymentModal from '../../../../Utility/Components/PaymentModal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Utility from '../../../../Utility/Utility';
import FancyBoxImage from '../../../../Utility/Components/FancyBoxImage';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal';
import { paymentPriceDistribution } from '../Controller/HomeController'
import PaymentDetailsModalContent from '../Components/PaymentDetailsModalContent'
import moment from 'moment';
import Select, { components } from "react-select";
import Tooltip from 'react-bootstrap/Tooltip';
import GuestCheckOutModalContent from '../Components/GuestCheckOutModalContent';
import BreadcrumbMenu from '../../../../Utility/Components/BreadcrumbMenu'
import LazyImage from '../../../../Utility/Components/LazyImageComponent';
import { customerWorkStatus } from '../../../../Utility/Controller/UtilityController';

function HotelDetails(props) {
    const [bookingDetailsObj, setBookingDetailsObj] = useState({});
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [userDetailsData, setUserDetailsData] = useState({})
    const [selectedRoom, setSelectedRoom] = useState(props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 ? props.userSearchDataFromReducer.selectedRoom : {})
    const [fancyBoxModal, setFancyBoxModal] = useState(false);
    const [fancyBoxSelectedImg, setFancyBoxSelectedImg] = useState("");
    const [paymentDetailsData, setPaymentDetailsData] = useState({});
    const [paymentDetailsModalFlag, setPaymentDetailsModalFlag] = useState(false);
    const [guestCheckOutModalFlag, setGuestCheckOutModalFlag] = useState(false);
    const [isGuestFlag, setIsGuestFlag] = useState(false);
    const [guestDetailsData, setGuestDetails] = useState({});
    const [showFull, setShowFull] = useState(false);
    const [roomBookFormData, setRoomBookFormData] = useState({
        "start_date": "",
        "end_date": "",
        "branch_id": "",
        "adults": 1,
        "children": 0,
        "rooms": 1,
        "location": ""
    })
    const [hotelRoomBookDataSet, setHotelRoomBookDataSet] = useState([])
    const history = useHistory();

    const options1 = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]


    const [exploreSlideOption, setExploreSlideOption] = useState({
        loop: false,
        autoplay: true,
        nav: false,
        margin: 0,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            481: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1000: {
                items: 5,
            },
        },
    });

    const [fancyimageList, setfancyimageList] = useState([])

    //check user logged in or not
    useEffect(() => {
        if (!props.isLoggedIn) {
        } else {
            sessionStorage.setItem('previousUrl', `/${localStorage.getItem('i18nextLng')}/hotel_details`);
        }
    }, [props.isLoggedIn]);

    //if no branch selected then redirct to home tab
    useEffect(() => {
        if (props.selected_branch && Object.keys(props.selected_branch).length == 0) {
            history.push(`/${localStorage.getItem('i18nextLng')}/home`);
        }
    }, [])

    //visitor count will update 
    useEffect(() => {
        if (props.isLoggedIn) {
            let dataset = {
                [props.selected_branch?.branch_id]:
                {
                    type: "visited"
                }
            }
            customerWorkStatus(dataset);
        }
    }, [props.isLoggedIn])


    const closePaymentModal = () => {
        setShowPaymentModal(false);
    }

    //room count selection onchange fn
    const handelChange = (e, idx, value) => {
        if (e) {
            e["price_today"] = value.price_today;
            e["total_room_price"] = Number(e.value) * Number(value.price_today);
            e["room_type_name"] = value.room_type;
            e["room_type_id"] = value.id;
            e["currency"] = props.selected_branch?.currency;
            e["start_date"] = props.selected_branch?.start_date;
            e["end_date"] = props.selected_branch?.end_date;
            e["num_of_adult_max"] = value.num_of_adult_max;
            e["num_of_child_max"] = value.num_of_child_max;

            setSelectedRoom(prevObject => ({
                ...prevObject,
                [idx]: e,
            }));


        } else {
            setSelectedRoom(prevObject => {
                const updatedObject = { ...prevObject };
                delete updatedObject[idx];
                return updatedObject;
            });
        }
    }

    //after selecting room , data will store on redux
    useEffect(() => {
        if (selectedRoom) {
            let reducerData = props.userSearchDataFromReducer;
            let tempSearchData = { ...reducerData, "selectedRoom": selectedRoom }
            props.userSearchData(tempSearchData)
        }
    }, [selectedRoom])

    //room type image show modal fn
    const fancyBoxModalFN = (e, img, imageArry = []) => {
        let imageList = [];
        setFancyBoxModal(true);
        setFancyBoxSelectedImg(img);
        if (imageArry.length > 0) {
            imageList = imageArry.map((value) => JSON.parse(value.image_url).img_url)
        }
        setfancyimageList(imageList)
    };

    //room type image close modal fn
    const closefancyBoxModal = () => {
        setFancyBoxModal(false);
    };

    //room type details ui
    const roomTypeUi = () => {
        const { selected_branch } = props;
        let array = [];
        if (Object.keys(selected_branch).length > 0) {
            const sortedArray = [...selected_branch?.roomtype_details];
            if (selected_branch && sortedArray && sortedArray.length > 0) {
                sortedArray.map((value, idx) => {
                    let isParimaryImage = "";
                    if (value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0) {
                        let isPrimary = value.room_type_images.filter((img) => img.is_primary);
                        if (isPrimary.length > 0) {
                            let parseUrl = JSON.parse(isPrimary[0].image_url);
                            isParimaryImage = parseUrl.img_url
                        }
                    }
                    let options = [];
                    for (let i = 0; i < value.total_available_rooms; i++) {
                        options.push({ value: i + 1, label: `${i + 1} ${i == 0 ? 'Room' : 'Rooms'}` })
                    }
                    let isBreakFast = value.is_breakfast_available;
                    array.push(
                        <div className='room-details-section' key={idx}>
                            {isBreakFast && <div className="vc"><span>Food available</span></div>}
                            {props.userSearchDataFromReducer?.selectedRoom && props.userSearchDataFromReducer.selectedRoom.hasOwnProperty(idx) ?
                                <div className='reserveBtn'><button>Reserve</button></div> : null}

                            <div className='mediaBoxes'>
                                <div className='mediaBoxLeftSide'>
                                    <div className="media room-type-image">
                                        {isParimaryImage != "" ?
                                            <div className='room-type-img'>
                                                <LazyImage
                                                    src={isParimaryImage}
                                                    alt={require('../../../../Utility/Public/images/defaultRoomImg.png')}
                                                    loaderImage={require('../../../../Utility/Public/images/defaultRoomImg.png')}
                                                    className={"room-img"}
                                                />
                                            </div> :
                                            <div className='room-type-img'>
                                                <LazyImage
                                                    src={require('../../../../Utility/Public/images/defaultRoomImg.png')}
                                                    alt={require('../../../../Utility/Public/images/defaultRoomImg.png')}
                                                    loaderImage={require('../../../../Utility/Public/images/defaultRoomImg.png')}
                                                    className={"room-img"}
                                                />
                                            </div>
                                        }
                                        <div className="media-body">
                                            <h5 className="mt-0">{value.room_type}</h5>
                                            <>
                                                {selected_branch?.branch_wise_booking_rule && Number(selected_branch?.branch_wise_booking_rule.booking_advance_percent) <= 0 ?
                                                    <p><span>No prepayment needed – pay at the property</span></p> :
                                                    <p><strong>A minimum payment of {selected_branch?.branch_wise_booking_rule?.booking_advance_percent}% will be required.</strong></p>}

                                                {selected_branch?.cancellationRule.length == 0 &&
                                                    <p>Free cancellation</p>
                                                }
                                            </>

                                        </div>
                                    </div>
                                    {Object.keys(value).length > 0 &&
                                        <div className='roomtypeDetailsBox'>
                                            {value.hasOwnProperty("description") && value.description && value.description != "" &&
                                                <p>{(value.description).length >= 180 ?
                                                    <OverlayTrigger overlay={<Tooltip className={(value.description).length >= 180 ? "largeTooltipBox" : ""}>{value.description}</Tooltip>}>
                                                        <span>{(value.description).substring(0, 177) + "..."}</span>
                                                    </OverlayTrigger> :
                                                    <span>{value.description}</span>
                                                }</p>
                                            }
                                            <ul>
                                                {
                                                    value.hasOwnProperty("room_measurement") && value.room_measurement && Number(value.room_measurement) != 0 &&
                                                    <li>Room Measurement - {value.room_measurement} Sq. Ft.</li>
                                                }
                                                {
                                                    value.hasOwnProperty("no_of_beds") && value.no_of_beds && Number(value.no_of_beds) != 0 &&
                                                    <li> Number of Beds - {value.no_of_beds}</li>
                                                }
                                                {
                                                    value.hasOwnProperty("num_of_bathrooms") && value.num_of_bathrooms && Number(value.num_of_bathrooms) != 0 &&
                                                    <li> No. of Bathrooms - {value.num_of_bathrooms}</li>
                                                }
                                                {
                                                    value.hasOwnProperty("num_of_windows") && value.num_of_windows && Number(value.num_of_windows) != 0 ?
                                                        <li> No. of Windows - {value.num_of_windows}</li> : null
                                                }
                                                {
                                                    value.hasOwnProperty("is_balcony_available") && value.is_balcony_available != null && value.is_balcony_available &&
                                                    <li> Balcony Available</li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                {selected_branch?.roomtype_details.length > 0 && selected_branch?.roomtype_details[idx].hasOwnProperty('room_type_facility') && selected_branch?.roomtype_details[idx].room_type_facility.length > 0 &&
                                    <div className='media-right-side amenitiesBox'>
                                        <h6>Room Facilities</h6>
                                        <ul>
                                            {selected_branch?.roomtype_details[idx].room_type_facility.map((value, i) => (
                                                <li key={i}>
                                                    <span>
                                                        <LazyImage
                                                            src={value?.RoomTypeFacilityRelationData?.logo_url && value?.RoomTypeFacilityRelationData?.logo_url != "" ? JSON.parse(value?.RoomTypeFacilityRelationData?.logo_url).img_url : require('../../../../Utility/Public/images/no-icon1.png')}
                                                            alt={require('../../../../Utility/Public/images/no-icon1.png')}
                                                            loaderImage={require('../../../../Utility/Public/images/no-icon1.png')}
                                                            className={"facilityIcon"}
                                                        />
                                                        {value?.RoomTypeFacilityRelationData?.facility_name}
                                                    </span>
                                                </li>))}</ul>
                                    </div>
                                }
                            </div>

                            <div className='room-type-details hotelDetailsBox'>
                                <div className='room-type-details-row'>
                                    <div className='room-type-details-left'>
                                        <span>Number of guests per room</span>
                                    </div>
                                    <div className='room-type-details-right guestNumber'>
                                        <div className='adults'>

                                            {Array.from({ length: selected_branch?.roomtype_details[idx].num_of_adult_max }, (_, i) => (
                                                <span key={i}><OverlayTrigger overlay={<Tooltip>Adult</Tooltip>}>
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </OverlayTrigger></span>
                                            ))}
                                        </div>
                                        <div className='children'>
                                            {Array.from({ length: selected_branch?.roomtype_details[idx].num_of_child_max }, (_, i) => (
                                                <span key={i}><OverlayTrigger overlay={<Tooltip>Child</Tooltip>}><i className="fa fa-user" aria-hidden="true"></i></OverlayTrigger></span>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                                <div className={value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0 ? 'row halfBoxSec' : 'row'}>
                                    <div className={value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0 ? 'col-sm-6' : 'col-sm-12'}>
                                        <div className='room-type-details-row'>
                                            <div className='room-type-details-left'>
                                                <span>Today's price</span>
                                            </div>
                                            <div className='room-type-details-right '>
                                                <span> {Utility.getCurrencySymbol(selected_branch?.currency)} {value.price_today} for {calculatedNights} {calculatedNights > 1 ? "Nights" : "Night"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='room-type-details-row position-relative noBorder'>
                                            <div className='room-type-details-left'>
                                                <span>Select rooms</span>
                                            </div>
                                            <div className='room-type-details-right'>
                                                <ErrorBoundary title="CustomInput Error">
                                                    <div className={value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0 ? "dropdowninnerbox" : "dropdowninnerbox full-width"}>
                                                        <AutosuggestComponent
                                                            handleOnChange={(e) => handelChange(e, idx, value)}
                                                            options={options}
                                                            selectedValue={props.userSearchDataFromReducer?.selectedRoom && props.userSearchDataFromReducer.selectedRoom.hasOwnProperty(idx) ? props.userSearchDataFromReducer.selectedRoom[idx] : ""}
                                                            name=''
                                                            isMulti={false}
                                                            placeholder="No. of rooms"
                                                            isDisabled={false}
                                                            isSearchable={true}
                                                            maxMenuHeight={120}
                                                            isClearable={true}
                                                            menuPosition="fixed"
                                                        />
                                                    </div>
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                    </div>
                                    {value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0 ?
                                        <div className='col-sm-6 img-caro'>
                                            <div className='room-type-details-row indx_sec1 hotelDetailsImgBox'>
                                                <OwlCarousel
                                                    {...exploreSlideOption}
                                                    className="owl-theme">
                                                    {value.hasOwnProperty('room_type_images') && value.room_type_images.length > 0 &&
                                                        value.room_type_images.map((imageValue, index) => (
                                                            <div className="item" key={index}>
                                                                <div className="caro_Itm_Explore">
                                                                    <div className="pic_Explore">
                                                                        <div className="pic_Inn_Explore">
                                                                            <LazyImage
                                                                                src={imageValue.image_url !== "" ? JSON.parse(imageValue.image_url).img_url : ""}
                                                                                alt={require('../../../../Utility/Public/images/no-icon1.png')}
                                                                                loaderImage={require('../../../../Utility/Public/images/no-icon1.png')}
                                                                                className={"carouselImgpic"}
                                                                            />
                                                                            <div className="middle_Explore">
                                                                                <div className="plus_Explore"
                                                                                    onClick={(e) => fancyBoxModalFN(e, JSON.parse(imageValue.image_url).img_url, value.room_type_images)}>
                                                                                    <i className="fa fa-search-plus" aria-hidden="true"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </OwlCarousel>
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </div >
                    )
                })
            }
        }
        return array;
    }

    //proceed to booking debounce onclick fn 
    const openProceedPriceDistributionPage = Utility.debounceButton(async () => {
        const { userSearchDataFromReducer, selected_branch, userCredentials } = props;
        setIsGuestFlag(false)
        let vaildData = validationRoomBookFromData();
        if (vaildData) {
            if (Object.keys(userCredentials).length > 0) {
                await hotelRoomBookedDataSave();
            } else {
                props.history.push(`/${localStorage.getItem('i18nextLng')}/login/hotel_details`);
            }
        }
    }, 300)

    //booking price distribution dataset validation
    const validationRoomBookFromData = () => {
        const { userSearchDataFromReducer } = props;
        let valid = true;
        let totalAdultRoomNeeded = 0
        let totalChildRoomNeeded = 0
        let totalAdultAndChild = 0
        let maxAdults = 0;
        let minAdult = 0;

        if (Object.keys(userSearchDataFromReducer.selectedRoom).length > 0) {
            let selectedRoomData = userSearchDataFromReducer.selectedRoom ? userSearchDataFromReducer.selectedRoom : {}
            {
                Object.values(selectedRoomData).map((roomData, index) => {
                    maxAdults += parseInt(roomData.num_of_adult_max) * parseInt(roomData.value);
                    minAdult += parseInt(roomData.value);
                    let roomAdultMulti = parseInt(roomData.num_of_adult_max) * parseInt(roomData.value)
                    totalAdultRoomNeeded += parseInt(roomAdultMulti)
                    let roomChildMulti = parseInt(roomData.num_of_child_max) * parseInt(roomData.value)
                    totalChildRoomNeeded += parseInt(roomChildMulti)
                    totalAdultAndChild += parseInt(roomData.num_of_adult_max + roomData.num_of_child_max) * parseInt(roomData.value)

                })
            }
        }

        let totalPersent = Number(userSearchDataFromReducer.adult) + Number(userSearchDataFromReducer.children);
        let numAdults = userSearchDataFromReducer.adult;
        let numAdultsGreaterMaxAdults = false;
        if (Number(numAdults) > Number(maxAdults)) {
            valid = false
            Utility.toastNotifications("Total number of adults exceeds the maximum allowed.", "Warning", "warning")
            numAdultsGreaterMaxAdults = true;
            return false;
        } else if (minAdult > numAdults) {
            valid = false
            Utility.toastNotifications("You have selected more rooms than your adults count.", "Warning", "warning")
            return false;
        }
        if (!numAdultsGreaterMaxAdults) {
            if (totalAdultRoomNeeded <= totalPersent) {
                if (numAdults > 0) {
                    if (totalAdultRoomNeeded < numAdults) {
                        valid = false
                        Utility.toastNotifications("Total number of adults exceeds the maximum allowed.", "Warning", "warning");
                        return false;
                    }
                    if (totalPersent <= totalAdultAndChild) {
                    } else {
                        valid = false
                        Utility.toastNotifications("Total number of adults and children exceeds the maximum allowed.", "Warning", "warning");
                        return false;
                    }
                } else {
                    valid = false
                    Utility.toastNotifications("No of adults must be greater than 0", "Warning", "warning");
                    return false;
                }
            }
        }
        return valid;
    }

    //booking price distribution post api
    async function hotelRoomBookedDataSave() {
        const { loaderStateTrue, loaderStateFalse } = props;
        let vaildData = validationRoomBookFromData();
        if (vaildData) {
            let dataset = formatingHotelBookRoomData();
            setHotelRoomBookDataSet(dataset);
            props.priceDistributionRequestData(dataset);
            loaderStateTrue();
            paymentPriceDistribution(dataset).then((response) => {
                loaderStateFalse();
                if (response[0].success) {
                    setPaymentDetailsModalFlag(true)
                    let updatedData = {
                        ...response[0].data,
                        is_igst_enable: false,
                    };
                    setPaymentDetailsData(updatedData);
                    props.priceDistributionResponseData(updatedData);
                } else {
                    Utility.toastNotifications("Data Not Found", "Warning", "warning");
                }
            }).catch((error) => {
                console.error("************error*************", error)
                if (error) {
                }
                loaderStateFalse();
                if (error.message == "Network Error") {

                }
            });
        }
    }

    //price disribution post api payload create
    const formatingHotelBookRoomData = () => {
        const { userSearchDataFromReducer, selected_branch } = props;
        function getDatesArray(startDate, endDate) {
            const datesArray = [];
            let currentDate = new Date(startDate);
            while (currentDate <= new Date(endDate)) {
                datesArray.push(new Date(currentDate).toISOString().split('T')[0]);
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return datesArray;
        }

        let date_wise_room_details = getDatesArray(moment(userSearchDataFromReducer.start_date).format("YYYY-MM-DD"), moment(userSearchDataFromReducer.end_date).format("YYYY-MM-DD") + ' 00:00:00');
        let tempHash = {}
        if (date_wise_room_details.length > 0) {
            date_wise_room_details.map((v, i) => {
                let branchIdWiseHash = {};
                let roomData = userSearchDataFromReducer.selectedRoom;
                for (const key in roomData) {
                    if (roomData.hasOwnProperty(key)) {
                        const room = roomData[key];
                        branchIdWiseHash[room.room_type_id] = {
                            "count": room.value,
                            "room_id": ""
                        }
                    }
                }
                tempHash[v] = branchIdWiseHash
            })
        }

        let agesArr = []
        let child_age = userSearchDataFromReducer.children_age
        if (child_age.length > 0) {
            child_age.map((val, idx) => {
                let agesObj = {}
                agesObj['age'] = val.age.value
                agesArr.push(agesObj)
            })
        }

        let data = [
            {
                "org_id": selected_branch?.branch_wise_booking_rule.org_id,
                "branch_id": selected_branch?.branch_id,
                "book_from": moment(userSearchDataFromReducer?.start_date).format("YYYY-MM-DD"),
                "book_to": moment(userSearchDataFromReducer?.end_date).format("YYYY-MM-DD"),
                "adult": userSearchDataFromReducer.adult,
                "child": JSON.stringify(agesArr),
                "date_wise_food_hash": {

                },
                "date_wise_room_details": tempHash
            }
        ]

        return data;
    }

    //close price distribution modal fn
    const closePaymentDetailsModalFn = () => {
        setPaymentDetailsModalFlag(false)
        setPaymentDetailsData({})
        setGuestDetails({})
    }

    let calculatedDays = 0;
    let calculatedNights = 0;

    if (props.userSearchDataFromReducer) {
        let { days, nights } = Utility.calculateDaysNights(props.userSearchDataFromReducer.start_date, props.userSearchDataFromReducer.end_date);

        calculatedDays = days;
        calculatedNights = nights;

    }

    // checkout as  guest onclick fn 
    const openGuestCheckOutModalFn = Utility.debounceButton(async () => {
        let vaildData = validationRoomBookFromData();
        if (vaildData) {
            setIsGuestFlag(true)
            await hotelRoomBookedDataSave();
        }
    }, 300);

    //close guest modal fn
    const closeGuestCheckOutModalFn = () => {
        setGuestCheckOutModalFlag(false)
    }

    const callBackToHotelDetails = async (guestDetails) => {
        setGuestDetails(guestDetails)
        setGuestCheckOutModalFlag(false)
        await hotelRoomBookedDataSave();
    }

    //bredcrumb home menu click fn
    const homeMenuClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }

    const hotelListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/search`);
    }
    const hotelDetailsClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
    }
    const bookingListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }

    //Initially fetch bredcrumb menu option
    useEffect(() => {
        breadcrumbUrlFn()
    }, [])

    const breadcrumbUrlFn = () => {
        let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
        let newArray = breadcrumb.slice(0, breadcrumb.indexOf('hotel_details') + 1);
        if (!breadcrumb.includes('hotel_details')) {
            breadcrumb.push('hotel_details')
            localStorage.setItem('pageArr', JSON.stringify(breadcrumb));
        } else {
            localStorage.setItem('pageArr', JSON.stringify(newArray));
        }
    }

    //total choosed room count fn
    const roomCountFn = (data) => {
        let totalRoomCount = 0;
        Object.keys(data).length > 0 && Object.keys(data).map((key) => {
            if (data[key].value) {
                totalRoomCount += Number(data[key].value)
            }
        })
        return totalRoomCount;
    }

    //checkin checkout time formatting
    const formatTime = (inputTime) => {
        let time = new Date('1970-01-01T' + inputTime);
        let hours = time.getHours();
        let minutes = time.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes;
    }

    //booking details show toogling fn for mobile user
    const toggleShowFull = (type) => {
        if (type === 'mob') {
            setShowFull(!showFull);
        }
    };

  
    return (
        <div className="booking-details-page hotel-details">

            <div className='filterBox filterBoxMBottom'>
                <div className='container'>
                    <div className='leftSectionFilter hotelDetailsTitleBox'>
                        {props.selected_branch && Object.keys(props.selected_branch).length > 0 &&
                            <h2 className='pageTitle'>
                                {props.selected_branch?.branch_name}
                                <ul className='destinationName hotelDetailsAddress'>
                                    {props.selected_branch.branch_full_details.hasOwnProperty('address1') && props.selected_branch.branch_full_details.address1 &&
                                        <li>
                                            <OverlayTrigger overlay={<Tooltip>Address</Tooltip>}>
                                                <span ><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                    {props.selected_branch.branch_full_details.address1}
                                                    {props.selected_branch.branch_full_details.hasOwnProperty('address2') && props.selected_branch.branch_full_details.address2 != "" &&
                                                        <>, {props.selected_branch.branch_full_details.address2}</>
                                                    }
                                                    {props.selected_branch.branch_full_details.hasOwnProperty('city') && props.selected_branch.branch_full_details.city != "" &&
                                                        <>, {props.selected_branch.branch_full_details.city}</>
                                                    }
                                                    {props.selected_branch.branch_full_details.hasOwnProperty('state') && props.selected_branch.branch_full_details.state != "" &&
                                                        <>, {props.selected_branch.branch_full_details.state}</>
                                                    }
                                                    {props.selected_branch.branch_full_details.hasOwnProperty('zip') && props.selected_branch.branch_full_details.zip != "" &&
                                                        <>, {props.selected_branch.branch_full_details.zip}</>
                                                    }
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                    {props.selected_branch.branch_full_details.hasOwnProperty('property_type') && props.selected_branch.branch_full_details.property_type &&
                                        <li>
                                            <OverlayTrigger overlay={<Tooltip>Property type</Tooltip>}>
                                                <span >
                                                    <i className="fa fa-home" aria-hidden="true"></i>
                                                    {props.selected_branch.branch_full_details.property_type}
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    }
                                </ul>
                            </h2>
                        }
                    </div>
                </div>
            </div>
            <div className='clearfix'></div>
            <div className='container'>
                <div className='paddingL0'>
                    <BreadcrumbMenu
                        homeMenuDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('home') ? true : false}
                        hotelListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('search') ? true : false}
                        hotelDetailsDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('hotel_details') ? true : false}
                        bookingListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('booking_history') ? true : false}
                        homeMenuClick={homeMenuClick}
                        hotelListClick={hotelListClick}
                        hotelDetailsClick={hotelDetailsClick}
                        bookingListClick={bookingListClick}
                        activeHotelDetails={true}
                    />
                </div>
                <div className='booking-details-box'>
                    <div className='booking-details-inner'>
                        <div className='row mobRevart'>
                            <div className="col-xl-9 col-md-8 col-sm-12">
                                <div className='hotel-name'>
                                    <h3>Availability for {calculatedNights} {calculatedNights == 1 ? "Night" : "Nights"} {calculatedDays} Days, Stay for {props.userSearchDataFromReducer.adult} {Number(props.userSearchDataFromReducer.adult) > 1 ? "Adults" : "Adult"}{Number(props.userSearchDataFromReducer.children) > 0 ? `, ${Number(props.userSearchDataFromReducer.children)} ${Number(props.userSearchDataFromReducer.children) > 1 ? 'Children' : "child"}` : ``}</h3>
                                </div>
                                <div className='detailsRightPanel hotelDetails'>
                                    {roomTypeUi()}
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-12 bookingProceedRightPanel">
                                <div className='detailsLeftPanel'>
                                    <div className={showFull ? 'inndetailsLeftPanel mobileView' : 'inndetailsLeftPanel desktopView'}>
                                        <div className='bookingCancelationSection bookingProceedSec yourBookingdetailsBox'>
                                            <div className='innbookingProceedSec'>
                                                <div className="descriptionSection bookingProceedSecBox">
                                                    <h6>Your Booking Details</h6>
                                                    <ul>
                                                        <li>
                                                            <p><em>Check-in</em><strong>{moment(props.userSearchDataFromReducer.start_date).format('ddd DD MMM YYYY')}</strong><span>{props.selected_branch.hasOwnProperty("branch_wise_booking_rule") && props.selected_branch.branch_wise_booking_rule && props.selected_branch.branch_wise_booking_rule.hasOwnProperty('checkin_time') && props.selected_branch.branch_wise_booking_rule.checkin_time != null ? formatTime(props.selected_branch.branch_wise_booking_rule.checkin_time) : ""}</span></p>
                                                        </li>
                                                        <li>
                                                            <p><em>Check-out</em><strong>{moment(props.userSearchDataFromReducer.end_date).format('ddd DD MMM YYYY')}</strong><span>{props.selected_branch.hasOwnProperty("branch_wise_booking_rule") && props.selected_branch.branch_wise_booking_rule && props.selected_branch.branch_wise_booking_rule.hasOwnProperty('checkout_time') && props.selected_branch.branch_wise_booking_rule.checkout_time != null ? formatTime(props.selected_branch.branch_wise_booking_rule.checkout_time) : ""}</span></p>
                                                        </li>
                                                    </ul>
                                                    <p className='bookingDetailsInnBox'>Total length of stay : <strong>{calculatedNights} {calculatedNights == 1 ? "Night" : "Nights"} {calculatedDays} Days</strong></p>
                                                    <div className='selectedBox'>
                                                        <h5>You Selected :</h5>
                                                        <p><strong>{roomCountFn(props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 ? props.userSearchDataFromReducer.selectedRoom : {})} {roomCountFn(props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 ? props.userSearchDataFromReducer.selectedRoom : {}) > 1 ? "Rooms" : "Room"} for {props.userSearchDataFromReducer.adult} {Number(props.userSearchDataFromReducer.adult) > 1 ? "Adults" : "Adult"}{Number(props.userSearchDataFromReducer.children) > 0 ? `, ${Number(props.userSearchDataFromReducer.children)} ${Number(props.userSearchDataFromReducer.children) > 1 ? 'Children' : "child"}` : ``}</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 &&
                                            <div className='bookingCancelationSection bookingProceedSec'>
                                                <DetailsLeftPanel selectedRoom={props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 ? props.userSearchDataFromReducer.selectedRoom : {}} start_date={props.userSearchDataFromReducer.start_date} end_date={props.userSearchDataFromReducer.end_date} />
                                                <div className='proceedBtnBoxes'>
                                                    {Object.keys(props.userCredentials).length == 0 &&
                                                        <button className='proceedBtn' onClick={() => openGuestCheckOutModalFn()}> Checkout as Guest</button>}
                                                    <button className='proceedBtn mtop5' onClick={() => openProceedPriceDistributionPage()}>{Object.keys(props.userCredentials).length == 0 ? "Checkout with login" : "Checkout"}</button>
                                                </div>
                                            </div>}
                                    </div>
                                    {props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0 &&
                                        <div className='bookingCancelationSection bookingProceedSec forMobile'>
                                            <div className='proceedBtnBoxes'>
                                                <button className='detailsBtn' onClick={() => toggleShowFull("mob")}>{showFull ? 'Hide Details' : 'Show Details'}</button>
                                                <div className={Object.keys(props.userCredentials).length == 0 ? 'innproceedBtnBoxes' : 'innproceedBtnBoxes singleBox'}>
                                                    {Object.keys(props.userCredentials).length == 0 &&
                                                        <button className='proceedBtn' onClick={() => openGuestCheckOutModalFn()}> Checkout as Guest</button>}
                                                    <button className='proceedBtn mtop5' onClick={() => openProceedPriceDistributionPage()}>{Object.keys(props.userCredentials).length == 0 ? "Checkout with login" : "Checkout"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!(props.userSearchDataFromReducer?.selectedRoom && Object.keys(props.userSearchDataFromReducer.selectedRoom).length > 0) &&
                                        <div className="bookingCancelationSection bookingProceedSec noSelect">
                                            <div className="innbookingProceedSec"><div className="descriptionSection bookingProceedSecBox">
                                                <h6>Room Type</h6>
                                                <ul>
                                                    {/* <li><span>₹ 0/Room X 0 Room X 0 Night</span> <span>0/-</span></li> */}
                                                    <li><span>{Utility.getCurrencySymbol(props.selected_branch?.currency)} 0/Room X 0 Room</span> <span>0/-</span></li>
                                                </ul>
                                            </div>
                                            </div>
                                            <div className='proceedBtnBoxes'>
                                                <button className="proceedBtn disabled">{Object.keys(props.userCredentials).length == 0 ? "Checkout with login" : "Checkout"}</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showPaymentModal ? <PartialPaymentModal bookingDetails={userDetailsData} closePaymentModal={closePaymentModal} /> : ""
            }
            <ModalGlobal
                show={fancyBoxModal}
                onHide={closefancyBoxModal}
                title=""
                className="modalcustomize fancyBoxModal"
                footer={false}
                closeButton={true}
                saveShow={false}
                cancelShow={true}
                cancelButtonLabel="Cancel"
                cancelButtonClassName="cancel-btn"
                onCancel={closefancyBoxModal}
                body={
                    <>
                        <FancyBoxImage
                            fancyBoxSelectedImg={fancyBoxSelectedImg}
                            fancyimageList={fancyimageList}
                            setFancyBoxSelectedImg={setFancyBoxSelectedImg}
                        />
                    </>
                }
            />

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={paymentDetailsModalFlag}
                    onHide={closePaymentDetailsModalFn}
                    title="Payment Details"
                    className="modalcustomize mondimension paymentModal paymentModal paymentModalDialog paymentModalLargeSize"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Payment"
                    saveButtonClassName="btn btn-primary savebtn modbtn"
                    cancelShow={true}
                    cancelButtonLabel={"Next Step"}
                    cancelButtonClassName="modbtn cancelbtn"
                    footerClassName="text-center topspace"
                    minimizeModal={false}
                    maximizeModal={true}
                    body={
                        <>
                            <ErrorBoundary title="BranchAddEditModalContent Error">
                                <PaymentDetailsModalContent
                                    roomBookFormData={roomBookFormData}
                                    paymentDetailsData={paymentDetailsData}
                                    closePaymentDetailsModalFn={closePaymentDetailsModalFn}
                                    hotelRoomBookDataSet={hotelRoomBookDataSet}
                                    guestDetailsData={guestDetailsData}
                                    isGuestFlag={isGuestFlag}
                                    isLoggedIn={props.isLoggedIn}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={guestCheckOutModalFlag}
                    onHide={closeGuestCheckOutModalFn}
                    title="Guest Details"
                    className="modalcustomize mondimension guestDetailsModal"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Payment"
                    saveButtonClassName="btn btn-primary savebtn modbtn"
                    cancelShow={true}
                    cancelButtonClassName="modbtn cancelbtn"
                    footerClassName="text-center topspace"
                    minimizeModal={false}
                    maximizeModal={true}
                    body={
                        <>
                            <ErrorBoundary title="BranchAddEditModalContent Error">
                                <GuestCheckOutModalContent
                                    callBackToHotelDetails={callBackToHotelDetails}

                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
        </div >
    )
}

//total booking details ui panel
const DetailsLeftPanel = (props) => {
    let selectedRoom = props.selectedRoom;
    let startDate = props.start_date;
    let endDate = props.end_date;
    return Object.keys(selectedRoom).length > 0 && Object.keys(selectedRoom).map((key) => (
        <div className='innbookingProceedSec'>
            <div className='descriptionSection bookingProceedSecBox'>
                <h6>{selectedRoom[key].room_type_name}</h6>
                <ul>
                    <li>
                        <span>{Utility.getCurrencySymbol(selectedRoom[key].currency)} {selectedRoom[key].price_today}/Room X {selectedRoom[key].value} {selectedRoom[key].value > 1 ? "Rooms" : "Room"}</span>
                        <span>{Number(selectedRoom[key].total_room_price)}/-</span>
                    </li>
                </ul>
            </div>
        </div>
    ))
};
HotelDetails.defaultProps = {
    selected_branch: {},
    userSearchDataFromReducer: {}
}


const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        selected_branch: globalState.mainReducerData.selected_branch,
        userSearchDataFromReducer: globalState.mainReducerData.userSearchData,
        priceDistributionRequestData: globalState.mainReducerData.priceDistributionRequestData,
        priceDistributionResponseData: globalState.mainReducerData.priceDistributionResponseData,
        trendingDestinationData: globalState.mainReducerData.trendingDestination
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials, userSearchData, priceDistributionRequestData, priceDistributionResponseData, trendingDestination })(withRouter(HotelDetails));