import AllActionTypes from '../Utility/AllActionTypes';

export default (state = {
    "loaderState": false, "leftbar": false,
    selected_branch: {},
    "activeLink": {
        'accName': "",
        'activeClass': "",
        'lockFlag': true,
        'planningEditableDay': 0
    },
    roleWisePermission: {},
    userSearchData: {
        destination: "",
        start_date: new Date(),
        end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        adult: 1,
        children: 0,
        selectedRoom: {},
        children_age: [],
        filterData: {}
    },
    userSearchDataError: {
        destination: "",
        start_date: null,
        end_date: null,
        adult: 1,
        children: 0,
        children_age: [],
        selectedRoom: {},
        filterData: {}
    },
    priceDistributionRequestData: [],
    priceDistributionResponseData: {},
    trendingDestination:{'location':'','type':''}
}, action) => {

    switch (action.type) {
        case AllActionTypes.LOADER_STATE_TRUE:
            return { ...state, "loaderState": action.payload };
        case AllActionTypes.LOADER_STATE_FALSE:
            return { ...state, "loaderState": action.payload };
        case AllActionTypes.ROLE_PERMISSION:
            return { ...state, "roleWisePermission": action.payload };
        case AllActionTypes.SELECT_BRANCH_DATA:
            return { ...state, "selected_branch": action.payload };
        case AllActionTypes.LOCK_FLAG:
            return { ...state, "lockFlag": action.payload };
        case AllActionTypes.PLANNING_EDITABLE_BEFORE_CURRENT_DAY:
            return { ...state, "planningEditableDay": action.payload };
        case AllActionTypes.USER_SEARCH_DATA:
            return { ...state, "userSearchData": action.payload };
        case AllActionTypes.USER_SEARCH_DATA_ERROR:
            return { ...state, "userSearchDataError": action.payload };
        case AllActionTypes.PRICE_DISTRIBUTION_REQUEST_DATA:
            return { ...state, "priceDistributionRequestData": action.payload };
        case AllActionTypes.PRICE_DISTRIBUTION_RESPONSE_DATA:
            return { ...state, "priceDistributionResponseData": action.payload };
        case AllActionTypes.TRENDING_DESTINATION:
            return { ...state, "trendingDestination": action.payload };


        case AllActionTypes.CLEAR_DATA:
            return action.payload;
        default:
            return state;
    }
};
