import React, { useState, useEffect, useRef, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../src/Utility/Public/css/toastify.css';
import '../src/Utility/Public/css/theme.css';
import '../src/Utility/Public/css/headerdoc.scss';
import '../src/Utility/Public/css/font-awesome.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);
root.render(  
  <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={false}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
    <ToastContainer
    position="top-right"
    type="error"
    autoClose={4000}
    hideProgressBar={false}
    closeOnClick={true}
    pauseOnHover={true}
/>
  </React.StrictMode>
);

reportWebVitals();
