import React from 'react';
import ReactDOM from 'react-dom';
// import {
//   PaymentElement,
//   Elements,
//   ElementsConsumer,
// } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
// import '../Public/css/bookingConfirm.scss';
import { createPaymentApi } from '../Controller/PaymentController';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { withRouter } from 'react-router-dom';

import Utility from '../../../../../src/Utility/Utility';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientSecretKey: "",
    }
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements, options, userDetailsData, paymentDetailsData, paidAmount } = this.props;

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      return;
    }

    // let createPaymentData = {
    //   amount: 5000,
    //   currency: 'inr',
    //   description: 'Software development services'
    // }
    userDetailsData.amount= parseInt(paidAmount);
    createPaymentApi(userDetailsData).then((response) => {
      const { loaderStateTrue, loaderStateFalse } = this.props;
      loaderStateTrue();
      if (response.success) {
        //this.savePaymentDetailsData(response)
        this.setState({
          clientSecretKey: response.clientSecret
        }, async () => {
          const { clientSecretKey } = this.state;
          const result = await stripe.confirmPayment({
            elements,
            clientSecret: clientSecretKey,
            confirmParams: {
              //return_url: `https://7a16-116-193-128-127.ngrok-free.app/#/en/payment_success/${bookingIdFromParam}`,
              return_url: `${process.env.REDIRECT_URL}/#/en/payment_success/${btoa(JSON.stringify(userDetailsData))}/${btoa(JSON.stringify(paymentDetailsData))}/${btoa(paidAmount)}`,
            
          },
            // },
          });
          if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            Utility.toastNotifications(result.error.message, "Error", "error")
          } else {
          }
        })
      }
      //loaderStateFalse();
    }).catch((error) => {
      console.error("************error*************", error)
      if (error) {
      }
      //loaderStateFalse();
      if (error.message == "Network Error") {
      }
    });
  };


  render() {
    //const { stripe, userDetailsData, paidAmount } = this.props;
    return (
      // <form onSubmit={this.handleSubmit}>
      <>
        {/* <PaymentElement />
        <div className='pay-now-btn-row'>
          <button type="submit" disabled={!stripe} onClick={this.handleSubmit}>
            Pay Now
            <span> {Utility.getCurrencySymbol(userDetailsData.currency)} {userDetailsData.currency == "inr" ? paidAmount : paidAmount}</span>
          </button>
        </div> */}

      </>
      // </form>
    );
  }
}

const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token
  };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })(withRouter(CheckoutForm));