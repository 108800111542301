import React, { useState, useEffect } from 'react'
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { auto } from '@popperjs/core';
import Utility from '../../../../Utility/Utility'
let u = Utility;

function GenerateInvoiceContent(props) {
  const ref = useRef()
  const [isPrintScreen, setIsPrintScreen] = useState(false);
  const [hasMargin, setHasMargin] = useState(false);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrintScreen(true);
    };

    const handleAfterPrint = () => {
      setIsPrintScreen(false);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  const handlePrint = () => {
    window.print();
  };

  //download PDF document fn
  const downloadPdfDocument = () => {
    const input = document.getElementById('invoiceId');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${props.invoiceData.invoiceId}.pdf`);
        const base64String = pdf.output('datauristring');
      })
  }

  //booking room type details value ui
  const roomTypeDetailsUi = () => {
    let roomTypeDetails = props.invoiceData.hasOwnProperty('room_type_booking_details') && props.invoiceData.room_type_booking_details != null ? props.invoiceData.room_type_booking_details : {};
    let roomTypeDetails_old = props.invoiceData.hasOwnProperty('previous_booking_hash') && props.invoiceData.previous_booking_hash != null ? props.invoiceData.previous_booking_hash : {};
    let array = [];
    Object.keys(roomTypeDetails).map((obj, i) => {
      let childObj = Object.keys(roomTypeDetails[obj])[0];
      array.push(
        <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }} key={i}>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingRight: 6, wordBreak: 'break-all' }}>{roomTypeDetails[obj][childObj].room_type_name}</div>
          <div style={{ width: '35%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingLeft: 5 }}>{`${moment(roomTypeDetails[obj][childObj].book_from).format("DD-MM-YYYY")} - ${moment(roomTypeDetails[obj][childObj].book_to).format("DD-MM-YYYY")} (${roomTypeDetails[obj][childObj].day_night})`}</div>
          <div style={{ width: '10%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'left' }}>{roomTypeDetails[obj][childObj].room_count}</div>
          <div style={{ width: '16%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{Utility.hasNonZeroValues(roomTypeDetails[obj][childObj]?.room_no) ? roomTypeDetails[obj][childObj]?.room_no : '-'}</div>
          <div style={{ width: '13%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right', paddingRight: 15 }}>{u.getTwoDecimalNumber(roomTypeDetails[obj][childObj]?.perday_rent)}</div>
          <div style={{ width: '12%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(roomTypeDetails[obj][childObj]?.room_rate)}</div>
        </div>
      )
    })
    Object.keys(roomTypeDetails_old).map((obj, i) => {
      let childObj = Object.keys(roomTypeDetails_old[obj])[0];
      array.push(
        <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }} key={i}>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingRight: 6, wordBreak: 'break-all' }}>{roomTypeDetails_old[obj][childObj].room_type_name}</div>
          <div style={{ width: '42%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingLeft: 5 }}>{`${moment(roomTypeDetails_old[obj][childObj].book_from).format("DD-MM-YYYY")} - ${moment(roomTypeDetails_old[obj][childObj].book_to).format("DD-MM-YYYY")} (${roomTypeDetails_old[obj][childObj].day_night})`}</div>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'center' }}>{roomTypeDetails_old[obj][childObj].room_count}</div>
          <div style={{ width: '17%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right', paddingRight: 15 }}>{u.getTwoDecimalNumber(roomTypeDetails_old[obj][childObj]?.perday_rent)}</div>
          <div style={{ width: '13%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(roomTypeDetails_old[obj][childObj]?.room_rate)}</div>
        </div>
      )
    })
    return array;
  }

  //booking early checkout room type details value ui
  const earlyCheckoutRoomTypeDetailsUi = () => {
    let roomTypeDetails = props.invoiceData.early_checkout_booking_details;

    let array = [];
    Object.keys(roomTypeDetails).map((obj, i) => {
      let childObj = Object.keys(roomTypeDetails[obj])[0];
      array.push(
        <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0 }} key={i}>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingRight: 6 }}>{roomTypeDetails[obj][childObj].room_type_name}</div>
          <div style={{ width: '30%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', paddingLeft: 5 }}>{`${moment(roomTypeDetails[obj][childObj].book_from).format("DD-MM-YYYY")} -${moment(roomTypeDetails[obj][childObj].book_to).format("DD-MM-YYYY")} ( ${roomTypeDetails[obj][childObj].day_night} )`}</div>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{roomTypeDetails[obj][childObj]?.room_count}</div>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{Utility.hasNonZeroValues(roomTypeDetails[obj][childObj]?.room_no) ? roomTypeDetails[obj][childObj]?.room_no : '-'}</div>

          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{roomTypeDetails[obj][childObj].perday_rent}</div>
          <div style={{ width: '14%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{Number(roomTypeDetails[obj][childObj].room_rate).toFixed(2)}</div>
        </div>
      )
    })
    return array;
  }

  //booking food details value ui
  const foodingDetailsUi = () => {
    let data = props.invoiceData.date_wise_food_hash;
    let array = [];
    let prevDate = ''
    Object.keys(data).map((dateKey, i) => {
      let innerArrUi = []; // Create a temporary array to hold inner elements
      for (const ageRange in data[dateKey]) {
        let newdate = ''
        if (prevDate != dateKey) {
          prevDate = dateKey
          newdate = dateKey
        }
        let room_type_details = data[dateKey][ageRange];
        let dinnerImage = 'tickicon.png'
        let breakFastImage = 'tickicon.png'
        let lunchImage = 'tickicon.png'
        let breakfastRate = "0";
        let lunchRate = "0";
        let dinnerRate = "0";
        let is_breakfast = false;
        let is_lunch = false;
        let is_dinner = false;
        let breakfastAmount = "0";
        let lunchAmount = "0";
        let dinnerAmount = "0";

        let headCount = 0;
        headCount = room_type_details.head_count
        if (room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_breakfast_available != null && room_type_details.is_breakfast_available == false) {
          breakFastImage = 'unapprovedicon.png'
        }
        if (room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_lunch_available != null && room_type_details.is_lunch_available == false) {
          lunchImage = 'unapprovedicon.png'
        }
        if (room_type_details.hasOwnProperty('is_dinner_available') && room_type_details.is_dinner_available != null && room_type_details.is_dinner_available == false) {
          dinnerImage = 'unapprovedicon.png'
        }

        is_breakfast = room_type_details.is_breakfast_available == 0 ? false : true;
        breakfastRate = room_type_details.breakfast_rate.toString()
        if (is_breakfast) {
          breakfastAmount = breakfastRate * headCount
        }
        is_lunch = room_type_details.is_lunch_available == 0 ? false : true;
        lunchRate = room_type_details.lunch_rate.toString()
        if (is_lunch) {
          lunchAmount = lunchRate * headCount;
        }
        is_dinner = room_type_details.is_dinner_available == 0 ? false : true;
        dinnerRate = room_type_details.dinner_rate.toString()
        if (is_dinner) {
          dinnerAmount = dinnerRate * headCount;
        }

        innerArrUi.push(
          <div style={{ width: '100%', display: 'inline-block' }} key={i}>
            <div className='paymentDetailsValue' style={{ width: '10%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>
              {newdate != '' ? <span className='dateField'>{moment(dateKey).format("DD-MM-YYYY")}</span> : ''}{ageRange.includes("adult") ? "Adult" : `Children(${ageRange})`}
            </div>
            <div className='paymentDetailsValue' style={{ width: '9%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{room_type_details.head_count}</div>

            <div className='paymentDetailsValue' style={{ width: '10%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>
              <img src={require(`../../../../Utility/Public/images/${breakFastImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>
            <div className='paymentDetailsValue' style={{ width: '15%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{u.getTwoDecimalNumber(room_type_details.breakfast_rate)}</div>


            <div className='paymentDetailsValue' style={{ width: '8%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>
              <img src={require(`../../../../Utility/Public/images/${lunchImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>

            <div className='paymentDetailsValue' style={{ width: '15%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>{u.getTwoDecimalNumber(room_type_details.lunch_rate)}</div>


            <div className='paymentDetailsValue' style={{ width: '8%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>
              <img src={require(`../../../../Utility/Public/images/${dinnerImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>

            <div className='paymentDetailsValue' style={{ width: '15%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>{u.getTwoDecimalNumber(Number(room_type_details.dinner_rate))}</div>
            <div className='paymentDetailsValue' style={{ width: '10%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(room_type_details.amount)}</div>
          </div>

        )
      }
      array.push(<div className="date-group date-group-view" key={`group-${dateKey}`}>{innerArrUi}</div>);
    })


    return array;

  }

  //early checkout food details value ui
  const earlyCheckoutFoodingDetailsUi = () => {
    let data = props.invoiceData.early_date_wise_food_hash;
    let array = [];
    let prevDate = ''
    Object.keys(data).map((dateKey, i) => {
      let innerArrUi = []; // Create a temporary array to hold inner elements
      for (const ageRange in data[dateKey]) {
        let newdate = ''
        if (prevDate != dateKey) {
          prevDate = dateKey
          newdate = dateKey
        }
        let room_type_details = data[dateKey][ageRange];
        let dinnerImage = 'tickicon.png'
        let breakFastImage = 'tickicon.png'
        let lunchImage = 'tickicon.png'
        let breakfastRate = "0";
        let lunchRate = "0";
        let dinnerRate = "0";
        let is_breakfast = false;
        let is_lunch = false;
        let is_dinner = false;
        let breakfastAmount = "0";
        let lunchAmount = "0";
        let dinnerAmount = "0";

        let headCount = 0;
        headCount = room_type_details.head_count
        if (room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_breakfast_available != null && room_type_details.is_breakfast_available == false) {
          breakFastImage = 'unapprovedicon.png'
        }
        if (room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_lunch_available != null && room_type_details.is_lunch_available == false) {
          lunchImage = 'unapprovedicon.png'
        }
        if (room_type_details.hasOwnProperty('is_dinner_available') && room_type_details.is_dinner_available != null && room_type_details.is_dinner_available == false) {
          dinnerImage = 'unapprovedicon.png'
        }

        is_breakfast = room_type_details.is_breakfast_available == 0 ? false : true;
        breakfastRate = room_type_details.breakfast_rate.toString()
        if (is_breakfast) {
          breakfastAmount = breakfastRate * headCount
        }
        is_lunch = room_type_details.is_lunch_available == 0 ? false : true;
        lunchRate = room_type_details.lunch_rate.toString()
        if (is_lunch) {
          lunchAmount = lunchRate * headCount;
        }
        is_dinner = room_type_details.is_dinner_available == 0 ? false : true;
        dinnerRate = room_type_details.dinner_rate.toString()
        if (is_dinner) {
          dinnerAmount = dinnerRate * headCount;
        }

        innerArrUi.push(
          <div style={{ width: '100%', display: 'inline-block' }} key={i}>
            <div className='paymentDetailsValue' style={{ width: '14%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>
              {newdate != '' ? <span className='dateField'>{moment(dateKey).format("DD-MM-YYYY")}</span> : ''}{ageRange.includes("adult") ? "Adult" : `Children(${ageRange})`}
            </div>
            <div className='paymentDetailsValue' style={{ width: '30%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{headCount}</div>
            <div className='paymentDetailsValue' style={{ width: '14%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>
              <img src={require(`../../../../Utility/Public/images/${breakFastImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>
            <div className='paymentDetailsValue' style={{ width: '14%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>
              <img src={require(`../../../../Utility/Public/images/${lunchImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>
            <div className='paymentDetailsValue' style={{ width: '14%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'left' }}>
              <img src={require(`../../../../Utility/Public/images/${dinnerImage}`)} style={{ width: 'auto', height: '17px' }} />
            </div>
            <div className='paymentDetailsValue' style={{ width: '14%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{parseFloat(Number(Number(breakfastAmount) + Number(lunchAmount) + Number(dinnerAmount))).toFixed(2)}</div>
          </div>
        )
      }
      array.push(<div className="date-group date-group-view" key={`group-${dateKey}`}>{innerArrUi}</div>);
    })


    return array;

  }

  //payable service details value ui
  const payableServiceDetailsUi = () => {
    let extraNotationsDetails = props.invoiceData.extra_notations;
    let array = [];
    extraNotationsDetails.map((obj, i) => {
      array.push(
        <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }} key={i}>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{obj.description}</div>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{obj.book_date != null ? moment(obj.book_date).format('DD-MM-YYYY') : ""}</div>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right', paddingRight: 6 }}>{u.getTwoDecimalNumber(obj.amount)}</div>
          {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <>
            <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right', paddingRight: 6 }}>{u.getTwoDecimalNumber(Number(obj.hasOwnProperty('service_tax_percentage') && obj.service_tax_percentage ? obj.service_tax_percentage : 0.00).toFixed(2))}</div>
            <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right', paddingRight: 6 }}>{u.getTwoDecimalNumber(Number(Number(obj.amount) + (Number(obj.amount) * Number(obj.hasOwnProperty('service_tax_percentage') && obj.service_tax_percentage ? obj.service_tax_percentage : Number(0.00)) / 100)).toFixed(2))}</div>
          </>}
        </div>
      )
    })
    return array;
  }

  //payment show ui
  const paymentHistory = () => {
    let paymentHistory = [...props.invoiceData.booking_credited_payments, ...props.invoiceData.booking_debited_payments];
    let array = [];
    if (paymentHistory.length > 0) {

      paymentHistory.map((value, idx) => {

        let paidAmount = value?.payment_flow_type && value.payment_flow_type == 'cr' && parseFloat(value.amount) > 0 ? u.getTwoDecimalNumber(value.amount) : "-";
        let refundAmount = value?.payment_flow_type && value.payment_flow_type == 'dr' && parseFloat(value.amount) > 0 ? u.getTwoDecimalNumber(value.amount) : "-";
        let mode = value.payment_mode.charAt(0).toUpperCase() + value.payment_mode.slice(1);
        array.push(
          <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10, paddingRight: 10 }} key={idx}>
            <div style={{ width: '27%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{value.recipet_id}</div>
            <div style={{ width: '15%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'left' }}>{mode}</div>
            <div style={{ width: '18%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'center' }}>{paidAmount}</div>
            <div style={{ width: '18%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'center' }}>{refundAmount}</div>
            <div style={{ width: '22%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'left' }}>{moment.utc(value.payment_date).format("DD-MM-YYYY HH:mm:ss")}</div>
          </div>
        )
      })
    }

    return array;

  }

  //early checkout additional service value ui
  const payableServiceDetailsEarlyCheckoutUi = () => {
    let extraNotationsDetails = props.invoiceData?.early_date_wise_note_arr;
    let array = [];
    extraNotationsDetails.map((obj, i) => {
      array.push(
        <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }} key={i}>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "40%" : "25%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{obj.description}</div>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "40%" : "25%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{obj.book_date != null ? moment(obj.book_date).format('DD-MM-YYYY') : ""}</div>
          <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "20%" : "15%", color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(obj.amount)}</div>
          {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <> <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(Number(obj.hasOwnProperty('service_tax_percentage') && obj.service_tax_percentage ? obj.service_tax_percentage : 0.00).toFixed(2))}</div>
            <div style={{ width: '19.6%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getTwoDecimalNumber(Number(Number(obj.amount) + (Number(obj.amount) * Number(obj.hasOwnProperty('service_tax_percentage') && obj.service_tax_percentage ? obj.service_tax_percentage : Number(0.00)) / 100)).toFixed(2))}</div>
          </>}
        </div>
      )
    })
    return array;
  }

  // hide food details value ui
  const foodingDetailsHideUi = () => {
    let paymentDetailsRoomTypeFoodingData = props.invoiceData.date_wise_food_hash;
    let paymentDetailsDataSetTemp = { ...props.invoiceData }
    let fsgst = paymentDetailsDataSetTemp.fsgst_percent
    let fcgst = paymentDetailsDataSetTemp.fcgst_percent
    const totalDays = Object.keys(paymentDetailsRoomTypeFoodingData).length;

    const calculatePerDayPerHead = (type) => {
      let totalRate = 0;
      let totalHeadCount = 0;
      let totalRateWithGst = 0;
      let food_cgst_amount = 0;
      let food_sgst_amount = 0;
      let food_gst_amount = 0;

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        let dayRate = 0;
        let dayHeadCount = 0;

        Object.keys(dayData).forEach((key) => {
          if (type === 'adult' && key === 'adult') {
            dayRate += (Number(dayData[key].breakfast_rate) +
              Number(dayData[key].lunch_rate) +
              Number(dayData[key].dinner_rate)) * dayData[key].head_count;
            dayHeadCount += dayData[key].head_count;
          } else if (type === 'child' && key !== 'adult') {
            dayRate += (Number(dayData[key].breakfast_rate) +
              Number(dayData[key].lunch_rate) +
              Number(dayData[key].dinner_rate)) * dayData[key].head_count;
            dayHeadCount += dayData[key].head_count;
          }
        });

        totalRate += dayRate;
        totalHeadCount += dayHeadCount;
      });

      food_cgst_amount = Number((totalRate * fcgst / 100).toFixed(2));
      food_sgst_amount = Number((totalRate * fsgst / 100).toFixed(2));
      food_gst_amount = food_cgst_amount + food_sgst_amount;
      totalRateWithGst = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalRate : totalRate + food_gst_amount;
      return totalHeadCount > 0 ? (totalRateWithGst / totalHeadCount).toFixed(2) : 0;
    };

    const calculateTotal = (type) => {
      let totalAmount = 0;
      let totalAmountWithGst = 0;
      let totalAmountChildren = 0;
      let totalAmountWithGstChildren = 0;
      let food_cgst_amount = 0;
      let food_sgst_amount = 0;
      let food_gst_amount = 0;

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        Object.keys(dayData).forEach((key) => {
          if (type === 'adult' && key === 'adult') {
            totalAmount += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
            food_cgst_amount = Number((totalAmount * fcgst / 100).toFixed(2));
            food_sgst_amount = Number((totalAmount * fsgst / 100).toFixed(2));
            food_gst_amount = food_cgst_amount + food_sgst_amount;
            totalAmountWithGst = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalAmount : totalAmount + food_gst_amount;
          } else if (type === 'child' && key !== 'adult') {
            totalAmountChildren += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
            food_cgst_amount = Number((totalAmountChildren * fcgst / 100).toFixed(2));
            food_sgst_amount = Number((totalAmountChildren * fsgst / 100).toFixed(2));
            food_gst_amount = food_cgst_amount + food_sgst_amount;
            totalAmountWithGstChildren = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalAmountChildren : totalAmountChildren + food_gst_amount;
          }
        });


      });

      return type == 'adult' ? totalAmountWithGst.toFixed(2) : totalAmountWithGstChildren.toFixed(2);
    };

    const getTypeAndHeadCount = () => {
      let adultCount = 0;
      let childrenCount = 0;
      let childrenCountMap = new Map();

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        if (dayData.adult) {
          adultCount = dayData.adult.head_count;
        }

        Object.keys(dayData).forEach((key) => {
          if (key !== 'adult' && dayData[key].hasOwnProperty('head_count') && dayData[key].head_count != null) {
            if (childrenCountMap.has(key)) {
              childrenCountMap.set(key, Number(dayData[key].head_count));
            } else {
              childrenCountMap.set(key, Number(dayData[key].head_count));
            }
          }
        });
      });
      childrenCountMap.forEach((count) => {
        childrenCount += count;
      });
      return { adultCount, childrenCount };
    };
    const { adultCount, childrenCount } = getTypeAndHeadCount();
    return (
      <div className="newFoodDetailsTable">
        {/* Adult Row */}
        <div style={{ display: "table", width: "100%", paddingBottom: 4 }}>
          <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{Number(adultCount) > 1 ? Utility.capitalizeFirstLetterOfEachWord('Adults') : Utility.capitalizeFirstLetterOfEachWord('Adult')}</div>
          <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{adultCount}</div>
          <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{totalDays}</div>
          <div style={{ width: '20%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
            {adultCount > 0 ? Number(calculatePerDayPerHead('adult')).toFixed(2) : 0}
          </div>
          <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
            {adultCount > 0 ? Number(calculateTotal('adult')).toFixed(2) : 0}
          </div>
        </div>

        {/* Children Row (if present) */}
        {childrenCount > 0 && (
          <div style={{ width: '100%', display: 'inline-block', paddingBottom: 4 }}>
            <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{Number(childrenCount) > 1 ? Utility.capitalizeFirstLetterOfEachWord('Children') : Utility.capitalizeFirstLetterOfEachWord('Child')}</div>
            <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{childrenCount}</div>
            <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{totalDays}</div>
            <div style={{ width: '20%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
              {/* Assuming per day per head calculation for children */}
              {childrenCount > 0 ? Number(calculatePerDayPerHead('child')).toFixed(2) : 0}
            </div>
            <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
              {childrenCount > 0 ? Number(calculateTotal('child')).toFixed(2) : 0}
            </div>
          </div>
        )}
      </div>
    );
  }

  //early checkout hide food details value ui
  const earlyCheckoutFoodingDetailsHideUi = () => {
    let paymentDetailsRoomTypeFoodingData = props.invoiceData?.early_date_wise_food_hash;
    let paymentDetailsDataSetTemp = { ...props.invoiceData }
    let fsgst = paymentDetailsDataSetTemp.fsgst_percent
    let fcgst = paymentDetailsDataSetTemp.fcgst_percent
    const totalDays = Object.keys(paymentDetailsRoomTypeFoodingData).length;

    const calculatePerDayPerHead = (type) => {
      let totalRate = 0;
      let totalHeadCount = 0;
      let totalRateWithGst = 0;
      let food_cgst_amount = 0;
      let food_sgst_amount = 0;
      let food_gst_amount = 0;

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        let dayRate = 0;
        let dayHeadCount = 0;

        Object.keys(dayData).forEach((key) => {
          if (type === 'adult' && key === 'adult') {
            dayRate += (Number(dayData[key].breakfast_rate) +
              Number(dayData[key].lunch_rate) +
              Number(dayData[key].dinner_rate)) * dayData[key].head_count;
            dayHeadCount += dayData[key].head_count;
          } else if (type === 'child' && key !== 'adult') {
            dayRate += (Number(dayData[key].breakfast_rate) +
              Number(dayData[key].lunch_rate) +
              Number(dayData[key].dinner_rate)) * dayData[key].head_count;
            dayHeadCount += dayData[key].head_count;
          }
        });

        totalRate += dayRate;
        totalHeadCount += dayHeadCount;
      });

      food_cgst_amount = Number((totalRate * fcgst / 100).toFixed(2));
      food_sgst_amount = Number((totalRate * fsgst / 100).toFixed(2));
      food_gst_amount = food_cgst_amount + food_sgst_amount;
      totalRateWithGst = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalRate : totalRate + food_gst_amount;
      return totalHeadCount > 0 ? (totalRateWithGst / totalHeadCount).toFixed(2) : 0;
    };

    const calculateTotal = (type) => {
      let totalAmount = 0;
      let totalAmountWithGst = 0;
      let totalAmountChildren = 0;
      let totalAmountWithGstChildren = 0;
      let food_cgst_amount = 0;
      let food_sgst_amount = 0;
      let food_gst_amount = 0;

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        Object.keys(dayData).forEach((key) => {
          if (type === 'adult' && key === 'adult') {
            totalAmount += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
            food_cgst_amount = Number((totalAmount * fcgst / 100).toFixed(2));
            food_sgst_amount = Number((totalAmount * fsgst / 100).toFixed(2));
            food_gst_amount = food_cgst_amount + food_sgst_amount;
            totalAmountWithGst = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalAmount : totalAmount + food_gst_amount;
          } else if (type === 'child' && key !== 'adult') {
            totalAmountChildren += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
            food_cgst_amount = Number((totalAmountChildren * fcgst / 100).toFixed(2));
            food_sgst_amount = Number((totalAmountChildren * fsgst / 100).toFixed(2));
            food_gst_amount = food_cgst_amount + food_sgst_amount;
            totalAmountWithGstChildren = props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? totalAmountChildren : totalAmountChildren + food_gst_amount;
          }
        });


      });

      return type == 'adult' ? totalAmountWithGst.toFixed(2) : totalAmountWithGstChildren.toFixed(2);
    };

    const getTypeAndHeadCount = () => {
      let adultCount = 0;
      let childrenCount = 0;
      let childrenCountMap = new Map();

      Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
        if (dayData.adult) {
          adultCount = dayData.adult.head_count;
        }

        Object.keys(dayData).forEach((key) => {
          if (key !== 'adult' && dayData[key].hasOwnProperty('head_count') && dayData[key].head_count != null) {
            if (childrenCountMap.has(key)) {
              childrenCountMap.set(key, Number(dayData[key].head_count));
            } else {
              childrenCountMap.set(key, Number(dayData[key].head_count));
            }
          }
        });
      });
      childrenCountMap.forEach((count) => {
        childrenCount += count;
      });
      return { adultCount, childrenCount };
    };
    const { adultCount, childrenCount } = getTypeAndHeadCount();
    return (
      <div style={{ width: '100%', display: 'inline-block' }}>
        {/* Adult Row */}
        <div style={{ display: "table", width: "100%", paddingBottom: 4 }}>
          <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{Number(adultCount) > 1 ? Utility.capitalizeFirstLetterOfEachWord('Adults') : Utility.capitalizeFirstLetterOfEachWord('Adult')}</div>
          <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{adultCount}</div>
          <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{totalDays}</div>
          <div style={{ width: '20%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
            {adultCount > 0 ? Number(calculatePerDayPerHead('adult')).toFixed(2) : 0}
          </div>
          <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
            {adultCount > 0 ? Number(calculateTotal('adult')).toFixed(2) : 0}
          </div>
        </div>

        {/* Children Row (if present) */}
        {childrenCount > 0 && (
          <div style={{ width: '100%', display: 'inline-block', paddingBottom: 4 }}>
            <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{Number(childrenCount) > 1 ? Utility.capitalizeFirstLetterOfEachWord('Children') : Utility.capitalizeFirstLetterOfEachWord('Child')}</div>
            <div style={{ width: '25%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{childrenCount}</div>
            <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232" }}>{totalDays}</div>
            <div style={{ width: '20%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
              {childrenCount > 0 ? Number(calculatePerDayPerHead('child')).toFixed(2) : 0}
            </div>
            <div style={{ width: '15%', display: "inline-block", fontSize: "13px", fontWeight: "400", color: "#323232", textAlign: 'right' }}>
              {childrenCount > 0 ? Number(calculateTotal('child')).toFixed(2) : 0}
            </div>
          </div>
        )}
      </div>
    );
  }

  const pageStyle = `@media print {   
    @page {size: A4;  margin: 0;}   
    body {margin: 1cm;} 
  }`;
  const divPrint = React.useRef(null);
  let currencyCode = props.invoiceData.hasOwnProperty('currency') && props.invoiceData.currency ? props.invoiceData.currency : "INR";
  let checkInLabel = 'Check-in';
  if (props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('checked_in_at') && props.invoiceData.booking_master_details.checked_in_at) {
    checkInLabel = 'Checked-in';
  }
  let checkOutLabel = 'Check-out';
  if (props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('checked_out_at') && props.invoiceData.booking_master_details.checked_out_at) {
    checkOutLabel = 'Checked-out';
  }
  let bookingStatus = props.invoiceData.hasOwnProperty("booking_master_details") && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty("book_status") && props.invoiceData.booking_master_details.book_status && props.invoiceData.booking_master_details.book_status != "" ? props.invoiceData.booking_master_details.book_status : "";

  return (
    <div className='innerbodydimension' >
      <div className='invoiceBodyScroll' ref={divPrint} id="invoiceId"
        style={{ margin: auto, paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 15 }}
      >
        <div style={{ width: '100%', display: 'inline-block' }}>
          {!props.paymentDetailsResend ?
            <div style={{ width: '100%', display: 'flex', paddingTop: 0, paddingBottom: 0 }}>
              <div style={{ width: '50%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'flex', textAlign: 'left', paddingTop: 0, paddingBottom: 0, alignItems: 'center', }}>
                  <img src={require('../../../../Utility/Public/images/stayingLogo.png')} style={{ width: 'auto', height: '70px' }} />
                  <div style={{ width: 'auto', display: 'inline-block', textAlign: 'left', paddingTop: 0, paddingBottom: 0, paddingLeft: 10 }}>
                    <div style={{ fontSize: 17, fontWeight: 600, color: '#525252', display: 'inline-block', width: '100%' }}>The Staying</div>
                    <div style={{ fontSize: 14, fontWeight: 400, color: '#525252', display: 'inline-block', width: '100%' }}>{props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('BookingMasterBranchData') && props.invoiceData.booking_master_details.BookingMasterBranchData && props.invoiceData.booking_master_details.BookingMasterBranchData.hasOwnProperty('branch_name') && props.invoiceData.booking_master_details.BookingMasterBranchData.branch_name ? props.invoiceData.booking_master_details.BookingMasterBranchData.branch_name : ""}</div>
                    <div style={{ fontSize: 14, fontWeight: 400, color: '#525252', display: 'inline-block', width: '100%' }}>{props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('BookingMasterBranchData') && props.invoiceData.booking_master_details.BookingMasterBranchData && props.invoiceData.booking_master_details.BookingMasterBranchData.hasOwnProperty('city') && props.invoiceData.booking_master_details.BookingMasterBranchData.city ? Utility.capitalizeFirstLetterOfEachWord(props.invoiceData.booking_master_details.BookingMasterBranchData.city) : ""} - {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('BookingMasterBranchData') && props.invoiceData.booking_master_details.BookingMasterBranchData && props.invoiceData.booking_master_details.BookingMasterBranchData.hasOwnProperty('zip') && props.invoiceData.booking_master_details.BookingMasterBranchData.zip ? props.invoiceData.booking_master_details.BookingMasterBranchData.zip : ""}</div>
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', display: 'inline-block', textAlign: 'right' }}>
                <div style={{ fontSize: 14, fontWeight: 400, color: '#525252', display: 'inline-block', width: '100%' }}><span style={{ fontWeight: 600 }}>Call:</span>{props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('BookingMasterBranchData') && props.invoiceData.booking_master_details.BookingMasterBranchData && props.invoiceData.booking_master_details.BookingMasterBranchData.hasOwnProperty('contact1') && props.invoiceData.booking_master_details.BookingMasterBranchData.contact1 ? (props.invoiceData.booking_master_details.BookingMasterBranchData.contact1).includes("+") ? props.invoiceData.booking_master_details.BookingMasterBranchData.contact1 : "+" + props.invoiceData.booking_master_details.BookingMasterBranchData.contact1 : ""}</div>
                <div style={{ fontSize: 14, fontWeight: 400, color: '#525252', display: 'inline-block', width: '100%' }}><span style={{ fontWeight: 600 }}>Email:</span> {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('BookingMasterBranchData') && props.invoiceData.booking_master_details.BookingMasterBranchData && props.invoiceData.booking_master_details.BookingMasterBranchData.hasOwnProperty('email1') && props.invoiceData.booking_master_details.BookingMasterBranchData.email1 ? props.invoiceData.booking_master_details.BookingMasterBranchData.email1 : ""}</div>
              </div>
            </div> : null}
          {!props.paymentDetailsResend ?
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', borderTop: '1px solid black', borderBottom: '1px solid black', marginTop: 15, paddingTop: 2, paddingBottom: 2 }}>
              {props.invoiceData?.booking_master_details.customer_gstin_no && props.invoiceData.booking_master_details.customer_gstin_no != "" &&
                <div style={{ width: '50%', display: 'inline-block' }}>
                  <div style={{ fontSize: 14, fontWeight: 400, color: '#525252', display: 'inline-block', width: '100%' }}> <span style={{ fontWeight: 600 }}>GSTIN:</span> {props.invoiceData.booking_master_details.customer_gstin_no}</div>
                </div>}


              <div style={{ width: '50%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'inline-block', textAlign: 'left', paddingTop: 0, paddingBottom: 0 }}>
                  <span style={{ fontSize: 22, fontWeight: 600, color: '#525252' }}>INVOICE</span>
                </div>
              </div>

            </div>
            : null}

          <div style={{ width: '100%', display: 'flex', borderBottom: '1px solid black' }}>
            {!props.paymentDetailsResend ?
              <div style={{ width: '36%', display: 'inline-block', borderRight: '1px solid black', }}>
                <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Customer</div>
                <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>
                  <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>M/S</div>
                  <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.booking_master_details.customer_name}</div>
                </div>

                {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('company_name') && props.invoiceData.booking_master_details.company_name && props.invoiceData.booking_master_details.company_name != "" &&
                  <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }}>
                    <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Company</div>
                    <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.booking_master_details.company_name}</div>
                  </div>}
                {props.invoiceData?.booking_master_details.company_address && props.invoiceData.booking_master_details.company_address != "" &&
                  <div style={{ width: '100%', display: 'inline-block' }}>
                    <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Address</div>
                    <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.booking_master_details.company_address}</div>
                  </div>}
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Phone</div>
                  <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.booking_master_details.customer_phone}</div>
                </div>

              </div>
              : null}
            <div style={{ width: '64%', display: 'inline-block', paddingLeft: 10, }}>
              {!props.paymentDetailsResend ?
                <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>

                  <div style={{ width: '55%', display: 'inline-block' }}>
                    <div style={{ width: '60%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Invoice No.</div>
                    <div style={{ width: '40%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right', paddingRight: 20 }}>{props.invoiceData.invoiceId}</div>
                  </div>

                  <div style={{ width: '45%', display: 'inline-block' }}>
                    <div style={{ width: '60%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Invoice Date</div>
                    <div style={{ width: '40%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{props.invoiceData.invoice_date}</div>
                  </div>
                </div> : null}
              <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>
                <div style={{ width: '55%', display: 'inline-block' }}>
                  <div style={{ width: '60%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Booking Amount</div>
                  <div style={{ width: '40%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right', paddingRight: 20 }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.total_amount)}</div>
                </div>
                <div style={{ width: '45%', display: 'inline-block' }}>
                  <div style={{ width: '60%', color: '#525252', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Billing Amount</div>
                  <div style={{ width: '40%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.hasOwnProperty('billing_amount') && props.invoiceData.billing_amount != "" && props.invoiceData.billing_amount != null ? props.invoiceData.billing_amount : 0.00)}</div>
                </div>
                <div style={{ width: '55%', display: 'inline-block' }}>
                  <div style={{ width: '60%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Total Paid Amount</div>
                  <div style={{ width: '40%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right', paddingRight: 20 }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.total_paid)}</div>
                </div>
                <div style={{ width: '45%', display: 'inline-block' }}>
                  <div style={{ width: '60%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Total Due Amount</div>
                  <div style={{ width: '40%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData.due_amt))}</div>
                </div>
                {Number(props.invoiceData.special_discount) > 0 ?
                  <div style={{ width: '55%', display: 'inline-block' }}>
                    <div style={{ width: '60%', color: '#525252', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Special Discount for Room{props.invoiceData.special_discount_perc && props.invoiceData.special_discount_perc != "" ? "(" + props.invoiceData.special_discount_perc + "%)" : null}</div>
                    <div style={{ width: '40%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right', paddingRight: 20 }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData.special_discount))}</div>
                  </div>
                  : null}
                {Number(props.invoiceData.special_discount_for_food) > 0 ?
                  <div style={{ width: '45%', display: 'inline-block' }}>
                    <div style={{ width: '60%', color: '#525252', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Special Discount for Food{props.invoiceData.special_discount_perc_for_food && props.invoiceData.special_discount_perc_for_food != "" ? "(" + props.invoiceData.special_discount_perc_for_food + "%)" : null}</div>
                    <div style={{ width: '40%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData.special_discount_for_food))}</div>
                  </div>
                  : null}
                {Number(props.invoiceData.round_off_amount) != 0 ?
                  <div style={{ width: '55%', display: 'inline-block' }}>
                    <div style={{ width: '60%', color: '#525252', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Round of Amount</div>
                    <div style={{ width: '40%', color: '#525252', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right', paddingRight: 20 }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData.round_off_amount))}</div>
                  </div>
                  : null}
              </div>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', borderBottom: '1px solid black' }}>
            <div style={{ width: '36%', display: 'inline-block', borderRight: '1px solid black', }}>
              <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Overview</div>
              <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Booking ID</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.booking_id}</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Booking Date</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{moment(props.invoiceData.booking_date).format('DD-MM-YYYY')}</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>{checkInLabel}</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{moment(props.invoiceData.checked_in).format('DD-MM-YYYY HH:mm:ss')}</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>{checkOutLabel}</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{moment(props.invoiceData.original_checked_datetime).format('DD-MM-YYYY HH:mm:ss')}</div>
              </div>
              {props.invoiceData.hasOwnProperty("booking_master_details") && props.invoiceData.booking_master_details.is_early_checkout == 1 &&
                <div style={{ width: '100%', display: 'inline-block', paddingBottom: 2 }}>
                  <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Early Checkout Date</div>
                  <div style={{ width: '70%', color: '#ff0000', fontSize: 13, fontWeight: 400, display: 'inline-block' }}>{moment(props.invoiceData.checked_out.toString()).format("DD-MM-YYYY HH:mm")}</div>
                </div>
              }
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Adult</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.adult}</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>child</div>
                <div style={{ width: '70%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block' }}>{props.invoiceData.child}</div>
              </div>

            </div>
            <div style={{ width: '64%', display: 'inline-block', paddingLeft: 0, paddingRight: 0 }}>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Room Type Details</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Room Type</div>
                <div style={{ width: '35%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', paddingLeft: 5 }}>Time Duration</div>
                {/* <div style={{ width: '17%',  fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Day Count</div> */}
                <div style={{ width: '10%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>#Room(s)</div>
                <div style={{ width: '16%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Room Nums</div>
                <div style={{ width: '13%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Price/Night ({u.getCurrencySymbol(currencyCode)})</div>
                <div style={{ width: '12%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
              </div>


              {roomTypeDetailsUi()}
              {props.invoiceData?.special_discount > 0 ?
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                  <div style={{ width: '86.5%', color: '#333333', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>Discount</div>
                  <div style={{ width: '13.5%', color: '#333333', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.special_discount)}</div>
                </div> : null}
              <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                <div style={{ width: '86.5%', color: '#333333', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>Sub Total</div>
                <div style={{ width: '13.5%', color: '#333333', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData?.total_amount_without_gst) - Number(props.invoiceData?.special_discount))}</div>
              </div>
              {props.invoiceData?.cgst_amount && parseFloat(props.invoiceData.cgst_amount) > 0 ?
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                  <div style={{ width: '86.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>CGST @ {u.getTwoDecimalNumber(props.invoiceData.cgst_percent)}%</div>
                  <div style={{ width: '13.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.cgst_amount)}</div>
                </div> : null}

              {props.invoiceData?.sgst_amount && parseFloat(props.invoiceData.sgst_amount) > 0 ?
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                  <div style={{ width: '86.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>SGST @ {u.getTwoDecimalNumber(props.invoiceData.sgst_percent)}%</div>
                  <div style={{ width: '13.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.sgst_amount)}</div>
                </div> : null}

              {props.invoiceData?.igst_amount && parseFloat(props.invoiceData.igst_amount) > 0 ?
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10 }}>
                  <div style={{ width: '86.5%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>IGST @ {u.getTwoDecimalNumber(props.invoiceData.igst_percent)}%</div>
                  <div style={{ width: '13.5%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.igst_amount)}</div>
                </div> : null}

              <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10, paddingBottom: 6 }}>
                <div style={{ width: '86.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Total Payable Amount (Room)</div>
                <div style={{ width: '13.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData?.total_amount_without_gst) + Number(props.invoiceData?.cgst_amount) + Number(props.invoiceData?.sgst_amount) + Number(props.invoiceData?.fgst_amount) - Number(props.invoiceData?.special_discount))}</div>
              </div>

            </div>
          </div>





          <div style={{ width: '100%', display: 'flex', borderBottom: '1px solid black' }}>
            <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0, paddingRight: 0 }}>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Fooding Details</div>
              </div>
              {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('hide_food_details') && props.invoiceData.booking_master_details.hide_food_details ?
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '25%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Type</div>
                  <div style={{ width: '25%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Head Count</div>
                  <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Day(s)</div>
                  <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', position: 'relative', textAlign: 'right' }}>Per Day Per Head ({u.getCurrencySymbol(currencyCode)}) {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <span style={{ fontSize: "8px", position: "absolute", right: "6px", bottom: "-8px" }}>(Including Tax)</span>}</div>
                  <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right' }}>Total ({u.getCurrencySymbol(currencyCode)})</div>
                </div>
                :
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '10%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Date</div>
                  <div style={{ width: '9%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Head Count</div>
                  <div style={{ width: '10%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Breakfast</div>
                  <div style={{ width: '15%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Breakfast / Person ({u.getCurrencySymbol(currencyCode)})</div>
                  <div style={{ width: '8%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Lunch</div>
                  <div style={{ width: '15%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Lunch / Person ({u.getCurrencySymbol(currencyCode)})</div>
                  <div style={{ width: '8%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Dinner</div>
                  <div style={{ width: '15%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Dinner / Person ({u.getCurrencySymbol(currencyCode)})</div>
                  <div style={{ width: '10%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
                </div>}


              {/* {foodingDetailsUi()} */}
              {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('hide_food_details') && props.invoiceData.booking_master_details.hide_food_details ? foodingDetailsHideUi() : foodingDetailsUi()}

              {Number(props.invoiceData?.special_discount_for_food) > 0 ?
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '91%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>Discount</div>
                  <div style={{ width: '9%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.special_discount_for_food)}</div>
                </div> : null}


              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '91%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>Sub Total</div>
                <div style={{ width: '9%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(props.invoiceData.food_amount) - Number(props.invoiceData.special_discount_for_food))}</div>
              </div>

              {props.invoiceData?.fcgst_amount && parseInt(props.invoiceData.fcgst_amount) > 0 ?

                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '91.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>CGST @ {u.getTwoDecimalNumber(props.invoiceData.fcgst_percent)}%</div>
                  <div style={{ width: '8.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.fcgst_amount)}</div>
                </div> : null}

              {props.invoiceData?.fsgst_amount && parseInt(props.invoiceData.fsgst_amount) > 0 ?
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '91.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>SGST @ {u.getTwoDecimalNumber(props.invoiceData.fsgst_percent)}%</div>
                  <div style={{ width: '8.5%', fontSize: 13, fontWeight: 400, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.fsgst_amount)}</div>
                </div>
                : null}

              {props.invoiceData?.fgst_amount && parseInt(props.invoiceData.fgst_amount) > 0 ?

                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div style={{ width: '91%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>IGST @ {u.getTwoDecimalNumber(props.invoiceData.fgst_percent)}%</div>
                  <div style={{ width: '9%', color: '#323232', fontSize: 13, fontWeight: 400, display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(props.invoiceData.fgst_amount)}</div>
                </div>
                : null}

              <div style={{ width: '100%', display: 'inline-block', paddingBottom: 6 }}>
                <div style={{ width: '91.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Total Payable Amount (Food)</div>
                <div style={{ width: '8.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(u.getTwoDecimalNumber(props.invoiceData.food_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.fcgst_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.fsgst_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.fgst_amount)) - Number(u.getTwoDecimalNumber(props.invoiceData.special_discount_for_food)))}
                </div>
              </div>
            </div>
          </div>

          {bookingStatus == 'Early Checkout' && <div style={{ width: '100%', display: 'flex', borderBottom: '1px solid black' }}>
            <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0, paddingRight: 0 }}>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'inline-block', textDecoration: 'underline', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Early Checkout Room Details</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0, paddingRight: 0 }}>
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0 }}>
                  <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Room Type</div>
                  <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', paddingLeft: 5 }}>Time Duration</div>
                  <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>#Room(s)</div>
                  <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Room Nums</div>
                  <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Price/Night ({u.getCurrencySymbol(currencyCode)})</div>
                  <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
                </div>
                {earlyCheckoutRoomTypeDetailsUi()}
              </div>
              {props.invoiceData.hasOwnProperty('early_date_wise_food_hash') && props.invoiceData.early_date_wise_food_hash && Object.keys(props.invoiceData.early_date_wise_food_hash).length > 0 ?
                <>
                  <div style={{ width: '100%', display: 'inline-block' }}>
                    <div style={{ width: '100%', display: 'inline-block', textDecoration: 'underline', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Early Checkout Food Details</div>
                  </div>
                  {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('hide_food_details') && props.invoiceData.booking_master_details.hide_food_details ?
                    <div style={{ width: '100%', display: 'inline-block' }}>
                      <div style={{ width: '25%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Type</div>
                      <div style={{ width: '25%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Head Count</div>
                      <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Day(s)</div>
                      <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', position: 'relative', textAlign: 'right' }}>Per Day Per Head ({u.getCurrencySymbol(currencyCode)}) {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <span style={{ fontSize: "8px", position: "absolute", right: "6px", bottom: "-8px" }}>(Including Tax)</span>}</div>
                      <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right' }}>Total ({u.getCurrencySymbol(currencyCode)})</div>
                    </div>
                    :
                    <div style={{ width: '100%', display: 'inline-block' }}>
                      <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Date</div>
                      <div style={{ width: '30%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Head Count</div>
                      <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Breakfast</div>
                      <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Lunch</div>
                      <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'left' }}>Dinner</div>
                      <div style={{ width: '14%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
                    </div>}
                  {props.invoiceData.hasOwnProperty('booking_master_details') && props.invoiceData.booking_master_details && props.invoiceData.booking_master_details.hasOwnProperty('hide_food_details') && props.invoiceData.booking_master_details.hide_food_details ? earlyCheckoutFoodingDetailsHideUi() : earlyCheckoutFoodingDetailsUi()}
                </> : null}
              {props.invoiceData.hasOwnProperty('early_date_wise_note_arr') && props.invoiceData.early_date_wise_note_arr && Object.keys(props.invoiceData.early_date_wise_note_arr).length > 0 ?
                <>
                  <div style={{ width: '100%', display: 'inline-block' }}>
                    <div style={{ width: '100%', display: 'inline-block', textDecoration: 'underline', color: '#323232', fontSize: 16, fontWeight: 600, textAlign: 'center' }}>Early Checkout Additional Service Details</div>
                  </div>
                  <div style={{ width: '100%', display: 'inline-block', paddingTop: 2 }}>
                    <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "40%" : "25%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Description</div>
                    <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "40%" : "25%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Service Date</div>
                    <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "20%" : "15%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right' }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
                    {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <> <div style={{ width: '15%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right' }}>Tax (%)</div>
                      <div style={{ width: '19.6%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right', position: 'relative', textAlign: 'right' }}>Amount({u.getCurrencySymbol(currencyCode)}) <span style={{ fontSize: '8px', position: 'absolute', right: '6px', bottom: '-8px' }}>(Including Tax)</span></div>
                    </>}
                  </div>
                  {payableServiceDetailsEarlyCheckoutUi()}
                </> : null}
              {(Number(props.invoiceData.full_cancelation_details.extra_amount) > 0 || Number(props.invoiceData.full_cancelation_details.extra_discount) > 0) &&
                <>
                  <div style={{ width: '100%', display: 'inline-block', paddingBottom: 6 }}>
                    <div style={{ width: '91.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Total Amount (Including Taxes)</div>
                    <div style={{ width: '8.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{props.invoiceData?.full_cancelation_details ? u.getCurrencySymbol(currencyCode) + "" + parseFloat(props.invoiceData.full_cancelation_details.total_amount_to_pay).toFixed(2) : 0}</div>
                  </div>
                  {Number(props.invoiceData?.full_cancelation_details.extra_discount) > 0 ?
                    <div style={{ width: '100%', display: 'inline-block', paddingBottom: 6 }}>
                      <div style={{ width: '91.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Discount</div>
                      <div style={{ width: '8.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{props.invoiceData.full_cancelation_details ? u.getCurrencySymbol(currencyCode) + "" + parseFloat(props.invoiceData.full_cancelation_details.extra_discount).toFixed(2) : 0}</div>
                    </div> :
                    <div style={{ width: '100%', display: 'inline-block', paddingBottom: 6 }}>
                      <div style={{ width: '91.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Extra charge</div>
                      <div style={{ width: '8.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{props.invoiceData.full_cancelation_details ? u.getCurrencySymbol(currencyCode) + "" + parseFloat(props.invoiceData.full_cancelation_details.extra_amount).toFixed(2) : 0}</div>
                    </div>}
                </>
              }
              <div style={{ width: '100%', display: 'inline-block', paddingBottom: 6 }}>
                <div style={{ width: '91.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Total Payable Amount (Including Taxes)</div>
                <div style={{ width: '8.5%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(u.getTwoDecimalNumber(props.invoiceData.full_cancelation_amount)))}</div>
              </div>
            </div>
          </div>}
          <div style={{ width: '100%', display: 'flex', borderBottom: '1px solid black' }}>
            <div style={{ width: '36%', display: 'inline-block', borderRight: '1px solid black', }}>
              <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Payable Service Details</div>
              <div style={{ width: '100%', display: 'inline-block', paddingTop: 2, marginBottom: '10px' }}>
                <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Description</div>
                <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block' }}>Service Date</div>
                <div style={{ width: props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? "33.33%" : "20%", color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right', paddingRight: 6 }}>Amount ({u.getCurrencySymbol(currencyCode)})</div>
                {props.invoiceData.hasOwnProperty('no_tax') && props.invoiceData.no_tax ? null : <>
                  <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right', paddingRight: 6 }}>Tax (%)</div>
                  <div style={{ width: '20%', color: '#323232', fontSize: 13, fontWeight: 600, display: 'inline-block', textAlign: 'right', paddingRight: 6, position: 'relative', }}>Amount({u.getCurrencySymbol(currencyCode)})<span style={{ fontSize: '8px', position: 'absolute', right: '6px', bottom: '-8px' }}>(Including Tax)</span></div>
                </>}
              </div>
              {payableServiceDetailsUi()}
              {(Number(u.getTwoDecimalNumber(props.invoiceData.notation_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.notation_sgst_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.notation_cgst_amount))) > 0 &&
                <div style={{ width: '100%', display: 'inline-block', paddingLeft: 0, paddingRight: 6, paddingBottom: 6 }}>
                  <div style={{ width: '76%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>Total Payable Amount for Service</div>
                  <div style={{ width: '24%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'right' }}>{u.getCurrencySymbol(currencyCode)} {u.getTwoDecimalNumber(Number(u.getTwoDecimalNumber(props.invoiceData.notation_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.notation_sgst_amount)) + Number(u.getTwoDecimalNumber(props.invoiceData.notation_cgst_amount)))}</div>
                </div>}
            </div>
            <div style={{ width: '64%', display: 'inline-block', paddingLeft: 0, paddingRight: 0 }}>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div style={{ width: '100%', display: 'inline-block', borderBottom: '1px solid black', fontSize: 16, fontWeight: 600, color: '#525252', textAlign: 'center' }}>Payment History</div>
              </div>
              <div style={{ width: '100%', display: 'inline-block', paddingLeft: 10, paddingRight: 10 }}>
                <div style={{ width: '27%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', paddingRight: 6 }}>Receipt ID</div>
                <div style={{ width: '15%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', paddingRight: 6 }}>Mode</div>
                <div style={{ width: '18%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'center' }}>Paid ({u.getCurrencySymbol(currencyCode)})</div>
                <div style={{ width: '18%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block', textAlign: 'center' }}>Refund ({u.getCurrencySymbol(currencyCode)})</div>
                <div style={{ width: '22%', fontSize: 13, fontWeight: 600, color: '#525252', display: 'inline-block' }}>Transaction Date</div>
              </div>
              {paymentHistory()}
            </div>
          </div>
        </div>
      </div>

      {props.paymentDetailsResend ?
        <div className={`printbttn ${isPrintScreen ? 'hide-print-button' : ''}`} style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
          <input type="button" value="Send" className='modbtn' onClick={props.sendDocumentToUser} />
        </div>
        :
        <div className={`printbttn ${isPrintScreen ? 'hide-print-button' : ''}`} style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
          <ReactToPrint
            trigger={() => <button className='modbtn' style={{ background: '#525252', border: '1px solid #525252', color: '#e9c905', borderRadius: '4px', fontSize: '13px', padding: '5px 20px' }}>Print</button>

            }
            content={() => divPrint.current}
            pageStyle={pageStyle}
          />
          <input type="button" value="Download" className='modbtn' onClick={downloadPdfDocument} />
        </div>
      }
    </div>
  )
}

GenerateInvoiceContent.defaultProps = {
  paymentDetailsResend: false
}



export default GenerateInvoiceContent