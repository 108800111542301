import React, { Component } from 'react';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
      errorStack: "",
      errorDetailsFlag: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorInfo: error.message, errorStack: info.componentStack });
  }

  errorDetails = () => {
    this.setState({
      errorDetailsFlag: !this.state.errorDetailsFlag
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
        <div className='errorDiv'>
          <h1>{this.props.title}</h1>
          <div className='errorIconBox'>
            <div className='errorIcon'>
              </div>
          <p>{this.state.errorInfo.toUpperCase()}</p>
          </div>
          
          <button className='errorDetailsbtn' onClick={() => this.errorDetails()}>Error Details</button>
          {this.state.errorDetailsFlag &&
            <p className='errorDetail'>{this.state.errorStack}</p>
          }
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
