import React, { useState, useEffect, useRef } from 'react';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { setToken, logOutApp, setUserCredentials } from '../Actions/LoginAction';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Utility from '../../../Utility/Utility';
import * as  LoginController from '../Controller/LoginController'
import QRCodeGenerator from './QRCodeGenerator';
import ModalGlobal from '../../../Utility/Components/ModalGlobal';
import ConfirmationAlert from '../../../Utility/Components/ConfirmationAlert'

const SmsVerification = (props) => {
  let length = 6;
  const [otp, setOtp] = useState(Array(length).fill(''));
  const [errorOtpState, setErrorOtpState] = useState(Array(length).fill(false));
  const inputs = useRef([]);
  const [enableMfa, setEnableMfa] = useState(false);
  const [enableMfaFormData, setEnableMfaFormData] = useState({ 'two_factor_login': false, 'two_factor_method': 'sms' });
  const [secretCodeValue, setSecretCodeValue] = useState('');
  const [authenticatorModalFlag, setAuthenticatorModalFlag] = useState(false);
  const [authenticatorModalFlagLink, setAuthenticatorModalFlagLink] = useState(false);
  const [disableMfaConfirmationModalFlag, setDisableMfaConfirmationModalFlag] = useState(false);


  //check mfa enable or not depending on enableMfa props
  useEffect(() => {
    if (props.enableMfa) {
      setEnableMfa(true);
    }
  }, [props.enableMfa])

  //check which type of mfa enable depending on callFrom props
  useEffect(() => {
    if (props.callFrom == 'SOFTWARE_TOKEN_MFA') {
      setEnableMfaFormData({ 'two_factor_login': true, 'two_factor_method': 'authenticator' });
    } else if (props.callFrom == 'SMS_MFA') {
      setEnableMfaFormData({ 'two_factor_login': true, 'two_factor_method': 'sms' });
    }
  }, [props.callFrom]);

  //otp input box handlechange fn
  const handleChange = (e, index) => {
    const { value } = e.target;
    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      const newErrorState = [...errorOtpState];
      newErrorState[index] = false;
      setErrorOtpState(newErrorState);
      // Move focus to the next input
      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  //otp input box handleKeyDown fn
  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      // If the current input is not empty, clear it
      if (newOtp[index]) {
        newOtp[index] = '';
        setOtp(newOtp);
      }
      // If the current input is already empty, move focus to the previous input
      else if (index > 0) {
        inputs.current[index - 1].focus();
        const prevOtp = [...otp];
        prevOtp[index - 1] = ''; // Clear the previous input
        setOtp(prevOtp);
      }
    }
  };

  //submit otp fn
  const verifyOtpFn = () => {
    const newErrorState = otp.map(value => value === '');
    setErrorOtpState(newErrorState);
    if (!newErrorState.includes(true)) {
      props.handleSubmitOtpVerfication(otp, enableMfaFormData.two_factor_method == 'authenticator' ? 'authenticator' : 'sms');
    }
  }

  //otp will resend using this api fn
  const resendVerificationCodeFn = () => {
    const { loaderStateTrue, loaderStateFalse } = props;
    loaderStateTrue();
    const data = {
      username: props.formData.email,
    }
    LoginController.resendOtp(data).then((response) => {
      if (response.success) {
        Utility.toastNotifications(response.message, 'Success', 'success');
      } else {
        Utility.toastNotifications(response.message, 'Error', 'error');
      }
      loaderStateFalse();
    }).catch((error) => {
      loaderStateFalse();

    });
  }

  //mfa enable toggle button and sms,auth app radio button handlechange fn
  const handleChangeEnableMfaFormDataFn = (e, type) => {
    let tempFormData = { ...enableMfaFormData };
    if (type == "two_factor_login") {
      if (e.target.checked) {
        tempFormData["two_factor_login"] = true;
      } else {
        tempFormData["two_factor_login"] = false;
      }
    }
    if (type == "sms") {
      if (e.target.checked) {
        tempFormData["two_factor_method"] = "sms";
      }
    }
    if (type == "authenticator") {
      if (e.target.checked) {
        tempFormData["two_factor_method"] = 'authenticator';
      }
    }
    setEnableMfaFormData(tempFormData)
  };

  //get current user details 
  const getCurrentUserFn = () => {
    const { loaderStateTrue, loaderStateFalse, setUserCredentials } = props;
    LoginController.getCurrentUser().then((response) => {
      if (response.success) {
        setUserCredentials(response.data)
      }
    }).catch((error) => {
      console.error("************error*************", error)
      loaderStateFalse();
    });
  }

  //mfa option submit fn
  const submitMfaFn = () => {
    const { loaderStateTrue, loaderStateFalse } = props;
    if (enableMfaFormData.two_factor_login) {
      if (enableMfaFormData.two_factor_login && enableMfaFormData.two_factor_method == 'authenticator') {
        loaderStateTrue();
        LoginController.associateSoftwareToken().then((response) => {
          if (response.success) {
            let secretCode = response?.data?.SecretCode;
            setSecretCodeValue(secretCode);
            setTimeout(() => {
              setEnableMfa(true);

            }, 200);
          } else {
          }
          loaderStateFalse();
        }).catch((error) => {
          loaderStateFalse();

        });
      } else {
        loaderStateTrue();
        const data = {
          "mfa_type": enableMfaFormData.two_factor_login ? enableMfaFormData.two_factor_method == 'sms' ? "sms" : "authenticator_app" : "disabled",
          "user_name": props?.formData?.email
        }
        LoginController.mfaAssign(data).then((response) => {
          if (response.success) {
            if (enableMfaFormData.two_factor_login && enableMfaFormData.two_factor_method == 'sms') {
              props.closeMfaModalFn(enableMfaFormData.two_factor_login);
              Utility.toastNotifications('SMS Multi-Factor Authentication is now enabled.', 'Success', 'success');
            } else if (enableMfaFormData.two_factor_login && enableMfaFormData.two_factor_method == 'authenticator') {
              setEnableMfa(true);
            } else {
              props.closeMfaModalFn(enableMfaFormData.two_factor_login);
            }
            getCurrentUserFn();
          } else {
            Utility.toastNotifications(response.message, 'Error', 'error');
          }
          loaderStateFalse();
        }).catch((error) => {
          loaderStateFalse();

        });
      }
    } else {
      if (props.userCredentials.user_details.user_type == 'app_admin') {
        setDisableMfaConfirmationModalFlag(true);
      } else {
        setDisableMfaConfirmationModalFlag(true);
      }
    }
  }

  //mfa popup skip fn
  const skipMfaFn = () => {
    props.closeMfaModalFn();
  }

  //auth app list show pop up
  const openAuthenticatorModalFn = () => {
    setAuthenticatorModalFlag(true);
  }

  //auth app list close fn
  const closAuthenticatorModalFn = () => {
    setAuthenticatorModalFlag(false);
  }

  //auth app list show pop up
  const openAuthenticatorModalFnLink = () => {
    setAuthenticatorModalFlagLink(true);
  }

  //auth app list close pop up fn
  const closAuthenticatorModalFnLink = () => {
    setAuthenticatorModalFlagLink(false);
  }

  //disable mfa confirmation modal close fn
  const closeDisableMfaConfirmationModalFn = () => {
    setDisableMfaConfirmationModalFlag(false);
  }

  //disable mfa confirmation modal confirm fn
  const deleteConfirmButton = () => {
    loaderStateTrue();
    const data = {
      "mfa_type": "disable",
      "user_name": props?.formData?.email
    }
    LoginController.mfaAssign(data).then((response) => {
      if (response.success) {
        Utility.toastNotifications(response.message, 'Success', 'success');
        if (enableMfaFormData.two_factor_login) {
          setEnableMfa(true);
        } else {
          props.closeMfaModalFn(enableMfaFormData.two_factor_login);
        }
        getCurrentUserFn();
      } else {
        Utility.toastNotifications(response.message, 'Error', 'error');
      }
      loaderStateFalse();
    }).catch((error) => {
      loaderStateFalse();

    });
    setDisableMfaConfirmationModalFlag(false);
  };

  //disable mfa confirmation modal cancel fn
  const deleteCancleButton = () => {
    setDisableMfaConfirmationModalFlag(false);
  };

  return (

    <div>
      {enableMfa ? <div className="smsverificationPage">
        <div className="logo-box-inner">
          <div className="titleposition">
          </div>
        </div>
        <div className="logo-box-inner getOTPBox">
          {props.callFrom === "SMS_MFA" && props.enableMfa ? (
            <p className='smsVerificationText'>Enter verification code sent to your registered phone number</p>
          ) : props.callFrom === "SOFTWARE_TOKEN_MFA" && props.enableMfa ? (
            <p className='smsVerificationText'>Please enter the 6-digit code from your <span onClick={openAuthenticatorModalFn}>authenticator app</span>. This code is time-sensitive and changes every 30 seconds. Ensure you enter the latest code before submitting.</p>
          ) : props.callFrom === "email" ? (
            <p className='smsVerificationText'>Enter verification code sent to {props.formData.email}</p>
          ) : (
            <p></p>
          )}
          {props.callFrom == '' && enableMfaFormData.two_factor_method == "authenticator" && !props.enableMfa && <div>
            <QRCodeGenerator secretCode={secretCodeValue} accountName={props.formData.email} openAuthenticatorModalFn={openAuthenticatorModalFn} />
          </div>}
          {(props.callFrom == 'SMS_MFA' || props.callFrom == 'SOFTWARE_TOKEN_MFA') && enableMfaFormData.two_factor_method == "authenticator" && !props.enableMfa && <div>
            <QRCodeGenerator secretCode={secretCodeValue} accountName={props.formData.email} openAuthenticatorModalFn={openAuthenticatorModalFn} />
          </div>}
        </div>
        <div className="itemsContainer login_left_inner"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              verifyOtpFn();
            }
          }}
          tabIndex="0"
        >
          <div className="forgotFormDiv">
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {otp.map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputs.current[index] = el)}
                    autoFocus={index === 0}
                    style={{
                      width: '40px',
                      height: '40px',
                      margin: '0 5px',
                      textAlign: 'center',
                      fontSize: '18px',
                      border: `1px solid ${errorOtpState[index] ? 'red' : '#ccc'}`,
                      borderRadius: '4px',
                    }}
                  />
                ))}
              </div>
              {props.callFrom == 'email' && props.enableMfa && <div className='resendVerificationCode'>
                <h6
                  style={{
                    margin: 0,
                    padding: 0,
                    position: 'relative',
                    color: '#5f646d',
                    fontSize: '13px',
                    lineHeight: '27px'
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'}
                  onClick={resendVerificationCodeFn}
                >
                  Resend Verification Code
                </h6>
              </div>}
              <div className="forgor_btn_inner_div varifyOTPButton">
                <button className="login-btn btn btn-primary verifyOtpBtn" onClick={() => { verifyOtpFn() }}>Verify OTP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
        :
        <div className="enabledMFAContainer authenticationBoxes">
          <div className="logo-box-inner authenticationLogo">
            <div className="titleposition">
              <img src={require('../../../Utility/Public/images/shield.png')} className="logoInner" />
            </div>
          </div>
          {props.disableAction ?
            <p className='mfaSelectionText'>Your organization requires MFA for enhanced security. Please enable MFA to proceed further by selecting one of the following options:</p> :
            <>
              <div className="col-md-12">
                <div className='listingPrefaranceBox globallistingPrefaranceBox'>
                  <div className='chkBoxPanelList'>
                    <p className='enableTitle'>Enable MFA</p>
                    <label className="switchBoxMFA">
                      <input type="checkbox"
                        checked={enableMfaFormData.two_factor_login}
                        onChange={(e) => handleChangeEnableMfaFormDataFn(e, "two_factor_login")}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </>}
          <div className="col-md-12">
            {
              <>
                <div className="radioBoxPanel">
                  <label className={`radiocontainer ${enableMfaFormData.two_factor_login ? '' : 'disable'}`}>
                    <div className="media">
                      <div className="media-left">
                        <img src={require('../../../Utility/Public/images/smartphone1.png')} className="phIcon" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Text Message (SMS)</h4>
                        <p>(Standard message rates apply) <span>Receive a text message to your mobile device when signing in.</span> </p>
                      </div>
                    </div>
                    <input type="radio" checked={enableMfaFormData.two_factor_login ? enableMfaFormData.two_factor_method === 'sms' : false} onChange={(e) => handleChangeEnableMfaFormDataFn(e, "sms")} name="twoFactorMethod" value="sms" disabled={enableMfaFormData.two_factor_login ? false : true} />
                    <span className="checkmark"></span>
                  </label>
                  <label className={`radiocontainer ${enableMfaFormData.two_factor_login ? '' : 'disable'}`}>
                    <div className="media">
                      <div className="media-left">
                        <img src={require('../../../Utility/Public/images/smartphone2.png')} className="phIcon" />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Authenticator App</h4>
                        <p><span className='clickspan'
                          onClick={openAuthenticatorModalFnLink}
                        >(Microsoft Authenticator, Twilio Authy etc.)</span> <span>Retrieve codes from an Authentication app on your device.</span></p>
                      </div>
                    </div>
                    <input type="radio" checked={enableMfaFormData.two_factor_login ? enableMfaFormData.two_factor_method === 'authenticator' : false} onChange={(e) => handleChangeEnableMfaFormDataFn(e, "authenticator")} name="twoFactorMethod" value="authenticator" disabled={enableMfaFormData.two_factor_login ? false : true} />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </>
            }
          </div>
          <div className="col-md-12 footerBtnContainer">
            <button className="login-btn btn btn-primary footerSubmitBtn" onClick={() => { submitMfaFn() }}>Submit</button>
            {!props.disableAction && <button className="login-btn btn btn-primary footerSkipBtn" onClick={() => { skipMfaFn() }}>Skip</button>}
          </div>
        </div>
      }
      <ModalGlobal
        show={authenticatorModalFlag}
        onHide={closAuthenticatorModalFn}
        title=""
        className="modalcustomize mondimension smsVerificationModalContent authenticatorappContent"
        footer={false}
        closeButton={true}
        saveButtonLabel="Confirm"
        saveButtonClassName="btn btn-primary savebtn"
        cancelShow={true}
        body={
          <>
            <p>The following authenticator apps support TOTP and can be configured by scanning the QR code:</p>
            <ul>
              <li>Twilio Authy</li>
              <li>Duo Mobile</li>
              <li>Microsoft Authenticator</li>
              <li>Google Authenticator</li>
              <li>Symantec VIP</li>
            </ul>

          </>
        }
      />
      <ModalGlobal
        show={authenticatorModalFlagLink}
        onHide={closAuthenticatorModalFnLink}
        title=""
        className="modalcustomize mondimension smsVerificationModalContent authenticatorappContent authenticatorModalFlagLinkContent"
        footer={false}
        closeButton={true}
        saveButtonLabel="Confirm"
        saveButtonClassName="btn btn-primary savebtn"
        cancelShow={true}
        body={
          <>
            <ul>
              <li>Twilio Authy</li>
              <li>Duo Mobile</li>
              <li>Microsoft Authenticator</li>
              <li>Google Authenticator</li>
              <li>Symantec VIP</li>
            </ul>

          </>
        }
      />

      <ModalGlobal
        show={disableMfaConfirmationModalFlag}
        onHide={closeDisableMfaConfirmationModalFn}
        className="modalcustomize confirmationalertmodal disableMFAconfirmation"
        bodyClassName="cancelConfirmationbody"
        headerclassName="close_btn_icon"
        title="Disabling MFA"
        footer={false}
        body={
          <>
            <ConfirmationAlert
              BodyFirstContent="Are you sure you want to disable MFA?"
              BodySecondContent={''}
              BodyThirdContent=""
              confirmationButtonContent="confirm"
              cancelButtonContent="cancel"
              deleteConfirmButton={deleteConfirmButton}
              deleteCancleButton={deleteCancleButton}
            />
          </>
        }
      />
    </div>
  )

}

SmsVerification.defaultProps = {
  formData: { 'email': '' },
  callFrom: "",
  enableMfa: true,
}

const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token,

  };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials, setToken, logOutApp })(withNamespaces()(SmsVerification));