import React, { useState, useEffect, useRef } from 'react';
import CustomInput from '../../../Utility/Components/CustomInput';
import Utility from '../../../Utility/Utility';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { setToken, logOutApp } from '../Actions/LoginAction';
import { connect } from 'react-redux';
import * as LoginController from '../Controller/LoginController'
import '../Assets/css/logindoc.scss';
import '../Assets/css/loginresponsivedoc.scss';
import { withNamespaces } from 'react-i18next';
import PhoneNumberWithCountryCode from '../Components/PhoneNumberWithCountryCode';
import ErrorBoundary from '../../../Utility/Components/ErrorBoundary';
import ModalGlobal from '../../../Utility/Components/ModalGlobal';
import ImageCropContent from '../Components/ImageCropContent';
import ValidateUtility from '../../../Utility/ValidateUtility';
import { useParams } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js'
import SmsVerification from '../Components/SmsVerification';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const RegisterPage = (props) => {
    const { type } = useParams();
    const [username, setusername] = useState("")
    const [phone_number, setphone_number] = useState("")
    const [state, setState] = useState({
        passwordResetCode: "",
        password: "",
        retypePassword: "",
        usernameError: "",
        passwordResetCodeError: "",
        passwordError: "",
        retypePasswordError: "",
        hideShowChangePasswordPanel: false,
        passwordLock: false,
        rePasswordLock: false,
        passType: "password",
        reTypePassType: "password",
    })
    const [addprofileImageSelected, setAddprofileImageSelected] = useState(require('../../../Utility/Public/images/no-picture.jpg'));
    const [addProfileImagePreviewShow, setAddProfileImagePreviewShow] = useState(false);
    const [imageCropModalFlag, setImageCropModalFlag] = useState(false);
    const [formData, setFormData] = useState({
        "first_name": "",
        "last_name": "",
        "email": "",
        "contact_number": "",
        "profile_image": "",
        "password": "",
        "confirm_password": "",
        "two_factor_login": false,
        "two_factor_method": 'sms'
    })
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 30,
        aspect: 1 / 1
    })
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const imageRef = useRef();
    const [errorFormData, setErrorFormData] = useState({
        "first_name": "",
        "last_name": "",
        "email": "",
        "contact_number": "",
        "profile_image": "",
        "password": "",
        "confirm_password": "",
    })
    const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("in");
    const [phoneNumberDialCode, setPhoneNumberDialCode] = useState("91");
    const [smsVerificationModalContentFlag, setSmsVerificationModalContentFlag] = useState(false);
    const [showPasswordHint, setShowPasswordHint] = useState(false);
    const [showReEnterPasswordHint, setShowReEnterPasswordHint] = useState(false);


    useEffect(() => {
        if (props.token && props.token != "" && props.token != null) {
            const previousUrl = sessionStorage.getItem('previousUrl');
            if (previousUrl && previousUrl !== `/${localStorage.getItem('i18nextLng')}/register`) {
                history.push(previousUrl);
            }
        }
    }, [props.isLoggedIn]);

    const { loaderStateTrue, loaderStateFalse, history, logOutApp } = props;

    //register user inputs handlers
    const handelChange = (e, type, dialCodeObj = {}) => {
        let tempFormData = { ...formData };
        let tempErrorFormData = { ...errorFormData }
        if (type == "first_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['first_name'] = ""
                    tempFormData["first_name"] = ""
                } else {
                    tempFormData["first_name"] = e.target.value
                    tempErrorFormData['first_name'] = ""
                }
            }
        }
        if (type == "last_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['last_name'] = ""
                    tempFormData["last_name"] = ""
                } else {
                    tempFormData["last_name"] = e.target.value
                    tempErrorFormData['last_name'] = ""
                }
            }
        }

        if (type == "email") {
            if (e.target.value != "") {
                let emailValidate = ValidateUtility.validate_email(e.target.value);
                if (emailValidate) {
                    tempFormData["email"] = e.target.value,
                        tempErrorFormData['email'] = ""
                } else {
                    tempFormData["email"] = e.target.value,
                        tempErrorFormData['email'] = "Enter valid email"
                }
            } else {
                tempFormData["email"] = "",
                    tempErrorFormData['email'] = ""
            }
        }

        if (type == "contact_number") {
            if (e.trim() !== "") {
                let modifiedString = ValidateUtility.deleteAndBefore(e, dialCodeObj.dialCode);
                let countryCodeValue = dialCodeObj.countryCode.toUpperCase();
                const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
                setPhoneNumberCountryCode(dialCodeObj.countryCode)
                setPhoneNumberDialCode(dialCodeObj.dialCode)
                if (phoneNumber && phoneNumber.isValid()) {
                    tempFormData["contact_number"] = e
                    tempErrorFormData['contact_number'] = ""
                } else {
                    tempFormData["contact_number"] = e
                    tempErrorFormData['contact_number'] = "Enter valid phone number"
                }
            } else {
                tempFormData["contact_number"] = "";
                tempErrorFormData['contact_number'] = "Required field";
            }
        }

        if (type == "password") {
            if (e.target.value == "") {
                tempErrorFormData['password'] = ""
                tempFormData["password"] = ""
                setShowPasswordHint(false);
            } else {
                tempFormData["password"] = e.target.value
                tempErrorFormData['password'] = ""
                setShowPasswordHint(true);
            }
        }

        if (type == "confirm_password") {

            if (e.target.value == "") {
                tempErrorFormData['confirm_password'] = ""
                tempFormData["confirm_password"] = ""
                setShowReEnterPasswordHint(false);
            } else {
                tempFormData["confirm_password"] = e.target.value
                tempErrorFormData['confirm_password'] = ""
                setShowReEnterPasswordHint(true);
            }
        }
        if (type == "two_factor_login") {
            if (e.target.checked) {
                tempFormData["two_factor_login"] = true;
            } else {
                tempFormData["two_factor_login"] = false;
            }
        }
        if (type == "sms") {
            if (e.target.checked) {
                tempFormData["two_factor_method"] = "sms";
            }
        }
        if (type == "authenticator") {
            if (e.target.checked) {
                tempFormData["two_factor_method"] = 'authenticator';
            }
        }

        setFormData(tempFormData)
        setErrorFormData(tempErrorFormData)
    }

    const passwordTooltip = (props) => (
        <Tooltip id="password-tooltip" {...props}>
            <h5>Password Hints:</h5>
            <ul className='password-inner-box'>
                <li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
                <li><span>Require numbers</span></li>
                <li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
                <li><span>Require uppercase letters</span></li>
                <li><span>Require lowercase letters</span></li>
            </ul>
        </Tooltip>
    );

    //navigate back to login
    const back_to_login = () => {
        props.history.push(`/en/login`);
    }

    const lanOnSelect = (lng) => {
        let confirmation = false;
        confirmation = window.confirm(`${props.t('areYouSureYouWantToReload')}`);
        if (confirmation) {
            i18n.changeLanguage(lng);
            let splitUrl = props.match.url.split('/');
            props.history.push(`/${lng}/${splitUrl[2]}`);
            window.location.reload();
        }

    }

    const addInputProfileImageChanged = (event) => {
        let tempformData = { ...formData };
        let temperrorFormData = { ...errorFormData };

        const fileInput = event.target;
        const storedFiles = event.target.files[0] // Store the files before clearing the input
        const storedAllFiles = event.target.files

        let targetFileSplit = storedFiles.name.split('.');
        let lastElement = targetFileSplit.pop();
        let user_profile_image = {
            "file_name": "",
            "file_obj": ""
        };
        const allowedTypes = Utility.acceptFileType();
        Utility.checkFileType(storedFiles, allowedTypes)
            .then(results => {
                if (results.isValid) {
                    const fsize = storedFiles.size;
                    const fileSize = Math.round((fsize / 1024));
                    if (fileSize >= 300) {
                        Utility.toastNotifications(props.t('imageUploadAlert'), "Warning", "warning");
                    } else {
                        setImageCropModalFlag(true)
                        if (storedAllFiles && storedAllFiles.length > 0) {
                            const reader = new FileReader();
                            reader.addEventListener('load', () =>
                                setSrc(reader.result)
                            );
                            reader.readAsDataURL(storedFiles);
                            user_profile_image["file_name"] = storedFiles.name
                            user_profile_image["file_obj"] = ""
                            tempformData['profile_image'] = user_profile_image
                            temperrorFormData['profile_image'] = ""
                            setAddProfileImagePreviewShow(true)
                        }
                    }
                } else {
                    tempformData['profile_image'] = "";
                    temperrorFormData['profile_image'] = props.t('invalidField');
                    setAddProfileImagePreviewShow(false);
                    setAddprofileImageSelected('');
                    Utility.toastNotifications("File format is not supported, it should be in jpg, jpeg or png format", "Warning", "warning");
                }
            })
            .catch(error => {
                console.error("Error:", error);

            });
        setTimeout(() => {
            fileInput.value = '';
        }, 100);
        setFormData(tempformData)
        setErrorFormData(temperrorFormData)
    }


    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }

    const onImageLoaded = (image) => {
        if (image) {
            imageRef.current = image
        }

    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop)
    };

    const makeClientCrop = async (crop) => {
        let tempformData = { ...formData };
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                'newFile.jpeg'
            );
            let user_profile_image = {}

            setAddprofileImageSelected(croppedImageUrl)
            user_profile_image["file_name"] = tempformData.profile_image.file_name
            user_profile_image["file_obj"] = croppedImageUrl
            tempformData["profile_image"] = user_profile_image

            setFormData(tempformData)

        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image
    }

    //Image url crop

    const imageCropModalShow = () => {
        setImageCropModalFlag(true)
    }

    const imageCropModalHide = () => {
        formData['profile_image'] = "",
            errorFormData['profile_image'] = "",
            setImageCropModalFlag(false)
        setAddprofileImageSelected(require('../../../Utility/Public/images/no-picture.jpg'))
        setAddProfileImagePreviewShow(false),
            //new add
            setSrc(null),
            setCrop({
                unit: '%',
                width: 30,
                aspect: 1 / 1
            }),
            setCroppedImageUrl("")
    }

    const imageCropDataSave = () => {
        setImageCropModalFlag(false)
    }

    //register user dataset for api
    const formatingDataset = () => {
        let dataset = [];
        let hash = {}
        if (formData.first_name != "") {
            hash['first_name'] = (formData.first_name).trim();
        }
        if (formData.last_name != "") {
            hash['last_name'] = (formData.last_name).trim();
        }
        if (formData.email != "") {
            hash['username'] = formData.email;
        }

        var phoneNumber = formData.contact_number
        var plusRemovePhoneNumber = phoneNumber.replace(/\+/g, '')
        if (formData.contact_number != "") {
            hash['contact_number'] = plusRemovePhoneNumber;
        }

        if (formData.profile_image != "") {
            hash['profile_img'] = formData.profile_image != "" ? formData.profile_image : "";
        }
        hash['password'] = formData.password;
        dataset.push(hash)
        return dataset;
    }

    //validation for register user api
    const validationFromData = () => {
        let tempFormData = { ...formData }
        let tempErrorFormData = { ...errorFormData }
        let valid = true
        if ((tempFormData.first_name).trim() == "") {
            tempErrorFormData['first_name'] = "Required field"
            valid = false
        }
        if ((tempFormData.last_name).trim() == "") {
            tempErrorFormData['last_name'] = "Required field"
            valid = false
        }
        if (tempFormData.contact_number == "" || tempFormData.contact_number == null) {
            tempErrorFormData['contact_number'] = "Required field"
            valid = false
        } else {
            let modifiedString = ValidateUtility.deleteAndBefore(tempFormData.contact_number, phoneNumberDialCode);
            let countryCodeValue = phoneNumberCountryCode.toUpperCase();
            const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
            if (phoneNumber && phoneNumber.isValid()) {
                tempErrorFormData['contact_number'] = ""
            } else {
                tempErrorFormData['contact_number'] = "Enter valid phone number"
                valid = false
            }
        }

        if (tempFormData.email == "") {
            tempErrorFormData['email'] = "Required field"
            valid = false
        } else {
            let emailValidate = ValidateUtility.validate_email(tempFormData.email);
            if (!emailValidate) {
                tempErrorFormData['email'] = "Enter valid email"
                valid = false
            }
        }
        if (tempFormData.password == "") {
            tempErrorFormData['password'] = "Required field"
            valid = false
        }
        if (tempFormData.confirm_password == "") {
            tempErrorFormData['confirm_password'] = "Required field"
            valid = false
        }
        if ((tempFormData.password != "" && tempFormData.confirm_password != "") && tempFormData.password != tempFormData.confirm_password) {
            valid = false;
            tempErrorFormData['confirm_password'] = `${props.t('passwordnotmatch')}`
        }

        setErrorFormData(tempErrorFormData)
        return valid
    }

    // reset user state reset
    const resetFormData = () => {
        setFormData({
            "first_name": "",
            "last_name": "",
            "email": "",
            "contact_number": "",
            "profile_image": "",
            "password": "",
            "confirm_password": "",
            "two_factor_login": false,
            "two_factor_method": 'sms'
        })
    }

    // register user api
    const registerSaveUser = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let vaildData = validationFromData();
        if (vaildData) {
            let data = formatingDataset();
            let apiType = 'post';
            let id = ""
            loaderStateTrue();
            LoginController.userCreate(data[0], id, apiType).then((response) => {
                if (apiType == "post") {
                    if (response.success) {
                        setSmsVerificationModalContentFlag(true);
                        Utility.toastNotifications(data.message, "Success", "success")
                    } else {
                        Utility.toastNotifications(data.message, "Error", "error")
                        loaderStateFalse();
                    }
                } else {
                    if (response.success) {
                        resetFormData();
                        Utility.toastNotifications(response.message, "Success", "success");
                        loaderStateFalse();
                    } else {
                        Utility.toastNotifications(response.message, "Error", "error")
                        loaderStateFalse();
                    }
                }
                loaderStateFalse();
            }).catch((error) => {
                loaderStateFalse();
            });
        }
    }

    //password show hide fn
    const hideShowEyeButton = (callFrom, type) => {

        if (type == "text") {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: true,
                    passType: "text"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: true,
                    reTypePassType: "text"
                }))
            }

        } else {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: false,
                    passType: "password"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: false,
                    reTypePassType: "password"
                }))
            }
        }
    }

    //sms verification modal close fn
    const closesmsVerificationFn = () => {
        setSmsVerificationModalContentFlag(false)
    }

    //mfa modal code submit fn
    const handleSubmitOtpVerfication = (otp, mfaType) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let data = {
            "confirmation_code": otp.join(''),
            "email": formData.email,
            "password": formData.password
        }
        loaderStateTrue();
        LoginController.verifyOtp(data).then((response) => {
            if (response.success) {
                resetFormData();
                setSmsVerificationModalContentFlag(false)
                Utility.toastNotifications(response.message, 'Success', 'success');
                if (type && type == "hotel_details") {
                    props.history.push(`/${localStorage.getItem('i18nextLng')}/login/hotel_details`);
                } else {
                    props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
                }
            } else {
                Utility.toastNotifications(response.message, 'Error', 'error');
            }
            loaderStateFalse();
        }).catch((error) => {
            loaderStateFalse();
        });
    }

    return (

        <div className="loginBackground">

            <div className='container'>
                <div className='innLoginPage'>
                    <div className='row'>
                        <div className='col-sm-6 d-none d-lg-block d-md-block'>
                            <div className='leftInnLoginPage'>
                                <img src={require('../../../Utility/Public/images/loginIcon.png')} className="loginIcon" />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='rightInnLoginPage'>
                                <div className="left_section login_box_inner white_background forgotpass">
                                    <div className="logo-box-inner">
                                        <div className="titleposition">
                                            <h2 className="titleone">Create an account</h2>
                                            <h3 className="titletwo">Online property booking management</h3>
                                        </div>
                                    </div>
                                    <div className="itemsContainer login_left_inner">
                                        <div className="registerFormDiv">
                                            <div className='inputBoxes'>
                                                <CustomInput
                                                    parentClassName="input_field_inner loginFormInnerDiv paddingL0"
                                                    labelName={props.t('firstname')}
                                                    errorLabel={errorFormData.first_name}
                                                    name="firstName"
                                                    type="text"
                                                    value={formData.first_name}
                                                    labelPresent={false}
                                                    requiredStar={false}
                                                    placeholder="First Name"
                                                    onChange={(e) => handelChange(e, 'first_name')}
                                                />
                                                <CustomInput
                                                    parentClassName="loginFormInnerDiv input_field_inner paddingR0"
                                                    labelName="Last Name"
                                                    errorLabel={errorFormData.last_name}
                                                    name="name"
                                                    type="text"
                                                    value={formData.last_name}
                                                    labelPresent={false}
                                                    requiredStar={false}
                                                    placeholder="Last Name"
                                                    onChange={(e) => handelChange(e, 'last_name')}
                                                />
                                            </div>
                                            <div className='inputBoxes'>
                                                <CustomInput
                                                    parentClassName="loginFormInnerDiv input_field_inner paddingL0"
                                                    labelName={props.t('Email')}
                                                    errorLabel={errorFormData.email}
                                                    name="email"
                                                    type="text"
                                                    value={formData.email}
                                                    labelPresent={false}
                                                    requiredStar={false}
                                                    placeholder={props.t('emailIdPlaceHolder')}
                                                    onChange={(e) => handelChange(e, 'email')}
                                                />
                                                <div className='inputBoxesLast regInputPh paddingR0'>
                                                    <PhoneNumberWithCountryCode
                                                        country={process.env.PHONE_NUMBER_COUNTRY_SET}
                                                        value={formData.contact_number}
                                                        onChange={(e, country) => handelChange(e, 'contact_number', country)}
                                                        error={errorFormData.contact_number}
                                                        labelShow={false}
                                                        requirStart={false}
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                            <div className='inputBoxes'>
                                                <div className='inputBoxesLast positionRelative paddingL0'>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        show={showPasswordHint}
                                                        overlay={passwordTooltip}
                                                    >
                                                        <CustomInput
                                                            parentClassName="loginFormInnerDiv input_field_inner paddingL0"
                                                            labelName="Enter Password"
                                                            errorLabel={errorFormData.password}
                                                            name="password"
                                                            type={state.passType == "text" ? "text" : "password"}
                                                            value={formData.password}
                                                            labelPresent={false}
                                                            requiredStar={false}
                                                            placeholder="Enter Password"
                                                            onChange={(e) => handelChange(e, 'password')}
                                                            onFocus={() => setShowPasswordHint(true)}
                                                            onBlur={() => setShowPasswordHint(false)}
                                                        />
                                                    </OverlayTrigger>
                                                    {!state.passwordLock ?
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "text") }}>
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </span>

                                                        :
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "password") }}>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </span>

                                                    }
                                                </div>
                                                <div className='inputBoxesLast positionRelative paddingR0'>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        show={showReEnterPasswordHint}
                                                        overlay={passwordTooltip}
                                                    >
                                                        <CustomInput
                                                            parentClassName="loginFormInnerDiv input_field_inner paddingR0"
                                                            labelName="Re-enter Password"
                                                            errorLabel={errorFormData.confirm_password}
                                                            name="retypePassword"
                                                            type={state.reTypePassType == "text" ? "text" : "password"}
                                                            value={formData.confirm_password}
                                                            labelPresent={false}
                                                            requiredStar={false}
                                                            placeholder="Re-enter Password"
                                                            onChange={(e) => handelChange(e, 'confirm_password')}
                                                            onFocus={() => setShowReEnterPasswordHint(true)}
                                                            onBlur={() => setShowReEnterPasswordHint(false)}
                                                        />
                                                    </OverlayTrigger>
                                                    {!state.rePasswordLock ?
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "text") }}>
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </span>

                                                        :
                                                        <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "password") }}>
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="forgor_btn_inner_div logbtndiv">
                                                <button className="login-btn btn btn-primary" onClick={registerSaveUser}>Register</button>
                                            </div>
                                            <div className="back_inner_div loglan ">
                                                <p className="">Already have an account ? <span onClick={back_to_login}><b>Login Here</b></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={imageCropModalFlag}
                    onHide={imageCropModalHide}
                    onCancel={imageCropModalHide}
                    onSave={imageCropDataSave}
                    className="modalcustomize cropmodalcontent"
                    bodyClassName="cropmodalcontentbody"
                    headerclassName="close_btn_icon"
                    title={props.t('cropimage')}
                    footer={true}
                    closeButton={true}
                    saveButtonLabel={props.t('crop')}
                    saveButtonClassName="delconfirmbtn btn btn-primary"
                    cancelButtonClassName="delcancelbtn btn btn-primary"
                    body={
                        <>
                            <ErrorBoundary title="ModalGlobal Error">
                                <ImageCropContent
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onCropChange={onCropChange}
                                    crop={crop}
                                    croppedImageUrl={croppedImageUrl}
                                    src={src}
                                    onCropComplete={onCropComplete}
                                    imageCropModalShow={imageCropModalShow}
                                    circularCrop={false}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={smsVerificationModalContentFlag}
                    onHide={closesmsVerificationFn}
                    title=""
                    className="modalcustomize mondimension smsVerificationModalContent"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Confirm"
                    saveButtonClassName="btn btn-primary savebtn"
                    cancelShow={true}
                    body={
                        <>
                            <ErrorBoundary title="SmsVerification Error">
                                <SmsVerification
                                    formData={formData}
                                    handleSubmitOtpVerfication={handleSubmitOtpVerfication}
                                    callFrom={'email'}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

        </div>
    )

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,

    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, logOutApp })(withNamespaces()(RegisterPage));