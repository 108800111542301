import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../Utility/Public/css/common.scss';
import '../../Utility/Public/css/commonresponsive.scss';

const WithoutLoginMessageShow = (props) => {

    return (
        <div className="msg-wihout-login-container not-found-container">
            <div className="containerBox">
                {props.isMessageShow &&
                    <p className="msg-description not-found-description">
                        {props.message}
                    </p>
                }
                {
                    props.isRedirectPageButtonShow &&
                    <Link to={`/${localStorage.getItem('i18nextLng')}${props.redirectButtonUrl}${props.redirectFromUrl}`} className="backToHome">{props.redirectButtonLabel}</Link>
                }

            </div>
        </div>
    );

}
WithoutLoginMessageShow.defaultProps = {
    isMessageShow: false,
    message: "",
    isRedirectPageButtonShow: false,
    redirectButtonLabel: "",
    redirectButtonUrl: "",
    redirectFromUrl: ""
}
export default WithoutLoginMessageShow;
