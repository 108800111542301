import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

class GridActionContent extends Component {
    render() {
        const { t, styleWidth, styleHight, gridObjData, editButtonShow, deleteButtonShow, roleManagePermissionButtonShow, cancellationBtnShow, checkinCheckoutBtnShow, checkinCheckoutLabel, roomEditBtnShow, createInvoiceBtnShow, makePaymentShow, quotationDetailsShow, quotationBookingConversionBtnShow, resendBookingDetailsShow, staffHistoryButtonShow, resendQuationButtonShow, viewButtonShow, subscribeNow, renewsubscribe, subscribeEdit, subscribeExtendNow, makeDefaultButtonShow, makePaymentDetailsShow } = this.props;
        return (
            <div className="actionableInnerBox" style={{ position: 'absolute', left: `${styleWidth}`, top: `${styleHight}` }} ref={this.props.codeOutsideClickRef}>
                <div className="actionableBtnCenter">
                    {quotationDetailsShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.quotationDetailsFn.bind(this, gridObjData)}><span><i className="fa fa-info-circle"></i></span>Quotation Details</button>
                    </div> : null}
                    {editButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={() => { this.props.modefier(gridObjData, "") }}><span><i className="fa fa-pencil" ></i></span> {t('edit')}</button>
                    </div> : null}
                    {roleManagePermissionButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={() => { this.props.roleManagePermissionFn(gridObjData, "") }}><span><i className="fa fa-pencil" ></i></span> {t('managePermission')}</button>
                    </div> : null}
                    {quotationBookingConversionBtnShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.quotationBookingConversionFn.bind(this, gridObjData)}><span><i className="fa fa-exchange"></i></span>Quotation to Booking Conversion</button>
                    </div> : null}
                    {makeDefaultButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={() => { this.props.makeDefault(gridObjData, "") }}><span><i className="fa fa-file-text" ></i></span>Make Default</button>
                    </div> : null}
                    {deleteButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.delete.bind(this, gridObjData)}><span><i className="fa fa-trash"></i></span> {t('delete')}</button>
                    </div> : null}

                    {checkinCheckoutBtnShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.checkinCheckout.bind(this, gridObjData)}><span><i className="fa-solid fa-square-check"></i></span>{checkinCheckoutLabel}</button>
                    </div> : null}

                    {cancellationBtnShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.cancellation.bind(this, gridObjData)}><span><i className="fa-solid fa-book"></i></span>Cancel Booking</button>
                    </div> : null}

                    {makePaymentShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.makePaymentFn.bind(this, gridObjData)}><span><i className="fa fa-file-text"></i></span>Payments</button>
                    </div> : null}
                    {makePaymentDetailsShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.resendBookingDetailsFn.bind(this, gridObjData, "paymentDetailsResend")}><span><i className="fa fa-file-text"></i></span>Resend Payment Details</button>
                    </div> : null}

                    {viewButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.view.bind(this, gridObjData)}><span><i className="fa fa-eye"></i></span>Booking Details</button>
                    </div> : null}

                    {resendBookingDetailsShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.resendBookingDetailsFn.bind(this, gridObjData)}><span><i className="fa fa-file-text"></i></span>Resend Booking Details</button>
                    </div> : null}

                    {createInvoiceBtnShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.createInvoiceFn.bind(this, gridObjData)}><span><i className="fa fa-file-text"></i></span>Create Invoice</button>
                    </div> : null}

                    {roomEditBtnShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.roomEdit.bind(this, gridObjData)}><span><i className="fa fa-pencil"></i></span>Change Room</button>
                    </div> : null}

                    {staffHistoryButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.hostory.bind(this, gridObjData)}><span><i className="fa fa-history"></i></span>History</button>
                    </div> : null}
                    {resendQuationButtonShow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.resendQuation.bind(this, gridObjData)}><span><i className="fa fa-repeat"></i></span>Resend Quotation</button>
                    </div> : null}

                    {subscribeNow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.opensubscribeModal.bind(this, gridObjData)}><span><i className="fa fa-plus"></i></span>Add Subscription</button>
                    </div> : null}

                    {renewsubscribe ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.opensubscribeModal.bind(this, gridObjData)}><span><i className="fa fa-refresh"></i></span>Renew Subscription</button>
                    </div> : null}

                    {subscribeEdit ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.opensubscribeModal.bind(this, gridObjData)}><span><i className="fa fa-pencil"></i></span>Edit Subscription</button>
                    </div> : null}
                    {subscribeExtendNow ? <div className="actionableRow">
                        <button className="actionableIcon" onClick={this.props.opensubscribeModal.bind(this, gridObjData, 'Extend')}><span><i className="fa fa-eye"></i></span>Extend Subscription</button>
                    </div> : null}



                </div>
            </div>
        );
    }
}
GridActionContent.defaultProps = {
    editButtonShow: true,
    deleteButtonShow: true,
    roleManagePermissionButtonShow: false,
    cancellationBtnShow: false,
    checkinCheckoutBtnShow: false,
    checkinCheckoutLabel: "Checkin",
    roomEditBtnShow: false,
    createInvoiceBtnShow: false,
    makePaymentShow: false,
    quotationDetailsShow: false,
    quotationBookingConversionBtnShow: false,
    resendBookingDetailsShow: false,
    staffHistoryButtonShow: false,
    resendQuationButtonShow: false,
    viewButtonShow: false,
    subscribeNow: false,
    renewsubscribe: false,
    subscribeEdit: false,
    subscribeExtendNow: false,
    makeDefaultButtonShow: false,
    makePaymentDetailsShow: false
}
const mapStateToProps = (globalState) => {
    return {

    };
}

export default connect(mapStateToProps, {})
    (withNamespaces()(GridActionContent));