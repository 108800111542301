import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import Utility from '../../../../Utility/Utility'

function ViewBookingDetails(props) {
    let bookingId = props.viewDetailsData.hasOwnProperty("booking_id") && props.viewDetailsData.booking_id && props.viewDetailsData.booking_id != "" ? props.viewDetailsData.booking_id : ""
    let bookingStatus = props.viewDetailsData.hasOwnProperty("booking_master_details") && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty("book_status") && props.viewDetailsData.booking_master_details.book_status && props.viewDetailsData.booking_master_details.book_status != "" ? props.viewDetailsData.booking_master_details.book_status : "";
    let totalAmount = props.viewDetailsData.hasOwnProperty("total_amount") && props.viewDetailsData.total_amount && props.viewDetailsData.total_amount != "" ? props.viewDetailsData.total_amount : ""
    let totalPaid = props.viewDetailsData.hasOwnProperty("total_paid") && props.viewDetailsData.total_paid && props.viewDetailsData.total_paid != "" ? props.viewDetailsData.total_paid : "0.00"
    let minimumBookingAmount = props.viewDetailsData.hasOwnProperty("minimum_booking_amount") && props.viewDetailsData.minimum_booking_amount && props.viewDetailsData.minimum_booking_amount != "" ? props.viewDetailsData.minimum_booking_amount : ""
    let checkedIn = props.viewDetailsData.hasOwnProperty("checked_in") && props.viewDetailsData.checked_in && props.viewDetailsData.checked_in != "" ? props.viewDetailsData.checked_in : "";
    let checkedOut = props.viewDetailsData.hasOwnProperty("checked_out") && props.viewDetailsData.checked_out && props.viewDetailsData.checked_out != "" ? props.viewDetailsData.checked_out : "";
    let bookingDate = props.viewDetailsData.hasOwnProperty("booking_date") && props.viewDetailsData.booking_date && props.viewDetailsData.booking_date != "" ? props.viewDetailsData.booking_date : ""
    let adult = props.viewDetailsData.hasOwnProperty("adult") && props.viewDetailsData.adult && props.viewDetailsData.adult != "" ? props.viewDetailsData.adult : ""
    let child = props.viewDetailsData.hasOwnProperty("child") && props.viewDetailsData.child && props.viewDetailsData.child != "" ? props.viewDetailsData.child : "0"
    let currency = props.viewDetailsData.hasOwnProperty("currency") && props.viewDetailsData.currency && props.viewDetailsData.currency != "" ? props.viewDetailsData.currency : "INR"

    let childAgeArr = props.viewDetailsData.hasOwnProperty('child_age') && props.viewDetailsData.child_age.length > 0 ? props.viewDetailsData.child_age : []
    let childAge = ""
    if (childAgeArr.length > 0) {
        childAge = childAgeArr.map((e) => (e.age).toString()).join(', ');
    }

    let checkInLabel = 'Check-in';
    if (props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('checked_in_at') && props.viewDetailsData.booking_master_details.checked_in_at) {
        checkInLabel = 'Checked-in';
    }
    let checkOutLabel = 'Check-out';
    if (props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('checked_out_at') && props.viewDetailsData.booking_master_details.checked_out_at) {
        checkOutLabel = 'Checked-out';
    }

    //room details header ui create
    const paymentPriceDistributionUi = () => {
        let room_type_data_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('room_type_booking_details') && props.viewDetailsData.room_type_booking_details && Object.keys(props.viewDetailsData.room_type_booking_details).length > 0) || (props.viewDetailsData.hasOwnProperty('previous_booking_hash') && props.viewDetailsData.previous_booking_hash && Object.keys(props.viewDetailsData.previous_booking_hash).length > 0)) {
            let paymentDetailsRoomTypeData = props.viewDetailsData.room_type_booking_details;
            let previousPaymentDetailsRoomTypeData = props.viewDetailsData.hasOwnProperty('previous_booking_hash') && props.viewDetailsData.previous_booking_hash ? props.viewDetailsData.previous_booking_hash : {};
            room_type_data_arr.push(
                <div className="paymentDetailsSectionBoxRow" >
                    <div className="paymentDetailsSectionDateRowBox">
                        <div className='paymentDetailsTitle roomType'>{Utility.capitalizeFirstLetterOfEachWord('Room type')}</div>
                        <div className='paymentDetailsTitle bookDate timeDuration'>{Utility.capitalizeFirstLetterOfEachWord('Time Duration')}</div>
                        <div className='paymentDetailsTitle dayCount'>{Utility.capitalizeFirstLetterOfEachWord('#Room(s)')}</div>
                        <div className='paymentDetailsTitle roomNumbers'>{Utility.capitalizeFirstLetterOfEachWord('Room Nums')}</div>
                        <div className='paymentDetailsTitle perDayRate'>{Utility.capitalizeFirstLetterOfEachWord('Price/Night')} ({Utility.getCurrencySymbol(currency)})</div>
                        <div className='paymentDetailsTitle admount'>{Utility.capitalizeFirstLetterOfEachWord('Amount')} ({Utility.getCurrencySymbol(currency)})</div>
                    </div>
                    <div className="paymentDetailsSectionDateRowDataBox">
                        {Object.keys(previousPaymentDetailsRoomTypeData).length > 0 && roomTypePerdayWisePrice(previousPaymentDetailsRoomTypeData, false)}
                        {Object.keys(paymentDetailsRoomTypeData).length > 0 && roomTypePerdayWisePrice(paymentDetailsRoomTypeData, true)}
                    </div>
                </div>
            );
        }
        return room_type_data_arr;
    }

    //room details value ui
    var room_type_amount_wise_price = {}
    const roomTypePerdayWisePrice = (data, is_new = true) => {
        let arrUi = []
        Object.keys(data).map((room_type_id, idx) => {
            Object.keys(data[room_type_id]).map((amount, i) => {
                let room_type_details = data[room_type_id][amount];
                room_type_amount_wise_price[room_type_id + '_' + amount] = room_type_details.room_rate
                arrUi.push(<div className={is_new ? "paymentDetailsDataBox" : "paymentDetailsDataBox previusValue"} key={idx}>
                    <div className='paymentDetailsValue roomType'>
                        {room_type_details.room_type_name.length > 17 ?
                            <OverlayTrigger overlay={<Tooltip>{room_type_details.room_type_name}</Tooltip>}>
                                <span className='roomTypeName'>{(room_type_details.room_type_name).substring(0, 14) + "..."}</span>
                            </OverlayTrigger> :
                            <span>{room_type_details.room_type_name}</span>
                        }
                    </div>
                    <div className='paymentDetailsValue bookDate timeDuration'>{`${moment(room_type_details.book_from).format('DD-MM-YYYY')} - ${moment(room_type_details.book_to).format('DD-MM-YYYY')} (${room_type_details.day_night})`}</div>
                    <div className='paymentDetailsValue dayCount textCenter room_count_l6'>{`${room_type_details.room_count}`}</div>
                    <div className='paymentDetailsValue dayCount room_number_l6'>{`${room_type_details.hasOwnProperty('room_no') && room_type_details.room_no != null && Utility.hasNonZeroValues(room_type_details.room_no) ? room_type_details.room_no : '-'}`}</div>
                    <div className='paymentDetailsValue perDayRate'>
                        {room_type_details.perday_rent}
                    </div>
                    <div className='paymentDetailsValue admount'>{Number(room_type_details.room_rate).toFixed(2)}</div>

                </div>)

            })
        })

        return arrUi
    }

    //food details header ui
    const foodPriceDistributionUi = () => {
        let fooding_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('date_wise_food_hash') && props.viewDetailsData.date_wise_food_hash && Object.keys(props.viewDetailsData.date_wise_food_hash).length > 0)) {
            let paymentDetailsRoomTypeFoodingData = props.viewDetailsData.date_wise_food_hash;

            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionBoxRowFood" >
                        <div className="paymentDetailsSectionDateRowBox">
                            <div className='paymentDetailsTitle date'>{Utility.capitalizeFirstLetterOfEachWord('date')}</div>
                            <div className='paymentDetailsTitle headCount'>{Utility.capitalizeFirstLetterOfEachWord('head count')}</div>
                            <div className='paymentDetailsTitle breakfast'>{Utility.capitalizeFirstLetterOfEachWord('breakfast')}</div>
                            <div className='paymentDetailsTitle breakfastPerson'>{Utility.capitalizeFirstLetterOfEachWord('breakfast / person')} ({Utility.getCurrencySymbol(currency)})</div>
                            <div className='paymentDetailsTitle lunch'>{Utility.capitalizeFirstLetterOfEachWord('lunch')}</div>
                            <div className='paymentDetailsTitle lunchPerson'>{Utility.capitalizeFirstLetterOfEachWord('lunch / person')} ({Utility.getCurrencySymbol(currency)})</div>
                            <div className='paymentDetailsTitle dinner'>{Utility.capitalizeFirstLetterOfEachWord('dinner')}</div>
                            <div className='paymentDetailsTitle dinnerPerson'>{Utility.capitalizeFirstLetterOfEachWord('dinner / person')} ({Utility.getCurrencySymbol(currency)})</div>
                            <div className='paymentDetailsTitle amount'>{`${Utility.capitalizeFirstLetterOfEachWord('Amount')} (${Utility.getCurrencySymbol(currency)})`}</div>
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {roomTypeWisefoodPrice(paymentDetailsRoomTypeFoodingData)}
                        </div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }

    //food details value ui
    const roomTypeWisefoodPrice = (data) => {
        let arrUi = [];
        let prevDate = ''
        Object.keys(data).map((dateKey, idx) => {
            let innerArrUi = []; // Create a temporary array to hold inner elements
            for (const ageRange in data[dateKey]) {
                let newdate = ''
                if (prevDate != dateKey) {
                    prevDate = dateKey
                    newdate = dateKey
                }
                let room_type_details = data[dateKey][ageRange];
                let breakfastRate = "0";
                let lunchRate = "0";
                let dinnerRate = "0";
                let is_breakfast = false;
                let is_lunch = false;
                let is_dinner = false;

                let breakfastAmount = "0";
                let lunchAmount = "0";
                let dinnerAmount = "0";

                let headCount = 0;

                headCount = room_type_details.head_count

                is_breakfast = room_type_details.is_breakfast_available == 0 ? false : true;
                breakfastRate = room_type_details.breakfast_rate.toString()
                if (is_breakfast) {
                    breakfastAmount = breakfastRate * headCount
                }

                is_lunch = room_type_details.is_lunch_available == 0 ? false : true;
                lunchRate = room_type_details.lunch_rate.toString()
                if (is_lunch) {
                    lunchAmount = lunchRate * headCount;
                }

                is_dinner = room_type_details.is_dinner_available == 0 ? false : true;
                dinnerRate = room_type_details.dinner_rate.toString()
                if (is_dinner) {
                    dinnerAmount = dinnerRate * headCount;
                }


                innerArrUi.push(<div className="paymentDetailsDataBox currentRow" key={idx}>
                    <div className='paymentDetailsValue date'>{newdate != '' ? <span className='dateField'>{moment(dateKey).format("DD-MM-YYYY")}</span> : ''}{ageRange.includes("adult") ? "Adult" : `Children(${ageRange})`}</div>
                    <div className='paymentDetailsValue headCount'>{headCount}</div>
                    {room_type_details.hasOwnProperty('is_breakfast_available') && room_type_details.is_breakfast_available != null && room_type_details.is_breakfast_available == true ? <div className='paymentDetailsValue breakfast'>
                        <label className="checkbox_meal">
                            <input type="checkbox"
                                checked={is_breakfast}
                            />
                            <span className="checkmarktickmeal"></span>
                        </label>
                    </div> : <div className='paymentDetailsValue breakfast'><span style={{ color: 'red' }}>N/A</span></div>}
                    <div className='paymentDetailsValue breakfastPerson'>
                        <label className="checkbox_meal">
                            {Number(breakfastRate).toFixed(2)}
                        </label>
                    </div>
                    {room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_lunch_available != null && room_type_details.is_lunch_available == true ? <div className='paymentDetailsValue lunch'>
                        <label className="checkbox_meal">
                            <input type="checkbox"
                                checked={is_lunch}
                            />
                            <span className="checkmarktickmeal"></span>
                        </label>
                    </div> : <div className='paymentDetailsValue lunch'><span style={{ color: 'red' }}>N/A</span></div>}
                    <div className='paymentDetailsValue lunchPerson'>
                        <label className="checkbox_meal">
                            {Number(lunchRate).toFixed(2)}
                        </label>
                    </div>
                    {room_type_details.hasOwnProperty('is_dinner_available') && room_type_details.is_dinner_available != null && room_type_details.is_dinner_available == true ? <div className='paymentDetailsValue dinner'>
                        <label className="checkbox_meal">
                            <input type="checkbox"
                                checked={is_dinner}
                            />
                            <span className="checkmarktickmeal"></span>
                        </label>
                    </div> : <div className='paymentDetailsValue dinner'><span style={{ color: 'red' }}>N/A</span></div>}
                    <div className='paymentDetailsValue dinnerPerson'>
                        <label className="checkbox_meal">
                            {Number(dinnerRate).toFixed(2)}
                        </label>
                    </div>
                    <div className='paymentDetailsValue amount'>{parseFloat(Number(Number(breakfastAmount) + Number(lunchAmount) + Number(dinnerAmount))).toFixed(2)}</div>
                </div>)
            }
            arrUi.push(<div className="date-group date-group-view" key={`group-${dateKey}`}>{innerArrUi}</div>);
        })
        return arrUi
    }

    //additional service  details header ui
    const payableServiceDetailsUi = () => {
        let payable_service_arr = [];
        let payableServiceData = []
        if ((props.viewDetailsData.hasOwnProperty('extra_notations') && props.viewDetailsData.extra_notations && props.viewDetailsData.extra_notations.length > 0)) {
            payableServiceData = props.viewDetailsData.extra_notations;
            payable_service_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionService" >
                        <div className="paymentDetailsSectionDateRowBox">
                            <div className='paymentDetailsTitle description'>{Utility.capitalizeFirstLetterOfEachWord('description')}</div>
                            <div className='paymentDetailsTitle serviceDate'>{Utility.capitalizeFirstLetterOfEachWord('service date')}</div>
                            <div className='paymentDetailsTitle amount'>{Utility.capitalizeFirstLetterOfEachWord('amount')} ({Utility.getCurrencySymbol(currency)})</div>
                            {props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? null : <>
                                <div className='paymentDetailsTitle tax'>{Utility.capitalizeFirstLetterOfEachWord('Tax')} (%)</div>
                                <div className='paymentDetailsTitle amount amount_including_tax'>{Utility.capitalizeFirstLetterOfEachWord('amount')} ({Utility.getCurrencySymbol(currency)})<span>(Including Tax)</span></div>
                            </>}
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {payableServicePrice(payableServiceData)}
                        </div>
                    </div>
                </>
            );
        }

        return payable_service_arr;
    }

    //additional service  details value ui
    const payableServicePrice = (data, is_early_checkout = false) => {
        let arrUi = []
        data.map((value, idx) => {
            arrUi.push(<div className='paymentDetailsDataBox' key={idx}>
                <div className={`paymentDetailsValue description ${is_early_checkout ? 'earlyCheckoutDesc' : ""}`}>
                    {value.description.length > Number(is_early_checkout ? 43 : 15) ?
                        <OverlayTrigger overlay={<Tooltip>{value.description}</Tooltip>}>
                            <span className='roomTypeName'>{(value.description).substring(0, Number(is_early_checkout ? 40 : 15)) + "..."}</span>
                        </OverlayTrigger> :
                        <span>{value.description}</span>
                    }
                </div>
                <div className={`paymentDetailsValue serviceDate ${is_early_checkout ? 'earlyCheckoutServiceDate' : ""}`}>{value.book_date != null ? moment(value.book_date).format('DD-MM-YYYY') : ""} </div>
                <div className={`paymentDetailsValue amount ${is_early_checkout ? 'earlyCheckoutAmount' : ""}`}> {parseFloat(value.amount).toFixed(2)}</div>
                {props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? null : <>
                    <div className={`paymentDetailsValue tax ${is_early_checkout ? 'earlyCheckoutTax' : ""}`}> {Number(value.hasOwnProperty('service_tax_percentage') && value.service_tax_percentage ? value.service_tax_percentage : 0.00).toFixed(2)}</div>
                    <div className={`paymentDetailsValue amount amount_including_tax ${is_early_checkout ? 'early_checkout_amount_including_tax' : ""}`}> {Number(Number(value.amount) + (Number(value.amount) * Number(value.hasOwnProperty('service_tax_percentage') && value.service_tax_percentage ? value.service_tax_percentage : Number(0.00)) / 100)).toFixed(2)}</div>
                </>}
            </div>)
        })

        return arrUi
    }
    let extraService = props.viewDetailsData.hasOwnProperty('extra_notations') && props.viewDetailsData.extra_notations && props.viewDetailsData.extra_notations.length > 0 ? props.viewDetailsData.extra_notations : []
    let dateWiseFoodHash = props.viewDetailsData.hasOwnProperty('date_wise_food_hash') && props.viewDetailsData.date_wise_food_hash

    //room tax ui
    const RoomTaxDetails = ({ viewDetailsData }) => {
        const renderIGST = () => {
            return (
                <div className='textRowInner roomType'>
                    {Number(viewDetailsData.special_discount) > 0 ?
                        <div className='textRow'>
                            <div className='textRowLeft'>Discount</div>
                            <div className='textRowRight'>{Utility.getCurrencySymbol(currency)} {Number(viewDetailsData.special_discount).toFixed(2)}</div>
                        </div>
                        : null}

                    <div className='textRow'>
                        <div className='textRowLeft'>Sub Total:</div>
                        <div className='textRowRight'>
                            {Utility.getCurrencySymbol(currency)} {Number(Number(viewDetailsData.total_amount_without_gst) - Number(viewDetailsData.special_discount)).toFixed(2)}</div>
                    </div>
                    {parseFloat(viewDetailsData?.igst_percent) > 0 &&
                        <div className='textRow'>
                            <div className='textRowLeft'>IGST @ {viewDetailsData?.igst_percent ? parseFloat(viewDetailsData.igst_percent).toFixed(2) : 0}%</div>
                            <div className='textRowRight'>{viewDetailsData?.igst_amount ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.igst_amount).toFixed(2) : 0}</div>
                        </div>}
                    <div className='textRow'>
                        <div className='textRowLeft boldText'>Total:</div>
                        <div className='textRowRight boldText'>
                            {Utility.getCurrencySymbol(currency) + "" + (Number(Number(viewDetailsData.total_amount_without_gst) + Number(viewDetailsData.igst_amount) - Number(viewDetailsData.special_discount))).toFixed(2)}
                        </div>
                    </div>
                </div>
            );
        };

        const renderCGSTSGST = () => {
            return (
                <div className='textRowInner roomType'>
                    {Number(viewDetailsData.special_discount) > 0 ?
                        <div className='textRow'>
                            <div className='textRowLeft'>Discount</div>
                            <div className='textRowRight'>{Utility.getCurrencySymbol(currency)} {Number(viewDetailsData.special_discount).toFixed(2)}</div>
                        </div>
                        : null}

                    <div className='textRow'>
                        <div className='textRowLeft'>Sub Total:</div>
                        <div className='textRowRight'>
                            {Utility.getCurrencySymbol(currency)} {Number(Number(viewDetailsData.total_amount_without_gst) - Number(viewDetailsData.special_discount)).toFixed(2)}</div>
                    </div>
                    {parseFloat(viewDetailsData?.cgst_percent) > 0 &&
                        <div className='textRow'>
                            <div className='textRowLeft'>CGST @ {viewDetailsData?.cgst_percent ? parseFloat(viewDetailsData.cgst_percent).toFixed(2) : 0}%</div>
                            <div className='textRowRight'>{viewDetailsData?.cgst_amount ? Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData.cgst_amount).toFixed(2) : 0}</div>
                        </div>}
                    {parseFloat(viewDetailsData?.sgst_percent) > 0 &&
                        <div className='textRow'>
                            <div className='textRowLeft'>SGST @ {viewDetailsData?.sgst_percent ? parseFloat(viewDetailsData.sgst_percent).toFixed(2) : 0}%</div>
                            <div className='textRowRight'>{viewDetailsData?.sgst_amount ? Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData.sgst_amount).toFixed(2) : 0}</div>
                        </div>}
                    <div className='textRowLeft boldText'>Total:</div>
                    <div className='textRowRight boldText'>
                        {Utility.getCurrencySymbol(currency) + " " + (Number(Number(viewDetailsData?.total_amount_without_gst) + Number(viewDetailsData?.cgst_amount) + Number(viewDetailsData?.sgst_amount) - Number(viewDetailsData?.special_discount))).toFixed(2)}
                    </div>
                </div>
            );
        };

        return (
            <div>
                {viewDetailsData.igst_amount > 0 ? renderIGST() : renderCGSTSGST()}
            </div>
        );
    };

    //food tax details ui
    const FoodTaxDetails = ({ viewDetailsData }) => {
        const renderIGST = () => (
            <div className='textRowInner'>
                {props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? null : <>
                    {Number(viewDetailsData.special_discount_for_food) > 0 ?
                        <div className='textRow'>
                            <div className='textRowLeft'>Discount</div>
                            <div className='textRowRight'>{Utility.getCurrencySymbol(currency)} {Number(viewDetailsData.special_discount_for_food).toFixed(2)}</div>
                        </div>
                        : null}
                    <div className='textRow'>
                        <div className='textRowLeft'>Sub Total:</div>
                        <div className='textRowRight'>
                            {Utility.getCurrencySymbol(currency)} {Number(Number(viewDetailsData.food_amount) - Number(viewDetailsData.special_discount_for_food)).toFixed(2)}</div>
                    </div>
                    {Number(viewDetailsData.fgst_percent) > 0 && <div className='textRow'>
                        <div className='textRowLeft'>IGST @ {viewDetailsData?.fgst_percent ? parseFloat(viewDetailsData.fgst_percent).toFixed(2) : 0}%</div>
                        <div className='textRowRight'>{viewDetailsData?.fgst_amount ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.fgst_amount).toFixed(2) : 0}</div>
                    </div>}
                </>}
                <div className='textRow'>
                    <div className='textRowLeft boldText'>Total:</div>
                    <div className='textRowRight boldText'>
                        {Utility.getCurrencySymbol(currency) + "" + Number(Number(viewDetailsData?.food_amount) + Number(viewDetailsData?.fgst_amount) - Number(viewDetailsData?.special_discount_for_food)).toFixed(2)}
                    </div>
                </div>
            </div >
        );

        const renderCGSTSGST = () => (
            <div className='textRowInner'>
                {Number(viewDetailsData.special_discount_for_food) > 0 ?
                    <div className='textRow'>
                        <div className='textRowLeft'>Discount</div>
                        <div className='textRowRight'>{Utility.getCurrencySymbol(currency)} {Number(viewDetailsData.special_discount_for_food).toFixed(2)}</div>
                    </div>
                    : null}
                {props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? null : <>
                    <div className='textRow'>
                        <div className='textRowLeft'>Sub Total:</div>
                        <div className='textRowRight'>
                            {Utility.getCurrencySymbol(currency)} {Number(Number(viewDetailsData.food_amount) - Number(viewDetailsData.special_discount_for_food)).toFixed(2)}</div>
                    </div>
                    {Number(viewDetailsData.fcgst_percent) > 0 && <div className='textRow'>
                        <div className='textRowLeft'>CGST @ {viewDetailsData?.fcgst_percent ? parseFloat(viewDetailsData.fcgst_percent).toFixed(2) : 0}%</div>
                        <div className='textRowRight'>{viewDetailsData?.fcgst_amount ? Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData.fcgst_amount).toFixed(2) : 0}</div>
                    </div>}
                    {Number(viewDetailsData.fsgst_percent) > 0 && <div className='textRow'>
                        <div className='textRowLeft'>SGST @ {viewDetailsData?.fsgst_percent ? parseFloat(viewDetailsData.fsgst_percent).toFixed(2) : 0}%</div>
                        <div className='textRowRight'>{viewDetailsData?.fsgst_amount ? Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData.fsgst_amount).toFixed(2) : 0}</div>
                    </div>}
                </>}
                <div className='textRow'>
                    <div className='textRowLeft boldText'>Total:</div>
                    <div className='textRowRight boldText'>
                        {Utility.getCurrencySymbol(currency) + " " + Number(Number(viewDetailsData?.food_amount) + Number(viewDetailsData?.fcgst_amount) + Number(viewDetailsData?.fsgst_amount) - Number(viewDetailsData?.special_discount_for_food)).toFixed(2)}
                    </div>
                </div>
            </div>
        );

        return (
            <div>
                {viewDetailsData.fgst_amount > 0 ? renderIGST() : renderCGSTSGST()}
            </div>
        );
    };

    //additional service  tax ui
    const NotationTaxDetails = ({ viewDetailsData }) => {
        const renderIGST = () => (
            <div className='textRowInner'>
                <div className='textRowLeft boldText'>Total:</div>
                <div className='textRowRight boldText'>{Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData?.notation_amount + viewDetailsData?.notation_igst_amount).toFixed(2)}</div>
            </div>
        );

        const renderCGSTSGST = () => (
            <div className='textRowInner payableService'>
                <div className='textRow'>
                    <div className='textRowLeft boldText'>Total:</div>
                    <div className='textRowRight boldText'>{Utility.getCurrencySymbol(currency) + " " + parseFloat(viewDetailsData?.notation_amount + viewDetailsData?.notation_cgst_amount + viewDetailsData?.notation_sgst_amount).toFixed(2)}</div>
                </div>
            </div>
        );

        return (
            <div>
                {viewDetailsData?.notation_igst_amount > 0 ? renderIGST() : renderCGSTSGST()}
            </div>
        );
    };

    //payment history ui
    const paymentHistory = () => {
        let paymentHistory = [...props.viewDetailsData.booking_credited_payments, ...props.viewDetailsData.booking_debited_payments];
        let array = [];
        if (paymentHistory.length > 0) {
            paymentHistory.map((value, idx) => {
                let paidAmount = value?.payment_flow_type && value.payment_flow_type == 'cr' ? parseFloat(value.amount).toFixed(2) : "-";
                let refundAmount = value?.payment_flow_type && value.payment_flow_type == 'dr' ? parseFloat(value.amount).toFixed(2) : "-";
                let mode = value.payment_mode.charAt(0).toUpperCase() + value.payment_mode.slice(1);
                array.push(
                    <div className="paymentDetailsSectionDateRowDataBox">
                        <div className={extraService.length > 0 ? "paymentDetailsDataBox smallBox" : "paymentDetailsDataBox largeBox"}>
                            <div className='paymentDetailsValue recipetId'>{value?.recipet_id}</div>
                            <div className='paymentDetailsValue modePayment'>{mode}</div>
                            <div className={value?.payment_flow_type && value.payment_flow_type == 'cr' ? "paymentDetailsValue bookDate" : "paymentDetailsValue bookDate rightSpaceCr"}>{paidAmount}</div>
                            <div className={value?.payment_flow_type && value.payment_flow_type == 'dr' ? "paymentDetailsValue bookDate" : "paymentDetailsValue bookDate rightSpaceDr"}>{refundAmount}</div>
                            <div className='paymentDetailsValue date'>{moment.utc(value.payment_date).format("DD-MM-YYYY HH:mm")}</div>
                        </div>
                    </div>
                )
            })
        }
        return array;
    }

    //early checkout room details header ui
    const earlyCheckoutRoomDetailsUi = () => {
        let room_type_data_arr = [];
        if (props.viewDetailsData.hasOwnProperty('early_checkout_booking_details') && props.viewDetailsData.early_checkout_booking_details && Object.keys(props.viewDetailsData.early_checkout_booking_details).length > 0) {
            let paymentDetailsRoomTypeData = props.viewDetailsData.early_checkout_booking_details;


            room_type_data_arr.push(
                <div className="paymentDetailsSectionBoxRow" >
                    <div className="paymentDetailsSectionDateRowBox">
                        <div className='paymentDetailsTitle roomType'>{Utility.capitalizeFirstLetterOfEachWord('Room type')}</div>
                        <div className='paymentDetailsTitle bookDate timeDuration'>{Utility.capitalizeFirstLetterOfEachWord('Time Duration')}</div>
                        <div className='paymentDetailsTitle dayCount'>{Utility.capitalizeFirstLetterOfEachWord('#Room(s)')}</div>
                        <div className='paymentDetailsTitle roomNumbers'>{Utility.capitalizeFirstLetterOfEachWord('Room Nums')}</div>
                        <div className='paymentDetailsTitle perDayRate'>{Utility.capitalizeFirstLetterOfEachWord('Price/Night')} ({Utility.getCurrencySymbol(currency)})</div>
                        <div className='paymentDetailsTitle admount'>{Utility.capitalizeFirstLetterOfEachWord('Amount')} ({Utility.getCurrencySymbol(currency)})</div>
                    </div>
                    <div className="paymentDetailsSectionDateRowDataBox">
                        {Object.keys(paymentDetailsRoomTypeData).length > 0 && earlyCheckoutRoomTypeDetails(paymentDetailsRoomTypeData, true)}
                    </div>
                </div>
            );
        }
        return room_type_data_arr;
    }

    //early checkout room details value ui
    var room_type_amount_wise_price_early = {}
    const earlyCheckoutRoomTypeDetails = (data, is_new = true) => {
        let arrUi = []
        Object.keys(data).map((room_type_id, idx) => {
            Object.keys(data[room_type_id]).map((amount, i) => {
                let room_type_details = data[room_type_id][amount];
                room_type_amount_wise_price_early[room_type_id + '_' + amount] = room_type_details.room_rate
                arrUi.push(<div className={is_new ? "paymentDetailsDataBox" : "paymentDetailsDataBox previusValue"} key={idx}>
                    <div className='paymentDetailsValue roomType'>
                        {room_type_details.room_type_name.length > 30 ?
                            <OverlayTrigger overlay={<Tooltip>{room_type_details.room_type_name}</Tooltip>}>
                                <span className='roomTypeName'>{(room_type_details.room_type_name).substring(0, 27) + "..."}</span>
                            </OverlayTrigger> :
                            <span>{room_type_details.room_type_name}</span>
                        }
                    </div>
                    <div className='paymentDetailsValue bookDate timeDuration'>{`${moment(room_type_details.book_from).format('DD-MM-YYYY')} - ${moment(room_type_details.book_to).format('DD-MM-YYYY')} (${room_type_details.day_night})`}</div>
                    <div className='paymentDetailsValue dayCount room_count_l6'>{`${room_type_details?.room_count}`}</div>
                    <div className='paymentDetailsValue roomNumbers room_number_l6'>{`${room_type_details.hasOwnProperty('room_no') && room_type_details.room_no != null && Utility.hasNonZeroValues(room_type_details.room_no) ? room_type_details.room_no : '-'}`}</div>
                    <div className='paymentDetailsValue perDayRate'>
                        {room_type_details.perday_rent}
                    </div>
                    <div className='paymentDetailsValue admount'>{Number(room_type_details.room_rate).toFixed(2)}</div>
                </div>)

            })
        })

        return arrUi
    }

    //early checkout food details header ui
    const earlyCheckoutFoodDetailsUi = () => {
        let fooding_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('early_date_wise_food_hash') && props.viewDetailsData.early_date_wise_food_hash && Object.keys(props.viewDetailsData.early_date_wise_food_hash).length > 0)) {
            let paymentDetailsRoomTypeFoodingData = props.viewDetailsData.early_date_wise_food_hash;

            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionBoxRowFood" >
                        <div className="paymentDetailsSectionDateRowBox">
                            <div className='paymentDetailsTitle date'>{Utility.capitalizeFirstLetterOfEachWord('date')}</div>
                            <div className='paymentDetailsTitle headCount'>{Utility.capitalizeFirstLetterOfEachWord('head count')}</div>
                            <div className='paymentDetailsTitle breakfast'>{Utility.capitalizeFirstLetterOfEachWord('breakfast')}</div>
                            <div className='paymentDetailsTitle lunch'>{Utility.capitalizeFirstLetterOfEachWord('lunch')}</div>
                            <div className='paymentDetailsTitle dinner'>{Utility.capitalizeFirstLetterOfEachWord('dinner')}</div>
                            <div className='paymentDetailsTitle amount'>{Utility.capitalizeFirstLetterOfEachWord('amount')}</div>
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {earlyRoomTypeWisefoodPrice(paymentDetailsRoomTypeFoodingData)}
                        </div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }

    //early checkout food details value ui
    const earlyRoomTypeWisefoodPrice = (data) => {
        let arrUi = [];
        let prevDate = ''
        Object.keys(data).map((dateKey, idx) => {
            let innerArrUi = []; // Create a temporary array to hold inner elements
            for (const ageRange in data[dateKey]) {
                let newdate = ''
                if (prevDate != dateKey) {
                    prevDate = dateKey
                    newdate = dateKey
                }
                let room_type_details = data[dateKey][ageRange];
                let breakfastRate = "0";
                let lunchRate = "0";
                let dinnerRate = "0";
                let is_breakfast = false;
                let is_lunch = false;
                let is_dinner = false;

                let breakfastAmount = "0";
                let lunchAmount = "0";
                let dinnerAmount = "0";

                let headCount = 0;

                headCount = room_type_details.head_count

                is_breakfast = room_type_details.is_breakfast_available == 0 ? false : true;
                breakfastRate = room_type_details.breakfast_rate.toString()
                if (is_breakfast) {
                    breakfastAmount = breakfastRate * headCount
                }

                is_lunch = room_type_details.is_lunch_available == 0 ? false : true;
                lunchRate = room_type_details.lunch_rate.toString()
                if (is_lunch) {
                    lunchAmount = lunchRate * headCount;
                }

                is_dinner = room_type_details.is_dinner_available == 0 ? false : true;
                dinnerRate = room_type_details.dinner_rate.toString()
                if (is_dinner) {
                    dinnerAmount = dinnerRate * headCount;
                }


                innerArrUi.push(
                    <div className="paymentDetailsDataBox currentRow" key={idx}>
                        <div className='paymentDetailsValue date'>{newdate != '' ? <span className='dateField'>{moment(dateKey).format("DD-MM-YYYY")}</span> : ''}{ageRange.includes("adult") ? "Adult" : `Children(${ageRange})`}</div>

                        <div className='paymentDetailsValue headCount'>{headCount}</div>

                        {room_type_details.hasOwnProperty('is_breakfast_available') && room_type_details.is_breakfast_available != null && room_type_details.is_breakfast_available == true ? <div className='paymentDetailsValue breakfast'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    checked={is_breakfast}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue breakfast'><span style={{ color: 'red' }}>N/A</span></div>}
                        {room_type_details.hasOwnProperty('is_lunch_available') && room_type_details.is_lunch_available != null && room_type_details.is_lunch_available == true ? <div className='paymentDetailsValue lunch'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    checked={is_lunch}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue lunch'><span style={{ color: 'red' }}>N/A</span></div>}
                        {room_type_details.hasOwnProperty('is_dinner_available') && room_type_details.is_dinner_available != null && room_type_details.is_dinner_available == true ? <div className='paymentDetailsValue dinner'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    checked={is_dinner}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue dinner'><span style={{ color: 'red' }}>N/A</span></div>}
                        <div className='paymentDetailsValue amount'>{parseFloat(Number(Number(breakfastAmount) + Number(lunchAmount) + Number(dinnerAmount))).toFixed(2)}</div>

                    </div>)
            }
            arrUi.push(<div className="date-group date-group-view" key={`group-${dateKey}`}>{innerArrUi}</div>);
        })
        return arrUi
    }

    //early checkout details ui
    const EarlyCheckoutDetails = ({ viewDetailsData }) => {
        return (
            <div className='textRowInner'>
                {(Number(viewDetailsData.full_cancelation_details.extra_amount) > 0 || Number(viewDetailsData.full_cancelation_details.extra_discount) > 0) &&
                    <>
                        <div className='textRow'>
                            <div className='textRowLeft'><span className='boldText'>Total Amount</span> (Including Taxes):</div>
                            <div className='textRowRight boldText'>{viewDetailsData?.full_cancelation_details ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.full_cancelation_details.total_amount_to_pay).toFixed(2) : 0}</div>
                        </div>
                        {Number(viewDetailsData?.full_cancelation_details.extra_discount) > 0 ?
                            <div className='textRow'>
                                <div className='textRowLeft boldText'>Discount:</div>
                                <div className='textRowRight boldText'>{viewDetailsData.full_cancelation_details ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.full_cancelation_details.extra_discount).toFixed(2) : 0}</div>
                            </div> : <div className='textRow'>
                                <div className='textRowLeft boldText'>Extra charge:</div>
                                <div className='textRowRight boldText'>{viewDetailsData.full_cancelation_details ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.full_cancelation_details.extra_amount).toFixed(2) : 0}</div>
                            </div>}
                    </>
                }
                <div className='textRow'>
                    <div className='textRowLeft'><span className='boldText'>Total Payable Amount</span> (Including Taxes):</div>
                    <div className='textRowRight boldText'>{viewDetailsData?.full_cancelation_amount ? Utility.getCurrencySymbol(currency) + "" + parseFloat(viewDetailsData.full_cancelation_amount).toFixed(2) : 0}</div>
                </div>
            </div >
        );
    };

    //early checkout additional service details ui
    const earlyCheckoutAdditionalServiceDetailsUi = () => {
        let fooding_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('early_date_wise_note_arr') && props.viewDetailsData.early_date_wise_note_arr && props.viewDetailsData.early_date_wise_note_arr.length > 0)) {
            let payableServiceData = props.viewDetailsData.early_date_wise_note_arr;

            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionService" >
                        <div className="paymentDetailsSectionDateRowBox">
                            <div className='paymentDetailsTitle description earlyCheckoutDesc'>{Utility.capitalizeFirstLetterOfEachWord('description')}</div>
                            <div className='paymentDetailsTitle serviceDate earlyCheckoutServiceDate'>{Utility.capitalizeFirstLetterOfEachWord('service date')}</div>
                            <div className='paymentDetailsTitle amount earlyCheckoutAmount'>{Utility.capitalizeFirstLetterOfEachWord('amount')} ({Utility.getCurrencySymbol(currency)})</div>
                            {props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? null : <>
                                <div className='paymentDetailsTitle tax earlyCheckoutTax'>{Utility.capitalizeFirstLetterOfEachWord('Tax')} (%)</div>
                                <div className='paymentDetailsTitle amount amount_including_tax early_checkout_amount_including_tax'>{Utility.capitalizeFirstLetterOfEachWord('amount')} ({Utility.getCurrencySymbol(currency)})<span>(Including Tax)</span></div>
                            </>}
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {payableServicePrice(payableServiceData, true)}
                        </div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }

    // hide food details header ui
    const foodPriceDistributionHideUi = () => {
        let fooding_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('date_wise_food_hash') && props.viewDetailsData.date_wise_food_hash && Object.keys(props.viewDetailsData.date_wise_food_hash).length > 0)) {
            let paymentDetailsRoomTypeFoodingData = props.viewDetailsData.date_wise_food_hash;

            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionBoxRowFood" >
                        <div className="paymentDetailsSectionDateRowBox foodDetailsWhenHide paymentDetailsDataBox">
                            <div className='paymentDetailsTitle adult'>{Utility.capitalizeFirstLetterOfEachWord('type')}</div>
                            <div className='paymentDetailsTitle adultCount'>{Utility.capitalizeFirstLetterOfEachWord('head count')}</div>
                            <div className='paymentDetailsTitle totalDays'>{Utility.capitalizeFirstLetterOfEachWord('day(s)')}</div>
                            <div className='paymentDetailsTitle perDayPerHead'>{`${Utility.capitalizeFirstLetterOfEachWord('per day per head')} (${Utility.getCurrencySymbol(currency)})`}  {props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? null : <span>(Including Tax)</span>}</div>
                            <div className='paymentDetailsTitle amount'>{`${Utility.capitalizeFirstLetterOfEachWord('total')} (${Utility.getCurrencySymbol(currency)})`}</div>
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {roomTypeWisefoodPriceHide(paymentDetailsRoomTypeFoodingData)}
                        </div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }

    //hide food details value ui
    const roomTypeWisefoodPriceHide = (paymentDetailsRoomTypeFoodingData, is_new = true) => {
        let paymentDetailsDataSetTemp = { ...props.viewDetailsData };
        let fsgst = paymentDetailsDataSetTemp.fsgst_percent;
        let fcgst = paymentDetailsDataSetTemp.fcgst_percent;
        const totalDays = Object.keys(paymentDetailsRoomTypeFoodingData).length;

        const calculatePerDayPerHead = (type) => {
            let totalRate = 0;
            let totalHeadCount = 0;
            let totalRateWithGst = 0;
            let food_cgst_amount = 0;
            let food_sgst_amount = 0;
            let food_gst_amount = 0;

            Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
                let dayRate = 0;
                let dayHeadCount = 0;

                Object.keys(dayData).forEach((key) => {
                    if (type === 'adult' && key === 'adult') {
                        dayRate += (Number(dayData[key].breakfast_rate) +
                            Number(dayData[key].lunch_rate) +
                            Number(dayData[key].dinner_rate)) * dayData[key].head_count;
                        dayHeadCount += dayData[key].head_count;
                    } else if (type === 'child' && key !== 'adult') {
                        dayRate += (Number(dayData[key].breakfast_rate) +
                            Number(dayData[key].lunch_rate) +
                            Number(dayData[key].dinner_rate)) * dayData[key].head_count;
                        dayHeadCount += dayData[key].head_count;
                    }
                });

                totalRate += dayRate;
                totalHeadCount += dayHeadCount;
            });

            food_cgst_amount = Number((totalRate * fcgst / 100).toFixed(2));
            food_sgst_amount = Number((totalRate * fsgst / 100).toFixed(2));
            food_gst_amount = food_cgst_amount + food_sgst_amount;
            totalRateWithGst = props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? totalRate : totalRate + food_gst_amount;
            return totalHeadCount > 0 ? (totalRateWithGst / totalHeadCount).toFixed(2) : 0;
        };

        const calculateTotal = (type) => {
            let totalAmount = 0;
            let totalAmountWithGst = 0;
            let totalAmountChildren = 0;
            let totalAmountWithGstChildren = 0;
            let food_cgst_amount = 0;
            let food_sgst_amount = 0;
            let food_gst_amount = 0;

            Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
                Object.keys(dayData).forEach((key) => {
                    if (type === 'adult' && key === 'adult') {
                        totalAmount += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
                        food_cgst_amount = Number((totalAmount * fcgst / 100).toFixed(2));
                        food_sgst_amount = Number((totalAmount * fsgst / 100).toFixed(2));
                        food_gst_amount = food_cgst_amount + food_sgst_amount;
                        totalAmountWithGst = props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? totalAmount : totalAmount + food_gst_amount;
                    } else if (type === 'child' && key !== 'adult') {
                        totalAmountChildren += Number(dayData[key].breakfast_total) + Number(dayData[key].lunch_total) + Number(dayData[key].dinner_total);
                        food_cgst_amount = Number((totalAmountChildren * fcgst / 100).toFixed(2));
                        food_sgst_amount = Number((totalAmountChildren * fsgst / 100).toFixed(2));
                        food_gst_amount = food_cgst_amount + food_sgst_amount;
                        totalAmountWithGstChildren = props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? totalAmountChildren : totalAmountChildren + food_gst_amount;
                    }
                });


            });

            return type == 'adult' ? totalAmountWithGst.toFixed(2) : totalAmountWithGstChildren.toFixed(2);
        };

        const getTypeAndHeadCount = () => {
            let adultCount = 0;
            let childrenCount = 0;
            let childrenCountMap = new Map();

            Object.values(paymentDetailsRoomTypeFoodingData).forEach((dayData) => {
                if (dayData.adult) {
                    adultCount = dayData.adult.head_count;
                }

                Object.keys(dayData).forEach((key) => {
                    if (key !== 'adult' && dayData[key].hasOwnProperty('head_count') && dayData[key].head_count != null) {
                        if (childrenCountMap.has(key)) {
                            childrenCountMap.set(key, Number(dayData[key].head_count));
                        } else {
                            childrenCountMap.set(key, Number(dayData[key].head_count));
                        }
                    }
                });
            });
            childrenCountMap.forEach((count) => {
                childrenCount += count;
            });
            return { adultCount, childrenCount };
        };
        const { adultCount, childrenCount } = getTypeAndHeadCount();

        return (
            <div className="newFoodDetailsTable">
                {/* Adult Row */}
                <div className={is_new ? "paymentDetailsDataBox currentRow" : "paymentDetailsDataBox previousRow"}>
                    <div className="paymentDetailsData adult">{adultCount > 1 ? 'Adults' : 'Adult'}</div>
                    <div className="paymentDetailsData paymentDetailsDataSmall middsize adultCount">{adultCount}</div>
                    <div className="paymentDetailsData paymentDetailsDataSmall totalDays">{totalDays}</div>
                    <div className="paymentDetailsData paymentDetailsDataLarge bigsize perDayPerHead">
                        {adultCount > 0 ? calculatePerDayPerHead('adult') : '0.00'}
                    </div>
                    <div className="paymentDetailsData text-right amount">
                        {adultCount > 0 ? calculateTotal('adult') : '0.00'}
                    </div>
                </div>

                {/* Children Row */}
                {childrenCount > 0 && (
                    <div className={is_new ? "paymentDetailsDataBox currentRow" : "paymentDetailsDataBox previousRow"}>
                        <div className="paymentDetailsData child">{childrenCount > 1 ? 'Children' : 'Child'}</div>
                        <div className="paymentDetailsData paymentDetailsDataSmall middsize adultCount">{childrenCount}</div>
                        <div className="paymentDetailsData paymentDetailsDataSmall totalDays">{totalDays}</div>
                        <div className="paymentDetailsData paymentDetailsDataLarge bigsize perDayPerHead">
                            {childrenCount > 0 ? calculatePerDayPerHead('child') : '0.00'}
                        </div>
                        <div className="paymentDetailsData text-right amount">
                            {childrenCount > 0 ? calculateTotal('child') : '0.00'}
                        </div>
                    </div>
                )}
            </div>
        );
    };


    //early checkout food details hide ui
    const earlyCheckoutFoodDetailsHideUi = () => {
        let fooding_arr = [];
        if ((props.viewDetailsData.hasOwnProperty('early_date_wise_food_hash') && props.viewDetailsData.early_date_wise_food_hash && Object.keys(props.viewDetailsData.early_date_wise_food_hash).length > 0)) {
            let paymentDetailsRoomTypeFoodingData = props.viewDetailsData.early_date_wise_food_hash;

            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionBoxRowFood paymentDetailsDataBox" >
                        <div className="paymentDetailsSectionDateRowBox foodDetailsWhenHide">
                            <div className='paymentDetailsTitle adult'>{Utility.capitalizeFirstLetterOfEachWord('type')}</div>
                            <div className='paymentDetailsTitle adultCount'>{Utility.capitalizeFirstLetterOfEachWord('head count')}</div>
                            <div className='paymentDetailsTitle totalDays'>{Utility.capitalizeFirstLetterOfEachWord('day(s)')}</div>
                            <div className='paymentDetailsTitle perDayPerHead'>{`${Utility.capitalizeFirstLetterOfEachWord('per day per head')} (${Utility.getCurrencySymbol(currency)})`}  {props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? null : <span>(Including Tax)</span>}</div>
                            <div className='paymentDetailsTitle amount totalEarlyCheckoutAmount'>{`${Utility.capitalizeFirstLetterOfEachWord('total')} (${Utility.getCurrencySymbol(currency)})`}</div>

                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {roomTypeWisefoodPriceHide(paymentDetailsRoomTypeFoodingData)}
                        </div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }


    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension bookingDetailsView">
                    <div className='overviewBookingSection'>

                        <div className='detailsBox overview'>
                            <fieldset>
                                <legend><h2 className='titleBox'>Customer Details</h2></legend>
                                <div className='bookingIdBox'>Name: <span>{props.viewDetailsData.first_name} {props.viewDetailsData.last_name} </span></div>
                                <div className='bookingIdBox'>Email: <span>{props.viewDetailsData.customer_email}</span> </div>
                                <div className='bookingIdBox'>Contact: <span>{props.viewDetailsData.customer_phone}</span> </div>

                            </fieldset>
                        </div>





                        <div className='detailsBox overview section2'>
                            <fieldset>
                                <legend><h2 className='titleBox'>Overview</h2></legend>
                                <div className='bookingIdBox'>Booking ID: <span>{bookingId}</span></div>
                                {((bookingStatus != "") || (bookingDate != "")) &&
                                    <div className='bookingStatusBox'>
                                        {bookingStatus != "" &&
                                            <div>Status: <span className={bookingStatus == 'Early Checkout' ? 'highlightColor' : ''}>{bookingStatus}</span></div>
                                        }
                                        {bookingDate != "" &&
                                            <div>Booking Date: <span>{moment(bookingDate).format("DD-MM-YYYY")}</span></div>
                                        }
                                    </div>
                                }
                                {((props.viewDetailsData.total_amount != null && props.viewDetailsData.total_amount != "") || (props.viewDetailsData.billing_amount != null && props.viewDetailsData.billing_amount != "")) &&
                                    <div className='bookingStatusBox'>
                                        {props.viewDetailsData.total_amount != null && props.viewDetailsData.total_amount != "" &&
                                            <div>Booking Amt: <span>{Utility.getCurrencySymbol(currency)} {Number(props.viewDetailsData.total_amount).toFixed(2)}</span></div>
                                        }
                                        {props.viewDetailsData.billing_amount != null && props.viewDetailsData.billing_amount != "" &&
                                            <div>Billing Amt: <span>{Utility.getCurrencySymbol(currency)} {Number(props.viewDetailsData.billing_amount).toFixed(2)}</span></div>
                                        }
                                    </div>}
                                {Number(props.viewDetailsData.special_discount) > 0 ?
                                    <div className='bookingStatusBox specialDiscountRow'>
                                        <p>Special Discount for Room{props.viewDetailsData.special_discount_perc && props.viewDetailsData.special_discount_perc != "" ? "(" + props.viewDetailsData.special_discount_perc + "%)" : null}:  <span>{Utility.getCurrencySymbol(currency)} {Number(props.viewDetailsData.special_discount).toFixed(2)}</span></p>
                                    </div>
                                    : null}
                                {Number(props.viewDetailsData.special_discount_for_food) > 0 ?
                                    <div className='bookingStatusBox specialDiscountRow'>
                                        <p>Special Discount for Food{props.viewDetailsData.special_discount_perc_for_food && props.viewDetailsData.special_discount_perc_for_food != "" ? "(" + props.viewDetailsData.special_discount_perc_for_food + "%)" : null}:  <span>{Utility.getCurrencySymbol(currency)} {Number(props.viewDetailsData.special_discount_for_food).toFixed(2)}</span></p>
                                    </div>
                                    : null}
                                {Number(props.viewDetailsData.round_off_amount) != 0 ?
                                    <div className='bookingStatusBox specialDiscountRow'>
                                        <p>Round of Amount: <span>{Utility.getCurrencySymbol(currency)} {Number(props.viewDetailsData.round_off_amount).toFixed(2)}</span></p>
                                    </div>
                                    : null}

                                <div className='detailsBoxRow'>
                                    <div className='detailsBoxRowInner label'>{checkInLabel}</div>
                                    <div className='detailsBoxRowInner value'>{moment(checkedIn.toString()).format("DD-MM-YYYY HH:mm")}</div>
                                </div>
                                <div className='detailsBoxRow'>
                                    <div className='detailsBoxRowInner label'>{checkOutLabel}</div>
                                    <div className='detailsBoxRowInner value'>{props.viewDetailsData.hasOwnProperty('original_checked_datetime') && props.viewDetailsData.original_checked_datetime != null ? moment(props.viewDetailsData.original_checked_datetime.toString()).format("DD-MM-YYYY HH:mm") : "-"}</div>
                                </div>
                                {props.viewDetailsData.hasOwnProperty("booking_master_details") && props.viewDetailsData.booking_master_details.is_early_checkout == 1 &&
                                    <div className='bookingIdBox'>Early Checkout Date: <span className='highlightColor'>{moment(checkedOut.toString()).format("DD-MM-YYYY HH:mm")}</span></div>
                                }
                                <div className='detailsBoxRows'>
                                    <div className='detailsBoxRow'>
                                        <div className='detailsBoxRowInner label'>Adult(s)</div>
                                        <div className='detailsBoxRowInner value'>{adult}</div>
                                    </div>
                                    <div className='detailsBoxRow'>
                                        <div className='detailsBoxRowInner label'>Children</div>
                                        <div className='detailsBoxRowInner value'>{child} {child != "" && child != 0 && <span>(Age: {childAge})</span>}</div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='detailsBox roomTypeDetails'>
                            <fieldset>
                                <legend><h2 className='titleBox'>Room Type Details</h2></legend>
                                {paymentPriceDistributionUi()}
                                <RoomTaxDetails viewDetailsData={props.viewDetailsData} />
                            </fieldset>
                        </div>
                    </div>
                    {Object.keys(dateWiseFoodHash).length > 0 &&
                        <div className={`detailsBox ${props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? "hide_fooding_details" : "show_fooding_details"}`}>
                            <fieldset>
                                <legend><h2 className='titleBox'>Fooding Details</h2></legend>
                                {props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? foodPriceDistributionHideUi() : foodPriceDistributionUi()}
                                {props.viewDetailsData.hasOwnProperty('food_amount') && props.viewDetailsData.food_amount && props.viewDetailsData.food_amount > 0 ?
                                    <FoodTaxDetails viewDetailsData={props.viewDetailsData} /> : null
                                }

                            </fieldset>
                        </div>}
                    {bookingStatus == 'Early Checkout' && <div className='detailsBox earlyCheckoutBox'>
                        <div className={`earlyCheckoutBoxInner ${props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? "hide_fooding_details" : "show_fooding_details"}`}>
                            <fieldset className='fieldsetSection1'>
                                <legend><h2 className='titleBox'>Early Checkout Room Details</h2></legend>
                                <div className='paymentDetailsSectionBoxesRows'>
                                    {earlyCheckoutRoomDetailsUi()}
                                </div>
                            </fieldset>
                            {props.viewDetailsData.hasOwnProperty('early_date_wise_food_hash') && props.viewDetailsData.early_date_wise_food_hash && Object.keys(props.viewDetailsData.early_date_wise_food_hash).length > 0 ?
                                <fieldset className='fieldsetSection2'>
                                    <legend><h2 className='titleBox'>Early Checkout Food Details</h2></legend>
                                    <div className='paymentDetailsSectionBoxesRows'>
                                        {/* {earlyCheckoutFoodDetailsUi()} */}
                                        {props.viewDetailsData.hasOwnProperty('booking_master_details') && props.viewDetailsData.booking_master_details && props.viewDetailsData.booking_master_details.hasOwnProperty('hide_food_details') && props.viewDetailsData.booking_master_details.hide_food_details ? earlyCheckoutFoodDetailsHideUi() : earlyCheckoutFoodDetailsUi()}
                                    </div>
                                </fieldset> : null}
                            {props.viewDetailsData.hasOwnProperty('early_date_wise_note_arr') && props.viewDetailsData.early_date_wise_note_arr && props.viewDetailsData.early_date_wise_note_arr.length > 0 ?
                                <fieldset className='fieldsetSection2'>
                                    <legend><h2 className='titleBox'>Early Checkout Additional Service Details</h2></legend>
                                    <div className={`paymentDetailsSectionBoxesRows earlyCheckoutPayableService ${props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? '' : "extraServiceWithTax"}`}>
                                        <div style={{ width: '100%' }}>
                                            {earlyCheckoutAdditionalServiceDetailsUi()}
                                        </div>
                                    </div>
                                </fieldset> : null}
                            <EarlyCheckoutDetails viewDetailsData={props.viewDetailsData} />
                        </div>
                    </div>}
                    <div className={`payableServicePayment ${props.viewDetailsData.hasOwnProperty('no_tax') && props.viewDetailsData.no_tax ? '' : "extraServiceWithTax"}`}>
                        {extraService.length > 0 &&
                            <div className='detailsBox service'>
                                <fieldset>
                                    <legend><h2 className='titleBox'>Payable Service Details</h2></legend>
                                    {payableServiceDetailsUi()}
                                    {props.viewDetailsData.hasOwnProperty('notation_amount') && props.viewDetailsData.notation_amount && props.viewDetailsData.notation_amount > 0 ?
                                        <NotationTaxDetails viewDetailsData={props.viewDetailsData} />
                                        : null}
                                </fieldset>
                            </div>}
                        {props.viewDetailsData.hasOwnProperty('booking_credited_payments') && props.viewDetailsData.booking_credited_payments.length > 0 &&
                            <div className={extraService.length > 0 ? "detailsBox payment" : "detailsBox paymentFull"}>
                                <fieldset>
                                    <legend><h2 className='titleBox'>Payment History</h2></legend>
                                    <div className="paymentDetailsSectionBoxRow paymentCreditHistory">
                                        <div className="paymentDetailsSectionDateRowBox">
                                            <div className='paymentDetailsTitle recipetId'>{Utility.capitalizeFirstLetterOfEachWord('Receipt Id')}</div>
                                            <div className='paymentDetailsTitle modePayment'>{Utility.capitalizeFirstLetterOfEachWord('Mode')}</div>
                                            <div className='paymentDetailsTitle bookDate'>{Utility.capitalizeFirstLetterOfEachWord('Paid')} ({Utility.getCurrencySymbol(currency)})</div>
                                            <div className='paymentDetailsTitle bookDate'>{Utility.capitalizeFirstLetterOfEachWord('Refund')} ({Utility.getCurrencySymbol(currency)})</div>
                                            <div className='paymentDetailsTitle date'>{Utility.capitalizeFirstLetterOfEachWord('Transaction Date')}</div>
                                        </div>
                                        {props.viewDetailsData.hasOwnProperty('booking_credited_payments') && props.viewDetailsData.booking_credited_payments.length > 0 ?
                                            paymentHistory()
                                            : null}
                                    </div>
                                </fieldset>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token
    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(ViewBookingDetails));
