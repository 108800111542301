import React, { useState, useEffect } from 'react';
import { Routes, Route, HashRouter, useHistory } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import V1Routes from '../src/V1/Routes/index';
import Popup from 'react-popup';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { loaderStateTrue, loaderStateFalse } from '../src/Actions/AllAction';
import { checkLiveOrNot } from './Utility/Controller/UtilityController';
import { connect } from 'react-redux';
import ComingSoonPage from './Utility/Pages/ComingSoonPage';

const channel = new BroadcastChannel('foobar');

function App(props) {
  const history = useHistory();
  const { loaderStateFalse } = props;
  const [live, setLive] = useState(null);
  const [liveApiSuccess, setLiveApiSuccess] = useState(false);

  //initialy check multiple channels 
  useEffect(() => {
    channel.onmessage = (event) => {
      if (event.data.isLogout) {
        window.location.href = window.location.origin + "/";
      }
      if (event.data.isLogin) {
        window.location.reload();
      }
    };
    loaderStateFalse();
  }, []);

  //check live or not using checkLiveOrNot api
  useEffect(() => {
    async function fetchData() {
      try {
        let data = {};
        data['type'] = "customer";
        let filters = {}
        filters['filters'] = JSON.stringify(data);
        const response = await checkLiveOrNot(filters);
        if (response?.success && response.data.length > 0) {
          setLive(response.data[0].is_live);
          setLiveApiSuccess(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  //if live then redirect all channels to live
  useEffect(() => {
    if (live === 1) {
      channelMessage();
    }
  }, [live]);

  const channelMessage = () => {
    channel.onmessage = (event) => {
      if (event.data.isLogout) {
        window.location.href = window.location.origin + "/";
      }
    };
    loaderStateFalse();
  }

  const { isLoading } = props;

  return (
    <>
      {liveApiSuccess && (
        <LoadingOverlay
          active={isLoading}
          className='loadingOverlayDiv'
          spinner={<FadeLoader color={'#ccc'} size={50} margin='0px' speedMultiplier={0.3} />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.1)',
              zIndex: '9999999999999'
            })
          }}
        >
          <Popup />
          <HashRouter>
            {live != null ? (
              <>
                {live === 1 ? (
                  <V1Routes />
                ) : (
                  <ComingSoonPage />
                )}</>
            ) : null}
          </HashRouter>
        </LoadingOverlay>
      )}
    </>
  );
}

const mapStateToProps = (globalState) => ({
  isLoading: globalState.mainReducerData.loaderState,
});

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })(App);
