import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal';
import { withNamespaces } from 'react-i18next';
import moment from 'moment'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { getPaymentDetails, paymentDetailsDelete } from '../Controller/HomeController';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PaymentModeModalContent from './PaymentModeModalContent';
import GridActionContent from '../../../../Utility/Components/GridActionContent';
import ConfirmationAlert from '../../../../Utility/Components/ConfirmationAlert'
import Utility from "../../../../Utility/Utility";

function PaymentViewModalContent(props) {
    const [modules, setmodules] = useState([InfiniteRowModelModule]);
    const [defaultColDef, setdefaultColDef] = useState({
        flex: 1,
        minWidth: 50
    });

    const [components, setcomponents] = useState({
        loadingRenderer: (params) => {
            if (params.value !== undefined) {
                return params.value;
            } else {
                return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
            }
        },
    })

    const [columnDefs, setColumnDefs] = useState(
        [
            {
                headerName: 'Payment Mode',
                field: "payment_mode",
                minWidth: 120,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.payment_mode && params.data.hasOwnProperty('payment_mode') && params.data.payment_mode != null && params.data.payment_mode != "") {
                            return <div className='payment_mode_label'>{params.data.payment_mode}</div>
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: 'Receipt Id',
                field: "recipet_id",
                minWidth: 170,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.recipet_id && params.data.hasOwnProperty('recipet_id') && params.data.recipet_id != null && params.data.recipet_id != "") {
                            if (params.data.recipet_id.length > 20) {
                                return <div className="usercursor">
                                    <OverlayTrigger overlay={<Tooltip>{params.data.recipet_id}</Tooltip>}>
                                        <span >{(params.data.recipet_id).substring(0, 20) + '...'}</span>
                                    </OverlayTrigger>
                                </div>
                            } else {
                                return <div className="detailsViewContentInner">
                                    <div className="detailsViewContent">
                                        <span className='textrenderview'>
                                            {params.data.recipet_id}
                                        </span>
                                    </div>
                                </div>
                            }
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: 'Cust Payment Ref Id',
                field: "payment_ref_id",
                minWidth: 200,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.payment_ref_id && params.data.hasOwnProperty('payment_ref_id') && params.data.payment_ref_id != null && params.data.payment_ref_id != "") {
                            if (params.data.payment_ref_id.length > 20) {
                                return <div className="usercursor">
                                    <OverlayTrigger overlay={<Tooltip>{params.data.payment_ref_id}</Tooltip>}>
                                        <span >{(params.data.payment_ref_id).substring(0, 20) + '...'}</span>
                                    </OverlayTrigger>
                                </div>
                            } else {
                                return <div className="detailsViewContentInner">
                                    <div className="detailsViewContent">
                                        <span className='textrenderview'>
                                            {params.data.payment_ref_id}
                                        </span>
                                    </div>
                                </div>
                            }
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: 'Amount',
                field: "amount",
                minWidth: 140,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.amount && params.data.hasOwnProperty('amount') && params.data.amount != null && params.data.amount != "") {
                            return <div className="detailsViewContentInner">
                                <div className="detailsViewContent">
                                    <span className='textrenderview'>
                                        {params.data.hasOwnProperty('currency') && params.data.currency != null && params.data.currency != "" ? Utility.getCurrencySymbol(params.data.currency) : ""} {parseFloat(params.data.amount).toFixed(2)}
                                    </span>
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: 'Transaction Date',
                field: "payment_date",
                minWidth: 150,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.payment_date && params.data.hasOwnProperty('payment_date') && params.data.payment_date != null && params.data.payment_date != "") {
                            return <div className="detailsViewContentInner">
                                <div className="detailsViewContent">
                                    <span className='textrenderview'>
                                        {moment.utc(params.data.payment_date).format("DD-MM-YYYY HH:mm")}
                                    </span>
                                </div>
                            </div>
                        } else {
                            return null;
                        }

                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: 'Approved By',
                field: "created_by",
                minWidth: 190,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.created_by && params.data.created_by.hasOwnProperty('name') && params.data.created_by.name != null && params.data.created_by.name != "") {
                            return <div className="detailsViewContentInner">
                                <div className="detailsViewContent">
                                    <span className='textrenderview'>
                                        {params.data.hasOwnProperty('payment_approved') && params.data.payment_approved ? params.data.created_by.name : "-"}
                                    </span>
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: "Status",
                field: "payment status",
                minWidth: 140,
                sortable: true,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.hasOwnProperty('payment_approved') && params.data.payment_approved != null) {
                            return <div className="detailsViewContentInner">
                                <div className="detailsViewContent">
                                    <span className='textrenderview'>
                                        {params.data.payment_approved ? <div className="status_symbol statusCompleted"><span className="dot">&nbsp;</span>Approved</div> : <div className="status_symbol statusCanceled awaitingSymbol"><span className="dot">&nbsp;</span><span className='awaitingconfirmation'>Awaiting for bank <br />confirmation</span></div>}
                                    </span>
                                </div>
                            </div>
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
            },
            {
                headerName: "",
                field: "edit",
                maxWidth: 50,
                suppressMovable: true,
                cellRenderer: (params) => {
                    if (params.data) {

                        return <div className="actionablePopup paymentAction">
                            {
                                props.bookingStatus && params.data.hasOwnProperty('payment_approved') && params.data.payment_approved != null && !params.data.payment_approved ?
                                    <button className="ellipsisIcon" onClick={(e) => actionModalfunction(e, params)}><i className="fa fa-ellipsis-v"></i></button>
                                    :
                                    null
                            }
                        </div>
                    } else {
                        return null;
                    }
                },
            }

        ]
    )

    const actionOutsideClickRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridparams, setgridparams] = useState(null);
    const [hotelBranchFilterSelectedValue, setHotelBranchFilterSelectedValue] = useState("");
    const [designationFilterSelectedValue, setDesignationFilterSelectedValue] = useState("");
    const [designationFilterList, setDesignationFilterList] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState("");
    const [isDisableRole, setIsDisableRole] = useState(true);
    const [actionModalflag, setActionModalflag] = useState(false);
    const [overlayHight, setOverlayHight] = useState(0);
    const [overlayWidth, setOverlayWidth] = useState(0);
    const [gridObjData, setGridObjData] = useState({});
    const [totalAmount, setTotalAmount] = useState("");
    const [paidAmount, setPaidAmount] = useState("");
    const [dueAmount, setDueAmount] = useState("");
    const [minimumAmount, setMinimumAmount] = useState("");
    const [minimumAmountToPay, setMinimumAmountToPay] = useState("");
    const [billingAmount, setBillingAmount] = useState("");
    const [isFirstPayment, setIsFirstPayment] = useState("");
    const [currency, setCurrency] = useState("");
    const [branch_id, setBranchId] = useState("");
    const [paymentModeModalFlag, setPaymentModeModalFlag] = useState(false);
    const [paymentModeModalHeaderTitle, setPaymentModeModalHeaderTitle] = useState("");
    const [skipPaymentModalFlag, setSkipPaymentModalFlag] = useState(false);
    // payment details delete
    const [deletePaymentDetailsModalFlag, setDeletePaymentDetailsModalFlag] = useState(false);
    const [paymentDetailsDeleteBodySecondContent, setPaymentDetailsDeleteBodySecondContent] = useState("");
    const [paymentDetailsDeleteObj, setPaymentDetailsDeleteObj] = useState({});
    // payment details edit
    const [editedFlag, setEditedFlag] = useState(false);
    const [editedId, setEditedId] = useState("");
    const [editedRowData, setEditedRowData] = useState({});

    //aggrid onGridReady function
    const onGridReady = useCallback((params) => {
        setgridparams(params)
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
        var datasource = serverSideDataSource()
        params.api.setDatasource(datasource);
    });

    //aggrid reset aggrid function
    const resetDataGrid = (e = null) => {
        var datasource = serverSideDataSource(e)
        gridApi.setDatasource(datasource);
    }

    const serverSideDataSource = () => {
        return {
            getRows(params) {
                const { startRow, endRow, filterModel, sortModel } = params
                const { loaderStateTrue, loaderStateFalse, bookingMasterId } = props;
                if (props.callFrom == "quotation") {
                    setTotalAmount(props.quotationTotalAmount)
                    setPaidAmount("0")
                    setDueAmount(props.quotationTotalAmount)
                    setMinimumAmount(props.minimumAmount)
                    setIsFirstPayment(true)
                    setCurrency(props.currency)
                    setBranchId(props.branchId)

                } else {
                    loaderStateTrue();
                    let filters = {};
                    let filter_op = {};
                    let globalQueryParamshash = {};
                    filters['filters'] = JSON.stringify(globalQueryParamshash)
                    let filterServerside = Object.keys(JSON.parse(filters.filters)).length > 0 ? filters : null;
                    getPaymentDetails(bookingMasterId, filterServerside).then((response) => {
                        if (response.success) {
                            let totalAmount = response.debitedTotal && response.debitedTotal != null && response.debitedTotal != "" ? parseFloat(response.debitedTotal).toFixed(2) : ""
                            let paidAmount = response.creditedTotal && response.creditedTotal != null && response.creditedTotal != "" ? parseFloat(response.creditedTotal).toFixed(2) : ""
                            let dueAmount = response.dueAmount && response.dueAmount != null && response.dueAmount != "" ? parseFloat(response.dueAmount).toFixed(2) : ""
                            let minimumAmount = response.minimumAmount != null && response.minimumAmount.toString() != "" ? parseFloat(response.minimumAmount).toFixed(2) : ""
                            let minimumAmountToPay = response.minimumAmountToPay != null && response.minimumAmountToPay.toString() != "" ? parseFloat(response.minimumAmountToPay).toFixed(2) : ""
                            let billingAmount = response.hasOwnProperty('billingAmount') && response.billingAmount != null && response.billingAmount.toString() != "" ? parseFloat(response.billingAmount).toFixed(2) : ""
                            let is_first_payment = response.is_first_payment && response.is_first_payment != null && response.is_first_payment != "" ? response.is_first_payment : ""
                            let currency = response.currency && response.currency != null && response.currency != "" ? response.currency : ""
                            let branchId = response.branch_id && response.branch_id != null && response.branch_id != "" ? response.branch_id : ""
                            setTotalAmount(totalAmount)
                            setPaidAmount(paidAmount)
                            setDueAmount(dueAmount)
                            setMinimumAmount(minimumAmount)
                            setIsFirstPayment(is_first_payment)
                            setCurrency(currency)
                            setBranchId(branchId)
                            setMinimumAmountToPay(minimumAmountToPay)
                            setBillingAmount(billingAmount)
                            params.successCallback(response.data, response.data.length);
                        } else {
                            console.error(error);
                            params.failCallback();
                            Utility.toastNotifications("Something went wrong !", "Error", "error")
                        }
                        loaderStateFalse();
                    }).catch((error) => {
                        console.error("************error*************", error)
                        if (error) {
                        }
                        loaderStateFalse();
                        if (error.message == "Network Error") {

                        }
                    });
                }
            }
        };
    }

    const gridOptions = useMemo(
        () => ({
            infiniteInitialRowCount: 1,
            // onGridReady,
            paginationPageSize: 100,
            rowBuffer: 0,
            rowModelType: 'infinite',
            suppressCellSelection: true,
            suppressHorizontalScroll: true,

        }),
        []
    )

    const actionModalfunction = (e, gridObj) => {
        e.stopPropagation();
        let width = (parseInt(e.clientX) - 320) + 'px';
        let height = (parseInt(e.clientY) - 95) + 'px';
        setActionModalflag(true);
        setOverlayHight(height);
        setOverlayWidth(width);
        setGridObjData(gridObj);
    }

    useEffect(() => {
        const handleMouseDown = (event) => {
            handleClickOutside(event);
        };
        document.body.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.body.removeEventListener('mousedown', handleMouseDown);
        };


    }, []);

    const handleClickOutside = (event) => {
        if (actionOutsideClickRef.current && !actionOutsideClickRef.current.contains(event.target)) {
            setActionModalflag(false);
            setOverlayHight(0);
            setOverlayWidth(0);
            setGridObjData({});
        }
    };


    //DELETE PaymentDetails
    const deletePaymentDetails = (params) => {
        setDeletePaymentDetailsModalFlag(true);
        setPaymentDetailsDeleteBodySecondContent("")
        setPaymentDetailsDeleteObj(params.data)
    }

    const deletePaymentDetailsConfirmationModalHide = () => {
        setDeletePaymentDetailsModalFlag(false);
        setPaymentDetailsDeleteBodySecondContent("")
    }

    const PaymentDetailsDeleteCancleButton = () => {
        setDeletePaymentDetailsModalFlag(false);
        setPaymentDetailsDeleteBodySecondContent("")
    }

    const PaymentDetailsDeleteConfirmButton = () => {
        PaymentDetailsDeleteApi()
    }

    const PaymentDetailsDeleteApi = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();
        let data = {}
        data['id'] = [paymentDetailsDeleteObj.id]
        paymentDetailsDelete(data).then((response) => {
            if (response.success) {
                deletePaymentDetailsConfirmationModalHide()
                resetDataGrid()
                Utility.toastNotifications(response.data[0].message, "Success", "success")
            } else {
                Utility.toastNotifications(response.data[0].message, "Error", "error")
            }
            loaderStateFalse();
        }).catch((error) => {
            loaderStateFalse();
            deletePaymentDetailsConfirmationModalHide()
        });

    }

    //EDIT PaymentDetails
    const editPaymentDetails = (params) => {
        openPaymentModeModalFn("edit")
        setEditedFlag(true)
        setEditedId(params.data.id)
        setEditedRowData(params.data)
    }

    const openPaymentModeModalFn = (callFrom = "") => {
        setPaymentModeModalFlag(true);
        if (callFrom == 'edit') {
            setPaymentModeModalHeaderTitle("Edit Payment Details")
        } else {
            setPaymentModeModalHeaderTitle("Add Payment")
        }
    }

    const closePaymentModeModalFnChild = () => {
        closePaymentModeModalFn()
    }

    const resetDataGridFnFromChild = () => {
        resetDataGrid()
    }

    const closePaymentModeModalFn = () => {
        setPaymentModeModalFlag(false);
        setPaymentModeModalHeaderTitle("");
        setEditedFlag(false)
        setEditedId("")
        setEditedRowData("")
    }

    const closePayModalSuccessFn = () => { }

    const skipPaymentConfirmationModalFn = () => {
        setSkipPaymentModalFlag(false);
        props.skipPaymentFn();
    }

    const skipPaymentConfirmationModalHide = () => {
        setSkipPaymentModalFlag(false)
    }

    const skipPaymentConfirmationModalOpenFn = () => {
        setSkipPaymentModalFlag(true)
    }

    const closeModalCallFromQuotationFn = (data, imageData = []) => {
        props.addPaymentBookingConversionFn(data, imageData);
    }

    return (
        <div className="gridcontainer paymentGridBox">
            <div className="totalworkercontent">
                <div className="gridtopviews gridtopviewsHistory bookingNumber">
                    <span>Booking No. {props.callFrom == "quotation" ? <span className='generateLabel'>Yet To Generate</span> : <span className='dataLabel'>{props.bookingId}</span>} {(props.OriginalbookingStatus == "Cancelled") ? <en className="paymentDueAmount"><>&nbsp;&nbsp;&nbsp;</><span className='dataLabel'>Cancelled</span></en> : ""}</span>
                    <span className="bookingNumberRight">
                        <span>Booking Amt. ({currency && currency != "" && currency != null ? Utility.getCurrencySymbol(currency) : ""}): {totalAmount != "" && totalAmount != null ? <span className='dataLabel'>{parseFloat(totalAmount).toFixed(2)}</span> : ""}</span>
                        <span>Billing Amt. ({currency && currency != "" && currency != null ? Utility.getCurrencySymbol(currency) : ""}): {billingAmount != "" && billingAmount != null ? <span className='dataLabel'>{parseFloat(billingAmount).toFixed(2)}</span> : <span className='dataLabel'>0.00</span>}</span>
                        <span>Initial Payable Amt. ({currency && currency != "" && currency != null ? Utility.getCurrencySymbol(currency) : ""}): {minimumAmountToPay != "" && minimumAmountToPay != null ? <span className='dataLabel'>{parseFloat(minimumAmountToPay).toFixed(2)}</span> : ""}</span>
                        <span>Total Paid Amt. ({currency && currency != "" && currency != null ? Utility.getCurrencySymbol(currency) : ""}): {paidAmount != "" && paidAmount != null ? <span className='dataLabel'>{parseFloat(paidAmount).toFixed(2)}</span> : ""}</span>
                        {(props.OriginalbookingStatus != "Cancelled") && <span>Due Amt. ({currency && currency !== "" && currency !== null ? Utility.getCurrencySymbol(currency) : ""}): <en className="paymentDueAmount">{dueAmount !== "" && dueAmount !== null ? <span className='dataLabel'>{parseFloat(dueAmount).toFixed(2)}</span> : parseFloat(0.00).toFixed(2)}</en></span>}
                    </span>
                </div>
                <div className='grid_content_inner paymentViewGridInner paymentDetailsGridInner'>
                    <div className='paymentViewGrid'>
                        <div className="ag-theme-alpine aggridview agprises historyGrid">
                            <AgGridReact
                                modules={modules}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                components={components}
                                rowSelection="single"
                                rowModelType="infinite"
                                onGridReady={onGridReady}
                                rowHeight={45}
                                headerHeight={40}
                                cacheBlockSize={process.env.PAGE_DATA_LIMIT}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>

                <div className={
                    props.bookingStatus ? "col-md-12 text-center topspace paymentViewModalFooter" : "col-md-12 text-center topspace paymentViewModalFooter fullWidth"}>
                    {
                        props.bookingStatus && !props.paymentHistoryShowFlag ?
                            <button type="button" className="modbtn"
                                onClick={(e) => openPaymentModeModalFn()}
                            >Add Payment</button>
                            :
                            null
                    }

                    <button type="button" className="modbtn cancelbtn" onClick={(e) => { props.closePaymentModalFn() }}
                    >Cancel</button>
                </div>

            </div>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={paymentModeModalFlag}
                    onHide={closePaymentModeModalFn}
                    title={paymentModeModalHeaderTitle}
                    className="modalcustomize mondimension paymentModalContent paymentModeModalContent paymentModeModalSection"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Confirm"
                    saveButtonClassName="btn btn-primary savebtn modbtn"
                    cancelShow={true}
                    cancelButtonLabel={"Cancel"}
                    cancelButtonClassName="modbtn cancelbtn"
                    footerClassName="text-center topspace"
                    body={
                        <>
                            <ErrorBoundary title="BranchAddEditModalContent Error">
                                <PaymentModeModalContent
                                    editedFlag={editedFlag}
                                    editedId={editedId}
                                    editedRowData={editedRowData}
                                    resetDataGridFnFromChild={resetDataGridFnFromChild}
                                    closePaymentModeModalFnChild={closePaymentModeModalFnChild}
                                    dueAmount={dueAmount}
                                    minimumAmount={minimumAmount}
                                    isFirstPayment={props.callFrom == "quotation" ? "true" : isFirstPayment}
                                    branch_id={branch_id}
                                    bookingMasterId={props.bookingMasterId}
                                    customerId={props.customerId}
                                    closePayModalSuccessFn={closePayModalSuccessFn}
                                    currency={currency}
                                    callFrom={props.callFrom == 'quotation' ? "quotation" : ''}
                                    closeModalCallFromQuotationFn={closeModalCallFromQuotationFn}
                                    paymentModalCallFrom={props.paymentModalCallFrom}
                                    bankInfoForPayments={props.bankInfoForPayments}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>


            {actionModalflag ?
                <>
                    <ErrorBoundary title="GridActionContent Error">
                        <GridActionContent
                            styleWidth={overlayWidth}
                            styleHight={overlayHight}
                            codeOutsideClickRef={actionOutsideClickRef}
                            gridObjData={gridObjData}
                            modefier={editPaymentDetails}
                            delete={deletePaymentDetails}
                            archivedButtonShow={false}
                            editButtonShow={false}
                            deleteButtonShow={true}
                        />
                    </ErrorBoundary>
                </>
                : null}

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={deletePaymentDetailsModalFlag}
                    onHide={deletePaymentDetailsConfirmationModalHide}
                    className="modalcustomize confirmationalertmodal"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Delete this payment details?"
                    footer={false}

                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <ConfirmationAlert
                                    BodyFirstContent="Do you confirm the deletion of the"
                                    BodySecondContent={
                                        paymentDetailsDeleteBodySecondContent.length >= 20 ? paymentDetailsDeleteBodySecondContent.substring(0, 20) + "..." : paymentDetailsDeleteBodySecondContent}
                                    BodyThirdContent="payment details definitely? You won't be able to go back."
                                    confirmationButtonContent="Confirm"
                                    cancelButtonContent="Cancel"
                                    deleteConfirmButton={PaymentDetailsDeleteConfirmButton}
                                    deleteCancleButton={PaymentDetailsDeleteCancleButton}
                                    deleteIconVissable={true}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={skipPaymentModalFlag}
                    onHide={skipPaymentConfirmationModalHide}
                    className="modalcustomize confirmationalertmodal"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Skip payment?"
                    footer={false}

                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <ConfirmationAlert
                                    BodyFirstContent="Are you sure you want to proceed without completing the payment?"
                                    BodySecondContent={""}
                                    BodyThirdContent=""
                                    confirmationButtonContent="Confirm"
                                    cancelButtonContent="Cancel"
                                    deleteConfirmButton={skipPaymentConfirmationModalFn}
                                    deleteCancleButton={skipPaymentConfirmationModalHide}
                                    deleteIconVissable={false}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
        </div>
    );
};


PaymentViewModalContent.defaultProps = {
    callFrom: "",
    quotationTotalAmount: "",
    branchId: "",
    currency: "INR",
    minimumAmount: "",
    addPaymentBookingConversionFn: () => { },
    paymentHistoryShowFlag: false,
    bookingStatus: "Confirmed"
}
const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token
    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(PaymentViewModalContent));
