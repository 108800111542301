import React, { useState, useEffect, useRef } from 'react';
import '../Assets/css/logindoc.scss';
import '../Assets/css/loginresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
const AppLandingPage = (props) => {
	const [state, setState] = useState({
	})
	const setLanguage = () => {
		props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
	}
	useEffect(() => {
		localStorage.setItem('api_key', "");
		setLanguage();
	}, [state.loginCredentials]);

	return (
		<></>
	);
}

const mapStateToProps = (globalState) => {
	return {
		token: globalState.LoginReducer.token
	};
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })(AppLandingPage);


