import React, { useState, useEffect, useRef } from 'react'
import { loaderStateTrue, loaderStateFalse } from '../../Actions/AllAction';
import { setUserCredentials } from '../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SkeletonThemeFilterContent(props) {


    return (
        <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4'>
            <div className='skeleton-animation-section'>
                <div className='filter-hotel-list-box'>
                    <div className='filter-section'>
                        <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ebebeb">
                            <div className='filterBySke'>
                                <Skeleton width="30%" height={18} borderRadius={0} />
                            </div>
                            <div className='filterDivider'>
                                <Skeleton width="100%" height={1} borderRadius={0} />
                            </div>
                            <div className='filterbudgetSke'>
                                <Skeleton width="50%" height={18} borderRadius={0} />
                                <div className='filterPriceSke'>
                                    <div className='filterPriceLeftSke'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                    <div className='filterPriceRightSke'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterRangeSke'>
                                    <div className='filterRoundSke'>
                                        <Skeleton width={18} height={18} borderRadius={18} />
                                    </div>
                                    <div className='filterRangeBoxSke'>
                                        <Skeleton width="100%" height={4} borderRadius={0} />
                                    </div>
                                    <div className='filterRoundSke'>
                                        <Skeleton width={18} height={18} borderRadius={18} />
                                    </div>
                                </div>
                            </div>
                            <div className='filterDivider'>
                                <Skeleton width="100%" height={2} borderRadius={0} />
                            </div>
                            <div className='filterBoxCheck'>
                                <div className='filterBoxLabel'>
                                    <Skeleton width="100%" height={18} borderRadius={0} />
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                            </div>
                            <div className='filterDivider'>
                                <Skeleton width="100%" height={2} borderRadius={0} />
                            </div>
                            <div className='filterBoxCheck'>
                                <div className='filterBoxLabel'>
                                    <Skeleton width="100%" height={18} borderRadius={0} />
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                            </div>
                            <div className='filterDivider'>
                                <Skeleton width="100%" height={2} borderRadius={0} />
                            </div>
                            <div className='filterBoxCheck'>
                                <div className='filterBoxLabel'>
                                    <Skeleton width="100%" height={18} borderRadius={0} />
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                            </div>
                            <div className='filterDivider'>
                                <Skeleton width="100%" height={2} borderRadius={0} />
                            </div>
                            <div className='filterBoxCheck'>
                                <div className='filterBoxLabel'>
                                    <Skeleton width="100%" height={18} borderRadius={0} />
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                                <div className='filterCheckRow'>
                                    <div className='filterCheckboxLeft'>
                                        <Skeleton width={20} height={20} borderRadius={6} />
                                    </div>
                                    <div className='filterCheckboxRight'>
                                        <Skeleton width="100%" height={18} borderRadius={0} />
                                    </div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        </div>
    );
}

SkeletonThemeFilterContent.defaultProps = {

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(SkeletonThemeFilterContent));