import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../../../../Utility/Public/css/common.scss';
import '../../Assets/css/profiledoc.scss';
import '../../Assets/css/profileresponsivedoc.scss';
import CustomInput from '../../../../Utility/Components/CustomInput';
import { withNamespaces } from 'react-i18next';
import { setToken, setUserCredentials, logOutApp } from '../../../../../src/GlobalModules/Login/Actions/LoginAction';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { connect } from 'react-redux';
import PhoneNumberWithCountryCode from '../../../../Utility/Components/PhoneNumberWithCountryCode';
import * as PersonalController from '../Personal/Controller/PersonalController';
import { getCurrentUser } from '../../../../GlobalModules/Login/Controller/LoginController';
import { Country, State, City } from 'country-state-city';
import ValidateUtility from '../../../../Utility/ValidateUtility'
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal';
import ImageCropContent from '../../../../Utility/Components/ImageCropContent';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';
import moment from 'moment';
import Utility from '../../../../Utility/Utility';
import parsePhoneNumber from 'libphonenumber-js'
import SmsVerification from '../../../Login/Components/SmsVerification';
function Personal(props) {
  const [isViewportBottom, setIsViewportBottom] = useState(false);
  const [userPersonalInfo, setUserPersonalInfo] = useState({});
  const [userPersonalId, setUserPersonalId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  let personalInfoRef = useRef(true);
  const [personalFormData, setPersonalFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    address1: "",
    address2: "",
    city: "",
    zip_code: "",
    country: "",
    date_of_birth: "",
    profile_image: "",
  })

  const [personalFormDataError, setPersonalFormDataError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    address1: "",
    address2: "",
    city: "",
    zip_code: "",
    country: "",
    date_of_birth: "",
    profile_image: "",
  })

  const [countryList, setCountryList] = useState([])

  const [selectedCountry, setSelectedCountry] = useState({ "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" })
  const [selectedCountryCode, setSelectedCountryCode] = useState("")
  const [selectedIdentityType, setSelectedIdentityType] = useState({})
  const [updateButtonEnable, setUpdateButtonEnable] = useState(false)
  const [isChangeOnlyUserBasicInfo, setIsChangeOnlyUserBasicInfo] = useState(false)
  const [isChangeOnlyUserPersonalInfo, setIsChangeOnlyUserPersonalInfo] = useState(false)


  const [addprofileImageSelected, setAddprofileImageSelected] = useState(require('../../../../Utility/Public/images/usericon.png'));
  const [addProfileImagePreviewShow, setAddProfileImagePreviewShow] = useState(false);
  const [imageCropModalFlag, setImageCropModalFlag] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1
  })
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const imageRef = useRef();

  const [firstNameFlag, setFirstNameFlag] = useState(true);
  const [lastNameFlag, setLastNameFlag] = useState(true);
  const [emailFlag, setEmailFlag] = useState(true);
  const [numberFlag, setNumberFlag] = useState(true);
  const [address1Flag, setAddress1Flag] = useState(true);
  const [address2Flag, setAddress2Flag] = useState(true);
  const [cityFlag, setCityFlag] = useState(true);
  const [zipCodeFlag, setZipCodeFlag] = useState(true);
  const [idnumFlag, setIdnumFlag] = useState(true);



  const inputFirstNameRef = useRef(null);
  const inputLastNameRef = useRef(null);
  const inputEmailRef = useRef(null);
  const inputNumberRef = useRef(null);
  const address1Ref = useRef(null);
  const address2Ref = useRef(null);
  const cityRef = useRef(null);
  const zipCodeRef = useRef(null);
  const idnumRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("in");
  const [phoneNumberDialCode, setPhoneNumberDialCode] = useState("91");
  const [mfaAlertModal, setMfaAlertModal] = useState(false);

  //customer details change edit icon click function 
  const editFN = (type) => {
    if (type == "firstname") {
      if (inputFirstNameRef.current) {
        inputFirstNameRef.current.focus();
        setFirstNameFlag(!firstNameFlag)
      } else {
      }
      if (!firstNameFlag) {
        inputFirstNameRef.current.blur();
      }
    }
    if (type == "lastName") {
      if (inputLastNameRef.current) {
        inputLastNameRef.current.focus();
        setLastNameFlag(!lastNameFlag)
      } else {
      }
      if (!lastNameFlag) {
        inputLastNameRef.current.blur();
      }
    }
    if (type == "email") {
      if (inputEmailRef.current) {
        inputEmailRef.current.focus();
        setEmailFlag(!emailFlag)
      } else {
      }

    }
    if (type == "contact_number") {
      if (phoneNumberRef.current) {
        phoneNumberRef.current.numberInputRef.focus();
      } else {
      }
      if (!numberFlag) {
        phoneNumberRef.current.numberInputRef.blur();
      }
      setNumberFlag(!numberFlag)
    }
    if (type == "address1") {
      if (address1Ref.current) {
        address1Ref.current.focus();
        setAddress1Flag(!address1Flag)
      } else {
      }
      if (!address1Flag) {
        address1Ref.current.blur();
      }
    }
    if (type == "address2") {
      if (address2Ref.current) {
        address2Ref.current.focus();
        setAddress2Flag(!address2Flag)
      } else {
      }
      if (!address2Flag) {
        address2Ref.current.blur();
      }
    }
    if (type == "city") {
      if (cityRef.current) {
        cityRef.current.focus();
        setCityFlag(!cityFlag)
      } else {
      }
      if (!cityFlag) {
        cityRef.current.blur();
      }
    }
    if (type == "zip_code") {
      if (zipCodeRef.current) {
        zipCodeRef.current.focus();
        setZipCodeFlag(!zipCodeFlag)
      } else {
      }
      if (!zipCodeFlag) {
        zipCodeRef.current.blur();
      }
    }
  }

  //Initially fech the customer details from api
  useEffect(() => {
    if (personalInfoRef.current) {
      personalInfoRef.current = false;
      getUserPersonalDetails()
    }
  }, []);

  //set country list from country npm
  useEffect(() => {
    if (countryList.length === 0) {
      const updatedCountryList = Country.getAllCountries().map(countryObj => ({
        label: countryObj.name,
        value: countryObj.isoCode,
        country_code: countryObj.phonecode,
        iso_code: countryObj.isoCode
      }));
      setCountryList(updatedCountryList);
    }
  }, [countryList]);


  //user personal details get api
  const getUserPersonalDetails = () => {
    const { loaderStateTrue, loaderStateFalse, userProfileId } = props;
    let userId = props.userCredentials.hasOwnProperty("id") ? props.userCredentials.id : ""
    setCurrentUserId(userId)
    loaderStateTrue();
    PersonalController.userPersonalInfoGet(userId).then((response) => {
      if (response.success) {
        let userPersonalData = response.data[0]
        let userPersonalId = response.data[0].hasOwnProperty('UserPersonalInfo') ? response.data[0].UserPersonalInfo.id : ""
        setUserPersonalInfo(userPersonalData)
        setUserPersonalId(userPersonalId)
      }
      loaderStateFalse();
    }).catch((error) => {
      console.error("************error*************", error)
      if (error) {
      }
      loaderStateFalse();
    });

  }

  //after getting the user details response set user details into the input fields
  useEffect(() => {
    if (Object.keys(userPersonalInfo).length > 0) {
      setUserPersonalInfoData()
    }
  }, [userPersonalInfo]);

  //user details handle input change
  const handelChange = (e, type, dialCodeObj = {}) => {
    let tempFormData = { ...personalFormData };
    let tempErrorFormData = { ...personalFormDataError }

    if (type == "first_name") {
      var expr = /^[a-zA-Z ]*$/;
      if (!expr.test(e.target.value)) {
      } else {
        if (e.target.value == "") {
          tempErrorFormData['first_name'] = ""
          tempFormData["first_name"] = ""
          setIsChangeOnlyUserBasicInfo(true)
        } else {
          setIsChangeOnlyUserBasicInfo(true)
          tempFormData["first_name"] = e.target.value
          tempErrorFormData['first_name'] = ""
        }
      }
    }

    if (type == "last_name") {
      var expr = /^[a-zA-Z ]*$/;
      if (!expr.test(e.target.value)) {
      } else {
        if (e.target.value == "") {
          tempErrorFormData['last_name'] = ""
          tempFormData["last_name"] = ""
          setIsChangeOnlyUserBasicInfo(true)
        } else {
          setIsChangeOnlyUserBasicInfo(true)
          tempFormData["last_name"] = e.target.value
          tempErrorFormData['last_name'] = ""
        }
      }
    }

    if (type == "contact_number") {
      if (e.trim() !== "") {
        let modifiedString = ValidateUtility.deleteAndBefore(e, dialCodeObj.dialCode);
        let countryCodeValue = dialCodeObj.countryCode.toUpperCase();
        const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
        setPhoneNumberCountryCode(dialCodeObj.countryCode)
        setPhoneNumberDialCode(dialCodeObj.dialCode)
        if (phoneNumber && phoneNumber.isValid()) {
          tempFormData["contact_number"] = e
          tempErrorFormData['contact_number'] = ""
          setIsChangeOnlyUserBasicInfo(true)
        } else {
          tempFormData["contact_number"] = e
          tempErrorFormData['contact_number'] = "Enter valid phone number"
        }
      } else {
        tempFormData["contact_number"] = "";
        tempErrorFormData['contact_number'] = "Required field";
      }
    }

    if (type == "email") {
      if (e.target.value != "") {
        let emailValidate = ValidateUtility.validate_email(e.target.value);
        if (emailValidate) {
          setIsChangeOnlyUserBasicInfo(true)
          tempFormData["email"] = e.target.value,
            tempErrorFormData['email'] = ""
        } else {
          tempFormData["email"] = e.target.value,
            tempErrorFormData['email'] = "Enter valid email"
        }
      } else {
        tempFormData["email"] = "",
          tempErrorFormData['email'] = ""
      }
    }

    if (type == "address1") {
      var expr = /^[a-zA-Z0-9 ,/.-]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['address1'] = ""
          tempFormData["address1"] = ""
        } else {
          setIsChangeOnlyUserPersonalInfo(true)
          tempFormData["address1"] = e.target.value
          tempErrorFormData['address1'] = ""
        }
      }
    }

    if (type == "address2") {
      var expr = /^[a-zA-Z0-9 ,/.-]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['address2'] = ""
          tempFormData["address2"] = ""
        } else {
          setIsChangeOnlyUserPersonalInfo(true)
          tempFormData["address2"] = e.target.value
          tempErrorFormData['address2'] = ""
        }
      }
    }

    if (type == "city") {
      var expr = /^[a-zA-Z ]*$/;
      if (!expr.test(e.target.value)) {

      } else {
        if (e.target.value == "") {
          tempErrorFormData['city'] = ""
          tempFormData["city"] = ""
        } else {
          setIsChangeOnlyUserPersonalInfo(true)
          tempFormData["city"] = e.target.value
          tempErrorFormData['city'] = ""
        }
      }
    }

    if (type == "zip_code") {
      if (e.target.value == "") {
        tempErrorFormData['zip_code'] = ""
        tempFormData["zip_code"] = ""
      } else {
        var sanitizedValue = e.target.value.replace(/\D/g, '');
        setIsChangeOnlyUserPersonalInfo(true)
        tempFormData["zip_code"] = sanitizedValue
        tempErrorFormData['zip_code'] = ""
      }
    }

    if (type == "country") {
      if (e) {
        let countryCode = e.value.toLowerCase().toString()
        if (selectedCountryCode.toLowerCase().toString() != countryCode) {
          tempFormData["country"] = e.value.toLowerCase().toString()
          tempErrorFormData['country'] = ""
          tempFormData["contact_number"] = ""
          setSelectedCountry(e)
          setSelectedCountryCode(countryCode)
          setIsChangeOnlyUserPersonalInfo(true)
          setIsChangeOnlyUserBasicInfo(true)
        }
      } else {
        tempErrorFormData['country'] = ""
        tempFormData["country"] = ""
        tempFormData["contact_number"] = ""
        setSelectedCountry({ "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" })
      }
    }

    if (type == "dob") {
      if (e == "") {
        tempErrorFormData['date_of_birth'] = ""
        tempFormData["date_of_birth"] = ""
      } else {
        tempFormData["date_of_birth"] = e
        tempErrorFormData['date_of_birth'] = ""
        setIsChangeOnlyUserPersonalInfo(true)
      }
    }

    setPersonalFormData(tempFormData)
    setPersonalFormDataError(tempErrorFormData)
    setUpdateButtonEnable(true)
  }

  //user details validation before update 
  const validationFromData = () => {
    let tempFormData = { ...personalFormData };
    let tempErrorFormData = { ...personalFormDataError }

    let valid = true
    if (isChangeOnlyUserBasicInfo) {
      if (tempFormData.first_name == null || tempFormData.first_name == "") {
        tempErrorFormData['first_name'] = "Required field"
        valid = false
      }

      if (tempFormData.last_name == null || tempFormData.last_name == "") {
        tempErrorFormData['last_name'] = "Required field"
        valid = false
      }
      if (tempFormData.email == null || tempFormData.email == "") {
        tempErrorFormData['email'] = "Required field"
        valid = false
      }

      if (tempFormData.contact_number == null || tempFormData.contact_number == "") {
        tempErrorFormData['contact_number'] = "Required field"
        valid = false
      } else {
        let modifiedString = ValidateUtility.deleteAndBefore(tempFormData.contact_number, phoneNumberDialCode);
        let countryCodeValue = phoneNumberCountryCode.toUpperCase();
        const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
        if (phoneNumber && phoneNumber.isValid()) {
          tempErrorFormData['contact_number'] = ""
        } else {
          tempErrorFormData['contact_number'] = "Enter valid phone number"
          valid = false
        }
      }
    }
    setPersonalFormDataError(tempErrorFormData)
    return valid
  }

  //formatting dataset before user details update api hits 
  const formatingDatasetForUserBasicInfo = () => {
    let dataset = [];
    let hash = {}
    if (personalFormData.first_name != "") {
      hash['first_name'] = personalFormData.first_name;
    }
    if (personalFormData.last_name != "") {
      hash['last_name'] = personalFormData.last_name;
    }
    if (personalFormData.contact_number != "") {
      hash['contact_number'] = personalFormData.contact_number;
    }
    if (personalFormData.profile_image != "") {
      hash['profile_img'] = personalFormData.profile_image != "" ? personalFormData.profile_image : "";
    }
    dataset.push(hash)
    return dataset;
  }

  //formatting dataset before user personal details update api his 
  const formatingDataset = () => {
    let dataset = [];
    let hash = {}
    if (personalFormData.address1 != "" && personalFormData.address1 != null) {
      hash['address1'] = (personalFormData.address1).trim();
    } else {
      hash['address1'] = "";
    }
    if (personalFormData.address2 != "" && personalFormData.address2 != null) {
      hash['address2'] = (personalFormData.address2).trim();
    } else {
      hash['address2'] = "";
    }
    if (personalFormData.city != "" && personalFormData.city != null) {
      hash['city'] = (personalFormData.city).trim();
    } else {
      hash['city'] = "";
    }
    if (personalFormData.zip_code != "" && personalFormData.zip_code != null) {
      hash['zip'] = (personalFormData.zip_code).trim();
    } else {
      hash['zip'] = "";
    }
    if (personalFormData.country != "" && personalFormData.country != null) {
      hash['country'] = (personalFormData.country).trim();
    } else {
      hash['country'] = "";
    }
    if (personalFormData.date_of_birth != "" && personalFormData.date_of_birth != null) {
      hash['date_of_birth'] = moment(personalFormData.date_of_birth.toString()).format('YYYY-MM-DD');
    } else {
      hash['date_of_birth'] = "";
    }
    hash['user_id'] = currentUserId;
    dataset.push(hash)
    return dataset;
  }

  //user details update button onclick fn
  const updatePersonalDetailsDecisionMaker = () => {
    if (isChangeOnlyUserBasicInfo && isChangeOnlyUserPersonalInfo) {
      bothUserBasicAndPersonalInfoChange()
    } else if (isChangeOnlyUserBasicInfo) {
      userBasicInfoSave()
    } else if (isChangeOnlyUserPersonalInfo) {
      updatePersonalDetailsSave()
    }
  }

  //update basic user details and personal details api fn
  const bothUserBasicAndPersonalInfoChange = () => {
    const { loaderStateTrue, loaderStateFalse } = props;
    let valid = validationFromData();
    if (valid) {
      let data = formatingDatasetForUserBasicInfo();
      data = data[0];
      let type = 'patch'
      loaderStateTrue();
      PersonalController.userBasicInfoUpdate(data, currentUserId, type).then((response) => {
        if (response.success) {
          setIsChangeOnlyUserBasicInfo(false)
          setUpdateButtonEnable(false)
          getCurrentUserData()
          updatePersonalDetailsSave()
          loaderStateFalse();
        } else {
          loaderStateFalse();
          Utility.toastNotifications(response.message, "Error", "error")
        }
      }).catch((error) => {
        loaderStateFalse();
      });
    }
  }

  //update basic user details ap fn
  const userBasicInfoSave = () => {
    const { loaderStateTrue, loaderStateFalse } = props;
    let valid = validationFromData();
    if (valid) {
      let data = formatingDatasetForUserBasicInfo();
      data = data[0];
      let type = 'patch'
      loaderStateTrue();
      PersonalController.userBasicInfoUpdate(data, currentUserId, type).then((response) => {
        if (response.success) {
          setIsChangeOnlyUserBasicInfo(false)
          setUpdateButtonEnable(false)
          getCurrentUserData()
          loaderStateFalse();
          Utility.toastNotifications(response.message, "Success", "success");
        } else {
          loaderStateFalse();
          Utility.toastNotifications(response.message, "Error", "error")
        }
      }).catch((error) => {
        loaderStateFalse();
      });
    }
  }

  //update personal details api fn
  const updatePersonalDetailsSave = () => {
    let valid = validationFromData();
    if (valid) {
      const { loaderStateTrue, loaderStateFalse } = props;
      let data = formatingDataset();
      data = data[0];
      let type = 'patch'
      loaderStateTrue();
      PersonalController.personalDetailsUpdate(data, type, userPersonalId).then((response) => {
        loaderStateFalse();
        if (response) {
          if (response.success) {
            setIsChangeOnlyUserPersonalInfo(false)
            setUpdateButtonEnable(false)
            Utility.toastNotifications(response.message, "Success", "success");
          } else {
            Utility.toastNotifications(response.message, "Error", "error")
          }
        }

      }).catch((error) => {
        console.error("************error*************", error)
        if (error) {
        }
        loaderStateFalse();
        if (error.message == "Network Error") {

        }
      });
    }
  }

  //get current user data through current user api
  const getCurrentUserData = () => {
    loaderStateTrue();
    getCurrentUser().then((userResponse) => {
      loaderStateFalse();
      if (userResponse.success) {
        setState(prev => ({
          ...prev,
          loginCredentials: userResponse.data
        }))
      }
    })
  }
  const [state, setState] = useState({
    loginCredentials: {},
  })

  //set current user data after api response
  useEffect(() => {
    if (state.loginCredentials && Object.keys(state.loginCredentials).length > 0) {
      setUserCredentialsData();
    }
  }, [state.loginCredentials]);

  //set current user api data to redux 
  const setUserCredentialsData = async () => {
    const { loginCredentials } = state
    props.setUserCredentials(state.loginCredentials)
  }

  //set personal data after fetching the personal details from api  
  const setUserPersonalInfoData = () => {
    let userInfo = userPersonalInfo.hasOwnProperty('UserPersonalInfo') ? userPersonalInfo.UserPersonalInfo : {}
    let dateOfBirth = ""
    if (userInfo.date_of_birth == null || userInfo.date_of_birth == "") {
      dateOfBirth = "";
    } else {
      dateOfBirth = new Date(userInfo.date_of_birth);
    }
    var countryListFilter = [{ "label": "India", "value": "IN", "country_code": "91", "iso_code": "IN" }]
    if (userInfo.country != null && userInfo.hasOwnProperty('country')) {
      countryListFilter = countryList.filter((el) => {
        return el.value.toLowerCase() == userInfo.country.toLowerCase();
      });
    }
    setAddprofileImageSelected(userPersonalInfo.profile_img_url != null && userPersonalInfo.profile_img_url != "" ? JSON.parse(userPersonalInfo.profile_img_url).img_url : require('../../../../Utility/Public/images/usericon.png'))
    setAddProfileImagePreviewShow(userPersonalInfo.profile_img_url != null && userPersonalInfo.profile_img_url != "" ? true : false)
    setPersonalFormData({
      "first_name": userPersonalInfo.first_name != "" ? userPersonalInfo.first_name : "",
      "last_name": userPersonalInfo.last_name != "" ? userPersonalInfo.last_name : "",
      "email": userPersonalInfo.email != "" ? userPersonalInfo.email : "",
      "contact_number": userPersonalInfo.contact_number != "" ? userPersonalInfo.contact_number.includes("+") ? userPersonalInfo.contact_number.replace("+", "") : userPersonalInfo.contact_number : "",
      "address1": userInfo.address1 != "" ? userInfo.address1 : "",
      "address2": userInfo.address2 != "" ? userInfo.address2 : "",
      "city": userInfo.city != "" ? userInfo.city : "",
      "zip_code": userInfo.zip != "" ? userInfo.zip : "",
      "country": countryListFilter[0]?.value,
      "date_of_birth": dateOfBirth,
    })
    setSelectedCountry(countryListFilter[0])
  }

  // Image Upload
  const addInputProfileImageChanged = (event) => {
    let tempFormData = { ...personalFormData };
    let tempErrorFormData = { ...personalFormDataError }

    const fileInput = event.target;
    const storedFiles = event.target.files[0] // Store the files before clearing the input
    const storedAllFiles = event.target.files

    let targetFileSplit = storedFiles.name.split('.');
    let lastElement = targetFileSplit.pop();
    let user_profile_image = {
      "file_name": "",
      "file_obj": ""
    };

    const allowedTypes = Utility.acceptFileType();
    Utility.checkFileType(storedFiles, allowedTypes)
      .then(results => {
        if (results.isValid) {
          const fsize = storedFiles.size;
          const fileSize = Math.round((fsize / 1024));
          if (fileSize >= 300) {
            Utility.toastNotifications(props.t('imageUploadAlert'), "Warning", "warning");
          } else {
            setImageCropModalFlag(true)
            if (storedAllFiles && storedAllFiles.length > 0) {
              const reader = new FileReader();
              reader.addEventListener('load', () =>
                setSrc(reader.result)
              );
              reader.readAsDataURL(storedFiles);
              user_profile_image["file_name"] = storedFiles.name
              user_profile_image["file_obj"] = ""
              tempFormData['profile_image'] = user_profile_image
              tempErrorFormData['profile_image'] = ""
              setIsChangeOnlyUserBasicInfo(true)
              setUpdateButtonEnable(true)
              setAddProfileImagePreviewShow(true)
            }
          }
        } else {
          tempFormData['profile_image'] = "";
          setAddProfileImagePreviewShow(false);
          Utility.toastNotifications("Please upload JPG, JPEG or PNG file format", "Warning", "warning");
        }
      })
      .catch(error => {
        console.error("Error:", error);

      });


    setPersonalFormData(tempFormData)
    setPersonalFormDataError(tempErrorFormData)
    fileInput.value = '';
  }

  const onImageLoaded = (image) => {
    if (image) {
      imageRef.current = image
    }

  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop)
  };

  const makeClientCrop = async (crop) => {

    let tempFormData = { ...personalFormData };
    let tempErrorFormData = { ...personalFormDataError }

    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      );
      let user_profile_image = {}

      setAddprofileImageSelected(croppedImageUrl)
      user_profile_image["file_name"] = tempFormData.profile_image.file_name
      user_profile_image["file_obj"] = croppedImageUrl
      tempFormData["profile_image"] = user_profile_image

      setPersonalFormData(tempFormData)

    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image
  }

  //Image url crop
  const imageCropModalShow = () => {
    setImageCropModalFlag(true)
  }

  const imageCropModalHide = () => {
    let tempFormData = { ...personalFormData };
    let tempErrorFormData = { ...personalFormDataError }

    tempFormData['profile_image'] = "",
      tempErrorFormData['profile_image'] = "",
      setPersonalFormData(tempFormData)
    setPersonalFormDataError(tempErrorFormData)

    setImageCropModalFlag(false)
    setAddprofileImageSelected(userPersonalInfo.profile_img_url != null && userPersonalInfo.profile_img_url != "" ? JSON.parse(userPersonalInfo.profile_img_url).img_url : require('../../../../Utility/Public/images/usericon.png'))
    setAddProfileImagePreviewShow(false),
      //new 
      setSrc(null),
      setCrop({
        unit: '%',
        width: 30,
        aspect: 1 / 1
      }),
      setCroppedImageUrl("")
  }

  const imageCropDataSave = () => {
    setImageCropModalFlag(false)
  }

  const resetFormData = () => {

    setIsChangeOnlyUserBasicInfo(false)
    setIsChangeOnlyUserPersonalInfo(false)
    setUpdateButtonEnable(false)

  }

  //mfa modal open fn
  const enableMfaFn = () => {
    setMfaAlertModal(true)
  }

  //otp submit fn for verfification
  const handleSubmitOtpVerfication = (otp, type) => {
    const { loaderStateTrue, loaderStateFalse } = props;
    if (type === 'authenticator') {
      loaderStateTrue();
      let data = {
        "mfaCode": otp.join(''),
      }
      PersonalController.verifySoftwareToken(data).then((response) => {
        if (response.success) {
          const mfaAssignData = {
            "mfa_type": "authenticator_app",
            "user_name": personalFormData.email
          }
          PersonalController.mfaAssign(mfaAssignData).then((response) => {
            if (response.success) {
              setMfaAlertModal(false);
              getCurrentUserData();
            } else {
              Utility.toastNotifications(response.message, 'Error', 'error');
            }
            loaderStateFalse();
          }).catch((error) => {
            loaderStateFalse();

          });
        } else {
          Utility.toastNotifications(response.message, "Error", "error")
        }
        loaderStateFalse();
      }).catch((error) => {
        console.error("************error*************", error)
        if (error) {
          Utility.toastNotifications(error?.response?.message, "Warning", "warning")
        }
        loaderStateFalse();
      });
    }
  }

  //mfa modal close fn
  const closeMfaModalFn = (two_factor_login = null) => {
    setMfaAlertModal(false);
  }

  //mfa modal hide fn
  const mfaConfirmationModalHide = () => {
    setMfaAlertModal(false);
  }

  const eightteenYearsAgo = new Date();
  eightteenYearsAgo.setFullYear(eightteenYearsAgo.getFullYear() - 18);


  return (
    <div className='personaldetails'>
      <div className='innpersonaldetails'>
        <div className="personaldetails-inner">
          <div className="titleposition">
            <h2 className="titleone">Personal Details
              <span className='userprofileEnableMFABtn'
                onClick={enableMfaFn}
              >{props.mfa == null ? 'Enable MFA ?' : 'Change MFA ?'}</span>
            </h2>
            <h3 className="titletwo">The Staying - Online property booking management</h3>
          </div>
        </div>
        <div className="personaldetailsFormSec">
          <div className="personalProfileDiv">
            <div className='inputBox'>
              <div className='inputfrm-Full'>
                <div className='userImage'>
                  <div className="userImageBox">
                    <div className="user_add_img modprofile userprofile_img">
                      <div className="userProfileImg">
                        {!addProfileImagePreviewShow ?
                          <label className="custom-file-upload">
                            <span className="filetext">
                              <img src={addprofileImageSelected}
                              />
                            </span>
                            <span className="plusicon">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <input type="file" accept={Utility.acceptFileType()} onChange={addInputProfileImageChanged} />
                            </span>
                          </label> :
                          <label className="custom-file-upload">
                            <img src={addprofileImageSelected} />
                            <span className="plusicon">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <input type="file" accept={Utility.acceptFileType()} onChange={addInputProfileImageChanged} />
                            </span>
                          </label>}

                        <div className="col-md-12 errorClass error_div">{personalFormDataError.profile_image}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='inputBoxes'>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={firstNameFlag == true ? "input_field_inner loginFormInnerDiv paddingL0 disabled" : "input_field_inner loginFormInnerDiv paddingL0"}
                  labelName="First Name"
                  errorLabel={personalFormDataError.first_name}
                  name="first_name"
                  type="text"
                  value={personalFormData.first_name}
                  labelPresent={false}
                  requiredStar={false}
                  placeholder="First name"
                  onChange={(e) => handelChange(e, 'first_name')}
                  selectRef={inputFirstNameRef}
                />
                <button className='editbtn' onClick={() => { editFN("firstname") }}>Edit</button>
              </div>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={lastNameFlag == true ? "loginFormInnerDiv input_field_inner paddingR0 disabled" : "loginFormInnerDiv input_field_inner paddingR0"}
                  labelName="Last Name"
                  errorLabel={personalFormDataError.last_name}
                  name="last_name"
                  type="text"
                  value={personalFormData.last_name}
                  labelPresent={false}
                  requiredStar={false}
                  placeholder="Last name"
                  onChange={(e) => handelChange(e, 'last_name')}
                  selectRef={inputLastNameRef}
                />
                <button className='editbtn' onClick={() => { editFN("lastName") }}>Edit</button>
              </div>
            </div>
            <div className='inputBoxes'>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={emailFlag == true ? "loginFormInnerDiv input_field_inner paddingL0 disabled" : "loginFormInnerDiv input_field_inner paddingL0"}
                  labelName="Email"
                  errorLabel={personalFormDataError.email}
                  name="email"
                  type="text"
                  value={personalFormData.email}
                  labelPresent={false}
                  requiredStar={false}
                  placeholder="Email"
                  onChange={(e) => handelChange(e, 'email')}
                  selectRef={inputEmailRef}
                />
              </div>
              <div className='inputfrm'>
                <div className="input_field_inner paddingR0 datepicker absPanel">
                  <CommonDateTimePicker
                    selected={personalFormData.date_of_birth}
                    onChange={(e) => handelChange(e, 'dob')}
                    showTimeSelect={false}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Date of birth"
                    minDate=""
                    maxDate={eightteenYearsAgo}
                  />
                </div>
              </div>
            </div>
            <div className='inputBoxes'>
              <div className="inputfrm">
                <div className="input_field_inner paddingL0">
                  <ErrorBoundary title="CustomInput Error">
                    <div className="dropdowninnerbox absPanel">
                      <AutosuggestComponent
                        handleOnChange={(e) => handelChange(e, 'country')}
                        options={countryList}
                        selectedValue={selectedCountry}
                        name=''
                        isMulti={false}
                        placeholder="Choose Country :"
                        isDisabled={false}
                        isSearchable={true}
                      />
                    </div>
                  </ErrorBoundary>
                </div>
              </div>
              <div className='inputfrm'>
                <div className={numberFlag == true ? "countryDisabled" : ""}></div>
                <PhoneNumberWithCountryCode
                  country={selectedCountryCode}
                  value={personalFormData.contact_number}
                  onChange={(e, country) => handelChange(e, 'contact_number', country)}
                  error={personalFormDataError.contact_number}
                  labelShow={false}
                  disableDropdown={true}
                  selectRef={phoneNumberRef}
                />
                <button className='editbtn countryDisabledEditButton' onClick={() => { editFN("contact_number") }}>Edit</button>
              </div>
            </div>
            <div className='inputBoxes'>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={address1Flag == true ? "input_field_inner paddingL0 disabled" : "input_field_inner paddingL0"}
                  type="text"
                  value={personalFormData.address1}
                  onChange={(e) => handelChange(e, 'address1')}
                  placeholder="Address 1"
                  selectRef={address1Ref}
                />
                <button className='editbtn' onClick={() => { editFN("address1") }}>Edit</button>
              </div>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={address2Flag == true ? "input_field_inner paddingR0 disabled" : "input_field_inner paddingR0"}
                  type="text"
                  value={personalFormData.address2}
                  onChange={(e) => handelChange(e, 'address2')}
                  placeholder="Address 2"
                  selectRef={address2Ref}
                />
                <button className='editbtn' onClick={() => { editFN("address2") }}>Edit</button>
              </div>
            </div>
            <div className='inputBoxes'>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={cityFlag == true ? "input_field_inner paddingL0 disabled" : "input_field_inner paddingL0"}
                  type="text"
                  value={personalFormData.city}
                  onChange={(e) => handelChange(e, 'city')}
                  placeholder="City"
                  selectRef={cityRef}
                />
                <button className='editbtn' onClick={() => { editFN("city") }}>Edit</button>
              </div>
              <div className='inputfrm'>
                <CustomInput
                  parentClassName={zipCodeFlag == true ? "input_field_inner paddingR0 disabled" : "input_field_inner paddingR0"}
                  type="text"
                  value={personalFormData.zip_code}
                  onChange={(e) => handelChange(e, 'zip_code')}
                  placeholder="Zip code"
                  selectRef={zipCodeRef}
                  errorLabel={personalFormDataError.zip_code}
                  maxlength={6}
                />
                <button className='editbtn' onClick={() => { editFN("zip_code") }}>Edit</button>
              </div>
            </div>
            <div className="buttonSec">
              {updateButtonEnable &&
                <button className="login-btn btn btn-primary" onClick={updatePersonalDetailsDecisionMaker} >Update</button>
              }
            </div>
          </div>
        </div>
      </div>
      <ErrorBoundary title="ModalGlobal Error">
        <ModalGlobal
          show={imageCropModalFlag}
          onHide={imageCropModalHide}
          onCancel={imageCropModalHide}
          onSave={imageCropDataSave}
          className="modalcustomize cropmodalcontent"
          bodyClassName="cropmodalcontentbody"
          headerclassName="close_btn_icon"
          title={props.t('cropimage')}
          footer={true}
          closeButton={true}
          saveButtonLabel={props.t('crop')}
          saveButtonClassName="delconfirmbtn btn btn-primary"
          cancelButtonClassName="delcancelbtn btn btn-primary"
          body={
            <>
              <ErrorBoundary title="ModalGlobal Error">
                <ImageCropContent
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onCropChange={onCropChange}
                  crop={crop}
                  croppedImageUrl={croppedImageUrl}
                  src={src}
                  onCropComplete={onCropComplete}
                  imageCropModalShow={imageCropModalShow}
                  circularCrop={false}
                />
              </ErrorBoundary>
            </>
          }
        />
      </ErrorBoundary>
      <ErrorBoundary title="ModalGlobal Error">
        <ModalGlobal
          show={mfaAlertModal}
          onHide={mfaConfirmationModalHide}
          title=""
          className="modalcustomize mondimension smsVerificationModalContent userprofileSmsVerificationModal"
          footer={false}
          closeButton={true}
          saveButtonLabel="Confirm"
          saveButtonClassName="btn btn-primary savebtn"
          cancelShow={true}
          headerShow={true}
          body={
            <>
              <ErrorBoundary title="ConfirmationAlert Error">
                <SmsVerification
                  formData={{ 'email': personalFormData.email }}
                  callFrom={props.mfa == null ? '' : props.mfa}
                  handleSubmitOtpVerfication={handleSubmitOtpVerfication}
                  enableMfa={false}
                  closeMfaModalFn={closeMfaModalFn}
                />
              </ErrorBoundary>
            </>
          }
        />
      </ErrorBoundary>
    </div>
  );
}


const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token,
    mfa: globalState.LoginReducer.userCredentials?.user_details?.mfa
  };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, setUserCredentials })(withNamespaces()(Personal));