import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal'
import FancyBoxImage from '../../../../Utility/Components/FancyBoxImage';
import LazyImage from '../../../../Utility/Components/LazyImageComponent';

function ReviewContentShowModal(props) {
    const [fancyBoxModal, setFancyBoxModal] = useState(false);
    const [fancyBoxSelectedImg, setFancyBoxSelectedImg] = useState("");

    const reviewDataUi = () => {
        let labelArray = []
        if (props.ratingData && props.ratingData.length > 0) {
            props.ratingData.map((rating, index) => {
                let firstName = rating.hasOwnProperty('UserRatingRelation') && rating.UserRatingRelation && rating.UserRatingRelation.hasOwnProperty('first_name') && rating.UserRatingRelation.first_name
                let lastName = rating.hasOwnProperty('UserRatingRelation') && rating.UserRatingRelation && rating.UserRatingRelation.hasOwnProperty('last_name') && rating.UserRatingRelation.last_name
                let email = rating.hasOwnProperty('UserRatingRelation') && rating.UserRatingRelation && rating.UserRatingRelation.hasOwnProperty('email') && rating.UserRatingRelation.email
                let commentsDate = rating.hasOwnProperty('createdAt') && rating.createdAt
                let profileImage = (rating.hasOwnProperty('UserRatingRelation') && rating.UserRatingRelation.hasOwnProperty('profile_img_url')) && rating.UserRatingRelation.profile_img_url != null ? (JSON.parse(rating.UserRatingRelation.profile_img_url))["img_url"] : "";

                labelArray.push(<div key={index} className="reviewRowInner">
                    <div className="reviewRow">
                        <div className='reviewBox reviewImg'>
                            {profileImage != "" ?
                                <LazyImage
                                    src={profileImage}
                                    alt={require('../../../../Utility/Public/images/profile-back.png')}
                                    loaderImage={require('../../../../Utility/Public/images/profile-back.png')}
                                    className={"ProfileImg userProfileImg"}
                                />
                                : <div className='profileImagesDesign'>
                                    {(firstName + " " + lastName).split(' ').map(name => name[0]).join('').toUpperCase()}
                                </div>
                            }
                        </div>
                        <div className='reviewBox reviewComments'>
                            <p className='customerName'><span className='name'>{firstName + " " + lastName}</span> </p>
                            <div className='reviewRatting'>
                                {ratingStarUi(rating.overall_rating)}
                                <span>( {moment(commentsDate).format('DD-MM-YYYY')} )</span>
                            </div>
                            <p className='comments'>{rating.comments.split('\n').map((line, ind) => (
                                <div key={ind}>{line}</div>
                            ))}</p>
                        </div>
                    </div>
                    {rating.CustomerRatingImageRelation.length > 0 &&
                        <div className='moreImg'>
                            {customerReviewMoreImg(rating.CustomerRatingImageRelation)}
                        </div>
                    }
                </div>)
            })
        }

        return labelArray
    }

    const ratingStarUi = (rating) => {
        let labelArray = []
        if (rating > 0) {
            for (let i = 0; i < rating; i++) {
                labelArray.push(
                    <div key={i} className="rating">
                        <i className="fa fa-star" aria-hidden="true"></i>
                    </div>
                )
            }
        }
        return labelArray
    }
    const customerReviewMoreImg = (image) => {
        let labelArray = []
        image.map((val, idx) => {
            labelArray.push(<div key={idx} className="imgBox">
                <img src={JSON.parse(val.img_url).img_url} alt="User Review" className='img' onClick={(e) => fancyBoxModalFN(JSON.parse(val.img_url).img_url)} />
            </div>)
        })
        return labelArray
    }
    const fancyBoxModalFN = (img) => {
        setFancyBoxModal(true);
        setFancyBoxSelectedImg(img)
    };

    const closefancyBoxModal = () => {
        setFancyBoxModal(false);
        setFancyBoxSelectedImg("")
    };



    return (
        <div className='reviewBody'>
            <div className='reviewInnerBody'>
                {props.ratingData && props.ratingData.length > 0 &&
                    reviewDataUi()
                }
            </div>
            <ModalGlobal
                show={fancyBoxModal}
                onHide={closefancyBoxModal}
                title=""
                className="modalcustomize fancyBoxModal"
                footer={false}
                closeButton={true}
                saveShow={false}
                cancelShow={true}
                cancelButtonLabel="Cancel"
                cancelButtonClassName="cancel-btn"
                onCancel={closefancyBoxModal}
                body={
                    <>
                        <FancyBoxImage
                            fancyBoxSelectedImg={fancyBoxSelectedImg}
                        />
                    </>
                }
            />
        </div>
    )
}

export default ReviewContentShowModal