import React, { useState, useEffect, useRef } from 'react'
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, userSearchData, selectChooseBranch, priceDistributionRequestData, priceDistributionResponseData } from '../../../../Actions/AllAction';
import { guestCreate } from '../Controller/HomeController'
import Utility from '../../../../Utility/Utility';
import ValidateUtility from '../../../../Utility/ValidateUtility';
import PhoneNumberWithCountryCode from '../../../../Utility/Components/PhoneNumberWithCountryCode';
import CustomInput from '../../../../Utility/Components/CustomInput';
import { Routes, Route, Switch, useHistory } from "react-router-dom";

function GuestCheckOutModalContent(props) {
    const history = useHistory();
    const [guestDetails, setGuestDetails] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        customer_email: "",
        customer_gstin_no: "",
        company_name: "",
        company_address: ""

    })
    const [guestDetailsError, setGuestDetailsError] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        customer_email: "",
        customer_gstin_no: "",
        company_name: "",
        company_address: ""

    })
    const [selectedCountryCode, setSelectedCountryCode] = useState("in");


    const handelChangeGuest = (e, type) => {
        if (type == 'phone_number') {
            setGuestDetails(prevGuestDetails => ({
                ...prevGuestDetails,
                ['phone_number']: e
            }));
            setGuestDetailsError(prevGuestDetailsError => ({
                ...prevGuestDetailsError,
                ['phone_number']: ""
            }));
        } else {
            const { name, value } = e.target;
            setGuestDetails(prevGuestDetails => ({
                ...prevGuestDetails,
                [name]: value
            }));
            setGuestDetailsError(prevGuestDetailsError => ({
                ...prevGuestDetailsError,
                [name]: ""
            }));
        }
    }

    useEffect(() => {
    }, [guestDetails])

    const validGuestDetailsFn = () => {
        let valid = true;
        let tempFormDataError = { ...guestDetailsError }

        if ((guestDetails.first_name).trim() == "") {
            valid = false;
            tempFormDataError['first_name'] = "Required Field"
        }

        if ((guestDetails.last_name).trim() == "") {
            valid = false;
            tempFormDataError['last_name'] = "Required Field"
        }


        const email = guestDetails.customer_email.trim();
        if (email === "") {
            valid = false;
            tempFormDataError['customer_email'] = "Required field";
        } else if (!isValidEmail(email)) {
            valid = false;
            tempFormDataError['customer_email'] = "Enter valid email";
        }

        if (guestDetails.phone_number != "") {

            let phoneValidate = false;
            if (ValidateUtility.validate_Phone_Number_without_plus(guestDetails.phone_number)) {
                phoneValidate = true;
            }
            if (phoneValidate) {
                tempFormDataError['phone_number'] = ""
            } else {
                valid = false;
                tempFormDataError['phone_number'] = "Enter valid contact number"
            }
        } else {
            valid = false;
            tempFormDataError['phone_number'] = "Required field"
        }

        if ((guestDetails.customer_gstin_no).trim() != "") {
            let gstnValidate = ValidateUtility.validate_gstn_Number((guestDetails.customer_gstin_no).trim());
            if (!gstnValidate) {
                valid = false;
                tempFormDataError['customer_gstin_no'] = "Please enter a correct GSTIN No"
            }
        } else {
            tempFormDataError['customer_gstin_no'] = ""
        }

        setGuestDetailsError(tempFormDataError)
        return valid;
    }

    const isValidEmail = (email) => {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };

    const formatingDataset = () => {
        let dataset = [];
        let hash = {}
        if (guestDetails.first_name != "") {
            hash['first_name'] = (guestDetails.first_name).trim();
        }
        if (guestDetails.last_name != "") {
            hash['last_name'] = (guestDetails.last_name).trim();
        }
        if (guestDetails.customer_email != "") {
            hash['email'] = guestDetails.customer_email;
        }
        var phoneNumber = guestDetails.phone_number
        var plusRemovePhoneNumber = phoneNumber.replace(/\+/g, '')
        if (guestDetails.phone_number != "") {
            hash['contact_number'] = plusRemovePhoneNumber;
        }
        hash['user_type'] = "customer";
        hash['is_active'] = true
        dataset.push(hash)
        return dataset;
    }

    const gaustDataSave = () => {
        let promise = new Promise((resolve, reject) => {
            const { loaderStateTrue, loaderStateFalse } = props;
            let data = formatingDataset();
            loaderStateTrue();
            guestCreate(data).then((response) => {
                if (response.length > 0) {
                    response.map((dataObj) => {
                        if (dataObj.success) {
                            resolve(dataObj)
                        } else {
                            Utility.toastNotifications(dataObj.message, "Error", "error")
                            loaderStateFalse();
                        }
                    })
                }
                loaderStateFalse();
            }).catch((error) => {
                loaderStateFalse();
            });
        })
        return promise;
    }

    const getGuestDetails = () => {
        let valid = validGuestDetailsFn();
        if (valid) {
            gaustDataSave().then((response) => {
                if (response.hasOwnProperty('success') && response.success) {
                    props.callBackToHotelDetails(guestDetails);
                }
            })

        }
    }


    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension relativeSection">
                    <div className="col-md-12 viewForMobile">
                        <div className='paymentDetailsSectionBoxScrollBox'>
                            <div className='customerSecBox'>
                                <div className='paymentCustomerHotelPart relativeRow' id='page-bottom'>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="First name"
                                                    errorLabel={guestDetailsError.first_name}
                                                    name="first_name"
                                                    type="text"
                                                    value={guestDetails.first_name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'first_name')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Last name"
                                                    errorLabel={guestDetailsError.last_name}
                                                    name="last_name"
                                                    type="text"
                                                    value={guestDetails.last_name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'last_name')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <PhoneNumberWithCountryCode
                                                    title="Phone Number"
                                                    country={selectedCountryCode}
                                                    value={guestDetails.phone_number}
                                                    onChange={(e) => handelChangeGuest(e, 'phone_number')}
                                                    error={guestDetailsError.phone_number}
                                                    labelShow={true}
                                                    requirStart={true}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Email"
                                                    errorLabel={guestDetailsError.customer_email}
                                                    name="customer_email"
                                                    type="text"
                                                    value={guestDetails.customer_email}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'customer_email')}
                                                    requiredStar={true}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="GSTIN Number"
                                                    errorLabel={guestDetailsError.customer_gstin_no}
                                                    name="customer_gstin_no"
                                                    type="text"
                                                    value={guestDetails.customer_gstin_no}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'customer_gstin_no')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="col-md-6">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Company Name"
                                                    errorLabel={guestDetailsError.company_name}
                                                    name="company_name"
                                                    type="text"
                                                    value={guestDetails.company_name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'company_name')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-12">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Company Address"
                                                    errorLabel={guestDetailsError.company_address}
                                                    name="company_address"
                                                    type="textarea"
                                                    value={guestDetails.company_address}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeGuest(e, 'company_address')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row footerSecForPaymentModal">
                    <div className="col-md-12 text-center topspace">
                        <button type="button" className="modbtn" onClick={() => getGuestDetails()}>Proceed to Checkout</button>
                    </div>
                </div>
            </div>

        </div>
    );
}


GuestCheckOutModalContent.defaultProps = {
    customerSelection: false,
    customerData: {},
    totalPaidAmount: '0',
    childrenAges: [],
    hotelRoomBookDataSet: [],
    callFrom: "",
    formData: {},
    payableNoteList: [],
    isIgstApplicable: false,
    customerIdProp: '',
    createBookingFlag: true
}
const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,
        selected_branch: globalState.mainReducerData.selected_branch,
        priceDistributionRequestData: globalState.mainReducerData.priceDistributionRequestData,
        priceDistributionResponseData: globalState.mainReducerData.priceDistributionResponseData,
        userSearchDataFromReducer: globalState.mainReducerData.userSearchData,
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, userSearchData, selectChooseBranch, priceDistributionRequestData, priceDistributionResponseData })(GuestCheckOutModalContent);