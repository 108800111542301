import React, { useState } from 'react';
import { logOutApp } from '../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../Utility/Public/css/common.scss';
import '../../Utility/Public/css/commonresponsive.scss';


function ComingSoonPage(props) {
    return (
        <div className='page-section'>
            <div className='header-inner'>
                <div className='bottom-header'>
                    <div className='container'>
                        <div className='websiteLogo'>
                            <img src={require('../Public/images/stayingLogoWhite.png')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='innerContainer'>
                    <h1 className='coming-soon-label'>Coming Soon</h1>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.mainReducerData.userCredentials,
        token: globalState.LoginReducer.token,
    };
}

export default connect(mapStateToProps, { logOutApp })(ComingSoonPage);