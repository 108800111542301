import React, { Component, useState, useEffect } from 'react';
import '../Public/css/footer.scss';
import '../Public/css/footerresponsivedoc.scss';
import { Container, Modal, Button } from 'react-bootstrap';
import CustomInput from './CustomInput'
import Utility from '../Utility';
import '../../Utility/Public/css/common.scss';

function PartialPaymentModal(props) {

    const [bookingIdFromParam, setBookingIdFromParam] = useState("")
    const [paymentPageShowHide, setPaymentPageShowHide] = useState(false)
    const [paymentAmountShowHide, setPaymentAmountShowHide] = useState(true)
    const [userDetailsData, setUserDetailsData] = useState({})

    useEffect(() => {
        setUserDetailsData({
            currency: props.bookingDetails.currency,
            amount: props.bookingDetails.due_amount
        });
    }, [])


    const openPaymentModal = () => {
        if (userDetailsData.amount > 0) {
            setPaymentPageShowHide(true);
            setPaymentAmountShowHide(false);
        } else {
            Utility.toastNotifications("Please enter amount", "Error", "error")
        }
    }

    const handelChange = (e) => {
        var expr = /^[0-9]*\.?[0-9]{0,2}$/;
        if (!expr.test(e.target.value)) {
        } else {
            setUserDetailsData({ ...userDetailsData, amount: e.target.value })
        }
    }

    useEffect(() => {
    }, [userDetailsData])

    return (
        <div className='row'>
            {
                paymentAmountShowHide ?
                    <div className='col-md-12 payableAmountSection'>
                        <div className='amountBox'>
                            <label className='label'>Total Amount</label>
                            <div className='label-value'>{props.bookingDetails.total_amount}</div>
                        </div>
                        <div className='amountBox'>
                            <label className='label'>Due Amount</label>
                            <div className='label-value'>{props.bookingDetails.due_amount}</div>
                        </div>
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="Enter Payable Amount"
                            name="amount"
                            type="text"
                            value={userDetailsData.amount}
                            labelPresent={true}
                            onChange={(e) => handelChange(e)}
                        />
                        <div className='next-btn-inner'>
                            <Button variant="primary" className="next-btn" onClick={(e) => openPaymentModal()}>Next</Button>
                        </div>
                    </div>
                    : ""
            }
        </div>
    );
}

export default (PartialPaymentModal);