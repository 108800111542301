import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import Auth from "../../../../Layout/Auth";
import AppLandingPage from "../../../../GlobalModules/Login/Pages/AppLandingPage";
const HomePage = lazy(() => import('../Pages/HomePage'))
import SearchPage from "../Pages/SearchPage";
import NotFound from "../../../../Utility/Pages/NotFound";
import HotelDetails from "../Pages/HotelDetails";
import ComingSoonPage from "../../../../Utility/Pages/ComingSoonPage";

function HomeRoute({ isLoggedIn, ...routeProps }) {
    const history = useHistory();
    return (
        <Switch>
            {/* applanding page route */}
            <Route
                exact
                path="/"
                render={() => (
                    <Auth history={history}>
                        <AppLandingPage history={history} />
                    </Auth>
                )}
            />
            {/* home page route */}
            <Route
                exact
                path="/:lng/home"
                render={() => (
                    <Auth history={history} allowedRoles={[]}>
                        <Suspense>
                            <HomePage isLoggedIn={isLoggedIn} history={routeProps.history} />
                        </Suspense>

                    </Auth>
                )}
            />
            {/* booking search page route */}
            <Route
                exact
                path="/:lng/search/:location?/:type?"
                render={() => (
                    <Auth history={history} allowedRoles={[]}>
                        <Suspense>
                            <SearchPage isLoggedIn={isLoggedIn} history={routeProps.history} />
                        </Suspense>

                    </Auth>
                )}
            />
            {/* not found page route */}
            <Route
                exact
                path="/:lng/notfound"
                render={() => (
                    <Auth history={history} allowedRoles={[]}>
                        <Suspense>
                            <NotFound history={history} />
                        </Suspense>

                    </Auth>
                )}
            />
            {/* hotel details page route */}
            <Route
                exact
                path="/:lng/hotel_details"
                render={() => (
                    <Auth history={routeProps.history} allowedRoles={['customer']}>
                        <Suspense>
                            <HotelDetails isLoggedIn={isLoggedIn} history={routeProps.history} />
                        </Suspense>

                    </Auth>
                )}
            />
            {/* coming soon page route */}
            <Route
                exact
                path="/:lng/comingsoon"
                render={() => (
                    <Auth history={history} allowedRoles={[]}>
                        <Suspense>
                            <ComingSoonPage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

        </Switch>
    );
}

export default HomeRoute;
